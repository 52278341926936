import { Tabs } from "antd";
import styled from "@emotion/styled";

export const TabComponent = styled(Tabs)`
  .ant-tabs-nav {
    &::before {
      border-bottom: 0px;
    }
    .ant-tabs-ink-bar {
      height: 0;
    }
    .ant-tabs-ink-bar::after {
      position: absolute;
      bottom: 0%;
      left: 35%;
      content: "";
      height: 0;
      width: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 15px solid #30413c;
    }
    .ant-tabs-nav-more {
      color: white;
    }
    .ant-space-item {
      .ant-typography {
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
`;
