import React from "react";
import { MonstaTagText } from "./style";

type TagProps = {
  text: string;
  color?: string;
  size?: string;
};
const MonstaTag: React.FC<TagProps> = ({
  text,
  color = "--white",
  size = "lg",
}) => {
  return (
    <MonstaTagText color={color} size={size === "lg" ? "0.8rem" : "0.6rem"}>
      {text}
    </MonstaTagText>
  );
};

export default MonstaTag;
