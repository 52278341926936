import primeIcon from "../../assets/images/marketplace_card/icon_prime.png";
import terminatorIcon from "../../assets/images/marketplace_card/icon_terminator.png";
import alchemistIcon from "../../assets/images/marketplace_card/icon_alchemist.png";
import golemIcon from "../../assets/images/marketplace_card/icon_golem.png";
import nightingaleIcon from "../../assets/images/marketplace_card/icon_nightingale.png";
import prophetIcon from "../../assets/images/marketplace_card/icon_prophet.png";
import grognakIcon from "../../assets/images/marketplace_card/icon_grognak.png";
import juggernautIcon from "../../assets/images/marketplace_card/icon_juggernaut.png";
import bomohIcon from "../../assets/images/marketplace_card/icon_bomoh.png";
import inceptionIcon from "../../assets/images/inception-icon.svg";
import chronoZeroIcon from "../../assets/images/chrono-zero-icon.svg";
import soulbondIcon from "../../assets/images/soulbond-icon.svg";
import normalIcon from "../../assets/images/normal-icon.svg";

import golemBg from "../../assets/images/marketplace_card/bg_golem.png";
import prophetBg from "../../assets/images/marketplace_card/bg_prophet.png";
import alchemistBg from "../../assets/images/marketplace_card/bg_alchemist.png";
import juggernautBg from "../../assets/images/marketplace_card/bg_juggernaut.png";
import nightingaleBg from "../../assets/images/marketplace_card/bg_nightingale.png";
import primeBg from "../../assets/images/marketplace_card/bg_prime.png";
import bomohBg from "../../assets/images/marketplace_card/bg_bomoh.png";
import terminatorBg from "../../assets/images/marketplace_card/bg_terminator.png";
import grognakBg from "../../assets/images/marketplace_card/bg_grognak.png";

import { MonstaClassDisplayValues, MonstaTypeDisplayValues } from "./types";

export const MONSTA_CLASS_DISPLAY_VALUES: MonstaClassDisplayValues = {
  PRIME: {
    image: primeIcon,
    title: "PRIME",
    background: primeBg,
  },
  TERMINATOR: {
    image: terminatorIcon,
    title: "TERMINATOR",
    background: terminatorBg,
  },
  ALCHEMIST: {
    image: alchemistIcon,
    title: "ALCHEMIST",
    background: alchemistBg,
  },
  GOLEM: {
    image: golemIcon,
    title: "GOLEM",
    background: golemBg,
  },
  NIGHTINGALE: {
    image: nightingaleIcon,
    title: "NIGHTINGALE",
    background: nightingaleBg,
  },
  PROPHET: {
    image: prophetIcon,
    title: "PROPHET",
    background: prophetBg,
  },
  GROGNAK: {
    image: grognakIcon,
    title: "GROGNAK",
    background: grognakBg,
  },
  JUGGERNAUT: {
    image: juggernautIcon,
    title: "JUGGERNAUT",
    background: juggernautBg,
  },
  BOMOH: {
    image: bomohIcon,
    title: "BOMOH",
    background: bomohBg,
  },
};

export const MONSTA_TYPE_DISPLAY_VALUES: MonstaTypeDisplayValues = {
  INCEPTION: {
    image: inceptionIcon,
    title: "INCEPTION",
  },
  "CHRONO-ZERO": {
    image: chronoZeroIcon,
    title: "CHRONO ZERO",
  },
  SOULBOND: {
    image: soulbondIcon,
    title: "SOULBOND",
  },
  NORMAL: {
    image: normalIcon,
    title: "NORMAL",
  },
};
