import { useEffect, useState } from "react";
import { ViewWrapper, MainCard } from "./style";
import MainCardHeader from "./components/MainCardHeader";
import TapwarScreen from "./TapwarScreen";
import AllowanceScreen from "./AllowanceScreen";
import wrapperBg from "../../assets/images/tapwar-bg.svg";
import TapshopScreen from "./TapshopScreen";

import useTapwarAuth from "../../hooks/useTapwarAuth";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";

export enum Screen {
  tapwar = "tapwar",
  allowance = "allowance",
  tapshop = "tapshop",
}

const TapwarView = () => {
  const { account } = useActiveWeb3React();
  const [prevAccount, setPrevAccount] = useState(account);
  const [screen, setScreen] = useState<Screen>(Screen.tapwar);
  const { isAuth, setIsAuth, handleLogin, handleLogout, loginLoading } =
    useTapwarAuth();

  const handleChangeScreen = (screen: Screen) => {
    setScreen(screen);
  };

  useEffect(() => {
    account && prevAccount && handleLogout();
    setPrevAccount(account);
  }, [account]);

  return (
    <ViewWrapper bg={wrapperBg}>
      <MainCard bordered={false}>
        <MainCardHeader
          title={screen}
          isAuth={isAuth}
          handleLogin={handleLogin}
          handleLogout={handleLogout}
          loginLoading={loginLoading}
          containsRefreshButton={screen === Screen.allowance}
        />
        {screen === Screen.tapwar && (
          <TapwarScreen
            isAuth={isAuth}
            setIsAuth={setIsAuth}
            onChangeScreen={handleChangeScreen}
          />
        )}
        {screen === Screen.allowance && (
          <AllowanceScreen onChangeScreen={handleChangeScreen} />
        )}
        {screen === Screen.tapshop && (
          <TapshopScreen
            isAuth={isAuth}
            setIsAuth={setIsAuth}
            onChangeScreen={handleChangeScreen}
          />
        )}
      </MainCard>
    </ViewWrapper>
  );
};

export default TapwarView;
