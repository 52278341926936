import React from "react";
import styled from "@emotion/styled";

export const DisplayWrapper = styled.div`
  width: 100%;
  height: 400px;
  position: relative;

  /* MOBILE */
  @media (max-width: 768px) {
    height: auto;
    margin-bottom: 2rem;
  }
`;

export const VerticalDottedLine = styled.div`
  width: 1px;
  height: 200px;
  border-left: 3px dashed white;
  border-bottom: none;
  position: absolute;
  left: 50%;
  bottom: 0%;

  /* MOBILE */
  @media (max-width: 768px) {
    visibility: hidden;
  }
`;

export const CloneDisplayWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  /* MOBILE */
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

type MonstaDisplayWrapperProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};
export const MonstaDisplayWrapper = styled.div<MonstaDisplayWrapperProps>`
  width: 200px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: ${(props) => (props.onClick ? "pointer" : "auto")};
  transition-duration: 300ms;

  &:hover {
    background: ${(props) =>
      props.onClick ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0)"};
  }
`;

export const MonstaDisplayImage = styled.img`
  width: 100%;
  margin-bottom: 0.5rem;
`;

type MonstaDisplayCloneUsageContainerProps = {
  isDark?: boolean;
};
export const MonstaDisplayCloneUsageContainer = styled.div<MonstaDisplayCloneUsageContainerProps>`
  width: 80%;
  border-radius: 6px;
  text-align: center;
  color: white;
  padding: 5px 0px;
  background: ${(props) => (props.isDark ? "black" : "#b36cdb")};
  font-size: 12px;

  p {
    margin: 0;
  }
`;

export const MonstaDisplayIdContainer = styled.div`
  width: 80%;
  border-radius: 6px;
  border: solid 1px white;
  text-align: center;
  color: white;
  padding: 5px 0px;

  p {
    margin: 0;
  }
`;

type SelectTheMonstaContainerProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};
export const SelectTheMonstaContainer = styled.div<SelectTheMonstaContainerProps>`
  width: 200px;
  height: 200px;
  border: dashed 2px white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: 300ms;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  p {
    margin-bottom: 0;
  }
`;

export const DottedLine = styled.div`
  width: 100px;
  height: 1px;
  border-bottom: 3px dashed white;
  border-left: none;

  /* MOBILE */
  @media (max-width: 768px) {
    width: 1px;
    height: 100px;
    border-bottom: none;
    border-left: 3px dashed white;
  }
`;

export const CostDisplayWrapper = styled.div`
  width: 180px;
  height: 180px;
  position: relative;
`;

export const CostDisplayCircle = styled.div`
  width: 100%;
  padding-bottom: 100%;
  background: #e6fbf2;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const CostDisplayPriceContainer = styled.div`
  width: 100%;
  height: 30px;
  background: #00d578;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CostDisplayXsttIcon = styled.img`
  width: 35%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
`;

export const CostDisplayPriceText = styled.p`
  margin: 0;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-btn[disabled] {
    background: rgba(254, 230, 21, 0.5);
  }
`;
