import styled from "@emotion/styled";
import { Form, Button } from "antd";

export const TradeForm = styled(Form)`
  height: 80%;
  margin-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .ant-form-item-label {
    align-self: center;
  }

  label {
    color: #fff;
  }

  .ant-input {
    background: #0d4134;
    border-radius: 30px;
    border: none;
    height: auto;
    padding: 10px 20px;
    color: white;
    caret-color: white;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: #fee615;
    padding-left: 15px;
    padding-bottom: 1rem;
  }
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background: #0d4134;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px #0d4134 inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const TransferButtonWrapper = styled(Form.Item)`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    background: #0d4134;
  }
  @media (min-width: 768px) {
    margin-left: 25px;
  }
`;

export const TransferFormWrapper = styled.div`
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
`;

export const TransferButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  color: #fee615;
  background: #5f907a;
  border: none;
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.5rem;
  box-shadow: 1px 1px 2px #0d4134;
  cursor: pointer;
  white-space: nowrap;
  padding: 1.5rem 1.7rem;
  @media (min-width: 992px) {
    font-size: 1.5rem;
    padding: 1.5rem 2rem;
    width: 100%;
  }
  @media (min-width: 768px) {
    font-size: 1.5rem;
    padding: 1.5rem 1.5rem;
  }
  @media (min-width: 414px) {
    margin: 0 auto;
  }
  img {
    width: 1.5rem;
    margin-right: 1rem;
  }
  &:hover {
    background: #0d4134;
    color: #fee615;
  }
  &:active {
    background: white;
  }
  &:focus {
    background: #0d4134;
    color: #fee615;
  }
`;
