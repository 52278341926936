import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
};

export type Account = {
  __typename?: "Account";
  ERC20approvalsOwner: Array<Erc20Approval>;
  ERC20approvalsSpender: Array<Erc20Approval>;
  ERC20balances: Array<Erc20Balance>;
  ERC20transferFromEvent: Array<Erc20Transfer>;
  ERC20transferToEvent: Array<Erc20Transfer>;
  ERC721operatorOperator: Array<Erc721Operator>;
  ERC721operatorOwner: Array<Erc721Operator>;
  ERC721tokens: Array<Erc721Token>;
  ERC721transferFromEvent: Array<Erc721Transfer>;
  ERC721transferToEvent: Array<Erc721Transfer>;
  asERC20?: Maybe<Erc20Contract>;
  asERC721?: Maybe<Erc721Contract>;
  events: Array<Event>;
  id: Scalars["ID"];
};

export type AccountErc20approvalsOwnerArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Approval_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Approval_Filter>;
};

export type AccountErc20approvalsSpenderArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Approval_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Approval_Filter>;
};

export type AccountErc20balancesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Balance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Balance_Filter>;
};

export type AccountErc20transferFromEventArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Transfer_Filter>;
};

export type AccountErc20transferToEventArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Transfer_Filter>;
};

export type AccountErc721operatorOperatorArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Operator_Filter>;
};

export type AccountErc721operatorOwnerArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Operator_Filter>;
};

export type AccountErc721tokensArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Token_Filter>;
};

export type AccountErc721transferFromEventArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type AccountErc721transferToEventArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type AccountEventsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Event_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Event_Filter>;
};

export type Account_Filter = {
  asERC20?: InputMaybe<Scalars["String"]>;
  asERC20_contains?: InputMaybe<Scalars["String"]>;
  asERC20_ends_with?: InputMaybe<Scalars["String"]>;
  asERC20_gt?: InputMaybe<Scalars["String"]>;
  asERC20_gte?: InputMaybe<Scalars["String"]>;
  asERC20_in?: InputMaybe<Array<Scalars["String"]>>;
  asERC20_lt?: InputMaybe<Scalars["String"]>;
  asERC20_lte?: InputMaybe<Scalars["String"]>;
  asERC20_not?: InputMaybe<Scalars["String"]>;
  asERC20_not_contains?: InputMaybe<Scalars["String"]>;
  asERC20_not_ends_with?: InputMaybe<Scalars["String"]>;
  asERC20_not_in?: InputMaybe<Array<Scalars["String"]>>;
  asERC20_not_starts_with?: InputMaybe<Scalars["String"]>;
  asERC20_starts_with?: InputMaybe<Scalars["String"]>;
  asERC721?: InputMaybe<Scalars["String"]>;
  asERC721_contains?: InputMaybe<Scalars["String"]>;
  asERC721_ends_with?: InputMaybe<Scalars["String"]>;
  asERC721_gt?: InputMaybe<Scalars["String"]>;
  asERC721_gte?: InputMaybe<Scalars["String"]>;
  asERC721_in?: InputMaybe<Array<Scalars["String"]>>;
  asERC721_lt?: InputMaybe<Scalars["String"]>;
  asERC721_lte?: InputMaybe<Scalars["String"]>;
  asERC721_not?: InputMaybe<Scalars["String"]>;
  asERC721_not_contains?: InputMaybe<Scalars["String"]>;
  asERC721_not_ends_with?: InputMaybe<Scalars["String"]>;
  asERC721_not_in?: InputMaybe<Array<Scalars["String"]>>;
  asERC721_not_starts_with?: InputMaybe<Scalars["String"]>;
  asERC721_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
};

export enum Account_OrderBy {
  Erc20approvalsOwner = "ERC20approvalsOwner",
  Erc20approvalsSpender = "ERC20approvalsSpender",
  Erc20balances = "ERC20balances",
  Erc20transferFromEvent = "ERC20transferFromEvent",
  Erc20transferToEvent = "ERC20transferToEvent",
  Erc721operatorOperator = "ERC721operatorOperator",
  Erc721operatorOwner = "ERC721operatorOwner",
  Erc721tokens = "ERC721tokens",
  Erc721transferFromEvent = "ERC721transferFromEvent",
  Erc721transferToEvent = "ERC721transferToEvent",
  AsErc20 = "asERC20",
  AsErc721 = "asERC721",
  Events = "events",
  Id = "id",
}

export type AuctionCancel = MonstaEvent & {
  __typename?: "AuctionCancel";
  id: Scalars["ID"];
  timestamp: Scalars["BigInt"];
  token: Monsta;
  type: MonstaEventType;
};

export type AuctionCancel_Filter = {
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  token?: InputMaybe<Scalars["String"]>;
  token_contains?: InputMaybe<Scalars["String"]>;
  token_ends_with?: InputMaybe<Scalars["String"]>;
  token_gt?: InputMaybe<Scalars["String"]>;
  token_gte?: InputMaybe<Scalars["String"]>;
  token_in?: InputMaybe<Array<Scalars["String"]>>;
  token_lt?: InputMaybe<Scalars["String"]>;
  token_lte?: InputMaybe<Scalars["String"]>;
  token_not?: InputMaybe<Scalars["String"]>;
  token_not_contains?: InputMaybe<Scalars["String"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]>;
  token_starts_with?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<MonstaEventType>;
  type_in?: InputMaybe<Array<MonstaEventType>>;
  type_not?: InputMaybe<MonstaEventType>;
  type_not_in?: InputMaybe<Array<MonstaEventType>>;
};

export enum AuctionCancel_OrderBy {
  Id = "id",
  Timestamp = "timestamp",
  Token = "token",
  Type = "type",
}

export type AuctionCreate = MonstaEvent & {
  __typename?: "AuctionCreate";
  duration: Scalars["BigInt"];
  endingPrice: Scalars["BigInt"];
  id: Scalars["ID"];
  seller: Scalars["Bytes"];
  startingPrice: Scalars["BigInt"];
  timestamp: Scalars["BigInt"];
  token: Monsta;
  type: MonstaEventType;
};

export type AuctionCreate_Filter = {
  duration?: InputMaybe<Scalars["BigInt"]>;
  duration_gt?: InputMaybe<Scalars["BigInt"]>;
  duration_gte?: InputMaybe<Scalars["BigInt"]>;
  duration_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  duration_lt?: InputMaybe<Scalars["BigInt"]>;
  duration_lte?: InputMaybe<Scalars["BigInt"]>;
  duration_not?: InputMaybe<Scalars["BigInt"]>;
  duration_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  endingPrice?: InputMaybe<Scalars["BigInt"]>;
  endingPrice_gt?: InputMaybe<Scalars["BigInt"]>;
  endingPrice_gte?: InputMaybe<Scalars["BigInt"]>;
  endingPrice_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  endingPrice_lt?: InputMaybe<Scalars["BigInt"]>;
  endingPrice_lte?: InputMaybe<Scalars["BigInt"]>;
  endingPrice_not?: InputMaybe<Scalars["BigInt"]>;
  endingPrice_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  seller?: InputMaybe<Scalars["Bytes"]>;
  seller_contains?: InputMaybe<Scalars["Bytes"]>;
  seller_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  seller_not?: InputMaybe<Scalars["Bytes"]>;
  seller_not_contains?: InputMaybe<Scalars["Bytes"]>;
  seller_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  startingPrice?: InputMaybe<Scalars["BigInt"]>;
  startingPrice_gt?: InputMaybe<Scalars["BigInt"]>;
  startingPrice_gte?: InputMaybe<Scalars["BigInt"]>;
  startingPrice_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  startingPrice_lt?: InputMaybe<Scalars["BigInt"]>;
  startingPrice_lte?: InputMaybe<Scalars["BigInt"]>;
  startingPrice_not?: InputMaybe<Scalars["BigInt"]>;
  startingPrice_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  token?: InputMaybe<Scalars["String"]>;
  token_contains?: InputMaybe<Scalars["String"]>;
  token_ends_with?: InputMaybe<Scalars["String"]>;
  token_gt?: InputMaybe<Scalars["String"]>;
  token_gte?: InputMaybe<Scalars["String"]>;
  token_in?: InputMaybe<Array<Scalars["String"]>>;
  token_lt?: InputMaybe<Scalars["String"]>;
  token_lte?: InputMaybe<Scalars["String"]>;
  token_not?: InputMaybe<Scalars["String"]>;
  token_not_contains?: InputMaybe<Scalars["String"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]>;
  token_starts_with?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<MonstaEventType>;
  type_in?: InputMaybe<Array<MonstaEventType>>;
  type_not?: InputMaybe<MonstaEventType>;
  type_not_in?: InputMaybe<Array<MonstaEventType>>;
};

export enum AuctionCreate_OrderBy {
  Duration = "duration",
  EndingPrice = "endingPrice",
  Id = "id",
  Seller = "seller",
  StartingPrice = "startingPrice",
  Timestamp = "timestamp",
  Token = "token",
  Type = "type",
}

export type AuctionSucceed = MonstaEvent & {
  __typename?: "AuctionSucceed";
  id: Scalars["ID"];
  timestamp: Scalars["BigInt"];
  token: Monsta;
  totalPrice: Scalars["BigInt"];
  type: MonstaEventType;
  winner: Scalars["Bytes"];
};

export type AuctionSucceed_Filter = {
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  token?: InputMaybe<Scalars["String"]>;
  token_contains?: InputMaybe<Scalars["String"]>;
  token_ends_with?: InputMaybe<Scalars["String"]>;
  token_gt?: InputMaybe<Scalars["String"]>;
  token_gte?: InputMaybe<Scalars["String"]>;
  token_in?: InputMaybe<Array<Scalars["String"]>>;
  token_lt?: InputMaybe<Scalars["String"]>;
  token_lte?: InputMaybe<Scalars["String"]>;
  token_not?: InputMaybe<Scalars["String"]>;
  token_not_contains?: InputMaybe<Scalars["String"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]>;
  token_starts_with?: InputMaybe<Scalars["String"]>;
  totalPrice?: InputMaybe<Scalars["BigInt"]>;
  totalPrice_gt?: InputMaybe<Scalars["BigInt"]>;
  totalPrice_gte?: InputMaybe<Scalars["BigInt"]>;
  totalPrice_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  totalPrice_lt?: InputMaybe<Scalars["BigInt"]>;
  totalPrice_lte?: InputMaybe<Scalars["BigInt"]>;
  totalPrice_not?: InputMaybe<Scalars["BigInt"]>;
  totalPrice_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  type?: InputMaybe<MonstaEventType>;
  type_in?: InputMaybe<Array<MonstaEventType>>;
  type_not?: InputMaybe<MonstaEventType>;
  type_not_in?: InputMaybe<Array<MonstaEventType>>;
  winner?: InputMaybe<Scalars["Bytes"]>;
  winner_contains?: InputMaybe<Scalars["Bytes"]>;
  winner_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  winner_not?: InputMaybe<Scalars["Bytes"]>;
  winner_not_contains?: InputMaybe<Scalars["Bytes"]>;
  winner_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
};

export enum AuctionSucceed_OrderBy {
  Id = "id",
  Timestamp = "timestamp",
  Token = "token",
  TotalPrice = "totalPrice",
  Type = "type",
  Winner = "winner",
}

export type Block = {
  __typename?: "Block";
  difficulty: Scalars["BigInt"];
  gasLimit: Scalars["BigInt"];
  gasUsed: Scalars["BigInt"];
  hash: Scalars["Bytes"];
  id: Scalars["ID"];
  number: Scalars["BigInt"];
  size?: Maybe<Scalars["BigInt"]>;
  timestamp: Scalars["BigInt"];
};

export type Block_Filter = {
  difficulty?: InputMaybe<Scalars["BigInt"]>;
  difficulty_gt?: InputMaybe<Scalars["BigInt"]>;
  difficulty_gte?: InputMaybe<Scalars["BigInt"]>;
  difficulty_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  difficulty_lt?: InputMaybe<Scalars["BigInt"]>;
  difficulty_lte?: InputMaybe<Scalars["BigInt"]>;
  difficulty_not?: InputMaybe<Scalars["BigInt"]>;
  difficulty_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  gasLimit?: InputMaybe<Scalars["BigInt"]>;
  gasLimit_gt?: InputMaybe<Scalars["BigInt"]>;
  gasLimit_gte?: InputMaybe<Scalars["BigInt"]>;
  gasLimit_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  gasLimit_lt?: InputMaybe<Scalars["BigInt"]>;
  gasLimit_lte?: InputMaybe<Scalars["BigInt"]>;
  gasLimit_not?: InputMaybe<Scalars["BigInt"]>;
  gasLimit_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  gasUsed?: InputMaybe<Scalars["BigInt"]>;
  gasUsed_gt?: InputMaybe<Scalars["BigInt"]>;
  gasUsed_gte?: InputMaybe<Scalars["BigInt"]>;
  gasUsed_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  gasUsed_lt?: InputMaybe<Scalars["BigInt"]>;
  gasUsed_lte?: InputMaybe<Scalars["BigInt"]>;
  gasUsed_not?: InputMaybe<Scalars["BigInt"]>;
  gasUsed_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  hash?: InputMaybe<Scalars["Bytes"]>;
  hash_contains?: InputMaybe<Scalars["Bytes"]>;
  hash_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  hash_not?: InputMaybe<Scalars["Bytes"]>;
  hash_not_contains?: InputMaybe<Scalars["Bytes"]>;
  hash_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  number?: InputMaybe<Scalars["BigInt"]>;
  number_gt?: InputMaybe<Scalars["BigInt"]>;
  number_gte?: InputMaybe<Scalars["BigInt"]>;
  number_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  number_lt?: InputMaybe<Scalars["BigInt"]>;
  number_lte?: InputMaybe<Scalars["BigInt"]>;
  number_not?: InputMaybe<Scalars["BigInt"]>;
  number_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  size?: InputMaybe<Scalars["BigInt"]>;
  size_gt?: InputMaybe<Scalars["BigInt"]>;
  size_gte?: InputMaybe<Scalars["BigInt"]>;
  size_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  size_lt?: InputMaybe<Scalars["BigInt"]>;
  size_lte?: InputMaybe<Scalars["BigInt"]>;
  size_not?: InputMaybe<Scalars["BigInt"]>;
  size_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
};

export type Block_Height = {
  hash?: InputMaybe<Scalars["Bytes"]>;
  number?: InputMaybe<Scalars["Int"]>;
};

export enum Block_OrderBy {
  Difficulty = "difficulty",
  GasLimit = "gasLimit",
  GasUsed = "gasUsed",
  Hash = "hash",
  Id = "id",
  Number = "number",
  Size = "size",
  Timestamp = "timestamp",
}

export type Erc20Approval = {
  __typename?: "ERC20Approval";
  contract: Erc20Contract;
  id: Scalars["ID"];
  owner: Account;
  spender: Account;
  value: Scalars["BigDecimal"];
  valueExact: Scalars["BigInt"];
};

export type Erc20Approval_Filter = {
  contract?: InputMaybe<Scalars["String"]>;
  contract_contains?: InputMaybe<Scalars["String"]>;
  contract_ends_with?: InputMaybe<Scalars["String"]>;
  contract_gt?: InputMaybe<Scalars["String"]>;
  contract_gte?: InputMaybe<Scalars["String"]>;
  contract_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_lt?: InputMaybe<Scalars["String"]>;
  contract_lte?: InputMaybe<Scalars["String"]>;
  contract_not?: InputMaybe<Scalars["String"]>;
  contract_not_contains?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with?: InputMaybe<Scalars["String"]>;
  contract_not_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_not_starts_with?: InputMaybe<Scalars["String"]>;
  contract_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  owner?: InputMaybe<Scalars["String"]>;
  owner_contains?: InputMaybe<Scalars["String"]>;
  owner_ends_with?: InputMaybe<Scalars["String"]>;
  owner_gt?: InputMaybe<Scalars["String"]>;
  owner_gte?: InputMaybe<Scalars["String"]>;
  owner_in?: InputMaybe<Array<Scalars["String"]>>;
  owner_lt?: InputMaybe<Scalars["String"]>;
  owner_lte?: InputMaybe<Scalars["String"]>;
  owner_not?: InputMaybe<Scalars["String"]>;
  owner_not_contains?: InputMaybe<Scalars["String"]>;
  owner_not_ends_with?: InputMaybe<Scalars["String"]>;
  owner_not_in?: InputMaybe<Array<Scalars["String"]>>;
  owner_not_starts_with?: InputMaybe<Scalars["String"]>;
  owner_starts_with?: InputMaybe<Scalars["String"]>;
  spender?: InputMaybe<Scalars["String"]>;
  spender_contains?: InputMaybe<Scalars["String"]>;
  spender_ends_with?: InputMaybe<Scalars["String"]>;
  spender_gt?: InputMaybe<Scalars["String"]>;
  spender_gte?: InputMaybe<Scalars["String"]>;
  spender_in?: InputMaybe<Array<Scalars["String"]>>;
  spender_lt?: InputMaybe<Scalars["String"]>;
  spender_lte?: InputMaybe<Scalars["String"]>;
  spender_not?: InputMaybe<Scalars["String"]>;
  spender_not_contains?: InputMaybe<Scalars["String"]>;
  spender_not_ends_with?: InputMaybe<Scalars["String"]>;
  spender_not_in?: InputMaybe<Array<Scalars["String"]>>;
  spender_not_starts_with?: InputMaybe<Scalars["String"]>;
  spender_starts_with?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["BigDecimal"]>;
  valueExact?: InputMaybe<Scalars["BigInt"]>;
  valueExact_gt?: InputMaybe<Scalars["BigInt"]>;
  valueExact_gte?: InputMaybe<Scalars["BigInt"]>;
  valueExact_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  valueExact_lt?: InputMaybe<Scalars["BigInt"]>;
  valueExact_lte?: InputMaybe<Scalars["BigInt"]>;
  valueExact_not?: InputMaybe<Scalars["BigInt"]>;
  valueExact_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  value_gt?: InputMaybe<Scalars["BigDecimal"]>;
  value_gte?: InputMaybe<Scalars["BigDecimal"]>;
  value_in?: InputMaybe<Array<Scalars["BigDecimal"]>>;
  value_lt?: InputMaybe<Scalars["BigDecimal"]>;
  value_lte?: InputMaybe<Scalars["BigDecimal"]>;
  value_not?: InputMaybe<Scalars["BigDecimal"]>;
  value_not_in?: InputMaybe<Array<Scalars["BigDecimal"]>>;
};

export enum Erc20Approval_OrderBy {
  Contract = "contract",
  Id = "id",
  Owner = "owner",
  Spender = "spender",
  Value = "value",
  ValueExact = "valueExact",
}

export type Erc20Balance = {
  __typename?: "ERC20Balance";
  account?: Maybe<Account>;
  contract: Erc20Contract;
  id: Scalars["ID"];
  transferFromEvent: Array<Erc20Transfer>;
  transferToEvent: Array<Erc20Transfer>;
  value: Scalars["BigDecimal"];
  valueExact: Scalars["BigInt"];
};

export type Erc20BalanceTransferFromEventArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Transfer_Filter>;
};

export type Erc20BalanceTransferToEventArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Transfer_Filter>;
};

export type Erc20Balance_Filter = {
  account?: InputMaybe<Scalars["String"]>;
  account_contains?: InputMaybe<Scalars["String"]>;
  account_ends_with?: InputMaybe<Scalars["String"]>;
  account_gt?: InputMaybe<Scalars["String"]>;
  account_gte?: InputMaybe<Scalars["String"]>;
  account_in?: InputMaybe<Array<Scalars["String"]>>;
  account_lt?: InputMaybe<Scalars["String"]>;
  account_lte?: InputMaybe<Scalars["String"]>;
  account_not?: InputMaybe<Scalars["String"]>;
  account_not_contains?: InputMaybe<Scalars["String"]>;
  account_not_ends_with?: InputMaybe<Scalars["String"]>;
  account_not_in?: InputMaybe<Array<Scalars["String"]>>;
  account_not_starts_with?: InputMaybe<Scalars["String"]>;
  account_starts_with?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<Scalars["String"]>;
  contract_contains?: InputMaybe<Scalars["String"]>;
  contract_ends_with?: InputMaybe<Scalars["String"]>;
  contract_gt?: InputMaybe<Scalars["String"]>;
  contract_gte?: InputMaybe<Scalars["String"]>;
  contract_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_lt?: InputMaybe<Scalars["String"]>;
  contract_lte?: InputMaybe<Scalars["String"]>;
  contract_not?: InputMaybe<Scalars["String"]>;
  contract_not_contains?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with?: InputMaybe<Scalars["String"]>;
  contract_not_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_not_starts_with?: InputMaybe<Scalars["String"]>;
  contract_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  value?: InputMaybe<Scalars["BigDecimal"]>;
  valueExact?: InputMaybe<Scalars["BigInt"]>;
  valueExact_gt?: InputMaybe<Scalars["BigInt"]>;
  valueExact_gte?: InputMaybe<Scalars["BigInt"]>;
  valueExact_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  valueExact_lt?: InputMaybe<Scalars["BigInt"]>;
  valueExact_lte?: InputMaybe<Scalars["BigInt"]>;
  valueExact_not?: InputMaybe<Scalars["BigInt"]>;
  valueExact_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  value_gt?: InputMaybe<Scalars["BigDecimal"]>;
  value_gte?: InputMaybe<Scalars["BigDecimal"]>;
  value_in?: InputMaybe<Array<Scalars["BigDecimal"]>>;
  value_lt?: InputMaybe<Scalars["BigDecimal"]>;
  value_lte?: InputMaybe<Scalars["BigDecimal"]>;
  value_not?: InputMaybe<Scalars["BigDecimal"]>;
  value_not_in?: InputMaybe<Array<Scalars["BigDecimal"]>>;
};

export enum Erc20Balance_OrderBy {
  Account = "account",
  Contract = "contract",
  Id = "id",
  TransferFromEvent = "transferFromEvent",
  TransferToEvent = "transferToEvent",
  Value = "value",
  ValueExact = "valueExact",
}

export type Erc20Contract = {
  __typename?: "ERC20Contract";
  approvals: Array<Erc20Approval>;
  asAccount: Account;
  balances: Array<Erc20Balance>;
  decimals: Scalars["Int"];
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  symbol?: Maybe<Scalars["String"]>;
  totalSupply: Erc20Balance;
  transfers: Array<Erc20Transfer>;
};

export type Erc20ContractApprovalsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Approval_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Approval_Filter>;
};

export type Erc20ContractBalancesArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Balance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Balance_Filter>;
};

export type Erc20ContractTransfersArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Transfer_Filter>;
};

export type Erc20Contract_Filter = {
  asAccount?: InputMaybe<Scalars["String"]>;
  asAccount_contains?: InputMaybe<Scalars["String"]>;
  asAccount_ends_with?: InputMaybe<Scalars["String"]>;
  asAccount_gt?: InputMaybe<Scalars["String"]>;
  asAccount_gte?: InputMaybe<Scalars["String"]>;
  asAccount_in?: InputMaybe<Array<Scalars["String"]>>;
  asAccount_lt?: InputMaybe<Scalars["String"]>;
  asAccount_lte?: InputMaybe<Scalars["String"]>;
  asAccount_not?: InputMaybe<Scalars["String"]>;
  asAccount_not_contains?: InputMaybe<Scalars["String"]>;
  asAccount_not_ends_with?: InputMaybe<Scalars["String"]>;
  asAccount_not_in?: InputMaybe<Array<Scalars["String"]>>;
  asAccount_not_starts_with?: InputMaybe<Scalars["String"]>;
  asAccount_starts_with?: InputMaybe<Scalars["String"]>;
  decimals?: InputMaybe<Scalars["Int"]>;
  decimals_gt?: InputMaybe<Scalars["Int"]>;
  decimals_gte?: InputMaybe<Scalars["Int"]>;
  decimals_in?: InputMaybe<Array<Scalars["Int"]>>;
  decimals_lt?: InputMaybe<Scalars["Int"]>;
  decimals_lte?: InputMaybe<Scalars["Int"]>;
  decimals_not?: InputMaybe<Scalars["Int"]>;
  decimals_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_ends_with?: InputMaybe<Scalars["String"]>;
  name_gt?: InputMaybe<Scalars["String"]>;
  name_gte?: InputMaybe<Scalars["String"]>;
  name_in?: InputMaybe<Array<Scalars["String"]>>;
  name_lt?: InputMaybe<Scalars["String"]>;
  name_lte?: InputMaybe<Scalars["String"]>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<Scalars["String"]>>;
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  name_starts_with?: InputMaybe<Scalars["String"]>;
  symbol?: InputMaybe<Scalars["String"]>;
  symbol_contains?: InputMaybe<Scalars["String"]>;
  symbol_ends_with?: InputMaybe<Scalars["String"]>;
  symbol_gt?: InputMaybe<Scalars["String"]>;
  symbol_gte?: InputMaybe<Scalars["String"]>;
  symbol_in?: InputMaybe<Array<Scalars["String"]>>;
  symbol_lt?: InputMaybe<Scalars["String"]>;
  symbol_lte?: InputMaybe<Scalars["String"]>;
  symbol_not?: InputMaybe<Scalars["String"]>;
  symbol_not_contains?: InputMaybe<Scalars["String"]>;
  symbol_not_ends_with?: InputMaybe<Scalars["String"]>;
  symbol_not_in?: InputMaybe<Array<Scalars["String"]>>;
  symbol_not_starts_with?: InputMaybe<Scalars["String"]>;
  symbol_starts_with?: InputMaybe<Scalars["String"]>;
  totalSupply?: InputMaybe<Scalars["String"]>;
  totalSupply_contains?: InputMaybe<Scalars["String"]>;
  totalSupply_ends_with?: InputMaybe<Scalars["String"]>;
  totalSupply_gt?: InputMaybe<Scalars["String"]>;
  totalSupply_gte?: InputMaybe<Scalars["String"]>;
  totalSupply_in?: InputMaybe<Array<Scalars["String"]>>;
  totalSupply_lt?: InputMaybe<Scalars["String"]>;
  totalSupply_lte?: InputMaybe<Scalars["String"]>;
  totalSupply_not?: InputMaybe<Scalars["String"]>;
  totalSupply_not_contains?: InputMaybe<Scalars["String"]>;
  totalSupply_not_ends_with?: InputMaybe<Scalars["String"]>;
  totalSupply_not_in?: InputMaybe<Array<Scalars["String"]>>;
  totalSupply_not_starts_with?: InputMaybe<Scalars["String"]>;
  totalSupply_starts_with?: InputMaybe<Scalars["String"]>;
};

export enum Erc20Contract_OrderBy {
  Approvals = "approvals",
  AsAccount = "asAccount",
  Balances = "balances",
  Decimals = "decimals",
  Id = "id",
  Name = "name",
  Symbol = "symbol",
  TotalSupply = "totalSupply",
  Transfers = "transfers",
}

export type Erc20Transfer = Event & {
  __typename?: "ERC20Transfer";
  block: Block;
  contract: Erc20Contract;
  emitter: Account;
  from?: Maybe<Account>;
  fromBalance?: Maybe<Erc20Balance>;
  id: Scalars["ID"];
  timestamp: Scalars["BigInt"];
  to?: Maybe<Account>;
  toBalance?: Maybe<Erc20Balance>;
  transaction: Transaction;
  value: Scalars["BigDecimal"];
  valueExact: Scalars["BigInt"];
};

export type Erc20Transfer_Filter = {
  block?: InputMaybe<Scalars["String"]>;
  block_contains?: InputMaybe<Scalars["String"]>;
  block_ends_with?: InputMaybe<Scalars["String"]>;
  block_gt?: InputMaybe<Scalars["String"]>;
  block_gte?: InputMaybe<Scalars["String"]>;
  block_in?: InputMaybe<Array<Scalars["String"]>>;
  block_lt?: InputMaybe<Scalars["String"]>;
  block_lte?: InputMaybe<Scalars["String"]>;
  block_not?: InputMaybe<Scalars["String"]>;
  block_not_contains?: InputMaybe<Scalars["String"]>;
  block_not_ends_with?: InputMaybe<Scalars["String"]>;
  block_not_in?: InputMaybe<Array<Scalars["String"]>>;
  block_not_starts_with?: InputMaybe<Scalars["String"]>;
  block_starts_with?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<Scalars["String"]>;
  contract_contains?: InputMaybe<Scalars["String"]>;
  contract_ends_with?: InputMaybe<Scalars["String"]>;
  contract_gt?: InputMaybe<Scalars["String"]>;
  contract_gte?: InputMaybe<Scalars["String"]>;
  contract_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_lt?: InputMaybe<Scalars["String"]>;
  contract_lte?: InputMaybe<Scalars["String"]>;
  contract_not?: InputMaybe<Scalars["String"]>;
  contract_not_contains?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with?: InputMaybe<Scalars["String"]>;
  contract_not_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_not_starts_with?: InputMaybe<Scalars["String"]>;
  contract_starts_with?: InputMaybe<Scalars["String"]>;
  emitter?: InputMaybe<Scalars["String"]>;
  emitter_contains?: InputMaybe<Scalars["String"]>;
  emitter_ends_with?: InputMaybe<Scalars["String"]>;
  emitter_gt?: InputMaybe<Scalars["String"]>;
  emitter_gte?: InputMaybe<Scalars["String"]>;
  emitter_in?: InputMaybe<Array<Scalars["String"]>>;
  emitter_lt?: InputMaybe<Scalars["String"]>;
  emitter_lte?: InputMaybe<Scalars["String"]>;
  emitter_not?: InputMaybe<Scalars["String"]>;
  emitter_not_contains?: InputMaybe<Scalars["String"]>;
  emitter_not_ends_with?: InputMaybe<Scalars["String"]>;
  emitter_not_in?: InputMaybe<Array<Scalars["String"]>>;
  emitter_not_starts_with?: InputMaybe<Scalars["String"]>;
  emitter_starts_with?: InputMaybe<Scalars["String"]>;
  from?: InputMaybe<Scalars["String"]>;
  fromBalance?: InputMaybe<Scalars["String"]>;
  fromBalance_contains?: InputMaybe<Scalars["String"]>;
  fromBalance_ends_with?: InputMaybe<Scalars["String"]>;
  fromBalance_gt?: InputMaybe<Scalars["String"]>;
  fromBalance_gte?: InputMaybe<Scalars["String"]>;
  fromBalance_in?: InputMaybe<Array<Scalars["String"]>>;
  fromBalance_lt?: InputMaybe<Scalars["String"]>;
  fromBalance_lte?: InputMaybe<Scalars["String"]>;
  fromBalance_not?: InputMaybe<Scalars["String"]>;
  fromBalance_not_contains?: InputMaybe<Scalars["String"]>;
  fromBalance_not_ends_with?: InputMaybe<Scalars["String"]>;
  fromBalance_not_in?: InputMaybe<Array<Scalars["String"]>>;
  fromBalance_not_starts_with?: InputMaybe<Scalars["String"]>;
  fromBalance_starts_with?: InputMaybe<Scalars["String"]>;
  from_contains?: InputMaybe<Scalars["String"]>;
  from_ends_with?: InputMaybe<Scalars["String"]>;
  from_gt?: InputMaybe<Scalars["String"]>;
  from_gte?: InputMaybe<Scalars["String"]>;
  from_in?: InputMaybe<Array<Scalars["String"]>>;
  from_lt?: InputMaybe<Scalars["String"]>;
  from_lte?: InputMaybe<Scalars["String"]>;
  from_not?: InputMaybe<Scalars["String"]>;
  from_not_contains?: InputMaybe<Scalars["String"]>;
  from_not_ends_with?: InputMaybe<Scalars["String"]>;
  from_not_in?: InputMaybe<Array<Scalars["String"]>>;
  from_not_starts_with?: InputMaybe<Scalars["String"]>;
  from_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  to?: InputMaybe<Scalars["String"]>;
  toBalance?: InputMaybe<Scalars["String"]>;
  toBalance_contains?: InputMaybe<Scalars["String"]>;
  toBalance_ends_with?: InputMaybe<Scalars["String"]>;
  toBalance_gt?: InputMaybe<Scalars["String"]>;
  toBalance_gte?: InputMaybe<Scalars["String"]>;
  toBalance_in?: InputMaybe<Array<Scalars["String"]>>;
  toBalance_lt?: InputMaybe<Scalars["String"]>;
  toBalance_lte?: InputMaybe<Scalars["String"]>;
  toBalance_not?: InputMaybe<Scalars["String"]>;
  toBalance_not_contains?: InputMaybe<Scalars["String"]>;
  toBalance_not_ends_with?: InputMaybe<Scalars["String"]>;
  toBalance_not_in?: InputMaybe<Array<Scalars["String"]>>;
  toBalance_not_starts_with?: InputMaybe<Scalars["String"]>;
  toBalance_starts_with?: InputMaybe<Scalars["String"]>;
  to_contains?: InputMaybe<Scalars["String"]>;
  to_ends_with?: InputMaybe<Scalars["String"]>;
  to_gt?: InputMaybe<Scalars["String"]>;
  to_gte?: InputMaybe<Scalars["String"]>;
  to_in?: InputMaybe<Array<Scalars["String"]>>;
  to_lt?: InputMaybe<Scalars["String"]>;
  to_lte?: InputMaybe<Scalars["String"]>;
  to_not?: InputMaybe<Scalars["String"]>;
  to_not_contains?: InputMaybe<Scalars["String"]>;
  to_not_ends_with?: InputMaybe<Scalars["String"]>;
  to_not_in?: InputMaybe<Array<Scalars["String"]>>;
  to_not_starts_with?: InputMaybe<Scalars["String"]>;
  to_starts_with?: InputMaybe<Scalars["String"]>;
  transaction?: InputMaybe<Scalars["String"]>;
  transaction_contains?: InputMaybe<Scalars["String"]>;
  transaction_ends_with?: InputMaybe<Scalars["String"]>;
  transaction_gt?: InputMaybe<Scalars["String"]>;
  transaction_gte?: InputMaybe<Scalars["String"]>;
  transaction_in?: InputMaybe<Array<Scalars["String"]>>;
  transaction_lt?: InputMaybe<Scalars["String"]>;
  transaction_lte?: InputMaybe<Scalars["String"]>;
  transaction_not?: InputMaybe<Scalars["String"]>;
  transaction_not_contains?: InputMaybe<Scalars["String"]>;
  transaction_not_ends_with?: InputMaybe<Scalars["String"]>;
  transaction_not_in?: InputMaybe<Array<Scalars["String"]>>;
  transaction_not_starts_with?: InputMaybe<Scalars["String"]>;
  transaction_starts_with?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["BigDecimal"]>;
  valueExact?: InputMaybe<Scalars["BigInt"]>;
  valueExact_gt?: InputMaybe<Scalars["BigInt"]>;
  valueExact_gte?: InputMaybe<Scalars["BigInt"]>;
  valueExact_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  valueExact_lt?: InputMaybe<Scalars["BigInt"]>;
  valueExact_lte?: InputMaybe<Scalars["BigInt"]>;
  valueExact_not?: InputMaybe<Scalars["BigInt"]>;
  valueExact_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  value_gt?: InputMaybe<Scalars["BigDecimal"]>;
  value_gte?: InputMaybe<Scalars["BigDecimal"]>;
  value_in?: InputMaybe<Array<Scalars["BigDecimal"]>>;
  value_lt?: InputMaybe<Scalars["BigDecimal"]>;
  value_lte?: InputMaybe<Scalars["BigDecimal"]>;
  value_not?: InputMaybe<Scalars["BigDecimal"]>;
  value_not_in?: InputMaybe<Array<Scalars["BigDecimal"]>>;
};

export enum Erc20Transfer_OrderBy {
  Block = "block",
  Contract = "contract",
  Emitter = "emitter",
  From = "from",
  FromBalance = "fromBalance",
  Id = "id",
  Timestamp = "timestamp",
  To = "to",
  ToBalance = "toBalance",
  Transaction = "transaction",
  Value = "value",
  ValueExact = "valueExact",
}

export type Erc721Contract = {
  __typename?: "ERC721Contract";
  asAccount: Account;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  operators: Array<Erc721Operator>;
  supportsMetadata?: Maybe<Scalars["Boolean"]>;
  symbol?: Maybe<Scalars["String"]>;
  tokens: Array<Erc721Token>;
  transfers: Array<Erc721Transfer>;
};

export type Erc721ContractOperatorsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Operator_Filter>;
};

export type Erc721ContractTokensArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Token_Filter>;
};

export type Erc721ContractTransfersArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type Erc721Contract_Filter = {
  asAccount?: InputMaybe<Scalars["String"]>;
  asAccount_contains?: InputMaybe<Scalars["String"]>;
  asAccount_ends_with?: InputMaybe<Scalars["String"]>;
  asAccount_gt?: InputMaybe<Scalars["String"]>;
  asAccount_gte?: InputMaybe<Scalars["String"]>;
  asAccount_in?: InputMaybe<Array<Scalars["String"]>>;
  asAccount_lt?: InputMaybe<Scalars["String"]>;
  asAccount_lte?: InputMaybe<Scalars["String"]>;
  asAccount_not?: InputMaybe<Scalars["String"]>;
  asAccount_not_contains?: InputMaybe<Scalars["String"]>;
  asAccount_not_ends_with?: InputMaybe<Scalars["String"]>;
  asAccount_not_in?: InputMaybe<Array<Scalars["String"]>>;
  asAccount_not_starts_with?: InputMaybe<Scalars["String"]>;
  asAccount_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  name?: InputMaybe<Scalars["String"]>;
  name_contains?: InputMaybe<Scalars["String"]>;
  name_ends_with?: InputMaybe<Scalars["String"]>;
  name_gt?: InputMaybe<Scalars["String"]>;
  name_gte?: InputMaybe<Scalars["String"]>;
  name_in?: InputMaybe<Array<Scalars["String"]>>;
  name_lt?: InputMaybe<Scalars["String"]>;
  name_lte?: InputMaybe<Scalars["String"]>;
  name_not?: InputMaybe<Scalars["String"]>;
  name_not_contains?: InputMaybe<Scalars["String"]>;
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  name_not_in?: InputMaybe<Array<Scalars["String"]>>;
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  name_starts_with?: InputMaybe<Scalars["String"]>;
  supportsMetadata?: InputMaybe<Scalars["Boolean"]>;
  supportsMetadata_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  supportsMetadata_not?: InputMaybe<Scalars["Boolean"]>;
  supportsMetadata_not_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  symbol?: InputMaybe<Scalars["String"]>;
  symbol_contains?: InputMaybe<Scalars["String"]>;
  symbol_ends_with?: InputMaybe<Scalars["String"]>;
  symbol_gt?: InputMaybe<Scalars["String"]>;
  symbol_gte?: InputMaybe<Scalars["String"]>;
  symbol_in?: InputMaybe<Array<Scalars["String"]>>;
  symbol_lt?: InputMaybe<Scalars["String"]>;
  symbol_lte?: InputMaybe<Scalars["String"]>;
  symbol_not?: InputMaybe<Scalars["String"]>;
  symbol_not_contains?: InputMaybe<Scalars["String"]>;
  symbol_not_ends_with?: InputMaybe<Scalars["String"]>;
  symbol_not_in?: InputMaybe<Array<Scalars["String"]>>;
  symbol_not_starts_with?: InputMaybe<Scalars["String"]>;
  symbol_starts_with?: InputMaybe<Scalars["String"]>;
};

export enum Erc721Contract_OrderBy {
  AsAccount = "asAccount",
  Id = "id",
  Name = "name",
  Operators = "operators",
  SupportsMetadata = "supportsMetadata",
  Symbol = "symbol",
  Tokens = "tokens",
  Transfers = "transfers",
}

export type Erc721Operator = {
  __typename?: "ERC721Operator";
  approved: Scalars["Boolean"];
  contract: Erc721Contract;
  id: Scalars["ID"];
  operator: Account;
  owner: Account;
};

export type Erc721Operator_Filter = {
  approved?: InputMaybe<Scalars["Boolean"]>;
  approved_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  approved_not?: InputMaybe<Scalars["Boolean"]>;
  approved_not_in?: InputMaybe<Array<Scalars["Boolean"]>>;
  contract?: InputMaybe<Scalars["String"]>;
  contract_contains?: InputMaybe<Scalars["String"]>;
  contract_ends_with?: InputMaybe<Scalars["String"]>;
  contract_gt?: InputMaybe<Scalars["String"]>;
  contract_gte?: InputMaybe<Scalars["String"]>;
  contract_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_lt?: InputMaybe<Scalars["String"]>;
  contract_lte?: InputMaybe<Scalars["String"]>;
  contract_not?: InputMaybe<Scalars["String"]>;
  contract_not_contains?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with?: InputMaybe<Scalars["String"]>;
  contract_not_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_not_starts_with?: InputMaybe<Scalars["String"]>;
  contract_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  operator?: InputMaybe<Scalars["String"]>;
  operator_contains?: InputMaybe<Scalars["String"]>;
  operator_ends_with?: InputMaybe<Scalars["String"]>;
  operator_gt?: InputMaybe<Scalars["String"]>;
  operator_gte?: InputMaybe<Scalars["String"]>;
  operator_in?: InputMaybe<Array<Scalars["String"]>>;
  operator_lt?: InputMaybe<Scalars["String"]>;
  operator_lte?: InputMaybe<Scalars["String"]>;
  operator_not?: InputMaybe<Scalars["String"]>;
  operator_not_contains?: InputMaybe<Scalars["String"]>;
  operator_not_ends_with?: InputMaybe<Scalars["String"]>;
  operator_not_in?: InputMaybe<Array<Scalars["String"]>>;
  operator_not_starts_with?: InputMaybe<Scalars["String"]>;
  operator_starts_with?: InputMaybe<Scalars["String"]>;
  owner?: InputMaybe<Scalars["String"]>;
  owner_contains?: InputMaybe<Scalars["String"]>;
  owner_ends_with?: InputMaybe<Scalars["String"]>;
  owner_gt?: InputMaybe<Scalars["String"]>;
  owner_gte?: InputMaybe<Scalars["String"]>;
  owner_in?: InputMaybe<Array<Scalars["String"]>>;
  owner_lt?: InputMaybe<Scalars["String"]>;
  owner_lte?: InputMaybe<Scalars["String"]>;
  owner_not?: InputMaybe<Scalars["String"]>;
  owner_not_contains?: InputMaybe<Scalars["String"]>;
  owner_not_ends_with?: InputMaybe<Scalars["String"]>;
  owner_not_in?: InputMaybe<Array<Scalars["String"]>>;
  owner_not_starts_with?: InputMaybe<Scalars["String"]>;
  owner_starts_with?: InputMaybe<Scalars["String"]>;
};

export enum Erc721Operator_OrderBy {
  Approved = "approved",
  Contract = "contract",
  Id = "id",
  Operator = "operator",
  Owner = "owner",
}

export type Erc721Token = {
  __typename?: "ERC721Token";
  approval: Account;
  contract: Erc721Contract;
  id: Scalars["ID"];
  identifier: Scalars["BigInt"];
  owner: Account;
  transfers: Array<Erc721Transfer>;
  uri?: Maybe<Scalars["String"]>;
};

export type Erc721TokenTransfersArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type Erc721Token_Filter = {
  approval?: InputMaybe<Scalars["String"]>;
  approval_contains?: InputMaybe<Scalars["String"]>;
  approval_ends_with?: InputMaybe<Scalars["String"]>;
  approval_gt?: InputMaybe<Scalars["String"]>;
  approval_gte?: InputMaybe<Scalars["String"]>;
  approval_in?: InputMaybe<Array<Scalars["String"]>>;
  approval_lt?: InputMaybe<Scalars["String"]>;
  approval_lte?: InputMaybe<Scalars["String"]>;
  approval_not?: InputMaybe<Scalars["String"]>;
  approval_not_contains?: InputMaybe<Scalars["String"]>;
  approval_not_ends_with?: InputMaybe<Scalars["String"]>;
  approval_not_in?: InputMaybe<Array<Scalars["String"]>>;
  approval_not_starts_with?: InputMaybe<Scalars["String"]>;
  approval_starts_with?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<Scalars["String"]>;
  contract_contains?: InputMaybe<Scalars["String"]>;
  contract_ends_with?: InputMaybe<Scalars["String"]>;
  contract_gt?: InputMaybe<Scalars["String"]>;
  contract_gte?: InputMaybe<Scalars["String"]>;
  contract_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_lt?: InputMaybe<Scalars["String"]>;
  contract_lte?: InputMaybe<Scalars["String"]>;
  contract_not?: InputMaybe<Scalars["String"]>;
  contract_not_contains?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with?: InputMaybe<Scalars["String"]>;
  contract_not_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_not_starts_with?: InputMaybe<Scalars["String"]>;
  contract_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  identifier?: InputMaybe<Scalars["BigInt"]>;
  identifier_gt?: InputMaybe<Scalars["BigInt"]>;
  identifier_gte?: InputMaybe<Scalars["BigInt"]>;
  identifier_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  identifier_lt?: InputMaybe<Scalars["BigInt"]>;
  identifier_lte?: InputMaybe<Scalars["BigInt"]>;
  identifier_not?: InputMaybe<Scalars["BigInt"]>;
  identifier_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  owner?: InputMaybe<Scalars["String"]>;
  owner_contains?: InputMaybe<Scalars["String"]>;
  owner_ends_with?: InputMaybe<Scalars["String"]>;
  owner_gt?: InputMaybe<Scalars["String"]>;
  owner_gte?: InputMaybe<Scalars["String"]>;
  owner_in?: InputMaybe<Array<Scalars["String"]>>;
  owner_lt?: InputMaybe<Scalars["String"]>;
  owner_lte?: InputMaybe<Scalars["String"]>;
  owner_not?: InputMaybe<Scalars["String"]>;
  owner_not_contains?: InputMaybe<Scalars["String"]>;
  owner_not_ends_with?: InputMaybe<Scalars["String"]>;
  owner_not_in?: InputMaybe<Array<Scalars["String"]>>;
  owner_not_starts_with?: InputMaybe<Scalars["String"]>;
  owner_starts_with?: InputMaybe<Scalars["String"]>;
  uri?: InputMaybe<Scalars["String"]>;
  uri_contains?: InputMaybe<Scalars["String"]>;
  uri_ends_with?: InputMaybe<Scalars["String"]>;
  uri_gt?: InputMaybe<Scalars["String"]>;
  uri_gte?: InputMaybe<Scalars["String"]>;
  uri_in?: InputMaybe<Array<Scalars["String"]>>;
  uri_lt?: InputMaybe<Scalars["String"]>;
  uri_lte?: InputMaybe<Scalars["String"]>;
  uri_not?: InputMaybe<Scalars["String"]>;
  uri_not_contains?: InputMaybe<Scalars["String"]>;
  uri_not_ends_with?: InputMaybe<Scalars["String"]>;
  uri_not_in?: InputMaybe<Array<Scalars["String"]>>;
  uri_not_starts_with?: InputMaybe<Scalars["String"]>;
  uri_starts_with?: InputMaybe<Scalars["String"]>;
};

export enum Erc721Token_OrderBy {
  Approval = "approval",
  Contract = "contract",
  Id = "id",
  Identifier = "identifier",
  Owner = "owner",
  Transfers = "transfers",
  Uri = "uri",
}

export type Erc721Transfer = Event & {
  __typename?: "ERC721Transfer";
  block: Block;
  contract: Erc721Contract;
  emitter: Account;
  from: Account;
  id: Scalars["ID"];
  timestamp: Scalars["BigInt"];
  to: Account;
  token: Erc721Token;
  transaction: Transaction;
};

export type Erc721Transfer_Filter = {
  block?: InputMaybe<Scalars["String"]>;
  block_contains?: InputMaybe<Scalars["String"]>;
  block_ends_with?: InputMaybe<Scalars["String"]>;
  block_gt?: InputMaybe<Scalars["String"]>;
  block_gte?: InputMaybe<Scalars["String"]>;
  block_in?: InputMaybe<Array<Scalars["String"]>>;
  block_lt?: InputMaybe<Scalars["String"]>;
  block_lte?: InputMaybe<Scalars["String"]>;
  block_not?: InputMaybe<Scalars["String"]>;
  block_not_contains?: InputMaybe<Scalars["String"]>;
  block_not_ends_with?: InputMaybe<Scalars["String"]>;
  block_not_in?: InputMaybe<Array<Scalars["String"]>>;
  block_not_starts_with?: InputMaybe<Scalars["String"]>;
  block_starts_with?: InputMaybe<Scalars["String"]>;
  contract?: InputMaybe<Scalars["String"]>;
  contract_contains?: InputMaybe<Scalars["String"]>;
  contract_ends_with?: InputMaybe<Scalars["String"]>;
  contract_gt?: InputMaybe<Scalars["String"]>;
  contract_gte?: InputMaybe<Scalars["String"]>;
  contract_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_lt?: InputMaybe<Scalars["String"]>;
  contract_lte?: InputMaybe<Scalars["String"]>;
  contract_not?: InputMaybe<Scalars["String"]>;
  contract_not_contains?: InputMaybe<Scalars["String"]>;
  contract_not_ends_with?: InputMaybe<Scalars["String"]>;
  contract_not_in?: InputMaybe<Array<Scalars["String"]>>;
  contract_not_starts_with?: InputMaybe<Scalars["String"]>;
  contract_starts_with?: InputMaybe<Scalars["String"]>;
  emitter?: InputMaybe<Scalars["String"]>;
  emitter_contains?: InputMaybe<Scalars["String"]>;
  emitter_ends_with?: InputMaybe<Scalars["String"]>;
  emitter_gt?: InputMaybe<Scalars["String"]>;
  emitter_gte?: InputMaybe<Scalars["String"]>;
  emitter_in?: InputMaybe<Array<Scalars["String"]>>;
  emitter_lt?: InputMaybe<Scalars["String"]>;
  emitter_lte?: InputMaybe<Scalars["String"]>;
  emitter_not?: InputMaybe<Scalars["String"]>;
  emitter_not_contains?: InputMaybe<Scalars["String"]>;
  emitter_not_ends_with?: InputMaybe<Scalars["String"]>;
  emitter_not_in?: InputMaybe<Array<Scalars["String"]>>;
  emitter_not_starts_with?: InputMaybe<Scalars["String"]>;
  emitter_starts_with?: InputMaybe<Scalars["String"]>;
  from?: InputMaybe<Scalars["String"]>;
  from_contains?: InputMaybe<Scalars["String"]>;
  from_ends_with?: InputMaybe<Scalars["String"]>;
  from_gt?: InputMaybe<Scalars["String"]>;
  from_gte?: InputMaybe<Scalars["String"]>;
  from_in?: InputMaybe<Array<Scalars["String"]>>;
  from_lt?: InputMaybe<Scalars["String"]>;
  from_lte?: InputMaybe<Scalars["String"]>;
  from_not?: InputMaybe<Scalars["String"]>;
  from_not_contains?: InputMaybe<Scalars["String"]>;
  from_not_ends_with?: InputMaybe<Scalars["String"]>;
  from_not_in?: InputMaybe<Array<Scalars["String"]>>;
  from_not_starts_with?: InputMaybe<Scalars["String"]>;
  from_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  to?: InputMaybe<Scalars["String"]>;
  to_contains?: InputMaybe<Scalars["String"]>;
  to_ends_with?: InputMaybe<Scalars["String"]>;
  to_gt?: InputMaybe<Scalars["String"]>;
  to_gte?: InputMaybe<Scalars["String"]>;
  to_in?: InputMaybe<Array<Scalars["String"]>>;
  to_lt?: InputMaybe<Scalars["String"]>;
  to_lte?: InputMaybe<Scalars["String"]>;
  to_not?: InputMaybe<Scalars["String"]>;
  to_not_contains?: InputMaybe<Scalars["String"]>;
  to_not_ends_with?: InputMaybe<Scalars["String"]>;
  to_not_in?: InputMaybe<Array<Scalars["String"]>>;
  to_not_starts_with?: InputMaybe<Scalars["String"]>;
  to_starts_with?: InputMaybe<Scalars["String"]>;
  token?: InputMaybe<Scalars["String"]>;
  token_contains?: InputMaybe<Scalars["String"]>;
  token_ends_with?: InputMaybe<Scalars["String"]>;
  token_gt?: InputMaybe<Scalars["String"]>;
  token_gte?: InputMaybe<Scalars["String"]>;
  token_in?: InputMaybe<Array<Scalars["String"]>>;
  token_lt?: InputMaybe<Scalars["String"]>;
  token_lte?: InputMaybe<Scalars["String"]>;
  token_not?: InputMaybe<Scalars["String"]>;
  token_not_contains?: InputMaybe<Scalars["String"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]>;
  token_starts_with?: InputMaybe<Scalars["String"]>;
  transaction?: InputMaybe<Scalars["String"]>;
  transaction_contains?: InputMaybe<Scalars["String"]>;
  transaction_ends_with?: InputMaybe<Scalars["String"]>;
  transaction_gt?: InputMaybe<Scalars["String"]>;
  transaction_gte?: InputMaybe<Scalars["String"]>;
  transaction_in?: InputMaybe<Array<Scalars["String"]>>;
  transaction_lt?: InputMaybe<Scalars["String"]>;
  transaction_lte?: InputMaybe<Scalars["String"]>;
  transaction_not?: InputMaybe<Scalars["String"]>;
  transaction_not_contains?: InputMaybe<Scalars["String"]>;
  transaction_not_ends_with?: InputMaybe<Scalars["String"]>;
  transaction_not_in?: InputMaybe<Array<Scalars["String"]>>;
  transaction_not_starts_with?: InputMaybe<Scalars["String"]>;
  transaction_starts_with?: InputMaybe<Scalars["String"]>;
};

export enum Erc721Transfer_OrderBy {
  Block = "block",
  Contract = "contract",
  Emitter = "emitter",
  From = "from",
  Id = "id",
  Timestamp = "timestamp",
  To = "to",
  Token = "token",
  Transaction = "transaction",
}

export type Event = {
  emitter: Account;
  id: Scalars["ID"];
  timestamp: Scalars["BigInt"];
  transaction: Transaction;
};

export type Event_Filter = {
  emitter?: InputMaybe<Scalars["String"]>;
  emitter_contains?: InputMaybe<Scalars["String"]>;
  emitter_ends_with?: InputMaybe<Scalars["String"]>;
  emitter_gt?: InputMaybe<Scalars["String"]>;
  emitter_gte?: InputMaybe<Scalars["String"]>;
  emitter_in?: InputMaybe<Array<Scalars["String"]>>;
  emitter_lt?: InputMaybe<Scalars["String"]>;
  emitter_lte?: InputMaybe<Scalars["String"]>;
  emitter_not?: InputMaybe<Scalars["String"]>;
  emitter_not_contains?: InputMaybe<Scalars["String"]>;
  emitter_not_ends_with?: InputMaybe<Scalars["String"]>;
  emitter_not_in?: InputMaybe<Array<Scalars["String"]>>;
  emitter_not_starts_with?: InputMaybe<Scalars["String"]>;
  emitter_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  transaction?: InputMaybe<Scalars["String"]>;
  transaction_contains?: InputMaybe<Scalars["String"]>;
  transaction_ends_with?: InputMaybe<Scalars["String"]>;
  transaction_gt?: InputMaybe<Scalars["String"]>;
  transaction_gte?: InputMaybe<Scalars["String"]>;
  transaction_in?: InputMaybe<Array<Scalars["String"]>>;
  transaction_lt?: InputMaybe<Scalars["String"]>;
  transaction_lte?: InputMaybe<Scalars["String"]>;
  transaction_not?: InputMaybe<Scalars["String"]>;
  transaction_not_contains?: InputMaybe<Scalars["String"]>;
  transaction_not_ends_with?: InputMaybe<Scalars["String"]>;
  transaction_not_in?: InputMaybe<Array<Scalars["String"]>>;
  transaction_not_starts_with?: InputMaybe<Scalars["String"]>;
  transaction_starts_with?: InputMaybe<Scalars["String"]>;
};

export enum Event_OrderBy {
  Emitter = "emitter",
  Id = "id",
  Timestamp = "timestamp",
  Transaction = "transaction",
}

export type Monsta = {
  __typename?: "Monsta";
  auctionStatus?: Maybe<MonstaEventType>;
  birthStatus?: Maybe<Scalars["Int"]>;
  classType?: Maybe<Scalars["Int"]>;
  cloneUsage?: Maybe<Scalars["Int"]>;
  clsdda?: Maybe<Scalars["Int"]>;
  clsddb?: Maybe<Scalars["Int"]>;
  clsddf?: Maybe<Scalars["Int"]>;
  clsddh?: Maybe<Scalars["Int"]>;
  clsddl?: Maybe<Scalars["Int"]>;
  clsddt?: Maybe<Scalars["Int"]>;
  genes: Scalars["BigInt"];
  id: Scalars["ID"];
  lastEndPrice?: Maybe<Scalars["BigInt"]>;
  lastSeller?: Maybe<Scalars["Bytes"]>;
  lastStartPrice?: Maybe<Scalars["BigInt"]>;
  matron?: Maybe<Monsta>;
  monstaEvents: Array<MonstaEvent>;
  monstaStatus?: Maybe<MonstaEventType>;
  owner: Scalars["Bytes"];
  sire?: Maybe<Monsta>;
  spa?: Maybe<Scalars["Int"]>;
  spb?: Maybe<Scalars["Int"]>;
  specialType?: Maybe<Scalars["Int"]>;
  spf?: Maybe<Scalars["Int"]>;
  sph?: Maybe<Scalars["Int"]>;
  spl?: Maybe<Scalars["Int"]>;
  spt?: Maybe<Scalars["Int"]>;
  timestamp: Scalars["BigInt"];
  token: Scalars["BigInt"];
  transferLog: Array<MonstaTransfer>;
  txid: Scalars["String"];
};

export type MonstaMonstaEventsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<MonstaEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MonstaEvent_Filter>;
};

export type MonstaTransferLogArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<MonstaTransfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MonstaTransfer_Filter>;
};

export type MonstaEvent = {
  id: Scalars["ID"];
  timestamp: Scalars["BigInt"];
  token: Monsta;
  type: MonstaEventType;
};

export enum MonstaEventType {
  Evolved = "Evolved",
  Rebirthed = "Rebirthed",
  Retired = "Retired",
  SystemAuctionCancelled = "SystemAuctionCancelled",
  SystemAuctionCreated = "SystemAuctionCreated",
  SystemAuctionSuccessful = "SystemAuctionSuccessful",
  UserAuctionCancelled = "UserAuctionCancelled",
  UserAuctionCreated = "UserAuctionCreated",
  UserAuctionSuccessful = "UserAuctionSuccessful",
}

export type MonstaEvent_Filter = {
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  token?: InputMaybe<Scalars["String"]>;
  token_contains?: InputMaybe<Scalars["String"]>;
  token_ends_with?: InputMaybe<Scalars["String"]>;
  token_gt?: InputMaybe<Scalars["String"]>;
  token_gte?: InputMaybe<Scalars["String"]>;
  token_in?: InputMaybe<Array<Scalars["String"]>>;
  token_lt?: InputMaybe<Scalars["String"]>;
  token_lte?: InputMaybe<Scalars["String"]>;
  token_not?: InputMaybe<Scalars["String"]>;
  token_not_contains?: InputMaybe<Scalars["String"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]>;
  token_starts_with?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<MonstaEventType>;
  type_in?: InputMaybe<Array<MonstaEventType>>;
  type_not?: InputMaybe<MonstaEventType>;
  type_not_in?: InputMaybe<Array<MonstaEventType>>;
};

export enum MonstaEvent_OrderBy {
  Id = "id",
  Timestamp = "timestamp",
  Token = "token",
  Type = "type",
}

export type MonstaEvolve = MonstaEvent & {
  __typename?: "MonstaEvolve";
  id: Scalars["ID"];
  newGenes: Scalars["BigInt"];
  oldGenes: Scalars["BigInt"];
  timestamp: Scalars["BigInt"];
  token: Monsta;
  type: MonstaEventType;
};

export type MonstaEvolve_Filter = {
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  newGenes?: InputMaybe<Scalars["BigInt"]>;
  newGenes_gt?: InputMaybe<Scalars["BigInt"]>;
  newGenes_gte?: InputMaybe<Scalars["BigInt"]>;
  newGenes_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  newGenes_lt?: InputMaybe<Scalars["BigInt"]>;
  newGenes_lte?: InputMaybe<Scalars["BigInt"]>;
  newGenes_not?: InputMaybe<Scalars["BigInt"]>;
  newGenes_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  oldGenes?: InputMaybe<Scalars["BigInt"]>;
  oldGenes_gt?: InputMaybe<Scalars["BigInt"]>;
  oldGenes_gte?: InputMaybe<Scalars["BigInt"]>;
  oldGenes_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  oldGenes_lt?: InputMaybe<Scalars["BigInt"]>;
  oldGenes_lte?: InputMaybe<Scalars["BigInt"]>;
  oldGenes_not?: InputMaybe<Scalars["BigInt"]>;
  oldGenes_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  token?: InputMaybe<Scalars["String"]>;
  token_contains?: InputMaybe<Scalars["String"]>;
  token_ends_with?: InputMaybe<Scalars["String"]>;
  token_gt?: InputMaybe<Scalars["String"]>;
  token_gte?: InputMaybe<Scalars["String"]>;
  token_in?: InputMaybe<Array<Scalars["String"]>>;
  token_lt?: InputMaybe<Scalars["String"]>;
  token_lte?: InputMaybe<Scalars["String"]>;
  token_not?: InputMaybe<Scalars["String"]>;
  token_not_contains?: InputMaybe<Scalars["String"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]>;
  token_starts_with?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<MonstaEventType>;
  type_in?: InputMaybe<Array<MonstaEventType>>;
  type_not?: InputMaybe<MonstaEventType>;
  type_not_in?: InputMaybe<Array<MonstaEventType>>;
};

export enum MonstaEvolve_OrderBy {
  Id = "id",
  NewGenes = "newGenes",
  OldGenes = "oldGenes",
  Timestamp = "timestamp",
  Token = "token",
  Type = "type",
}

export type MonstaRebirth = MonstaEvent & {
  __typename?: "MonstaRebirth";
  genes: Scalars["BigInt"];
  id: Scalars["ID"];
  timestamp: Scalars["BigInt"];
  token: Monsta;
  type: MonstaEventType;
};

export type MonstaRebirth_Filter = {
  genes?: InputMaybe<Scalars["BigInt"]>;
  genes_gt?: InputMaybe<Scalars["BigInt"]>;
  genes_gte?: InputMaybe<Scalars["BigInt"]>;
  genes_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  genes_lt?: InputMaybe<Scalars["BigInt"]>;
  genes_lte?: InputMaybe<Scalars["BigInt"]>;
  genes_not?: InputMaybe<Scalars["BigInt"]>;
  genes_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  token?: InputMaybe<Scalars["String"]>;
  token_contains?: InputMaybe<Scalars["String"]>;
  token_ends_with?: InputMaybe<Scalars["String"]>;
  token_gt?: InputMaybe<Scalars["String"]>;
  token_gte?: InputMaybe<Scalars["String"]>;
  token_in?: InputMaybe<Array<Scalars["String"]>>;
  token_lt?: InputMaybe<Scalars["String"]>;
  token_lte?: InputMaybe<Scalars["String"]>;
  token_not?: InputMaybe<Scalars["String"]>;
  token_not_contains?: InputMaybe<Scalars["String"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]>;
  token_starts_with?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<MonstaEventType>;
  type_in?: InputMaybe<Array<MonstaEventType>>;
  type_not?: InputMaybe<MonstaEventType>;
  type_not_in?: InputMaybe<Array<MonstaEventType>>;
};

export enum MonstaRebirth_OrderBy {
  Genes = "genes",
  Id = "id",
  Timestamp = "timestamp",
  Token = "token",
  Type = "type",
}

export type MonstaRetire = MonstaEvent & {
  __typename?: "MonstaRetire";
  id: Scalars["ID"];
  timestamp: Scalars["BigInt"];
  token: Monsta;
  type: MonstaEventType;
};

export type MonstaRetire_Filter = {
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  token?: InputMaybe<Scalars["String"]>;
  token_contains?: InputMaybe<Scalars["String"]>;
  token_ends_with?: InputMaybe<Scalars["String"]>;
  token_gt?: InputMaybe<Scalars["String"]>;
  token_gte?: InputMaybe<Scalars["String"]>;
  token_in?: InputMaybe<Array<Scalars["String"]>>;
  token_lt?: InputMaybe<Scalars["String"]>;
  token_lte?: InputMaybe<Scalars["String"]>;
  token_not?: InputMaybe<Scalars["String"]>;
  token_not_contains?: InputMaybe<Scalars["String"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]>;
  token_starts_with?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<MonstaEventType>;
  type_in?: InputMaybe<Array<MonstaEventType>>;
  type_not?: InputMaybe<MonstaEventType>;
  type_not_in?: InputMaybe<Array<MonstaEventType>>;
};

export enum MonstaRetire_OrderBy {
  Id = "id",
  Timestamp = "timestamp",
  Token = "token",
  Type = "type",
}

export type MonstaTransfer = {
  __typename?: "MonstaTransfer";
  from: Scalars["Bytes"];
  id: Scalars["ID"];
  timestamp: Scalars["BigInt"];
  to: Scalars["Bytes"];
  token: Monsta;
};

export type MonstaTransfer_Filter = {
  from?: InputMaybe<Scalars["Bytes"]>;
  from_contains?: InputMaybe<Scalars["Bytes"]>;
  from_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  from_not?: InputMaybe<Scalars["Bytes"]>;
  from_not_contains?: InputMaybe<Scalars["Bytes"]>;
  from_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  to?: InputMaybe<Scalars["Bytes"]>;
  to_contains?: InputMaybe<Scalars["Bytes"]>;
  to_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  to_not?: InputMaybe<Scalars["Bytes"]>;
  to_not_contains?: InputMaybe<Scalars["Bytes"]>;
  to_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  token?: InputMaybe<Scalars["String"]>;
  token_contains?: InputMaybe<Scalars["String"]>;
  token_ends_with?: InputMaybe<Scalars["String"]>;
  token_gt?: InputMaybe<Scalars["String"]>;
  token_gte?: InputMaybe<Scalars["String"]>;
  token_in?: InputMaybe<Array<Scalars["String"]>>;
  token_lt?: InputMaybe<Scalars["String"]>;
  token_lte?: InputMaybe<Scalars["String"]>;
  token_not?: InputMaybe<Scalars["String"]>;
  token_not_contains?: InputMaybe<Scalars["String"]>;
  token_not_ends_with?: InputMaybe<Scalars["String"]>;
  token_not_in?: InputMaybe<Array<Scalars["String"]>>;
  token_not_starts_with?: InputMaybe<Scalars["String"]>;
  token_starts_with?: InputMaybe<Scalars["String"]>;
};

export enum MonstaTransfer_OrderBy {
  From = "from",
  Id = "id",
  Timestamp = "timestamp",
  To = "to",
  Token = "token",
}

export type Monsta_Filter = {
  auctionStatus?: InputMaybe<MonstaEventType>;
  auctionStatus_in?: InputMaybe<Array<MonstaEventType>>;
  auctionStatus_not?: InputMaybe<MonstaEventType>;
  auctionStatus_not_in?: InputMaybe<Array<MonstaEventType>>;
  birthStatus?: InputMaybe<Scalars["Int"]>;
  birthStatus_gt?: InputMaybe<Scalars["Int"]>;
  birthStatus_gte?: InputMaybe<Scalars["Int"]>;
  birthStatus_in?: InputMaybe<Array<Scalars["Int"]>>;
  birthStatus_lt?: InputMaybe<Scalars["Int"]>;
  birthStatus_lte?: InputMaybe<Scalars["Int"]>;
  birthStatus_not?: InputMaybe<Scalars["Int"]>;
  birthStatus_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  classType?: InputMaybe<Scalars["Int"]>;
  classType_gt?: InputMaybe<Scalars["Int"]>;
  classType_gte?: InputMaybe<Scalars["Int"]>;
  classType_in?: InputMaybe<Array<Scalars["Int"]>>;
  classType_lt?: InputMaybe<Scalars["Int"]>;
  classType_lte?: InputMaybe<Scalars["Int"]>;
  classType_not?: InputMaybe<Scalars["Int"]>;
  classType_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  cloneUsage?: InputMaybe<Scalars["Int"]>;
  cloneUsage_gt?: InputMaybe<Scalars["Int"]>;
  cloneUsage_gte?: InputMaybe<Scalars["Int"]>;
  cloneUsage_in?: InputMaybe<Array<Scalars["Int"]>>;
  cloneUsage_lt?: InputMaybe<Scalars["Int"]>;
  cloneUsage_lte?: InputMaybe<Scalars["Int"]>;
  cloneUsage_not?: InputMaybe<Scalars["Int"]>;
  cloneUsage_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  clsdda?: InputMaybe<Scalars["Int"]>;
  clsdda_gt?: InputMaybe<Scalars["Int"]>;
  clsdda_gte?: InputMaybe<Scalars["Int"]>;
  clsdda_in?: InputMaybe<Array<Scalars["Int"]>>;
  clsdda_lt?: InputMaybe<Scalars["Int"]>;
  clsdda_lte?: InputMaybe<Scalars["Int"]>;
  clsdda_not?: InputMaybe<Scalars["Int"]>;
  clsdda_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  clsddb?: InputMaybe<Scalars["Int"]>;
  clsddb_gt?: InputMaybe<Scalars["Int"]>;
  clsddb_gte?: InputMaybe<Scalars["Int"]>;
  clsddb_in?: InputMaybe<Array<Scalars["Int"]>>;
  clsddb_lt?: InputMaybe<Scalars["Int"]>;
  clsddb_lte?: InputMaybe<Scalars["Int"]>;
  clsddb_not?: InputMaybe<Scalars["Int"]>;
  clsddb_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  clsddf?: InputMaybe<Scalars["Int"]>;
  clsddf_gt?: InputMaybe<Scalars["Int"]>;
  clsddf_gte?: InputMaybe<Scalars["Int"]>;
  clsddf_in?: InputMaybe<Array<Scalars["Int"]>>;
  clsddf_lt?: InputMaybe<Scalars["Int"]>;
  clsddf_lte?: InputMaybe<Scalars["Int"]>;
  clsddf_not?: InputMaybe<Scalars["Int"]>;
  clsddf_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  clsddh?: InputMaybe<Scalars["Int"]>;
  clsddh_gt?: InputMaybe<Scalars["Int"]>;
  clsddh_gte?: InputMaybe<Scalars["Int"]>;
  clsddh_in?: InputMaybe<Array<Scalars["Int"]>>;
  clsddh_lt?: InputMaybe<Scalars["Int"]>;
  clsddh_lte?: InputMaybe<Scalars["Int"]>;
  clsddh_not?: InputMaybe<Scalars["Int"]>;
  clsddh_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  clsddl?: InputMaybe<Scalars["Int"]>;
  clsddl_gt?: InputMaybe<Scalars["Int"]>;
  clsddl_gte?: InputMaybe<Scalars["Int"]>;
  clsddl_in?: InputMaybe<Array<Scalars["Int"]>>;
  clsddl_lt?: InputMaybe<Scalars["Int"]>;
  clsddl_lte?: InputMaybe<Scalars["Int"]>;
  clsddl_not?: InputMaybe<Scalars["Int"]>;
  clsddl_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  clsddt?: InputMaybe<Scalars["Int"]>;
  clsddt_gt?: InputMaybe<Scalars["Int"]>;
  clsddt_gte?: InputMaybe<Scalars["Int"]>;
  clsddt_in?: InputMaybe<Array<Scalars["Int"]>>;
  clsddt_lt?: InputMaybe<Scalars["Int"]>;
  clsddt_lte?: InputMaybe<Scalars["Int"]>;
  clsddt_not?: InputMaybe<Scalars["Int"]>;
  clsddt_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  genes?: InputMaybe<Scalars["BigInt"]>;
  genes_gt?: InputMaybe<Scalars["BigInt"]>;
  genes_gte?: InputMaybe<Scalars["BigInt"]>;
  genes_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  genes_lt?: InputMaybe<Scalars["BigInt"]>;
  genes_lte?: InputMaybe<Scalars["BigInt"]>;
  genes_not?: InputMaybe<Scalars["BigInt"]>;
  genes_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  lastEndPrice?: InputMaybe<Scalars["BigInt"]>;
  lastEndPrice_gt?: InputMaybe<Scalars["BigInt"]>;
  lastEndPrice_gte?: InputMaybe<Scalars["BigInt"]>;
  lastEndPrice_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  lastEndPrice_lt?: InputMaybe<Scalars["BigInt"]>;
  lastEndPrice_lte?: InputMaybe<Scalars["BigInt"]>;
  lastEndPrice_not?: InputMaybe<Scalars["BigInt"]>;
  lastEndPrice_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  lastSeller?: InputMaybe<Scalars["Bytes"]>;
  lastSeller_contains?: InputMaybe<Scalars["Bytes"]>;
  lastSeller_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  lastSeller_not?: InputMaybe<Scalars["Bytes"]>;
  lastSeller_not_contains?: InputMaybe<Scalars["Bytes"]>;
  lastSeller_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  lastStartPrice?: InputMaybe<Scalars["BigInt"]>;
  lastStartPrice_gt?: InputMaybe<Scalars["BigInt"]>;
  lastStartPrice_gte?: InputMaybe<Scalars["BigInt"]>;
  lastStartPrice_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  lastStartPrice_lt?: InputMaybe<Scalars["BigInt"]>;
  lastStartPrice_lte?: InputMaybe<Scalars["BigInt"]>;
  lastStartPrice_not?: InputMaybe<Scalars["BigInt"]>;
  lastStartPrice_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  matron?: InputMaybe<Scalars["String"]>;
  matron_contains?: InputMaybe<Scalars["String"]>;
  matron_ends_with?: InputMaybe<Scalars["String"]>;
  matron_gt?: InputMaybe<Scalars["String"]>;
  matron_gte?: InputMaybe<Scalars["String"]>;
  matron_in?: InputMaybe<Array<Scalars["String"]>>;
  matron_lt?: InputMaybe<Scalars["String"]>;
  matron_lte?: InputMaybe<Scalars["String"]>;
  matron_not?: InputMaybe<Scalars["String"]>;
  matron_not_contains?: InputMaybe<Scalars["String"]>;
  matron_not_ends_with?: InputMaybe<Scalars["String"]>;
  matron_not_in?: InputMaybe<Array<Scalars["String"]>>;
  matron_not_starts_with?: InputMaybe<Scalars["String"]>;
  matron_starts_with?: InputMaybe<Scalars["String"]>;
  monstaStatus?: InputMaybe<MonstaEventType>;
  monstaStatus_in?: InputMaybe<Array<MonstaEventType>>;
  monstaStatus_not?: InputMaybe<MonstaEventType>;
  monstaStatus_not_in?: InputMaybe<Array<MonstaEventType>>;
  owner?: InputMaybe<Scalars["Bytes"]>;
  owner_contains?: InputMaybe<Scalars["Bytes"]>;
  owner_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  owner_not?: InputMaybe<Scalars["Bytes"]>;
  owner_not_contains?: InputMaybe<Scalars["Bytes"]>;
  owner_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  sire?: InputMaybe<Scalars["String"]>;
  sire_contains?: InputMaybe<Scalars["String"]>;
  sire_ends_with?: InputMaybe<Scalars["String"]>;
  sire_gt?: InputMaybe<Scalars["String"]>;
  sire_gte?: InputMaybe<Scalars["String"]>;
  sire_in?: InputMaybe<Array<Scalars["String"]>>;
  sire_lt?: InputMaybe<Scalars["String"]>;
  sire_lte?: InputMaybe<Scalars["String"]>;
  sire_not?: InputMaybe<Scalars["String"]>;
  sire_not_contains?: InputMaybe<Scalars["String"]>;
  sire_not_ends_with?: InputMaybe<Scalars["String"]>;
  sire_not_in?: InputMaybe<Array<Scalars["String"]>>;
  sire_not_starts_with?: InputMaybe<Scalars["String"]>;
  sire_starts_with?: InputMaybe<Scalars["String"]>;
  spa?: InputMaybe<Scalars["Int"]>;
  spa_gt?: InputMaybe<Scalars["Int"]>;
  spa_gte?: InputMaybe<Scalars["Int"]>;
  spa_in?: InputMaybe<Array<Scalars["Int"]>>;
  spa_lt?: InputMaybe<Scalars["Int"]>;
  spa_lte?: InputMaybe<Scalars["Int"]>;
  spa_not?: InputMaybe<Scalars["Int"]>;
  spa_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  spb?: InputMaybe<Scalars["Int"]>;
  spb_gt?: InputMaybe<Scalars["Int"]>;
  spb_gte?: InputMaybe<Scalars["Int"]>;
  spb_in?: InputMaybe<Array<Scalars["Int"]>>;
  spb_lt?: InputMaybe<Scalars["Int"]>;
  spb_lte?: InputMaybe<Scalars["Int"]>;
  spb_not?: InputMaybe<Scalars["Int"]>;
  spb_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  specialType?: InputMaybe<Scalars["Int"]>;
  specialType_gt?: InputMaybe<Scalars["Int"]>;
  specialType_gte?: InputMaybe<Scalars["Int"]>;
  specialType_in?: InputMaybe<Array<Scalars["Int"]>>;
  specialType_lt?: InputMaybe<Scalars["Int"]>;
  specialType_lte?: InputMaybe<Scalars["Int"]>;
  specialType_not?: InputMaybe<Scalars["Int"]>;
  specialType_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  spf?: InputMaybe<Scalars["Int"]>;
  spf_gt?: InputMaybe<Scalars["Int"]>;
  spf_gte?: InputMaybe<Scalars["Int"]>;
  spf_in?: InputMaybe<Array<Scalars["Int"]>>;
  spf_lt?: InputMaybe<Scalars["Int"]>;
  spf_lte?: InputMaybe<Scalars["Int"]>;
  spf_not?: InputMaybe<Scalars["Int"]>;
  spf_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  sph?: InputMaybe<Scalars["Int"]>;
  sph_gt?: InputMaybe<Scalars["Int"]>;
  sph_gte?: InputMaybe<Scalars["Int"]>;
  sph_in?: InputMaybe<Array<Scalars["Int"]>>;
  sph_lt?: InputMaybe<Scalars["Int"]>;
  sph_lte?: InputMaybe<Scalars["Int"]>;
  sph_not?: InputMaybe<Scalars["Int"]>;
  sph_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  spl?: InputMaybe<Scalars["Int"]>;
  spl_gt?: InputMaybe<Scalars["Int"]>;
  spl_gte?: InputMaybe<Scalars["Int"]>;
  spl_in?: InputMaybe<Array<Scalars["Int"]>>;
  spl_lt?: InputMaybe<Scalars["Int"]>;
  spl_lte?: InputMaybe<Scalars["Int"]>;
  spl_not?: InputMaybe<Scalars["Int"]>;
  spl_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  spt?: InputMaybe<Scalars["Int"]>;
  spt_gt?: InputMaybe<Scalars["Int"]>;
  spt_gte?: InputMaybe<Scalars["Int"]>;
  spt_in?: InputMaybe<Array<Scalars["Int"]>>;
  spt_lt?: InputMaybe<Scalars["Int"]>;
  spt_lte?: InputMaybe<Scalars["Int"]>;
  spt_not?: InputMaybe<Scalars["Int"]>;
  spt_not_in?: InputMaybe<Array<Scalars["Int"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  token?: InputMaybe<Scalars["BigInt"]>;
  token_gt?: InputMaybe<Scalars["BigInt"]>;
  token_gte?: InputMaybe<Scalars["BigInt"]>;
  token_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  token_lt?: InputMaybe<Scalars["BigInt"]>;
  token_lte?: InputMaybe<Scalars["BigInt"]>;
  token_not?: InputMaybe<Scalars["BigInt"]>;
  token_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  txid?: InputMaybe<Scalars["String"]>;
  txid_contains?: InputMaybe<Scalars["String"]>;
  txid_ends_with?: InputMaybe<Scalars["String"]>;
  txid_gt?: InputMaybe<Scalars["String"]>;
  txid_gte?: InputMaybe<Scalars["String"]>;
  txid_in?: InputMaybe<Array<Scalars["String"]>>;
  txid_lt?: InputMaybe<Scalars["String"]>;
  txid_lte?: InputMaybe<Scalars["String"]>;
  txid_not?: InputMaybe<Scalars["String"]>;
  txid_not_contains?: InputMaybe<Scalars["String"]>;
  txid_not_ends_with?: InputMaybe<Scalars["String"]>;
  txid_not_in?: InputMaybe<Array<Scalars["String"]>>;
  txid_not_starts_with?: InputMaybe<Scalars["String"]>;
  txid_starts_with?: InputMaybe<Scalars["String"]>;
};

export enum Monsta_OrderBy {
  AuctionStatus = "auctionStatus",
  BirthStatus = "birthStatus",
  ClassType = "classType",
  CloneUsage = "cloneUsage",
  Clsdda = "clsdda",
  Clsddb = "clsddb",
  Clsddf = "clsddf",
  Clsddh = "clsddh",
  Clsddl = "clsddl",
  Clsddt = "clsddt",
  Genes = "genes",
  Id = "id",
  LastEndPrice = "lastEndPrice",
  LastSeller = "lastSeller",
  LastStartPrice = "lastStartPrice",
  Matron = "matron",
  MonstaEvents = "monstaEvents",
  MonstaStatus = "monstaStatus",
  Owner = "owner",
  Sire = "sire",
  Spa = "spa",
  Spb = "spb",
  SpecialType = "specialType",
  Spf = "spf",
  Sph = "sph",
  Spl = "spl",
  Spt = "spt",
  Timestamp = "timestamp",
  Token = "token",
  TransferLog = "transferLog",
  Txid = "txid",
}

export enum OrderDirection {
  Asc = "asc",
  Desc = "desc",
}

export type Query = {
  __typename?: "Query";
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  auctionCancel?: Maybe<AuctionCancel>;
  auctionCancels: Array<AuctionCancel>;
  auctionCreate?: Maybe<AuctionCreate>;
  auctionCreates: Array<AuctionCreate>;
  auctionSucceed?: Maybe<AuctionSucceed>;
  auctionSucceeds: Array<AuctionSucceed>;
  block?: Maybe<Block>;
  blocks: Array<Block>;
  erc20Approval?: Maybe<Erc20Approval>;
  erc20Approvals: Array<Erc20Approval>;
  erc20Balance?: Maybe<Erc20Balance>;
  erc20Balances: Array<Erc20Balance>;
  erc20Contract?: Maybe<Erc20Contract>;
  erc20Contracts: Array<Erc20Contract>;
  erc20Transfer?: Maybe<Erc20Transfer>;
  erc20Transfers: Array<Erc20Transfer>;
  erc721Contract?: Maybe<Erc721Contract>;
  erc721Contracts: Array<Erc721Contract>;
  erc721Operator?: Maybe<Erc721Operator>;
  erc721Operators: Array<Erc721Operator>;
  erc721Token?: Maybe<Erc721Token>;
  erc721Tokens: Array<Erc721Token>;
  erc721Transfer?: Maybe<Erc721Transfer>;
  erc721Transfers: Array<Erc721Transfer>;
  event?: Maybe<Event>;
  events: Array<Event>;
  monsta?: Maybe<Monsta>;
  monstaEvent?: Maybe<MonstaEvent>;
  monstaEvents: Array<MonstaEvent>;
  monstaEvolve?: Maybe<MonstaEvolve>;
  monstaEvolves: Array<MonstaEvolve>;
  monstaRebirth?: Maybe<MonstaRebirth>;
  monstaRebirths: Array<MonstaRebirth>;
  monstaRetire?: Maybe<MonstaRetire>;
  monstaRetires: Array<MonstaRetire>;
  monstaTransfer?: Maybe<MonstaTransfer>;
  monstaTransfers: Array<MonstaTransfer>;
  monstas: Array<Monsta>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
};

export type Query_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type QueryAccountArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryAccountsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Account_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Account_Filter>;
};

export type QueryAuctionCancelArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryAuctionCancelsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<AuctionCancel_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AuctionCancel_Filter>;
};

export type QueryAuctionCreateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryAuctionCreatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<AuctionCreate_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AuctionCreate_Filter>;
};

export type QueryAuctionSucceedArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryAuctionSucceedsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<AuctionSucceed_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AuctionSucceed_Filter>;
};

export type QueryBlockArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryBlocksArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Block_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Block_Filter>;
};

export type QueryErc20ApprovalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryErc20ApprovalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Approval_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Approval_Filter>;
};

export type QueryErc20BalanceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryErc20BalancesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Balance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Balance_Filter>;
};

export type QueryErc20ContractArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryErc20ContractsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Contract_Filter>;
};

export type QueryErc20TransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryErc20TransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Transfer_Filter>;
};

export type QueryErc721ContractArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryErc721ContractsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Contract_Filter>;
};

export type QueryErc721OperatorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryErc721OperatorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Operator_Filter>;
};

export type QueryErc721TokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryErc721TokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Token_Filter>;
};

export type QueryErc721TransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryErc721TransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type QueryEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Event_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Event_Filter>;
};

export type QueryMonstaArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryMonstaEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryMonstaEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<MonstaEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MonstaEvent_Filter>;
};

export type QueryMonstaEvolveArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryMonstaEvolvesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<MonstaEvolve_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MonstaEvolve_Filter>;
};

export type QueryMonstaRebirthArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryMonstaRebirthsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<MonstaRebirth_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MonstaRebirth_Filter>;
};

export type QueryMonstaRetireArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryMonstaRetiresArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<MonstaRetire_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MonstaRetire_Filter>;
};

export type QueryMonstaTransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryMonstaTransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<MonstaTransfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MonstaTransfer_Filter>;
};

export type QueryMonstasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Monsta_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Monsta_Filter>;
};

export type QueryTransactionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type QueryTransactionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Transaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Transaction_Filter>;
};

export type Subscription = {
  __typename?: "Subscription";
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  account?: Maybe<Account>;
  accounts: Array<Account>;
  auctionCancel?: Maybe<AuctionCancel>;
  auctionCancels: Array<AuctionCancel>;
  auctionCreate?: Maybe<AuctionCreate>;
  auctionCreates: Array<AuctionCreate>;
  auctionSucceed?: Maybe<AuctionSucceed>;
  auctionSucceeds: Array<AuctionSucceed>;
  block?: Maybe<Block>;
  blocks: Array<Block>;
  erc20Approval?: Maybe<Erc20Approval>;
  erc20Approvals: Array<Erc20Approval>;
  erc20Balance?: Maybe<Erc20Balance>;
  erc20Balances: Array<Erc20Balance>;
  erc20Contract?: Maybe<Erc20Contract>;
  erc20Contracts: Array<Erc20Contract>;
  erc20Transfer?: Maybe<Erc20Transfer>;
  erc20Transfers: Array<Erc20Transfer>;
  erc721Contract?: Maybe<Erc721Contract>;
  erc721Contracts: Array<Erc721Contract>;
  erc721Operator?: Maybe<Erc721Operator>;
  erc721Operators: Array<Erc721Operator>;
  erc721Token?: Maybe<Erc721Token>;
  erc721Tokens: Array<Erc721Token>;
  erc721Transfer?: Maybe<Erc721Transfer>;
  erc721Transfers: Array<Erc721Transfer>;
  event?: Maybe<Event>;
  events: Array<Event>;
  monsta?: Maybe<Monsta>;
  monstaEvent?: Maybe<MonstaEvent>;
  monstaEvents: Array<MonstaEvent>;
  monstaEvolve?: Maybe<MonstaEvolve>;
  monstaEvolves: Array<MonstaEvolve>;
  monstaRebirth?: Maybe<MonstaRebirth>;
  monstaRebirths: Array<MonstaRebirth>;
  monstaRetire?: Maybe<MonstaRetire>;
  monstaRetires: Array<MonstaRetire>;
  monstaTransfer?: Maybe<MonstaTransfer>;
  monstaTransfers: Array<MonstaTransfer>;
  monstas: Array<Monsta>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
};

export type Subscription_MetaArgs = {
  block?: InputMaybe<Block_Height>;
};

export type SubscriptionAccountArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionAccountsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Account_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Account_Filter>;
};

export type SubscriptionAuctionCancelArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionAuctionCancelsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<AuctionCancel_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AuctionCancel_Filter>;
};

export type SubscriptionAuctionCreateArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionAuctionCreatesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<AuctionCreate_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AuctionCreate_Filter>;
};

export type SubscriptionAuctionSucceedArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionAuctionSucceedsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<AuctionSucceed_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AuctionSucceed_Filter>;
};

export type SubscriptionBlockArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionBlocksArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Block_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Block_Filter>;
};

export type SubscriptionErc20ApprovalArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionErc20ApprovalsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Approval_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Approval_Filter>;
};

export type SubscriptionErc20BalanceArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionErc20BalancesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Balance_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Balance_Filter>;
};

export type SubscriptionErc20ContractArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionErc20ContractsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Contract_Filter>;
};

export type SubscriptionErc20TransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionErc20TransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc20Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc20Transfer_Filter>;
};

export type SubscriptionErc721ContractArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionErc721ContractsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Contract_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Contract_Filter>;
};

export type SubscriptionErc721OperatorArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionErc721OperatorsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Operator_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Operator_Filter>;
};

export type SubscriptionErc721TokenArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionErc721TokensArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Token_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Token_Filter>;
};

export type SubscriptionErc721TransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionErc721TransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Erc721Transfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Erc721Transfer_Filter>;
};

export type SubscriptionEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Event_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Event_Filter>;
};

export type SubscriptionMonstaArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionMonstaEventArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionMonstaEventsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<MonstaEvent_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MonstaEvent_Filter>;
};

export type SubscriptionMonstaEvolveArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionMonstaEvolvesArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<MonstaEvolve_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MonstaEvolve_Filter>;
};

export type SubscriptionMonstaRebirthArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionMonstaRebirthsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<MonstaRebirth_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MonstaRebirth_Filter>;
};

export type SubscriptionMonstaRetireArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionMonstaRetiresArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<MonstaRetire_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MonstaRetire_Filter>;
};

export type SubscriptionMonstaTransferArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionMonstaTransfersArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<MonstaTransfer_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MonstaTransfer_Filter>;
};

export type SubscriptionMonstasArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Monsta_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Monsta_Filter>;
};

export type SubscriptionTransactionArgs = {
  block?: InputMaybe<Block_Height>;
  id: Scalars["ID"];
};

export type SubscriptionTransactionsArgs = {
  block?: InputMaybe<Block_Height>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Transaction_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Transaction_Filter>;
};

export type Transaction = {
  __typename?: "Transaction";
  blockNumber: Scalars["BigInt"];
  events: Array<Event>;
  gasLimit: Scalars["BigInt"];
  gasPrice: Scalars["BigInt"];
  id: Scalars["ID"];
  input?: Maybe<Scalars["Bytes"]>;
  logIndex?: Maybe<Scalars["BigInt"]>;
  logType?: Maybe<Scalars["String"]>;
  nonce?: Maybe<Scalars["BigInt"]>;
  timestamp: Scalars["BigInt"];
  transactionLogIndex?: Maybe<Scalars["BigInt"]>;
};

export type TransactionEventsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Event_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<Event_Filter>;
};

export type Transaction_Filter = {
  blockNumber?: InputMaybe<Scalars["BigInt"]>;
  blockNumber_gt?: InputMaybe<Scalars["BigInt"]>;
  blockNumber_gte?: InputMaybe<Scalars["BigInt"]>;
  blockNumber_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  blockNumber_lt?: InputMaybe<Scalars["BigInt"]>;
  blockNumber_lte?: InputMaybe<Scalars["BigInt"]>;
  blockNumber_not?: InputMaybe<Scalars["BigInt"]>;
  blockNumber_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  gasLimit?: InputMaybe<Scalars["BigInt"]>;
  gasLimit_gt?: InputMaybe<Scalars["BigInt"]>;
  gasLimit_gte?: InputMaybe<Scalars["BigInt"]>;
  gasLimit_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  gasLimit_lt?: InputMaybe<Scalars["BigInt"]>;
  gasLimit_lte?: InputMaybe<Scalars["BigInt"]>;
  gasLimit_not?: InputMaybe<Scalars["BigInt"]>;
  gasLimit_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  gasPrice?: InputMaybe<Scalars["BigInt"]>;
  gasPrice_gt?: InputMaybe<Scalars["BigInt"]>;
  gasPrice_gte?: InputMaybe<Scalars["BigInt"]>;
  gasPrice_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  gasPrice_lt?: InputMaybe<Scalars["BigInt"]>;
  gasPrice_lte?: InputMaybe<Scalars["BigInt"]>;
  gasPrice_not?: InputMaybe<Scalars["BigInt"]>;
  gasPrice_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  id?: InputMaybe<Scalars["ID"]>;
  id_gt?: InputMaybe<Scalars["ID"]>;
  id_gte?: InputMaybe<Scalars["ID"]>;
  id_in?: InputMaybe<Array<Scalars["ID"]>>;
  id_lt?: InputMaybe<Scalars["ID"]>;
  id_lte?: InputMaybe<Scalars["ID"]>;
  id_not?: InputMaybe<Scalars["ID"]>;
  id_not_in?: InputMaybe<Array<Scalars["ID"]>>;
  input?: InputMaybe<Scalars["Bytes"]>;
  input_contains?: InputMaybe<Scalars["Bytes"]>;
  input_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  input_not?: InputMaybe<Scalars["Bytes"]>;
  input_not_contains?: InputMaybe<Scalars["Bytes"]>;
  input_not_in?: InputMaybe<Array<Scalars["Bytes"]>>;
  logIndex?: InputMaybe<Scalars["BigInt"]>;
  logIndex_gt?: InputMaybe<Scalars["BigInt"]>;
  logIndex_gte?: InputMaybe<Scalars["BigInt"]>;
  logIndex_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  logIndex_lt?: InputMaybe<Scalars["BigInt"]>;
  logIndex_lte?: InputMaybe<Scalars["BigInt"]>;
  logIndex_not?: InputMaybe<Scalars["BigInt"]>;
  logIndex_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  logType?: InputMaybe<Scalars["String"]>;
  logType_contains?: InputMaybe<Scalars["String"]>;
  logType_ends_with?: InputMaybe<Scalars["String"]>;
  logType_gt?: InputMaybe<Scalars["String"]>;
  logType_gte?: InputMaybe<Scalars["String"]>;
  logType_in?: InputMaybe<Array<Scalars["String"]>>;
  logType_lt?: InputMaybe<Scalars["String"]>;
  logType_lte?: InputMaybe<Scalars["String"]>;
  logType_not?: InputMaybe<Scalars["String"]>;
  logType_not_contains?: InputMaybe<Scalars["String"]>;
  logType_not_ends_with?: InputMaybe<Scalars["String"]>;
  logType_not_in?: InputMaybe<Array<Scalars["String"]>>;
  logType_not_starts_with?: InputMaybe<Scalars["String"]>;
  logType_starts_with?: InputMaybe<Scalars["String"]>;
  nonce?: InputMaybe<Scalars["BigInt"]>;
  nonce_gt?: InputMaybe<Scalars["BigInt"]>;
  nonce_gte?: InputMaybe<Scalars["BigInt"]>;
  nonce_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  nonce_lt?: InputMaybe<Scalars["BigInt"]>;
  nonce_lte?: InputMaybe<Scalars["BigInt"]>;
  nonce_not?: InputMaybe<Scalars["BigInt"]>;
  nonce_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_gte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  timestamp_lt?: InputMaybe<Scalars["BigInt"]>;
  timestamp_lte?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not?: InputMaybe<Scalars["BigInt"]>;
  timestamp_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  transactionLogIndex?: InputMaybe<Scalars["BigInt"]>;
  transactionLogIndex_gt?: InputMaybe<Scalars["BigInt"]>;
  transactionLogIndex_gte?: InputMaybe<Scalars["BigInt"]>;
  transactionLogIndex_in?: InputMaybe<Array<Scalars["BigInt"]>>;
  transactionLogIndex_lt?: InputMaybe<Scalars["BigInt"]>;
  transactionLogIndex_lte?: InputMaybe<Scalars["BigInt"]>;
  transactionLogIndex_not?: InputMaybe<Scalars["BigInt"]>;
  transactionLogIndex_not_in?: InputMaybe<Array<Scalars["BigInt"]>>;
};

export enum Transaction_OrderBy {
  BlockNumber = "blockNumber",
  Events = "events",
  GasLimit = "gasLimit",
  GasPrice = "gasPrice",
  Id = "id",
  Input = "input",
  LogIndex = "logIndex",
  LogType = "logType",
  Nonce = "nonce",
  Timestamp = "timestamp",
  TransactionLogIndex = "transactionLogIndex",
}

export type _Block_ = {
  __typename?: "_Block_";
  /** The hash of the block */
  hash?: Maybe<Scalars["Bytes"]>;
  /** The block number */
  number: Scalars["Int"];
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  __typename?: "_Meta_";
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars["String"];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars["Boolean"];
};

export enum _SubgraphErrorPolicy_ {
  /** Data will be returned even if the subgraph has indexing errors */
  Allow = "allow",
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  Deny = "deny",
}

export type GetErc20ApprovalQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetErc20ApprovalQuery = {
  __typename?: "Query";
  erc20Approval?:
    | { __typename?: "ERC20Approval"; valueExact: any }
    | null
    | undefined;
};

export type GetAccountQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAccountQuery = {
  __typename?: "Query";
  account?:
    | {
        __typename?: "Account";
        ERC20balances: Array<{
          __typename?: "ERC20Balance";
          valueExact: any;
          contract: {
            __typename?: "ERC20Contract";
            symbol?: string | null | undefined;
          };
        }>;
      }
    | null
    | undefined;
};

export type GetErc721TokenQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetErc721TokenQuery = {
  __typename?: "Query";
  erc721Token?:
    | {
        __typename?: "ERC721Token";
        approval: { __typename?: "Account"; id: string };
        owner: { __typename?: "Account"; id: string };
      }
    | null
    | undefined;
};

export type GetErc721ApprovalQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetErc721ApprovalQuery = {
  __typename?: "Query";
  erc721Token?:
    | {
        __typename?: "ERC721Token";
        approval: { __typename?: "Account"; id: string };
        owner: { __typename?: "Account"; id: string };
      }
    | null
    | undefined;
};

export type GetMonstaQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetMonstaQuery = {
  __typename?: "Query";
  monsta?:
    | {
        __typename?: "Monsta";
        id: string;
        genes: any;
        owner: any;
        timestamp: any;
        classType?: number | null | undefined;
        cloneUsage?: number | null | undefined;
      }
    | null
    | undefined;
};

export type GetParentsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetParentsQuery = {
  __typename?: "Query";
  monsta?:
    | {
        __typename?: "Monsta";
        sire?:
          | {
              __typename?: "Monsta";
              id: string;
              genes: any;
              owner: any;
              timestamp: any;
              classType?: number | null | undefined;
              cloneUsage?: number | null | undefined;
            }
          | null
          | undefined;
        matron?:
          | {
              __typename?: "Monsta";
              id: string;
              genes: any;
              owner: any;
              timestamp: any;
              classType?: number | null | undefined;
              cloneUsage?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetChildrenQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type GetChildrenQuery = {
  __typename?: "Query";
  matron: Array<{
    __typename?: "Monsta";
    id: string;
    auctionStatus?: MonstaEventType | null | undefined;
    genes: any;
    owner: any;
    timestamp: any;
    classType?: number | null | undefined;
    cloneUsage?: number | null | undefined;
    monstaEvents: Array<
      | { __typename?: "AuctionCancel"; timestamp: any }
      | {
          __typename?: "AuctionCreate";
          startingPrice: any;
          endingPrice: any;
          duration: any;
          seller: any;
          timestamp: any;
        }
      | { __typename?: "AuctionSucceed"; timestamp: any }
      | { __typename?: "MonstaEvolve"; timestamp: any }
      | { __typename?: "MonstaRebirth"; timestamp: any }
      | { __typename?: "MonstaRetire"; timestamp: any }
    >;
  }>;
  sire: Array<{
    __typename?: "Monsta";
    id: string;
    auctionStatus?: MonstaEventType | null | undefined;
    genes: any;
    owner: any;
    timestamp: any;
    classType?: number | null | undefined;
    cloneUsage?: number | null | undefined;
    monstaEvents: Array<
      | { __typename?: "AuctionCancel"; timestamp: any }
      | {
          __typename?: "AuctionCreate";
          startingPrice: any;
          endingPrice: any;
          duration: any;
          seller: any;
          timestamp: any;
        }
      | { __typename?: "AuctionSucceed"; timestamp: any }
      | { __typename?: "MonstaEvolve"; timestamp: any }
      | { __typename?: "MonstaRebirth"; timestamp: any }
      | { __typename?: "MonstaRetire"; timestamp: any }
    >;
  }>;
};

export type GetAuctionMonstaQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAuctionMonstaQuery = {
  __typename?: "Query";
  monsta?:
    | {
        __typename?: "Monsta";
        id: string;
        token: any;
        genes: any;
        owner: any;
        auctionStatus?: MonstaEventType | null | undefined;
        timestamp: any;
        monstaEvents: Array<
          | { __typename?: "AuctionCancel"; timestamp: any }
          | {
              __typename?: "AuctionCreate";
              startingPrice: any;
              endingPrice: any;
              duration: any;
              seller: any;
              timestamp: any;
            }
          | { __typename?: "AuctionSucceed"; timestamp: any }
          | { __typename?: "MonstaEvolve"; timestamp: any }
          | { __typename?: "MonstaRebirth"; timestamp: any }
          | { __typename?: "MonstaRetire"; timestamp: any }
        >;
      }
    | null
    | undefined;
};

export type GetMonstasQueryVariables = Exact<{ [key: string]: never }>;

export type GetMonstasQuery = {
  __typename?: "Query";
  monstas: Array<{
    __typename?: "Monsta";
    id: string;
    genes: any;
    owner: any;
    timestamp: any;
    classType?: number | null | undefined;
    cloneUsage?: number | null | undefined;
  }>;
};

export type GetUserAuctionsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  first?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Monsta_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Monsta_Filter>;
}>;

export type GetUserAuctionsQuery = {
  __typename?: "Query";
  monstas: Array<{
    __typename?: "Monsta";
    id: string;
    genes: any;
    cloneUsage?: number | null | undefined;
    auctionStatus?: MonstaEventType | null | undefined;
    monstaEvents: Array<
      | { __typename?: "AuctionCancel"; timestamp: any }
      | {
          __typename?: "AuctionCreate";
          startingPrice: any;
          endingPrice: any;
          duration: any;
          seller: any;
          timestamp: any;
        }
      | { __typename?: "AuctionSucceed"; timestamp: any }
      | { __typename?: "MonstaEvolve"; timestamp: any }
      | { __typename?: "MonstaRebirth"; timestamp: any }
      | { __typename?: "MonstaRetire"; timestamp: any }
    >;
  }>;
};

export type GetUserMonstaQueryVariables = Exact<{
  orderBy?: InputMaybe<Monsta_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Monsta_Filter>;
  first?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetUserMonstaQuery = {
  __typename?: "Query";
  monstas: Array<{
    __typename?: "Monsta";
    id: string;
    genes: any;
    timestamp: any;
    classType?: number | null | undefined;
    cloneUsage?: number | null | undefined;
    monstaEvents: Array<
      | { __typename?: "AuctionCancel"; timestamp: any }
      | {
          __typename?: "AuctionCreate";
          startingPrice: any;
          endingPrice: any;
          duration: any;
          seller: any;
          timestamp: any;
        }
      | { __typename?: "AuctionSucceed"; timestamp: any }
      | { __typename?: "MonstaEvolve"; timestamp: any }
      | { __typename?: "MonstaRebirth"; timestamp: any }
      | { __typename?: "MonstaRetire"; timestamp: any }
    >;
  }>;
};

export type GetUserAuctionQueryVariables = Exact<{
  where?: InputMaybe<Monsta_Filter>;
  first?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetUserAuctionQuery = {
  __typename?: "Query";
  monstas: Array<{
    __typename?: "Monsta";
    id: string;
    genes: any;
    classType?: number | null | undefined;
    cloneUsage?: number | null | undefined;
    monstaEvents: Array<
      | { __typename?: "AuctionCancel"; timestamp: any }
      | {
          __typename?: "AuctionCreate";
          startingPrice: any;
          endingPrice: any;
          duration: any;
          seller: any;
          timestamp: any;
        }
      | { __typename?: "AuctionSucceed"; timestamp: any }
      | { __typename?: "MonstaEvolve"; timestamp: any }
      | { __typename?: "MonstaRebirth"; timestamp: any }
      | { __typename?: "MonstaRetire"; timestamp: any }
    >;
  }>;
};

export type GetAuctionCreatedQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]>;
}>;

export type GetAuctionCreatedQuery = {
  __typename?: "Query";
  auctionCreates: Array<{
    __typename?: "AuctionCreate";
    id: string;
    seller: any;
    endingPrice: any;
    timestamp: any;
    token: { __typename?: "Monsta"; id: string; genes: any };
  }>;
};

export type GetAuctionSucceedsQueryVariables = Exact<{
  orderBy?: InputMaybe<AuctionSucceed_OrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AuctionSucceed_Filter>;
  first?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
}>;

export type GetAuctionSucceedsQuery = {
  __typename?: "Query";
  auctionSucceeds: Array<{
    __typename?: "AuctionSucceed";
    id: string;
    winner: any;
    totalPrice: any;
    timestamp: any;
    token: { __typename?: "Monsta"; id: string; genes: any };
  }>;
};

export const GetErc20ApprovalDocument = gql`
  query GetErc20Approval($id: ID!) {
    erc20Approval(id: $id) {
      valueExact
    }
  }
`;

/**
 * __useGetErc20ApprovalQuery__
 *
 * To run a query within a React component, call `useGetErc20ApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErc20ApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErc20ApprovalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetErc20ApprovalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetErc20ApprovalQuery,
    GetErc20ApprovalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetErc20ApprovalQuery, GetErc20ApprovalQueryVariables>(
    GetErc20ApprovalDocument,
    options
  );
}
export function useGetErc20ApprovalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetErc20ApprovalQuery,
    GetErc20ApprovalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetErc20ApprovalQuery,
    GetErc20ApprovalQueryVariables
  >(GetErc20ApprovalDocument, options);
}
export type GetErc20ApprovalQueryHookResult = ReturnType<
  typeof useGetErc20ApprovalQuery
>;
export type GetErc20ApprovalLazyQueryHookResult = ReturnType<
  typeof useGetErc20ApprovalLazyQuery
>;
export type GetErc20ApprovalQueryResult = Apollo.QueryResult<
  GetErc20ApprovalQuery,
  GetErc20ApprovalQueryVariables
>;
export const GetAccountDocument = gql`
  query GetAccount($id: ID!) {
    account(id: $id) {
      ERC20balances {
        contract {
          symbol
        }
        valueExact
      }
    }
  }
`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountQuery,
    GetAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(
    GetAccountDocument,
    options
  );
}
export function useGetAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountQuery,
    GetAccountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(
    GetAccountDocument,
    options
  );
}
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<
  typeof useGetAccountLazyQuery
>;
export type GetAccountQueryResult = Apollo.QueryResult<
  GetAccountQuery,
  GetAccountQueryVariables
>;
export const GetErc721TokenDocument = gql`
  query GetErc721Token($id: ID!) {
    erc721Token(id: $id) {
      approval {
        id
      }
      owner {
        id
      }
    }
  }
`;

/**
 * __useGetErc721TokenQuery__
 *
 * To run a query within a React component, call `useGetErc721TokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErc721TokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErc721TokenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetErc721TokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetErc721TokenQuery,
    GetErc721TokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetErc721TokenQuery, GetErc721TokenQueryVariables>(
    GetErc721TokenDocument,
    options
  );
}
export function useGetErc721TokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetErc721TokenQuery,
    GetErc721TokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetErc721TokenQuery, GetErc721TokenQueryVariables>(
    GetErc721TokenDocument,
    options
  );
}
export type GetErc721TokenQueryHookResult = ReturnType<
  typeof useGetErc721TokenQuery
>;
export type GetErc721TokenLazyQueryHookResult = ReturnType<
  typeof useGetErc721TokenLazyQuery
>;
export type GetErc721TokenQueryResult = Apollo.QueryResult<
  GetErc721TokenQuery,
  GetErc721TokenQueryVariables
>;
export const GetErc721ApprovalDocument = gql`
  query GetErc721Approval($id: ID!) {
    erc721Token(id: $id) {
      approval {
        id
      }
      owner {
        id
      }
    }
  }
`;

/**
 * __useGetErc721ApprovalQuery__
 *
 * To run a query within a React component, call `useGetErc721ApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErc721ApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErc721ApprovalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetErc721ApprovalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetErc721ApprovalQuery,
    GetErc721ApprovalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetErc721ApprovalQuery,
    GetErc721ApprovalQueryVariables
  >(GetErc721ApprovalDocument, options);
}
export function useGetErc721ApprovalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetErc721ApprovalQuery,
    GetErc721ApprovalQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetErc721ApprovalQuery,
    GetErc721ApprovalQueryVariables
  >(GetErc721ApprovalDocument, options);
}
export type GetErc721ApprovalQueryHookResult = ReturnType<
  typeof useGetErc721ApprovalQuery
>;
export type GetErc721ApprovalLazyQueryHookResult = ReturnType<
  typeof useGetErc721ApprovalLazyQuery
>;
export type GetErc721ApprovalQueryResult = Apollo.QueryResult<
  GetErc721ApprovalQuery,
  GetErc721ApprovalQueryVariables
>;
export const GetMonstaDocument = gql`
  query GetMonsta($id: ID!) {
    monsta(id: $id) {
      id
      genes
      owner
      timestamp
      classType
      cloneUsage
    }
  }
`;

/**
 * __useGetMonstaQuery__
 *
 * To run a query within a React component, call `useGetMonstaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonstaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonstaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMonstaQuery(
  baseOptions: Apollo.QueryHookOptions<GetMonstaQuery, GetMonstaQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMonstaQuery, GetMonstaQueryVariables>(
    GetMonstaDocument,
    options
  );
}
export function useGetMonstaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMonstaQuery,
    GetMonstaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMonstaQuery, GetMonstaQueryVariables>(
    GetMonstaDocument,
    options
  );
}
export type GetMonstaQueryHookResult = ReturnType<typeof useGetMonstaQuery>;
export type GetMonstaLazyQueryHookResult = ReturnType<
  typeof useGetMonstaLazyQuery
>;
export type GetMonstaQueryResult = Apollo.QueryResult<
  GetMonstaQuery,
  GetMonstaQueryVariables
>;
export const GetParentsDocument = gql`
  query GetParents($id: ID!) {
    monsta(id: $id) {
      sire {
        id
        genes
        owner
        timestamp
        classType
        cloneUsage
      }
      matron {
        id
        genes
        owner
        timestamp
        classType
        cloneUsage
      }
    }
  }
`;

/**
 * __useGetParentsQuery__
 *
 * To run a query within a React component, call `useGetParentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParentsQuery,
    GetParentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetParentsQuery, GetParentsQueryVariables>(
    GetParentsDocument,
    options
  );
}
export function useGetParentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParentsQuery,
    GetParentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetParentsQuery, GetParentsQueryVariables>(
    GetParentsDocument,
    options
  );
}
export type GetParentsQueryHookResult = ReturnType<typeof useGetParentsQuery>;
export type GetParentsLazyQueryHookResult = ReturnType<
  typeof useGetParentsLazyQuery
>;
export type GetParentsQueryResult = Apollo.QueryResult<
  GetParentsQuery,
  GetParentsQueryVariables
>;
export const GetChildrenDocument = gql`
  query GetChildren($id: String!) {
    matron: monstas(where: { matron: $id }) {
      id
      auctionStatus
      genes
      owner
      timestamp
      classType
      cloneUsage
      monstaEvents(
        orderBy: timestamp
        orderDirection: desc
        where: { type_in: [UserAuctionCreated] }
        first: 1
      ) {
        timestamp
        ... on AuctionCreate {
          startingPrice
          endingPrice
          duration
          seller
        }
      }
    }
    sire: monstas(where: { sire: $id }) {
      id
      auctionStatus
      genes
      owner
      timestamp
      classType
      cloneUsage
      monstaEvents(
        orderBy: timestamp
        orderDirection: desc
        where: { type_in: [UserAuctionCreated] }
        first: 1
      ) {
        timestamp
        ... on AuctionCreate {
          startingPrice
          endingPrice
          duration
          seller
        }
      }
    }
  }
`;

/**
 * __useGetChildrenQuery__
 *
 * To run a query within a React component, call `useGetChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChildrenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChildrenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetChildrenQuery,
    GetChildrenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChildrenQuery, GetChildrenQueryVariables>(
    GetChildrenDocument,
    options
  );
}
export function useGetChildrenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChildrenQuery,
    GetChildrenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChildrenQuery, GetChildrenQueryVariables>(
    GetChildrenDocument,
    options
  );
}
export type GetChildrenQueryHookResult = ReturnType<typeof useGetChildrenQuery>;
export type GetChildrenLazyQueryHookResult = ReturnType<
  typeof useGetChildrenLazyQuery
>;
export type GetChildrenQueryResult = Apollo.QueryResult<
  GetChildrenQuery,
  GetChildrenQueryVariables
>;
export const GetAuctionMonstaDocument = gql`
  query GetAuctionMonsta($id: ID!) {
    monsta(id: $id) {
      id
      token
      genes
      owner
      auctionStatus
      timestamp
      monstaEvents(
        orderBy: timestamp
        orderDirection: desc
        where: { type_in: [UserAuctionCreated] }
        first: 1
      ) {
        timestamp
        ... on AuctionCreate {
          startingPrice
          endingPrice
          duration
          seller
        }
      }
    }
  }
`;

/**
 * __useGetAuctionMonstaQuery__
 *
 * To run a query within a React component, call `useGetAuctionMonstaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuctionMonstaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuctionMonstaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAuctionMonstaQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuctionMonstaQuery,
    GetAuctionMonstaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuctionMonstaQuery, GetAuctionMonstaQueryVariables>(
    GetAuctionMonstaDocument,
    options
  );
}
export function useGetAuctionMonstaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuctionMonstaQuery,
    GetAuctionMonstaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuctionMonstaQuery,
    GetAuctionMonstaQueryVariables
  >(GetAuctionMonstaDocument, options);
}
export type GetAuctionMonstaQueryHookResult = ReturnType<
  typeof useGetAuctionMonstaQuery
>;
export type GetAuctionMonstaLazyQueryHookResult = ReturnType<
  typeof useGetAuctionMonstaLazyQuery
>;
export type GetAuctionMonstaQueryResult = Apollo.QueryResult<
  GetAuctionMonstaQuery,
  GetAuctionMonstaQueryVariables
>;
export const GetMonstasDocument = gql`
  query GetMonstas {
    monstas {
      id
      genes
      owner
      timestamp
      classType
      cloneUsage
    }
  }
`;

/**
 * __useGetMonstasQuery__
 *
 * To run a query within a React component, call `useGetMonstasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonstasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonstasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMonstasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMonstasQuery,
    GetMonstasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMonstasQuery, GetMonstasQueryVariables>(
    GetMonstasDocument,
    options
  );
}
export function useGetMonstasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMonstasQuery,
    GetMonstasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMonstasQuery, GetMonstasQueryVariables>(
    GetMonstasDocument,
    options
  );
}
export type GetMonstasQueryHookResult = ReturnType<typeof useGetMonstasQuery>;
export type GetMonstasLazyQueryHookResult = ReturnType<
  typeof useGetMonstasLazyQuery
>;
export type GetMonstasQueryResult = Apollo.QueryResult<
  GetMonstasQuery,
  GetMonstasQueryVariables
>;
export const GetUserAuctionsDocument = gql`
  query GetUserAuctions(
    $skip: Int
    $first: Int
    $orderBy: Monsta_orderBy
    $orderDirection: OrderDirection
    $where: Monsta_filter
  ) {
    monstas(
      skip: $skip
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $where
    ) {
      id
      genes
      cloneUsage
      auctionStatus
      monstaEvents(
        orderBy: timestamp
        orderDirection: desc
        where: { type_in: [UserAuctionCreated] }
        first: 1
      ) {
        timestamp
        ... on AuctionCreate {
          startingPrice
          endingPrice
          duration
          seller
        }
      }
    }
  }
`;

/**
 * __useGetUserAuctionsQuery__
 *
 * To run a query within a React component, call `useGetUserAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAuctionsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUserAuctionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserAuctionsQuery,
    GetUserAuctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAuctionsQuery, GetUserAuctionsQueryVariables>(
    GetUserAuctionsDocument,
    options
  );
}
export function useGetUserAuctionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAuctionsQuery,
    GetUserAuctionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserAuctionsQuery,
    GetUserAuctionsQueryVariables
  >(GetUserAuctionsDocument, options);
}
export type GetUserAuctionsQueryHookResult = ReturnType<
  typeof useGetUserAuctionsQuery
>;
export type GetUserAuctionsLazyQueryHookResult = ReturnType<
  typeof useGetUserAuctionsLazyQuery
>;
export type GetUserAuctionsQueryResult = Apollo.QueryResult<
  GetUserAuctionsQuery,
  GetUserAuctionsQueryVariables
>;
export const GetUserMonstaDocument = gql`
  query GetUserMonsta(
    $orderBy: Monsta_orderBy
    $orderDirection: OrderDirection
    $where: Monsta_filter
    $first: Int
    $skip: Int
  ) {
    monstas(
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $where
      first: $first
      skip: $skip
    ) {
      id
      genes
      timestamp
      classType
      cloneUsage
      monstaEvents(
        orderBy: timestamp
        orderDirection: desc
        where: { type_in: [SystemAuctionCreated] }
        first: 1
      ) {
        timestamp
        ... on AuctionCreate {
          startingPrice
          endingPrice
          duration
          seller
        }
      }
    }
  }
`;

/**
 * __useGetUserMonstaQuery__
 *
 * To run a query within a React component, call `useGetUserMonstaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMonstaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMonstaQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetUserMonstaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserMonstaQuery,
    GetUserMonstaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserMonstaQuery, GetUserMonstaQueryVariables>(
    GetUserMonstaDocument,
    options
  );
}
export function useGetUserMonstaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserMonstaQuery,
    GetUserMonstaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserMonstaQuery, GetUserMonstaQueryVariables>(
    GetUserMonstaDocument,
    options
  );
}
export type GetUserMonstaQueryHookResult = ReturnType<
  typeof useGetUserMonstaQuery
>;
export type GetUserMonstaLazyQueryHookResult = ReturnType<
  typeof useGetUserMonstaLazyQuery
>;
export type GetUserMonstaQueryResult = Apollo.QueryResult<
  GetUserMonstaQuery,
  GetUserMonstaQueryVariables
>;
export const GetUserAuctionDocument = gql`
  query GetUserAuction($where: Monsta_filter, $first: Int, $skip: Int) {
    monstas(where: $where, first: $first, skip: $skip) {
      id
      genes
      classType
      cloneUsage
      monstaEvents(
        orderBy: timestamp
        orderDirection: desc
        where: { type_in: [UserAuctionCreated] }
        first: 1
      ) {
        timestamp
        ... on AuctionCreate {
          startingPrice
          endingPrice
          duration
          seller
        }
      }
    }
  }
`;

/**
 * __useGetUserAuctionQuery__
 *
 * To run a query within a React component, call `useGetUserAuctionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAuctionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAuctionQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetUserAuctionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserAuctionQuery,
    GetUserAuctionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserAuctionQuery, GetUserAuctionQueryVariables>(
    GetUserAuctionDocument,
    options
  );
}
export function useGetUserAuctionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAuctionQuery,
    GetUserAuctionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserAuctionQuery, GetUserAuctionQueryVariables>(
    GetUserAuctionDocument,
    options
  );
}
export type GetUserAuctionQueryHookResult = ReturnType<
  typeof useGetUserAuctionQuery
>;
export type GetUserAuctionLazyQueryHookResult = ReturnType<
  typeof useGetUserAuctionLazyQuery
>;
export type GetUserAuctionQueryResult = Apollo.QueryResult<
  GetUserAuctionQuery,
  GetUserAuctionQueryVariables
>;
export const GetAuctionCreatedDocument = gql`
  query GetAuctionCreated($first: Int) {
    auctionCreates(orderBy: timestamp, orderDirection: desc, first: $first) {
      id
      token {
        id
        genes
      }
      seller
      endingPrice
      timestamp
    }
  }
`;

/**
 * __useGetAuctionCreatedQuery__
 *
 * To run a query within a React component, call `useGetAuctionCreatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuctionCreatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuctionCreatedQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetAuctionCreatedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAuctionCreatedQuery,
    GetAuctionCreatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAuctionCreatedQuery,
    GetAuctionCreatedQueryVariables
  >(GetAuctionCreatedDocument, options);
}
export function useGetAuctionCreatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuctionCreatedQuery,
    GetAuctionCreatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuctionCreatedQuery,
    GetAuctionCreatedQueryVariables
  >(GetAuctionCreatedDocument, options);
}
export type GetAuctionCreatedQueryHookResult = ReturnType<
  typeof useGetAuctionCreatedQuery
>;
export type GetAuctionCreatedLazyQueryHookResult = ReturnType<
  typeof useGetAuctionCreatedLazyQuery
>;
export type GetAuctionCreatedQueryResult = Apollo.QueryResult<
  GetAuctionCreatedQuery,
  GetAuctionCreatedQueryVariables
>;
export const GetAuctionSucceedsDocument = gql`
  query GetAuctionSucceeds(
    $orderBy: AuctionSucceed_orderBy
    $orderDirection: OrderDirection
    $where: AuctionSucceed_filter
    $first: Int
    $skip: Int
  ) {
    auctionSucceeds(
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $where
      first: $first
      skip: $skip
    ) {
      id
      token {
        id
        genes
      }
      winner
      totalPrice
      timestamp
    }
  }
`;

/**
 * __useGetAuctionSucceedsQuery__
 *
 * To run a query within a React component, call `useGetAuctionSucceedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuctionSucceedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuctionSucceedsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      orderDirection: // value for 'orderDirection'
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAuctionSucceedsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAuctionSucceedsQuery,
    GetAuctionSucceedsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAuctionSucceedsQuery,
    GetAuctionSucceedsQueryVariables
  >(GetAuctionSucceedsDocument, options);
}
export function useGetAuctionSucceedsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuctionSucceedsQuery,
    GetAuctionSucceedsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuctionSucceedsQuery,
    GetAuctionSucceedsQueryVariables
  >(GetAuctionSucceedsDocument, options);
}
export type GetAuctionSucceedsQueryHookResult = ReturnType<
  typeof useGetAuctionSucceedsQuery
>;
export type GetAuctionSucceedsLazyQueryHookResult = ReturnType<
  typeof useGetAuctionSucceedsLazyQuery
>;
export type GetAuctionSucceedsQueryResult = Apollo.QueryResult<
  GetAuctionSucceedsQuery,
  GetAuctionSucceedsQueryVariables
>;
