import { useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import Loading from "../../../../components/Loading/Loading";
import { toBinaryGenes } from "../../../../utils/toBinaryGenes";
import { WEBGL_MONSTA_OBJECT, WEBGL_SOURCE_URL } from "../../constants";
import { UnityContentWrapper } from "./style";

type MonstaUnityAnimationProps = {
  gene: string;
};

const MonstaUnityAnimation = ({ gene }: MonstaUnityAnimationProps) => {
  const WEBGL_BG_COLOR = "#11644f";
  const { unityProvider, sendMessage, isLoaded } = useUnityContext({
    loaderUrl: WEBGL_SOURCE_URL + "/MonstaLoader.loader.js",
    dataUrl: WEBGL_SOURCE_URL + "/MonstaLoader.data",
    frameworkUrl: WEBGL_SOURCE_URL + "/MonstaLoader.framework.js",
    codeUrl: WEBGL_SOURCE_URL + "/MonstaLoader.wasm",
  });

  useEffect(() => {
    if (isLoaded && gene) {
      const monstaGeneBin = toBinaryGenes(gene);
      sendMessage(WEBGL_MONSTA_OBJECT, "SetCameraBgColor", WEBGL_BG_COLOR);
      sendMessage(WEBGL_MONSTA_OBJECT, "CreateMonstaFromGene", monstaGeneBin);
    }
  }, [isLoaded, gene]);

  return (
    <UnityContentWrapper>
      {!isLoaded && <Loading />}
      <Unity className="unity" unityProvider={unityProvider} />
    </UnityContentWrapper>
  );
};

export default MonstaUnityAnimation;
