import { Row, Typography, Col } from "antd";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";

const { Text } = Typography;

export const CenteredRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
`;

export const AlignedCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TokenIdText = styled(Text)`
  font-weight: bold;
  font-size: 18px;
`;

export const ImageContainer = styled.div`
  position: relative;
  margin: 4rem auto;
`;

interface ImageProps {
  startAnimation: boolean;
}

export const EggImage = styled.img<ImageProps>`
  position: relative;
  width: 20vh;
  opacity: 1;

  ${({ startAnimation }) =>
    startAnimation &&
    css`
      animation: ${eggFadeOut} 1s linear 2.2s forwards;
    `}
`;

export const FlashImage = styled.img<ImageProps>`
  width: 30vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  opacity: 0;

  ${({ startAnimation }) =>
    startAnimation &&
    css`
      animation: ${flashFadeInOut} 2s ease 2s;
    `}
`;

export const MonstaImage = styled.img<ImageProps>`
  width: 30vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;

  ${({ startAnimation }) =>
    startAnimation &&
    css`
      animation: ${monstaFadeIn} 2s ease 3s forwards;
    `}
`;

// Keyframes
const eggFadeOut = keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  `;

const flashFadeInOut = keyframes`
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
    }
    50% {
        opacity: 0.7;
        transform: translate(-50%, -50%) scale(2);
    }
    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(2);
    }
`;

const monstaFadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;
