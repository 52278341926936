import { useCallback } from "react";
import { getMonstaPreview, hatchMonsta } from "../services/hatch-api";

export const useHatchApi = () => {
  const preview = useCallback(async (gene: string) => {
    try {
      const res = await getMonstaPreview(gene);
      return res;
    } catch (err) {
      throw err;
    }
  }, []);

  const hatch = useCallback(async (token_id: string) => {
    try {
      const res = await hatchMonsta(token_id);
      return res;
    } catch (err) {
      throw err;
    }
  }, []);
  return { preview, hatch };
};
