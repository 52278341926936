import {
  MonthlyPassWrapper,
  MonthlyPassTitleText,
  MonthlyPassContentWrapper,
  MonthlyPassConditionWrapper,
  MonthlyPassTitleWrapper,
  MonthlyPassContent,
  MonthlyPassContentTitle,
  MonthlyPassContentText,
  MonthlyPassAltText,
  MonthlyPassCard,
} from "../style";
import MonthlyMonstaCrate from "../../../assets/images/fc_sale/monthly_monsta_image.png";

type MonthlyPackageProps = {
  price: number;
};
const MonthlyPackage = ({ price }: MonthlyPackageProps) => {
  return (
    <MonthlyPassWrapper>
      <MonthlyPassCard>
        <MonthlyPassTitleWrapper>
          <MonthlyPassTitleText>
            Fortune Crate Monthly Pass
          </MonthlyPassTitleText>
        </MonthlyPassTitleWrapper>
        <MonthlyPassContentWrapper>
          <img src={MonthlyMonstaCrate} alt="Monthly Crate" />
          <MonthlyPassContent>
            <MonthlyPassContentTitle>
              Subscribe to 10 Bundles
            </MonthlyPassContentTitle>
            <MonthlyPassContentText>
              to get{" "}
              <MonthlyPassAltText>1780 Fortune Crates</MonthlyPassAltText> in a
              month!
            </MonthlyPassContentText>
          </MonthlyPassContent>
        </MonthlyPassContentWrapper>
        <MonthlyPassConditionWrapper>
          <li>
            User will receive <MonthlyPassAltText>1 Bundle</MonthlyPassAltText>{" "}
            every <MonthlyPassAltText>3 days</MonthlyPassAltText> until all{" "}
            <MonthlyPassAltText>10 Bundles</MonthlyPassAltText> have been
            received. Each bundle consists of{" "}
            <MonthlyPassAltText>178 Fortune Crates</MonthlyPassAltText>.
          </li>
          <li>
            Users can only purchase Fortune Crates again once all{" "}
            <MonthlyPassAltText>10 Bundles </MonthlyPassAltText>
            have been received.
          </li>
        </MonthlyPassConditionWrapper>
      </MonthlyPassCard>
    </MonthlyPassWrapper>
  );
};

export default MonthlyPackage;
