import { useWeb3React } from "@web3-react/core";
import { Space, Row, Col } from "antd";
import {
  InfoCircleOutlined,
  PlayCircleFilled,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import {
  FlexContainer,
  StyledButton,
  UserNameContainer,
  WalletAddressText,
  UnderLineLink,
  UserNameText,
  ButtonsContainer,
} from "./style";
import { formatToReadibleNumber } from "../../utils/formatBalance";
import shieldIcon from "../../assets/images/shield-icon.png";
import soulIcon from "../../assets/images/soul-icon.png";
import InputGroup from "./components/InputGroup";
import { Screen } from "./";
import Loading from "../../components/Loading/Loading";
import { Text } from "../../components/Text";

import useTapwar from "./hooks/useTapwar";
import useTapwarAllowance from "./hooks/useTapwarAllowance";
import { SHIELD_TOOLTIP, SOULS_TOOLTIP, WALLET_TOOLTIP } from "./constants";
import { RefreshButton } from "./components/RefreshButton";

type TapwarScreenProps = {
  onChangeScreen: (screen: Screen) => void;
  isAuth: boolean;
  setIsAuth: (value: boolean) => void;
};

const TapwarScreen = (props: TapwarScreenProps) => {
  const { onChangeScreen, isAuth, setIsAuth } = props;

  const { account } = useWeb3React();
  const {
    name,
    shieldBalance,
    soulBalance,
    tapwarScreenLoading,
    setIsRefreshing,
  } = useTapwar(isAuth, setIsAuth);
  const { sttBalance } = useTapwarAllowance();

  return (
    <div>
      <UserNameContainer>
        {tapwarScreenLoading ? (
          <Loading />
        ) : (
          <UserNameText ellipsis>{name}</UserNameText>
        )}
        <RefreshButton onRefresh={() => setIsRefreshing(true)} />
        <WalletAddressText>
          <Text>Monsta Wallet:&nbsp;</Text>
          {account ? (
            <>
              <Text ellipsis>
                {account?.replace("0x", "monsta:").slice(0, -4)}
              </Text>
              {account?.slice(-4)}
            </>
          ) : (
            "Not connected"
          )}
        </WalletAddressText>
      </UserNameContainer>
      <InputGroup
        label="Wallet Balance"
        LabelIcon={<InfoCircleOutlined />}
        toolTipText={WALLET_TOOLTIP}
        inputValue={formatToReadibleNumber(
          `${Math.trunc(sttBalance.toNumber() / 1e5) / 1e3}`
        )}
        inputSuffix="xSTT"
      />
      <InputGroup
        label="Shield"
        icon={shieldIcon}
        LabelIcon={<InfoCircleOutlined />}
        toolTipText={SHIELD_TOOLTIP}
        inputValue={`${shieldBalance}`}
        // onClick={() => onChangeScreen(Screen.tapshop)}
        loading={tapwarScreenLoading}
      />
      <InputGroup
        label="Soul"
        icon={soulIcon}
        LabelIcon={<InfoCircleOutlined />}
        toolTipText={SOULS_TOOLTIP}
        inputValue={`${soulBalance}`}
        loading={tapwarScreenLoading}
      />
      <ButtonsContainer>
        <Row gutter={20}>
          <Col span={12}>
            <StyledButton
              type="ghost"
              size="large"
              onClick={() => onChangeScreen(Screen.allowance)}
              block
            >
              set allowance
            </StyledButton>
          </Col>
          <Col span={12}>
            <StyledButton
              type="ghost"
              size="large"
              onClick={() => onChangeScreen(Screen.tapshop)}
              block
            >
              <ShoppingCartOutlined />
              go to tapshop
            </StyledButton>
          </Col>
        </Row>
      </ButtonsContainer>

      <FlexContainer justifyContent="center">
        <Space direction="vertical" size="middle">
          <div></div>
          {/* temporary hidden for beta version */}
          <FlexContainer justifyContent="space-around" hidden>
            <UnderLineLink>
              <SearchOutlined />
              Guide
            </UnderLineLink>
            <UnderLineLink>
              <PlayCircleFilled />
              Watch Tutorial
            </UnderLineLink>
          </FlexContainer>
        </Space>
      </FlexContainer>
    </div>
  );
};

export default TapwarScreen;
