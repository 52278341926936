/* eslint-disable no-param-reassign */
import DEFAULT_MAINNET_TOKEN_LIST from "../../config/tokenLists/swap-default-mainnet-tokenlist.json";
import DEFAULT_TESTNET_TOKEN_LIST from "../../config/tokenLists/swap-default-testnet-tokenlist.json";
import { config } from "../../config";
import { TokenAddressMap } from "./types";
import { combineMaps, listToTokenMap } from "./helper";

// get all the tokens from active lists, combine with local default tokens
export function useCombinedActiveList(): TokenAddressMap {
  const defaultTokenMap = listToTokenMap(
    config.app.env === "production"
      ? DEFAULT_MAINNET_TOKEN_LIST
      : DEFAULT_TESTNET_TOKEN_LIST
  );
  return combineMaps(defaultTokenMap);
}
