import { WarningOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Checkbox, Input, Typography } from "antd";
import AWARD_WRAPPER_BG from "../../assets/images/monsta_awards/award_bg.png";

const { Text } = Typography;
export const AwardPageWrapper = styled.div`
  background: url(${AWARD_WRAPPER_BG}) no-repeat;
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const AwardCard = styled.div`
  background: #11644f;
  max-width: 900px;
  border-radius: 20px;
  text-align: center;
  padding-left: 130px;
  padding-right: 130px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 50px;
  height: max-content;
  width: 96%;
  @media screen and (max-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }
  @media screen and (max-width: 576px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 96%;
  }
`;

export const AwardImage = styled.img`
  max-height: 250px;
`;

export const TCCheckbox = styled(Checkbox)`
  margin-left: 20px;
  margin-bottom: 20px;
  .ant-checkbox-checked .ant-checkbox-inner {
    color: #ffffff;
    border-color: #ffffff;
    background: transparent;
  }
`;

type BaseTextProps = {
  color?: string;
  fontSize?: string;
  display?: string;
};
export const BaseText = styled(Text)<BaseTextProps>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  color: ${({ color }) => (color ? color : "#ffffff")};
  display: ${({ display }) => (display ? display : "inline")};
`;

export const InputTitle = styled.div`
  text-align: left;
`;

export const MonstaIdInput = styled(Input)`
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 10px;
  min-width: calc(100% - 32px);
  &:hover {
    border: 2px solid #40a9ff;
  }
  &:focus {
    border: 2px solid #40a9ff;
  }
`;

export const AwardButton = styled(Button)`
  width: 95%;
  height: 40px;
  margin-bottom: 2px;
  background: #fee615;
  border-radius: 35px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  border: none;
  &:hover {
    background: #fee615;
    opacity: 0.8;
    color: #000000;
  }
  &:active {
    opacity: 0.9;
    transform: translateY(1px);
    box-shadow: none;
  }
  &:focus {
    background: #fee615;
    color: #000000;
  }
  &:disabled {
    background: #fee615;
    color: #000000;
    opacity: 0.7;
    &:hover {
      background: #fee615;
      color: #000000;
    }
    &:active {
      opacity: 0.7;
      transform: translateY(1px);
      box-shadow: none;
    }
    &:focus {
      background: #fee615;
      opacity: 0.7;
      color: #000000;
    }
  }
`;

export const AwardWarning = styled.div`
  display: flex;
  align-items: center;
  // max-width: 540px;
  gap: 20px;
  justify-content: space-between;
  padding: 18px 31px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
`;

export const WarningIcon = styled(WarningOutlined)`
  font-size: 14px;
  color: #fee615;
`;

export const WarningText = styled(BaseText)`
  line-height: 1.75rem;
  text-align: justify;
`;

export const WarningTextSpan = styled.span`
  color: #fee615;
`;

export const StopIcon = styled(ExclamationCircleOutlined)`
  color: #f05353;
`;

export const StopTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  text-align: justify;
  justify-content: space-between;
  @media screen and (max-width: 576px) {
    gap: 10px;
  }
`;
export const TcAlertText = styled(BaseText)`
  color: #f05353;
`;
