import { StyledFooter, SocialMediaCard } from "./style";

const Footer = () => {
  const SOCIAL_MEDIA = [
    {
      name: "facebook",
      url: "https://www.facebook.com/monstainfinite",
    },
    {
      name: "twitter",
      url: "https://twitter.com/monsta_infinite",
    },
    {
      name: "telegram",
      url: "https://t.me/monstainfinite",
    },
    {
      name: "discord",
      url: "https://discord.com/invite/monsta",
    },
  ];

  const getSocialMediaIcon = (name: string) => {
    const icon =
      require(`../../assets/images/socmed/${name.toLowerCase()}.svg`).default;
    return icon;
  };

  return (
    <StyledFooter>
      {SOCIAL_MEDIA.map((socmed) => {
        return (
          <a
            key={socmed.name}
            href={socmed.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            <SocialMediaCard
              src={getSocialMediaIcon(socmed.name)}
              preview={false}
            />
          </a>
        );
      })}
    </StyledFooter>
  );
};

export default Footer;
