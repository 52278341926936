import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_MARKETPLACE_AGGREGATOR_REST,
  headers: {
    "Content-Type": "application/json",
  },
});

const fetchAggregate = async (filter: any) => {
  // The API will return the number of the aggregate count query
  // depending of the selected filters in marketplace
  try {
    const dataPayload = {
      where: filter,
    };
    const response = await axiosInstance({
      method: "post",
      url: "/aggregate",
      data: dataPayload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchTotalMonsta = async (numberOfHours: number) => {
  // Returns the total Monstas sold in a time frame
  try {
    // Input (accept number of hours as parameter)
    const dataPayload = {
      periodMonsta: numberOfHours,
    };
    const response = await axiosInstance({
      method: "post",
      url: "/total-monsta",
      data: dataPayload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const fetchTotalVolume = async (numberOfHours: number) => {
  // Returns the xSTT sum of Monstas sold in a time frame
  try {
    // Input (accept number of hours as parameter)
    const dataPayload = {
      period: numberOfHours,
    };
    const response = await axiosInstance({
      method: "post",
      url: "/total-volume",
      data: dataPayload,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export { fetchAggregate, fetchTotalMonsta, fetchTotalVolume };
