import { useNavigate } from "react-router-dom";

import DnaImage from "../../../../../assets/images/dna.svg";
import IncubatorImage from "../../../../../assets/images/incubator2.png";
import { TextButton } from "../../../../../components/Button";
import {
  FlexContainer,
  ButtonWrapper,
  CircleContainer,
  CircleImage,
  InfoContainer,
  InfoText,
  CloneUsageContainer,
  CloneUsageDnaImage,
  MessageContainer,
} from "./style";

type CloneSuccessScreenProps = {
  eggId: string;
  eggCloneUsage: number | undefined | null;
};

const CloneSuccessScreen = (props: CloneSuccessScreenProps) => {
  const { eggId, eggCloneUsage } = props;

  const navigate = useNavigate();

  return (
    <FlexContainer>
      <FlexContainer>
        <CircleContainer>
          <CircleImage src={IncubatorImage} alt="Incubator" />
        </CircleContainer>
        <InfoContainer>
          <InfoText>#{eggId}</InfoText>
          <CloneUsageContainer>
            <CloneUsageDnaImage src={DnaImage} alt="DNA Icon" />
            <InfoText>Clone {eggCloneUsage}/4</InfoText>
          </CloneUsageContainer>
        </InfoContainer>
        <MessageContainer>
          <p>Congratulations on your new born egg!</p>
          <p>Please wait for 5 days to hatch and get your new monsta!</p>
        </MessageContainer>
      </FlexContainer>
      <ButtonWrapper>
        <TextButton
          scale="lg"
          bgColor="#fee615"
          textColor="#000000"
          onClick={() => {
            navigate("/");
          }}
        >
          View Profile
        </TextButton>
      </ButtonWrapper>
    </FlexContainer>
  );
};

export default CloneSuccessScreen;
