import { Row, Col, Card, Button, Typography } from "antd";
import styled from "@emotion/styled";
import { ArrowDownOutlined } from "@ant-design/icons";

const { Text } = Typography;

type ViewWrapperProps = {
  bg: string;
};
export const ViewWrapper = styled.div<ViewWrapperProps>`
  background-image: url(${({ bg }) => bg});
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Label = styled(Text)`
  font-size: 14px;
  color: #ffffff;
`;

export const ExchangeCard = styled(Card)`
  max-width: 436px;
  width: 100%;
  z-index: 1;
  background-color: #11644f;
  border-radius: 24px;
  margin-top: 100px;
  overflow: unset;
  @media screen and (max-width: 576px) {
    max-width: 90%;
  }
`;

export const ExchangeLogo = styled.img`
  position: absolute;
  left: 50%;
  top: -90px;
  transform: translateX(-50%);
  @media screen and (max-width: 576px) {
    width: 180px;
  }
`;

export const ExchangeHeader = styled(Card.Meta)`
  justify-content: space-between;
  padding: 60px 24px 24px 0px;
  border-bottom: 1px solid white;
  .ant-card-meta-title {
    color: white;
  }
  .ant-card-meta-description {
    color: white;
  }
  @media screen and (max-width: 576px) {
    padding-top: 40px;
  }
`;

export const ExchangeWrapper = styled(Col)`
  margin-top: 20px;
`;

export const ArrowDown = styled(ArrowDownOutlined)`
  font-size: 16px;
  margin: 10px 15px;
`;

export const SlippageRow = styled(Row)`
  justify-content: space-between;
  padding: 10px 16px;
`;

export const SlippageValue = styled(Label)`
  font-weight: bold;
`;

export const ExchangeButton = styled(Button)`
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  border: 0;
  border-radius: 16px;
  box-shadow: 0px -1px 0px 0px rgba(14, 14, 44, 0.4) inset;
  cursor: pointer;
  letter-spacing: 0.03em;
  line-height: 1;
  color: black;
  background-color: white;
`;

export const ConnectWalletButton = styled(Button)`
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  border: 0;
  border-radius: 16px;
  box-shadow: 0px -1px 0px 0px rgba(14, 14, 44, 0.4) inset;
  cursor: pointer;
  letter-spacing: 0.03em;
  line-height: 1;
  color: black;
  background-color: white;
`;

// CurrencyInputPanel
export const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: ${({ selected }) =>
    selected ? "0.75rem 1rem 0.75rem 1rem" : "0.75rem 1rem 0.75rem 1rem"};
  & > input::placeholder {
    color: white;
  }
`;

export const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: white;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
`;

export const RowBetween = styled(Row)`
  justify-content: space-between;
  width: 100%;
`;

export const CurrencySelectButton = styled(Button)`
  variant: "text",
  scale: "sm",
  padding: 0 0.5rem;
`;

export const InputPanel = styled.div<{ hideInput?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? "8px" : "20px")};
  background-color: white;
  z-index: 1;
`;

export const CurrencyContainer = styled.div<{ hideInput: boolean }>`
  border-radius: 16px;
  background-color: #202020;
`;

export const BalanceLabel = styled(Label)`
  display: inline;
  cursor: pointer;
`;

export const MaxButton = styled(Button)`
  color: #fee615;
`;

export const TokenIcon = styled.img`
  width: 25px;
  marginright: 10px;
`;
export const TokenName = styled.span`
  color: #ffffff;
  width: 50px;
  text-align: right;
`;

// Numerical Input
type NumericalInputStyle = {
  error?: boolean;
  fontSize?: string;
  align?: string;
};
export const StyledInput = styled.input<NumericalInputStyle>`
  color: white;
  width: 0;
  position: relative;
  font-weight: 500;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  font-size: 16px;
  text-align: ${({ align }) => align && align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  -webkit-appearance: textfield;
  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type="number"] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

// Swap Warning Component
export const WarningBox = styled.div`
  margin: 30px 0 50px;
  background: rgba(0, 0, 0, 0.7);
  padding: 16px 20px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  column-gap: 15px;
  @media screen and (max-width: 576px) {
    width: 90%;
  }
`;

export const WarningIcon = styled.img`
  width: 30px;
`;
export const WarningTextBox = styled.div`
  color: white;
`;
export const WarningText = styled(Text)`
  display: block;
`;

export const CheapestMonstaListContainer = styled.div`
  max-width: 750px;
  margin: 0 auto;
  padding-bottom: 40px;
  text-align: center;
`;

export const CheapestDescription = styled.div`
  margin-bottom: 50px;
  font-family: Obelix Pro;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  background: linear-gradient(#f9e931, #f3892b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ViewMoreButton = styled(Button)`
  background: black;
  border: 1px solid #ffe36c;
  border-radius: 30px;
  margin: 30px;
  padding: 8px 50px;
  height: auto;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 500;
`;
