import { useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import { MonstaType } from "../../../models/monsta";
import {
  MonstaPreviewCardCol,
  MonstaPreviewCardIDCard,
  MonstaPreviewCardMonstaImageWrapper,
  MonstaPreviewCardMonstaImage,
  MonstaLoadMoreButtonWrapper,
  ClonedStatus,
  MonstaTypeLabel,
  CardIcon,
  Divider,
} from "../style";
import { CenteredRow } from "../style";
import { MonstaCardWrapper } from "../style";
import Icon from "../../../assets/images/icon.svg";
import { getMonstaType } from "../../../utils/monstaHelpers";
import incubatorImage from "../../../assets/images/incubator2.png";
import { MONSTA_CLASSES } from "../../../models/constants";
import useCloneChamber from "../../../hooks/useCloneChamber";
import CloneChamberButton from "./CloneChamberButton";

type UserMonstaCardsProps = {
  monstas: MonstaType[];
  onLoadMore: () => void;
  monstasLoading: boolean;
  monstasMaxReached: boolean;
};

const UserMonstaCards = ({
  monstas,
  onLoadMore,
  monstasLoading,
  monstasMaxReached,
}: UserMonstaCardsProps) => {
  const navigate = useNavigate();
  const { addMonstaChamber } = useCloneChamber();

  const handleClickMonsta = (monsta: MonstaType) => {
    const { id, isAdult, timeLeft } = monsta;
    if (isAdult) {
      navigate(`/monsta/${id}`, {
        state: {
          prevPath: "profile",
          scrollTop: window.scrollY,
          monstasCount: monstas.length,
        },
      });
    } else if (!timeLeft) {
      navigate(`/hatch/${id}`);
    } else {
      message.warning("Not ready to hatch");
    }
  };

  return (
    <>
      <MonstaCardWrapper>
        {monstas.map((monsta) => (
          <MonstaPreviewCardCol
            key={monsta.id}
            onClick={() => handleClickMonsta(monsta)}
          >
            <MonstaPreviewCardMonstaImageWrapper>
              <MonstaPreviewCardMonstaImage
                src={monsta.isAdult ? monsta.img : incubatorImage}
                preview={false}
                adult={!!monsta.isAdult}
              />
              {!!monsta.isAdult && monsta.cloneUsage < 4 && (
                <CloneChamberButton
                  onClick={(e) => {
                    e.stopPropagation();
                    addMonstaChamber(monsta);
                  }}
                />
              )}
            </MonstaPreviewCardMonstaImageWrapper>
            <MonstaPreviewCardIDCard>#{monsta.id}</MonstaPreviewCardIDCard>
            {!!monsta.isAdult && (
              <CardIcon
                src={MONSTA_CLASSES[monsta.classType].roundIcon}
                alt="icon"
              />
            )}
            {!monsta.isAdult && (
              <CenteredRow>
                {monsta.timeLeft ? monsta.timeLeft + " Left" : "Hatch"}
              </CenteredRow>
            )}
            <CenteredRow>
              <MonstaTypeLabel>{getMonstaType(monsta.id)}</MonstaTypeLabel>
            </CenteredRow>
            <CenteredRow>
              <Divider />
            </CenteredRow>
            <CenteredRow>
              <ClonedStatus>Cloned {monsta.cloneUsage}/4</ClonedStatus>
            </CenteredRow>
          </MonstaPreviewCardCol>
        ))}
      </MonstaCardWrapper>
      <MonstaLoadMoreButtonWrapper hidden={monstasMaxReached}>
        <Button
          shape="round"
          size="large"
          loading={monstasLoading}
          onClick={onLoadMore}
        >
          Load More
        </Button>
      </MonstaLoadMoreButtonWrapper>
    </>
  );
};

export default UserMonstaCards;
