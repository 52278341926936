import styled from "@emotion/styled";

export const MonstaCardList = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;

  /* MOBILE */
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const MonstaCardContainer = styled.div`
  width: 230px;
  height: 270px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
`;

export const MonstaCardImageWrapper = styled.div`
  width: 230px;
  height: 230px;
  background: white;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type MonstaCardImageProps = {
  isSelected?: boolean;
};
export const MonstaCardImage = styled.img<MonstaCardImageProps>`
  width: 80%;
  opacity: ${(props) => (props.isSelected ? "100%" : "30%")};
  transition-duration: 300ms;

  &:hover {
    opacity: 100%;
  }
`;

export const MonstaCardInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MonstaCardInfoText = styled.p`
  font-size: 1rem;
  margin-bottom: 0px;
`;

export const MonstaCardCloneUsageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

export const MonstaCardCloneUsageDnaImage = styled.img`
  transform: rotate(90deg);
  width: 1rem;
`;

export const MonstaCardPagination = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  & .ant-pagination {
    background-color: #000;
    & .ant-pagination-total-text {
      color: grey;
      position: absolute;
      right: 0;
      margin-right: 15%;
    }
    & .ant-pagination-item {
      border: 1px solid #9c99ae;
      border-radius: 0;
      background-color: #000000;
      & > a {
        color: white;
      }
      &.ant-pagination-item-active {
        background: #ffffff;
        border: none;
        a {
          color: #000;
        }
      }
    }
    & .ant-pagination-prev,
    & .ant-pagination-next {
      border: 1px solid #9c99ae;
      border-radius: 0;
      button {
        background-color: #000000;
      }
      & .ant-pagination-item-link {
        color: white;
        border: none;
      }
    }
    & .anticon-double-right,
    & .anticon-double-left {
      color: white;
    }
    & .ant-pagination-item-ellipsis {
      color: #ffffff !important;
    }
  }
`;
