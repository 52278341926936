import {
  Layout,
  Col,
  Row,
  Typography,
  Button,
  Radio,
  Input,
  Divider,
  Slider,
  Checkbox,
  Select,
} from "antd";
import styled from "@emotion/styled";

const { Content, Sider } = Layout;
const { Text } = Typography;

export const MarketplaceContent = styled(Content)`
  padding: 2rem;
  min-height: calc(100vh - 64px);
`;
export const MarketplaceSider = styled(Sider)`
  padding: 2rem 0rem;
`;

export const SiderContent = styled.div`
  padding: 0rem 2rem;
`;

export const SiderTriggerButtonOutside = styled(Button)`
  margin-right: 1rem;
  background: white;
  color: black;
  border: solid 1px white;

  &:hover {
    background: white;
    color: black;
    border: solid 1px white;
  }

  &:focus {
    background: white;
    color: black;
    border: solid 1px white;
  }

  &:active {
    background: white;
    color: black;
    border: solid 1px white;
  }
`;

export const SiderTriggerButtonInside = styled(Button)`
  margin-right: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  background: #0d4134;
  color: white;
  border: solid 1px #0d4134;

  &:hover {
    background: white;
    color: black;
    border: solid 1px white;
  }

  &:focus {
    background: #0d4134;
    color: white;
    border: solid 1px #0d4134;
  }

  &:active {
    background: #0d4134;
    color: white;
    border: solid 1px #0d4134;
  }
`;

export const SiderSectionTitle = styled.p`
  margin: 1rem 0rem 1rem 0rem;
  font-weight: bold;
`;

export const SiderTextInput = styled(Input)`
  background: #0d4134;
  border: solid 1px #0d4134;

  &:hover {
    border: solid 1px white;
  }

  &:focus {
    border: solid 1px white;
  }

  .ant-input {
    background: #0d4134;
  }
`;

export const SiderDivider = styled(Divider)`
  background: #707070;
  height: 2px;
`;

type MonstaTypeItemProps = {
  checked?: boolean;
};
export const MonstaTypeItem = styled.div<MonstaTypeItemProps>`
  cursor: pointer;
  text-align: center;
  img {
    width: 50px;
    padding: 0.3rem;
    border-radius: 0.5rem;
    background: ${(props) => (props.checked ? "#30413c" : "none")};
    transition: background 0.2s ease;
  }
  .title {
    padding-top: 0.3rem;
    cursor: pointer;
    font-size: 0.7rem;
  }
`;

export const SiderCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background: #202020;
    border-color: white;
  }
`;

export const SiderCheckboxIcon = styled.img`
  width: 16px;
  margin-right: 0.4rem;
`;

export const SiderCloneUsageSlider = styled(Slider)`
  .ant-slider-mark-text {
    color: white;
  }

  .ant-slider-dot {
    background-color: #f0f0f0;
  }

  .ant-slider-track {
    background: #f9ff6b;
  }

  .ant-slider-dot-active {
    border-color: #f9ff6b;
    background-color: #f9ff6b;
  }

  .ant-slider-handle {
    border: solid 2px #f9ff6b;
    background-color: #f9ff6b;
  }
`;

// MonstaPreviewCard
export const MonstaPreviewCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MonstaPreviewCardCol = styled(Col)`
  width: 200px;
  height: 280px;
  border-radius: 10px;
  max-width: 200px;
  text-align: center;
  cursor: pointer;
  position: relative;

  &:hover {
    opacity: 0.8;
  }
`;

export const MonstaPreviewCardHeader = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
`;

export const MonstaPreviewCardBodyImage = styled.div<{ background: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  background-image: url(${(props) => props.background});
  background-size: cover;
`;
export const MonstaPreviewCardTagWrapper = styled(Row)`
  display: flex;
  justify-content: space-between;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -5px;
  & .ant-image {
    top: -5px;
  }
`;

export const MonstaPreviewTableImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const MonstaPreviewCardTopLeft = styled.div`
  background: #232323;
  padding: 10px 7px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 10px;
`;
export const MonstaPreviewCardKindImage = styled.img`
  width: 30px;
`;

export const MonstaPreviewCardTopRightTagImage = styled.img`
  transform: scale(0.8);
  margin: 5px 5px auto auto;
`;
export const MonstaPreviewCardMonstaImage = styled.img`
  width: 100%;
  position: absolute;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
`;
export const MonstaPreviewCardIDCard = styled(Text)`
  background-color: black;
  font-size: 0.8rem;
  border-radius: 6px;
  color: white;
  padding: 0px 6px;
`;
export const MonstaPreviewCardCloned = styled.div`
  width: 21px;
  height: 41px;
  margin: 5px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  img {
    width: 90%;
  }
  span {
    color: white;
    font-size: 8px;
  }
`;
export const MonstaPreviewCardID = styled.div`
  font-size: 10px;
  color: white;
  white-space: nowrap;
  padding: 5px 8px;
`;
export const MonstaPreviewCardPriceCard = styled(Text)`
  background: linear-gradient(284.5deg, #0e423b 3.77%, #0b6c52 96.23%);
  border: 1px solid #414042;
  width: 165px;
  height: 36px;
  padding-top: 12px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;
export const MonstaPreviewCardCurrencyIcon = styled.img`
  position: absolute;
  top: 3px;
  width: 23px;
  left: 7px;
`;
export const MonstaPreviewCardCurrencyLabel = styled.span`
  background: #231f20;
  height: 14px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
`;

export const PageTitle = styled.p`
  font-family: "Molot", sans-serif;
  font-size: 38px;
  margin-bottom: 12px;
`;

export const MonstaPreviewCardRow = styled(Row)`
  row-gap: 16px;
  column-gap: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
`;
export const MarketplaceButton = styled(Button)`
  background: #253933;
  border-radius: 10px;
  margin: 0.3rem;
  font-size: 0.7rem;
  border: 0;
  &:hover {
    color: #253933;
    background: white;
  }
  &:focus {
    color: #253933;
    background: white;
  }

  &:active {
    color: #253933;
    background: white;
  }
`;
export const MarketplaceRadioButton = styled(Radio.Button)`
  background: #253933;
  font-size: 0.7rem;
  color: white;
  border: 0;
  &:hover {
    color: #253933;
    background: white;
  }
  &:active {
    color: #253933;
    background: white;
  }
`;
export const MarketplaceRadioWrapper = styled(Radio.Group)`
  border-radius: 10px;
  &:first-of-type {
    border-radius: 10px 0 0 10px;
  }
  &:last-of-type {
    border-radius: 0 10px 10px 0;
  }
  & .ant-radio-button-wrapper:first-of-type {
    border-left: 0px;
    border-right: 0px;
    border-radius: 10px 0 0 10px;
  }
  & .ant-radio-button-wrapper {
    border-left: 0px;
    border-right: 0px;
  }
  & .ant-radio-button-wrapper:last-of-type {
    border-right: 0px;
    border-radius: 0 10px 10px 0;
  }
  &
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #253933;
    background: white;
  }
  &
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-of-type {
    border-color: white;
    border-radius: 10px 0 0 10px;
  }
  &
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):last-of-type {
    border-color: white;
    border-radius: 0 10px 10px 0;
  }
  & .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #253933;
    background: white;
  }
  & .ant-radio-button-wrapper-checked {
    color: #253933;
    background: white;
  }
  &
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: transparent;
  }
  & .ant-radio-button-wrapper:not(:first-of-type)::before {
    background: transparent;
  }
`;
export const SortButtonStyle = styled(Button)`
  background: #253933;
  border-radius: 10px;
  font-size: 0.7rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  border: 0;
  margin: 0.3rem;
  &:hover {
    color: #253933;
    background: white;
  }
  &:focus {
    color: #253933;
    background: white;
  }
  &:active {
    color: #253933;
    background: white;
  }
`;
export const SortButtonLabel = styled.span`
  background: white;
  color: #253933;
  padding: 6px;
  border-radius: 10px 18px 18px 10px;
  margin-right: 7.5px;
  height: 100%;
`;
export const MarketplaceFilterBarRow = styled(Row)`
  margin: 1rem auto;
`;
export const MarketplacePagination = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  & .ant-pagination {
    background-color: #000;
    & .ant-pagination-total-text {
      color: grey;
      position: absolute;
      right: 0;
      margin-right: 15%;
    }
    & .ant-pagination-item {
      border: 1px solid #9c99ae;
      border-radius: 0;
      background-color: #000000;
      & > a {
        color: white;
      }
      &.ant-pagination-item-active {
        background: #ffffff;
        border: none;
        a {
          color: #000;
        }
      }
    }
    & .ant-pagination-prev,
    & .ant-pagination-next {
      border: 1px solid #9c99ae;
      border-radius: 0;
      button {
        background-color: #000000;
      }
      & .ant-pagination-item-link {
        color: white;
        border: none;
      }
    }
    & .anticon-double-right,
    & .anticon-double-left {
      color: white;
    }
    & .ant-pagination-item-ellipsis {
      color: #ffffff !important;
    }
  }
`;
export const StyledSortSelect = styled(Select)`
  font-size: 0.7rem;
  color: white;
  width: 115px;

  .ant-select-selector {
    background-color: #253933 !important;
    border: none !important;
    border-radius: 6px !important;
  }

  .ant-select-arrow {
    color: white;
  }

  .ant-select-item-option-selected {
    background: white;
    color: #253933;
  }

  .ant-select-item-option:hover {
    background: #516661;
  }
`;

export const MarketplaceTotalMonsta = styled.div`
  font-size: 25px;
  font-weight: bold;
`;
