import { keyframes } from "@emotion/react";

export const eggFadeOut = keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;

export const flashFadeInOut = keyframes`
    0% {
      opacity: 0; transform: scale(0)
    }
    50% {
      opacity: 0.7;transform: scale(2)
    }
    100% {
      opacity: 0; transform: scale(2)
    }
`;

export const monstaFadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;
