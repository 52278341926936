import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MonstaType } from "../../../models/monsta";
import { AppThunk } from "..";

export interface CloneChamberState {
  monstas: (MonstaType | null)[];
}

const initialState: CloneChamberState = {
  monstas: [null, null],
};

export const slice = createSlice({
  name: "CloneChamber",
  initialState,
  reducers: {
    addMonsta: (
      state: CloneChamberState,
      action: PayloadAction<{ monsta: MonstaType }>
    ) => {
      const { monsta } = action.payload;
      const existingMonsta = state.monstas.find((m) => m?.id === monsta.id);
      if (!existingMonsta) {
        if (!state.monstas[0]) {
          state.monstas[0] = monsta;
        } else if (!state.monstas[1]) {
          state.monstas[1] = monsta;
        }
      }
    },
    removeMonsta: (
      state: CloneChamberState,
      action: PayloadAction<{ id: number }>
    ) => {
      const { id } = action.payload;
      if (id in [0, 1]) {
        state.monstas[id] = null;
      }
    },
    clearMonstas: (state: CloneChamberState) => {
      state.monstas = initialState.monstas;
    },
  },
});

export const addMonsta =
  (monsta: MonstaType): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.addMonsta({ monsta }));
  };

export const removeMonsta =
  (id: number): AppThunk =>
  (dispatch) => {
    dispatch(slice.actions.removeMonsta({ id }));
  };

export const clearMonstas = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.clearMonstas());
};

export default slice.reducer;
