import { Image } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import {
  MonstaPreviewCardChamberButtonWrapper,
  MonstaPreviewCardChamberButton,
  MonstaPreviewCardChamberImageWrapper,
} from "../style";
import chamberCroppedImg from "../../../assets/images/chamber-cropped.png";

type CloneChamberButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const CloneChamberButton = ({ onClick }: CloneChamberButtonProps) => {
  return (
    <MonstaPreviewCardChamberButtonWrapper className="monsta-preview-card-chamber-button-wrapper">
      <MonstaPreviewCardChamberButton
        onClick={onClick}
        shape="round"
        size="large"
        block
      >
        <PlusCircleFilled />
        Cloning Chamber
        <MonstaPreviewCardChamberImageWrapper>
          <Image src={chamberCroppedImg} preview={false} />
        </MonstaPreviewCardChamberImageWrapper>
      </MonstaPreviewCardChamberButton>
    </MonstaPreviewCardChamberButtonWrapper>
  );
};

export default CloneChamberButton;
