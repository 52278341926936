// your-app-name/src/fetchGraphQL.js
import { config } from "../config";

async function fetchGraphQL(endpoint: String, text: String, variables: any) {
  // Fetch data from GitHub's GraphQL API:
  const response = await fetch(config.app.marketplaceGraphql + endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: text,
      variables,
    }),
  });

  // Get the response as JSON
  return await response.json();
}

export default fetchGraphQL;
