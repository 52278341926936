import {
  MonstaPreviewCardCol,
  MonstaPreviewCardMonstaImage,
  MonstaPreviewCardPriceCard,
  MonstaPreviewCardTopLeft,
  MonstaPreviewCardTopRightTagImage,
  MonstaPreviewCardKindImage,
  MonstaPreviewCardHeader,
  MonstaPreviewCardBodyImage,
  MonstaPreviewCardCurrencyLabel,
  MonstaPreviewCardCurrencyIcon,
  MonstaPreviewCardCloned,
  MonstaPreviewCardID,
  MonstaPreviewTableImage,
} from "../style";
import { useNavigate } from "react-router-dom";
import { formatToReadibleNumber } from "../../../utils/formatBalance";

import { MonstaEventType } from "../../../graphql/generated-types";
import {
  MONSTA_CLASS_DISPLAY_VALUES,
  MONSTA_TYPE_DISPLAY_VALUES,
} from "../constants";
import CloneImg from "../../../assets/images/marketplace_card/clone.svg";
import xSTT from "../../../assets/images/xstt.png";
import RoundTableImg from "../../../assets/images/marketplace_card/table.png";

type MonstaPreviewProps = {
  id: string;
  price: string;
  image: any;
  monstaTypeKey: string;
  monstaClassKey: string;
  cloneUsage: string;
  auctionStatus: MonstaEventType;
  gotoDetailPage: Function;
};

const MonstaPreviewCard = (props: MonstaPreviewProps) => {
  const {
    id,
    price,
    image,
    monstaTypeKey,
    monstaClassKey,
    cloneUsage,
    auctionStatus,
    gotoDetailPage,
  } = props;
  const navigate = useNavigate();

  const monstaClassTitle =
    MONSTA_CLASS_DISPLAY_VALUES[monstaClassKey] &&
    MONSTA_CLASS_DISPLAY_VALUES[monstaClassKey].title;
  const monstaClassImage =
    MONSTA_CLASS_DISPLAY_VALUES[monstaClassKey] &&
    MONSTA_CLASS_DISPLAY_VALUES[monstaClassKey].image;
  const monstaTypeBg =
    MONSTA_CLASS_DISPLAY_VALUES[monstaClassKey] &&
    MONSTA_CLASS_DISPLAY_VALUES[monstaClassKey].background;
  const monstaTypeImage =
    MONSTA_TYPE_DISPLAY_VALUES[monstaTypeKey] &&
    MONSTA_TYPE_DISPLAY_VALUES[monstaTypeKey].image;

  const formattedPrice = formatToReadibleNumber(price);

  return (
    <MonstaPreviewCardCol
      key={id}
      style={{
        paddingLeft: "0",
        paddingRight: "0",
      }}
    >
      <div onClick={() => gotoDetailPage(id)}>
        <MonstaPreviewCardBodyImage background={monstaTypeBg || ""} />
        <MonstaPreviewCardHeader>
          <MonstaPreviewCardTopLeft>
            <MonstaPreviewCardKindImage src={monstaTypeImage} />
            <MonstaPreviewCardCloned>
              <img src={CloneImg} alt="clone" />
              <span>{cloneUsage}/4</span>
            </MonstaPreviewCardCloned>
          </MonstaPreviewCardTopLeft>
          <MonstaPreviewCardID>ID: {id}</MonstaPreviewCardID>
          <MonstaPreviewCardTopRightTagImage
            src={monstaClassImage}
            alt="Monsta type icon"
          />
        </MonstaPreviewCardHeader>
        <MonstaPreviewTableImage src={RoundTableImg} />
        <MonstaPreviewCardMonstaImage src={image} />
        {auctionStatus === MonstaEventType.UserAuctionCreated && (
          <MonstaPreviewCardPriceCard>
            <MonstaPreviewCardCurrencyLabel>
              xSTT
            </MonstaPreviewCardCurrencyLabel>
            <MonstaPreviewCardCurrencyIcon src={xSTT} alt="xSTT" />
            {formattedPrice}
          </MonstaPreviewCardPriceCard>
        )}
      </div>
    </MonstaPreviewCardCol>
  );
};

export default MonstaPreviewCard;
