import { Col, Image } from "antd";
import MonstaTag from "./MonstaTag";
import {
  CenteredRow,
  MonstaBody,
  MonstaBodyImage,
  MonstaBodyLabelImage,
} from "./style";
import { Text } from "../../../../components/Text";
import { MonstaBodyParts } from "./types";
import { MONSTA_CLASSES } from "../../../../models/constants";

type MonstaBodyCardProps = {
  bodyPart: MonstaBodyParts;
};

const MonstaBodyCard = ({ bodyPart }: MonstaBodyCardProps) => {
  return (
    <Col xs={12} sm={8} md={8} lg={8}>
      <div style={{ width: "fit-content", margin: "auto" }}>
        <MonstaBody>
          <MonstaBodyLabelImage>
            <Image
              src={MONSTA_CLASSES[bodyPart.class].roundIcon}
              preview={false}
            />
          </MonstaBodyLabelImage>
          <CenteredRow>
            <MonstaBodyImage>
              <Image src={bodyPart.img} preview={false} />
            </MonstaBodyImage>
            <CenteredRow>
              <Text size="1rem" weight="bold">
                {MONSTA_CLASSES[bodyPart.class].name}
              </Text>
            </CenteredRow>
            <CenteredRow>
              <MonstaTag
                text={`VARIANT: ${bodyPart.variant}`}
                color="--monsta-gray"
                size="sm"
              />
              {bodyPart.isSpecial ? (
                <MonstaTag text="LEGENDARY" color="--monsta-yellow" size="sm" />
              ) : (
                <MonstaTag text="NORMAL" color="--monsta-gray" size="sm" />
              )}
            </CenteredRow>
          </CenteredRow>
        </MonstaBody>
      </div>
    </Col>
  );
};

export default MonstaBodyCard;
