import {
  BorderedCol,
  EggImage,
  InceptionText,
  MonstaCardWrapper,
} from "../style";
import InceptionEgg from "../../../assets/images/InceptionEgg.svg";
import { useNavigate } from "react-router-dom";

type InceptionEggProps = {
  eggs: number;
};

const InceptionEggCardList = ({ eggs }: InceptionEggProps) => {
  const navigate = useNavigate();
  return (
    <MonstaCardWrapper>
      {[...Array(eggs)].map((_, i) => (
        <BorderedCol key={i} onClick={() => navigate("/hatch-inception")}>
          <EggImage src={InceptionEgg} preview={false} />
          <InceptionText>Inception</InceptionText>
        </BorderedCol>
      ))}
    </MonstaCardWrapper>
  );
};

export default InceptionEggCardList;
