import buddyMagnet_1 from "../../assets/images/monsta_awards/buddy_magnet_1.png";
import buddyMagnet_2 from "../../assets/images/monsta_awards/buddy_magnet_2.png";
import buddyMagnet_3 from "../../assets/images/monsta_awards/buddy_magnet_3.png";
import doctorsInWar_1 from "../../assets/images/monsta_awards/doctors_in_war_1.png";
import doctorsInWar_2 from "../../assets/images/monsta_awards/doctors_in_war_2.png";
import doctorsInWar_3 from "../../assets/images/monsta_awards/doctors_in_war_3.png";
import soulThieves_1 from "../../assets/images/monsta_awards/soul_thieves_1.png";
import soulThieves_2 from "../../assets/images/monsta_awards/soul_thieves_2.png";
import soulThieves_3 from "../../assets/images/monsta_awards/soul_thieves_3.png";
import theProtector_1 from "../../assets/images/monsta_awards/the_protector_1.png";
import theProtector_2 from "../../assets/images/monsta_awards/the_protector_2.png";
import theProtector_3 from "../../assets/images/monsta_awards/the_protector_3.png";
import originRivalry_1 from "../../assets/images/monsta_awards/origins_of_the_rivalry_1.png";
import originRivalry_2 from "../../assets/images/monsta_awards/origins_of_the_rivalry_2.png";
import originRivalry_3 from "../../assets/images/monsta_awards/origins_of_the_rivalry_3.png";
import headToHeadRivalries_1 from "../../assets/images/monsta_awards/head_to_head_rivalries_1.png";
import headToHeadRivalries_2 from "../../assets/images/monsta_awards/head_to_head_rivalries_2.png";
import headToHeadRivalries_3 from "../../assets/images/monsta_awards/head_to_head_rivalries_3.png";
import raceOfTheReapers_1 from "../../assets/images/monsta_awards/race_of_the_reapers_1.png";
import raceOfTheReapers_2 from "../../assets/images/monsta_awards/race_of_the_reapers_2.png";
import raceOfTheReapers_3 from "../../assets/images/monsta_awards/race_of_the_reapers_3.png";
import santaSnowdown_1 from "../../assets/images/monsta_awards/santa_snowdown_1.png";
import santaSnowdown_2 from "../../assets/images/monsta_awards/santa_snowdown_2.png";
import santaSnowdown_3 from "../../assets/images/monsta_awards/santa_snowdown_3.png";
interface MonstaAwards {
  [key: string]: string;
}
export const MONSTA_AWARDS: MonstaAwards = {
  "buddy-magnet-1": buddyMagnet_1,
  "buddy-magnet-2": buddyMagnet_2,
  "buddy-magnet-3": buddyMagnet_3,
  "doctor-in-war-1": doctorsInWar_1,
  "doctor-in-war-2": doctorsInWar_2,
  "doctor-in-war-3": doctorsInWar_3,
  "soul-thieves-1": soulThieves_1,
  "soul-thieves-2": soulThieves_2,
  "soul-thieves-3": soulThieves_3,
  "the-protector-1": theProtector_1,
  "the-protector-2": theProtector_2,
  "the-protector-3": theProtector_3,
  "origin-rivalry-1": originRivalry_1,
  "origin-rivalry-2": originRivalry_2,
  "origin-rivalry-3": originRivalry_3,
  "head-to-head-1": headToHeadRivalries_1,
  "head-to-head-2": headToHeadRivalries_2,
  "head-to-head-3": headToHeadRivalries_3,
  "race-reapers-1": raceOfTheReapers_1,
  "race-reapers-2": raceOfTheReapers_2,
  "race-reapers-3": raceOfTheReapers_3,
  "santa-snowdown-1": santaSnowdown_1,
  "santa-snowdown-2": santaSnowdown_2,
  "santa-snowdown-3": santaSnowdown_3,
};
