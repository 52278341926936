import bomohRoundIcon from "../assets/images/bomoh-round-icon.svg";
import nightingaleRoundIcon from "../assets/images/nightingale-round-icon.svg";
import juggernautRoundIcon from "../assets/images/juggernaut-round-icon.svg";
import terminatorRoundIcon from "../assets/images/terminator-round-icon.svg";
import golemRoundIcon from "../assets/images/golem-round-icon.svg";
import prophetRoundIcon from "../assets/images/prophet-round-icon.svg";
import grognakRoundIcon from "../assets/images/grognak-round-icon.svg";
import primeRoundIcon from "../assets/images/prime-round-icon.svg";
import alchemistRoundIcon from "../assets/images/alchemist-round-icon.svg";

export const MONSTA_CLASSES = [
  {
    name: "Prime",
    roundIcon: primeRoundIcon,
    value: "prime",
  },
  {
    name: "Terminator",
    roundIcon: terminatorRoundIcon,
    value: "terminator",
  },
  {
    name: "Alchemist",
    roundIcon: alchemistRoundIcon,
    value: "alchemist",
  },
  {
    name: "Golem",
    roundIcon: golemRoundIcon,
    value: "golem",
  },
  {
    name: "Nightingale",
    roundIcon: nightingaleRoundIcon,
    value: "nightingale",
  },
  {
    name: "Prophet",
    roundIcon: prophetRoundIcon,
    value: "prophet",
  },
  {
    name: "Grognak",
    roundIcon: grognakRoundIcon,
    value: "grognak",
  },
  {
    name: "Juggernaut",
    roundIcon: juggernautRoundIcon,
    value: "juggernaut",
  },
  {
    name: "Bomoh",
    roundIcon: bomohRoundIcon,
    value: "bomoh",
  },
];
