/* eslint-disable no-param-reassign */
import { Token } from "@pancakeswap/sdk";
import { useMemo } from "react";
import useActiveWeb3React from "../useActiveWeb3React";
import { TokenAddressMap } from "./types";

// reduce token map into standard address <-> Token mapping, optionally include user added tokens
export function useTokensFromMap(
  tokenMap: TokenAddressMap,
  includeUserAdded: boolean
): { [address: string]: Token } {
  const { chainId } = useActiveWeb3React();

  return useMemo(() => {
    if (!chainId) return {};

    // reduce to just tokens
    const mapWithoutUrls = Object.keys(
      (tokenMap as any)[chainId] || {}
    ).reduce<{
      [address: string]: Token;
    }>((newMap, address) => {
      newMap[address] = (tokenMap as any)[chainId][address].token;
      return newMap;
    }, {});

    return mapWithoutUrls;
  }, [chainId, tokenMap]);
}
