import React, { ReactNode } from "react";
import { StyledTooltipWrapper } from "./style";
import { Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";

type InfoTooltipProps = {
  title: string;
  placement: TooltipPlacement;
  color: string;
  children: NonNullable<ReactNode>;
};

export const InfoTooltip = (props: InfoTooltipProps) => {
  const { title, placement, color, children } = props;
  return (
    <StyledTooltipWrapper>
      <Tooltip
        title={title}
        color={color}
        placement={placement}
        getPopupContainer={(trigger) => {
          return trigger;
        }}
      >
        {children}
      </Tooltip>
    </StyledTooltipWrapper>
  );
};
