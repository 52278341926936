import sample from "lodash/sample";
import { config } from "../config";
// Array of available nodes to connect to
export const nodes = [config.app.REACT_APP_NODE_1];

const getNodeUrl = () => {
  return sample(nodes);
};

export default getNodeUrl;
