import { gql } from "@apollo/client";

export const GET_MONSTA = gql`
  query GetMonsta($id: ID!) {
    monsta(id: $id) {
      id
      genes
      owner
      timestamp
      classType
      cloneUsage
    }
  }
`;

export const GET_PARENTS = gql`
  query GetParents($id: ID!) {
    monsta(id: $id) {
      sire {
        id
        genes
        owner
        timestamp
        classType
        cloneUsage
      }
      matron {
        id
        genes
        owner
        timestamp
        classType
        cloneUsage
      }
    }
  }
`;

export const GET_CHILDREN = gql`
  query GetChildren($id: String!) {
    matron: monstas(where: { matron: $id }) {
      id
      auctionStatus
      genes
      owner
      timestamp
      classType
      cloneUsage
      monstaEvents(
        orderBy: timestamp
        orderDirection: desc
        where: { type_in: [UserAuctionCreated] }
        first: 1
      ) {
        timestamp
        ... on AuctionCreate {
          startingPrice
          endingPrice
          duration
          seller
        }
      }
    }
    sire: monstas(where: { sire: $id }) {
      id
      auctionStatus
      genes
      owner
      timestamp
      classType
      cloneUsage
      monstaEvents(
        orderBy: timestamp
        orderDirection: desc
        where: { type_in: [UserAuctionCreated] }
        first: 1
      ) {
        timestamp
        ... on AuctionCreate {
          startingPrice
          endingPrice
          duration
          seller
        }
      }
    }
  }
`;

export const GET_AUTION_MONSTA = gql`
  query GetAuctionMonsta($id: ID!) {
    monsta(id: $id) {
      id
      token
      genes
      owner
      auctionStatus
      timestamp
      monstaEvents(
        orderBy: timestamp
        orderDirection: desc
        where: { type_in: [UserAuctionCreated] }
        first: 1
      ) {
        timestamp
        ... on AuctionCreate {
          startingPrice
          endingPrice
          duration
          seller
        }
      }
    }
  }
`;

export const GET_MONSTAS = gql`
  query GetMonstas {
    monstas {
      id
      genes
      owner
      timestamp
      classType
      cloneUsage
    }
  }
`;

export const GET_USER_AUCTIONS = gql`
  query GetUserAuctions(
    $skip: Int
    $first: Int
    $orderBy: Monsta_orderBy
    $orderDirection: OrderDirection
    $where: Monsta_filter
  ) {
    monstas(
      skip: $skip
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $where
    ) {
      id
      genes
      cloneUsage
      auctionStatus
      monstaEvents(
        orderBy: timestamp
        orderDirection: desc
        where: { type_in: [UserAuctionCreated] }
        first: 1
      ) {
        timestamp
        ... on AuctionCreate {
          startingPrice
          endingPrice
          duration
          seller
        }
      }
    }
  }
`;

export const GET_USER_MONSTA = gql`
  query GetUserMonsta(
    $orderBy: Monsta_orderBy
    $orderDirection: OrderDirection
    $where: Monsta_filter
    $first: Int
    $skip: Int
  ) {
    monstas(
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $where
      first: $first
      skip: $skip
    ) {
      id
      genes
      timestamp
      classType
      cloneUsage
      monstaEvents(
        orderBy: timestamp
        orderDirection: desc
        where: { type_in: [SystemAuctionCreated] }
        first: 1
      ) {
        timestamp
        ... on AuctionCreate {
          startingPrice
          endingPrice
          duration
          seller
        }
      }
    }
  }
`;

export const GET_USER_AUCTION = gql`
  query GetUserAuction($where: Monsta_filter, $first: Int, $skip: Int) {
    monstas(where: $where, first: $first, skip: $skip) {
      id
      genes
      classType
      cloneUsage
      monstaEvents(
        orderBy: timestamp
        orderDirection: desc
        where: { type_in: [UserAuctionCreated] }
        first: 1
      ) {
        timestamp
        ... on AuctionCreate {
          startingPrice
          endingPrice
          duration
          seller
        }
      }
    }
  }
`;

export const GET_AUCTION_CREATED = gql`
  query GetAuctionCreated($first: Int) {
    auctionCreates(orderBy: timestamp, orderDirection: desc, first: $first) {
      id
      token {
        id
        genes
      }
      seller
      endingPrice
      timestamp
    }
  }
`;

export const GET_AUCTION_SUCCEEDS = gql`
  query GetAuctionSucceeds(
    $orderBy: AuctionSucceed_orderBy
    $orderDirection: OrderDirection
    $where: AuctionSucceed_filter
    $first: Int
    $skip: Int
  ) {
    auctionSucceeds(
      orderBy: $orderBy
      orderDirection: $orderDirection
      where: $where
      first: $first
      skip: $skip
    ) {
      id
      token {
        id
        genes
      }
      winner
      totalPrice
      timestamp
    }
  }
`;
