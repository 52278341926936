import styled from "@emotion/styled";
import { Image, Row } from "antd";

export const StyledFooter = styled(Row)`
  height: 200px;
  width: 100%;
  background: #0d0d11;
  justify-content: center;
  align-items: center;
`;

export const SocialMediaCard = styled(Image)`
  height: 50px;
  width: 50px;
  background: #343444;
  border-radius: 10px;
  margin: auto 5px;
  padding: 15px;
`;
