import { Col, Row } from "antd";
import { BundleCatalog } from "../../../hooks/usePurchase";
import { PACKAGE_VALUES } from "../constants";
import {
  ConfirmModalText,
  ConfirmModalNoButton,
  ConfirmModalOkButton,
  ConfirmModalPackageWrapper,
  StyledConfirmModal,
  ConfirmModalContentText,
} from "../style";
import FCPackage from "./FCPackage";

type ConfirmModalProps = {
  visible: boolean;
  bundleDetail: BundleCatalog;
  isSubmitting: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

const ConfirmModal = ({
  visible,
  bundleDetail,
  isSubmitting,
  onSubmit,
  onCancel,
}: ConfirmModalProps) => {
  return (
    <StyledConfirmModal
      visible={visible}
      onCancel={onCancel}
      centered
      width={700}
      footer={null}
      closable={false}
    >
      <Row align="middle">
        <Col span={24} md={8}>
          <ConfirmModalPackageWrapper>
            <FCPackage
              defaultBg={PACKAGE_VALUES[bundleDetail.type].defaultBg}
              selectedBg={PACKAGE_VALUES[bundleDetail.type].selectedBg}
              bundleDetail={bundleDetail}
            />
          </ConfirmModalPackageWrapper>
        </Col>
        <Col span={24} md={15}>
          <Row justify="center">
            <ConfirmModalText>Confirm Purchase?</ConfirmModalText>
          </Row>
          <Row justify="center" gutter={[24, 16]}>
            <Col>
              <ConfirmModalContentText>
                Once confirmed, your items will be locked and a QR code will be
                generated for your purchase. Please make the payment within 5
                minutes of receiving the QR code to confirm your purchase.
              </ConfirmModalContentText>
            </Col>
            <Col span={11} offset={1}>
              <ConfirmModalOkButton onClick={onSubmit} loading={isSubmitting}>
                Yes
              </ConfirmModalOkButton>
            </Col>
            <Col span={11}>
              <ConfirmModalNoButton onClick={onCancel}>No</ConfirmModalNoButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledConfirmModal>
  );
};

export default ConfirmModal;
