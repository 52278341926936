import { useContext } from "react";
import { RefreshContext } from "../contexts/RefreshContext";

interface RefreshContextValue {
  fastRefresh: number;
  slowRefresh: number;
}

const useRefresh = (): RefreshContextValue => {
  const { fast, slow } = useContext(RefreshContext);
  return { fastRefresh: fast, slowRefresh: slow };
};

export default useRefresh;
