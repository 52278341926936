import { WarningBox, WarningIcon, WarningTextBox, WarningText } from "../style";

import warningIcon from "../../../assets/images/warning-icon.svg";

export default function SwapWarning() {
  return (
    <WarningBox>
      <WarningIcon src={warningIcon} />
      <WarningTextBox>
        <WarningText>Warning : Beware of STT price fluctuations!</WarningText>
        <WarningText>
          Please wait for STT to be replenish if the price is high
        </WarningText>
      </WarningTextBox>
    </WarningBox>
  );
}
