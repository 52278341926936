import { SyncOutlined } from "@ant-design/icons";
import useTapwarAllowance from "../hooks/useTapwarAllowance";
import { RotateSync, StyledRefreshButton } from "../style";

type RefreshButtonProps = {
  onRefresh?: Function;
};

export const RefreshButton = ({ onRefresh }: RefreshButtonProps) => {
  const { isRefreshingWallet, handleRefreshWallet } = useTapwarAllowance();

  const handleRefresh = () => {
    handleRefreshWallet();
    onRefresh && onRefresh();
  };

  return (
    <StyledRefreshButton
      shape="circle"
      size="large"
      icon={
        <RotateSync rotating={isRefreshingWallet}>
          <SyncOutlined style={{ strokeWidth: "1.5em", stroke: "#303B45" }} />
        </RotateSync>
      }
      onClick={handleRefresh}
      disabled={isRefreshingWallet}
    />
  );
};
