import BigNumber from "bignumber.js";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Layout, Row, message } from "antd";
import { ethers, Contract } from "ethers";
import {
  ViewWrapper,
  ExchangeCard,
  ExchangeLogo,
  ExchangeHeader,
  ExchangeWrapper,
  ArrowDown,
  SlippageRow,
  Label,
  SlippageValue,
  ExchangeButton,
  ConnectWalletButton,
} from "./style";
import CurrencyInputPanel from "./components/CurrencyInputPanel";
import SwapWarning from "./components/SwapWarning";
import Logo from "../../assets/images/SwapLogo.png";
import juggyswapBackground from "../../assets/images/juggyswap-background.jpg";
import { Field } from "../../services/redux/swap/actions";
import useRefresh from "../../hooks/useRefresh";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { getMarketplaceExchangeAddress } from "../../utils/addressHelpers";
import {
  getMarketplaceExchangecontract,
  getMoniContract,
} from "../../utils/contractHelpers";
import { getMoniAddress } from "../../utils/addressHelpers";
import fetchAllowance from "../../utils/fetchAllowance";
import {
  computeTradePriceBreakdown,
  warningSeverity,
} from "../../utils/prices";
import { Trade } from "@pancakeswap/sdk";
import { useSwapCallback } from "../../hooks/useSwapCallback";
import useMonstaAuth from "../../hooks/useMonstaAuth";
import { useSwapState } from "../../hooks/swap/useSwapState";
import { useDerivedSwapInfo } from "../../hooks/swap/useDerivedSwapInfo";
import { useSwapActionHandlers } from "../../hooks/swap/useSwapActionHandlers";
import CheapestMonstaList from "./components/CheapestMonstaList";

let reserve0: any;
let reserve1: any;

const Swap = () => {
  const { active, connect, disconnect } = useMonstaAuth();

  const { independentField, typedValue, recipient } = useSwapState();
  const { account, library } = useActiveWeb3React();
  const [outputAmount, setOutputAmount] = useState<string>("0.0");
  const [inputAmount, setInputAmount] = useState<string>();
  const [parsedInputAmount, setParsedInputAmount] = useState<string>();
  const [parsedOutputAmount, setParsedOutputAmount] = useState<string>();
  const [isSwapping, setIsSwapping] = useState<boolean>(false);
  const [isApproving, setIsApproving] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [allowance, setAllowance] = useState(new BigNumber(0));
  const { fastRefresh } = useRefresh();

  const {
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();

  // modal and loading
  const [, setSwapState] = useState<{
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });
  // When switching account
  useEffect(() => {
    if (account) {
      // TODO: switch to async await
      fetchAllowance(
        getMoniAddress(),
        account,
        getMarketplaceExchangeAddress()
      ).then((value: any) => setAllowance(value));
    }
    // eslint-disable-next-line
  }, [account]);

  // When pending approval
  useEffect(() => {
    if (isApproving) {
      fetchAllowance(
        getMoniAddress(),
        account,
        getMarketplaceExchangeAddress()
      ).then((value: any) => {
        if (value.toNumber() >= parseInt(parsedInputAmount || "0")) {
          setAllowance(value);
          setIsApproving(false);
        }
      });
    }
  }, [account, currencies, fastRefresh, isApproving, parsedInputAmount]);

  const updatePrice = async (value: any) => {
    if (parseFloat(value) > 0 && reserve0 && reserve1) {
      const amountIn = new BigNumber(value).multipliedBy(1e18).toNumber();
      const numerator = reserve1 * amountIn * 997;
      const denominator = reserve0 * 1000 + amountIn * 997;
      const output = numerator / denominator;
      setOutputAmount(new BigNumber(output).div(1e8).toFixed());

      setParsedInputAmount(
        ethers.utils
          .parseEther(new BigNumber(amountIn).div(1e18).toFixed())
          .toString()
      );
      setParsedOutputAmount(((output * 93) / 100).toFixed().toString());
      // onUserInput(Field.OUTPUT, parseFloat(res.toFixed() / 1e18));
    } else {
      setOutputAmount("0.0");
      setParsedInputAmount("0.0");
      setParsedOutputAmount("0.0");
      //setParsedInputAmount
    }
  };

  useMemo(() => {
    if (parseFloat(inputAmount || "0") > 0 || !reserve0 || !reserve1) {
      const contract: Contract | null = getMarketplaceExchangecontract();
      contract?.getReserves().then((res: any) => {
        reserve0 = res[0];
        reserve1 = res[1];
        updatePrice(inputAmount);
      });
    }
  }, [inputAmount, account, fastRefresh]);

  const { onCurrencySelection, onUserInput } = useSwapActionHandlers();

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
      setInputAmount(value);
      updatePrice(value);
    },
    [onUserInput]
  );

  const trade = undefined;
  const isExpertMode = true;
  const isValid = !swapInputError;

  const parsedAmounts = {
    [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : undefined,
    [Field.OUTPUT]:
      independentField === Field.OUTPUT ? parsedAmount : undefined,
  };

  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  const allowedSlippage = 0;
  // the callback to execute the swap
  const { error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient
  );

  useEffect(() => {
    const defCurrecy: any = {};
    onCurrencySelection(Field.INPUT, defCurrecy);
    onCurrencySelection(Field.OUTPUT, defCurrecy);
  }, [onCurrencySelection]);

  const handleSwap = async () => {
    try {
      const now = new Date();
      const secondsSinceEpoch = Math.round(now.getTime() / 1000);
      if (library) {
        const contract: Contract | null = getMarketplaceExchangecontract(
          library.getSigner()
        );
        setIsSwapping(true);
        await contract?.estimateGas["swapExactMoniForStt"](
          parsedInputAmount,
          parsedOutputAmount, // TODO : This hardcode the slipage
          secondsSinceEpoch + 3000,
          { gasLimit: 200000 }
        );
        await contract?.swapExactMoniForStt(
          parsedInputAmount,
          parsedOutputAmount, // TODO : This hardcode the slipage
          secondsSinceEpoch + 3000,
          { gasLimit: 200000 }
        );
        setTimeout(() => {
          message.success(
            "Transaction submitted, please wait a few minutes for it to reflect in your account balance"
          );
          setIsSuccess(true);
          setIsSwapping(false);
          const allowance_ = new BigNumber(
            allowance.toNumber() - parseInt(parsedInputAmount || "0")
          );
          setAllowance(allowance_);
          handleTypeInput("");
        }, 1000);
      }
    } catch (err) {
      setIsSwapping(false);
      console.log(err);
      message.error("Transaction rejected.");
    }
  };

  const handleApprove = () => {
    if (library) {
      const exchangeContract: Contract | null = getMarketplaceExchangecontract(
        library.getSigner()
      );
      const contract: Contract | null = getMoniContract(library.getSigner());
      setIsApproving(true);
      contract
        ?.approve(exchangeContract?.address, parsedInputAmount)
        /*.then((res) => {
        setTimeout(() => {
          setIsApproving(false);
          //setIsApproved(true);
        }, 10000);
      })*/
        .catch((err: any) => {
          console.log(err);
          setIsApproving(false);
        });
    }
  };

  return (
    <ViewWrapper bg={juggyswapBackground}>
      <Row align="middle" justify="center">
        <ExchangeCard>
          <ExchangeLogo className="logo" src={Logo} alt="" width="200px" />
          <ExchangeHeader title="Exchange" description="Swap xMONI to xSTT" />
          <ExchangeWrapper flex="auto">
            <CurrencyInputPanel
              id="swap-currency-input"
              onUserInput={handleTypeInput}
              value={formattedAmounts[Field.INPUT]}
              currency={currencies[Field.INPUT]}
              showMaxButton={true}
              label="From"
              otherCurrency={currencies[Field.OUTPUT]}
            />
            <ArrowDown />
            <CurrencyInputPanel
              id="swap-currency-output"
              onUserInput={() => {}}
              value={outputAmount}
              currency={currencies[Field.OUTPUT]}
              label="To"
              showMaxButton={true}
              otherCurrency={currencies[Field.INPUT]}
            />
          </ExchangeWrapper>
          <SlippageRow>
            <Label>Slippage Tolerance</Label>
            <SlippageValue>7%</SlippageValue>
          </SlippageRow>
          <Row justify="center">
            {!account ? (
              <ConnectWalletButton
                type="primary"
                block
                size="large"
                onClick={active ? disconnect : connect}
              >
                Connect Wallet
              </ConnectWalletButton>
            ) : allowance?.toNumber() <= 0 ||
              allowance?.toNumber() < parseInt(parsedInputAmount || "0") ? (
              <ExchangeButton
                type="primary"
                block
                danger={
                  isValid && priceImpactSeverity > 2 && !swapCallbackError
                }
                size="large"
                onClick={() => {
                  if (isExpertMode) {
                    handleApprove();
                  } else {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      txHash: undefined,
                    });
                  }
                }}
                disabled={isApproving}
              >
                Approve
              </ExchangeButton>
            ) : (
              <ExchangeButton
                type="primary"
                danger={
                  isValid && priceImpactSeverity > 2 && !swapCallbackError
                }
                block
                size="large"
                onClick={() => {
                  if (isExpertMode) {
                    handleSwap();
                  } else {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      txHash: undefined,
                    });
                  }
                }}
                disabled={
                  isSwapping || parseFloat(parsedInputAmount || "0") <= 0
                }
              >
                Swap
              </ExchangeButton>
            )}
          </Row>
        </ExchangeCard>
      </Row>
      <Row align="middle" justify="center">
        <SwapWarning />
      </Row>
      {isSuccess && <CheapestMonstaList />}
    </ViewWrapper>
  );
};

export default Swap;
