import styled from "@emotion/styled";
import { Button, Col, Image, Row, Table, Typography, Radio } from "antd";

const { Text } = Typography;

// Carousel
export const CarouselWrapper = styled.div`
  margin: 40px 60px 0px 60px;
  @media screen and (max-width: 576px) {
    margin: 32px;
  }

  .ant-carousel {
    border-radius: 100px;
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-next,
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover {
    font-size: 14px;
    background: rgba(0, 0, 0, 0.7);
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }

  .ant-carousel .anticon svg {
    transform: translateY(5px);
  }

  .ant-carousel .slick-prev,
  .ant-carousel .slick-prev:hover {
    left: 10px;
    z-index: 2;
    color: white;
  }

  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover {
    right: 10px;
    z-index: 2;
    color: white;
  }

  .ant-carousel .slick-dots button {
    background-color: white;
  }

  .ant-carousel .slick-dots .slick-active button {
    background-color: white;
  }
`;

export const CarouselItemWrapper = styled.div`
  background: black;
  border-radius: 15px;
  @media only screen and (max-width: 600px) {
    height: auto;
  }

  @media only screen and (min-width: 600px) {
    height: auto;
  }

  @media only screen and (min-width: 768px) {
    height: auto;
    max-height: 475px;
  }
`;

export const CarouselItemImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 15px;
`;

export const DashboardContainer = styled(Row)`
  flex-direction: column;
`;

export const WrapperRow = styled(Row)`
  margin: 40px 60px;
  @media screen and (max-width: 576px) {
    margin: 32px;
  }
`;

export const WrapperRowGreen = styled(Row)`
  background: #11644f;
  border-radius: 20px;
  padding: 30px;
  margin: 40px 60px;
  @media screen and (max-width: 576px) {
    margin: 32px;
    padding: 10px 0px 0px 10px;
  }
`;

export const LeftCol = styled(Col)`
  @media screen and (min-width: 576px) {
    padding-right: 10px;
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 32px;
  }
`;

export const RightCol = styled(Col)`
  @media screen and (min-width: 576px) {
    padding-left: 10px;
  }
`;

export const TitleWrapper = styled.div`
  background: ${(props) => props.theme.color.green1};
  padding: 25px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

export const TableTitle = styled(Text)`
  font-size: ${(props) => props.theme.fontSize.three};
  font-family: ${(props) => props.theme.fontFamily.fourth};
`;

export const HorizontalDivider = styled.div`
  background: #393556;
  height: 1px;
`;

export const TableHeader = styled(Text)`
  font-size: 16px;
`;

export const TableContent = styled(Row)`
  background: #161616;
`;

export const StyledTable = styled(Table)`
  .ant-table {
    width: 100%;
    background: #161616;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-family: ${(props) => props.theme.fontFamily.fourth};
  }
  .ant-table-thead > tr > th {
    background: ${(props) => props.theme.color.green1};
    color: #e0e0e0;
    font-weight: normal;
    border-bottom: none;
    font-size: ${(props) => props.theme.fontSize.one};
  }
  .ant-table-container table > thead > tr:first-of-type th:first-of-type {
    border-top-left-radius: 0px;
    padding-left: 16px;
  }
  .ant-table-container table > thead > tr:first-of-type th:last-child {
    border-top-right-radius: 0px;
    padding-right: 16px;
  }
  .ant-table-tbody > tr {
    cursor: pointer;
    & > td {
      border-bottom: none;
      font-size: ${(props) => props.theme.fontSize.one};
    }
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #161616;
  }
  .ant-table-tbody > .ant-table-expanded-row > td {
    background: linear-gradient(
      0.43deg,
      #133a30 0.33%,
      rgba(19, 58, 48, 0) 99.58%
    );
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr > td:first-of-type {
    padding-left: 16px;
  }
  .ant-table.ant-table-middle .ant-table-tbody > tr > td:last-of-type {
    padding-right: 16px;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row:hover {
    background: #161616;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .ant-table-cell-fix-left {
    background: #161616;
  }
  .ant-table-tbody
    > tr.ant-table-row:last-of-type
    > td.ant-table-cell-fix-left:first-of-type {
    border-bottom-left-radius: 20px;
  }
  .ant-table-body {
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-track {
      background: #66d6da99;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #66d6da;
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #336869;
    }
    &::-webkit-scrollbar-corner {
      visibility: hidden;
    }
  }
  .ant-table-cell-scrollbar {
    box-shadow: none;
  }
`;

export const IdTagImage = styled.img`
  width: 100%;
`;

export const TitleText = styled(Text)`
  display: block;
  color: #ffffff80;
  font-size: 10px;
`;

export const ItemColWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const MonstaImage = styled(Image)`
  padding-right: 5px;
  height: 40px;
`;

export const ViewMoreButton = styled(Button)`
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 32px;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 32px;
`;

export const AggregateRadioWrapper = styled(Radio.Group)`
  background: #000;
  padding: 5px 5px 5px 5px;
  border-radius: 30px;
  border: 0px;
  margin-bottom: 30px;
  @media screen and (max-width: 576px) {
    margin-bottom: 20px;
  }

  & .ant-radio-button-wrapper:first-of-type {
    background: #000;
    border-radius: 30px;
    height: 38px;
    padding-left: 40px;
    padding-right: 40px;
    border: 0px;
    padding-top: 4px;
    @media screen and (max-width: 576px) {
      padding-left: 20px;
      padding-right: 20px;
      height: 34px;
      padding-top: 2px;
    }
  }

  & .ant-radio-button-wrapper {
    background: #000;
    border-radius: 30px;
    height: 38px;
    padding-left: 40px;
    padding-right: 40px;
    border: 0px;
    padding-top: 4px;
    @media screen and (max-width: 576px) {
      padding-left: 20px;
      padding-right: 20px;
      height: 34px;
      padding-top: 2px;
    }
  }
  & .ant-radio-button-wrapper:last-of-type {
    background: #000;
    border-radius: 30px;
    height: 38px;
    padding-left: 40px;
    padding-right: 40px;
    border: 0px;
    padding-top: 4px;
    @media screen and (max-width: 576px) {
      padding-left: 20px;
      padding-right: 20px;
      height: 34px;
      padding-top: 2px;
    }
  }
  &
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-of-type {
    border-radius: 30px;
    border-top: 1px solid;
    border-color: #505257;
  }
  &
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):last-of-type {
    border-radius: 30px;
    border-top: 1px solid;
    border-color: #505257;
  }
  & .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #282b30;
    border-radius: 30px;
    border-top: 1px solid;
    border-color: #505257;
  }
  &
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 0px #000;
  }
  & .ant-radio-button-wrapper-checked {
    color: #fff;
    background: #282b30;
  }
  &
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: transparent;
    border: 0px;
  }
  & .ant-radio-button-wrapper:not(:first-of-type)::before {
    background: transparent;
    border: 0px;
  }
`;

export const AggregateActiveButton = styled(Radio.Button)`
  font-size: 12px;

  &:hover {
    color: #fff;
    border: 0;
  }
  &:active {
    color: #fff;
    border: 0;
  }
`;

export const AggregateTitle = styled.div`
  color: #fff;
  font-size: 40px;
  line-height: 1;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 576px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    font-size: 30px;
    margin-bottom: 10px;
  }
`;
export const AggregateSubTitle = styled.div`
  color: #fff;
  font-size: 16px;
  line-height: 1;
  margin-top: 5px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 576px) {
    font-size: 14px;
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    font-size: 14px;
  }
`;

export const AggregateIconContainer = styled.div`
  width: 60px;
  height: 60px;
  margin: 0;
  padding: 0;
  background: #282b30;
  border-radius: 25px;
  float: left;
  display: flex;
  justify-content: center;
  border-top: 1px solid;
  border-color: #505257;
  margin: 5px 25px 5px 5px;
  position: relative;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 576px) {
    width: 35px;
    height: 35px;
    border-radius: 15px;
    margin: 5px 15px 5px 5px;
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    width: 40px;
    height: 40px;
    border-radius: 15px;
    margin: 5px 15px 5px 5px;
  }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    width: 40px;
    height: 40px;
    border-radius: 15px;
    margin: 5px 15px 5px 5px;
  }
`;

export const AggregateIcon = styled.img`
  width: 40px;
  height: 40px;
  display: block;
  margin: auto;
  @media screen and (max-width: 576px) {
    width: 20px;
    height: 20px;
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    width: 30px;
    height: 30px;
  }
`;

export const AggregateInfoIcon = styled.div`
  background: #fff;
  color: #11644f;
  font-size: 10px;
  border-radius: 10px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  line-height: 1;
  margin: 7px 0px 5px 4px;
`;

export const AggregateElementWrapper = styled.div`
  display: flex;
`;

export const AggregateRowWrapper = styled(Row)`
  padding-left: 30px;
  @media screen and (max-width: 576px) {
    padding-left: 10px;
    display: block;
  }
`;

export const AggregateColumnWrapper = styled(Col)`
  margin-right: 120px;
  @media screen and (max-width: 576px) {
    margin-right: 0px;
  }
  @media screen and (min-width: 577px) and (max-width: 768px) {
    margin-right: 10px;
  }
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    margin-right: 50px;
  }
`;

export const ExpandButton = styled(Button)`
  background: #11644f;
  border-color: #11644f;
  &:hover {
    background: #11644f;
    opacity: 0.8;
    border-color: #11644f;
  }
  &:active {
    opacity: 0.9;
    transform: translateY(1px);
    box-shadow: none;
    border-color: #11644f;
  }
  &:focus {
    background: #11644f;

    color: #ffffff;
    border-color: #11644f;
  }
`;
