import { useState, useEffect } from "react";
import { encode as btoa } from "base-64";

import Loading from "../../../components/Loading/Loading";
import MonstaPreviewCard from "./MonstaPreviewCard";
import { MonstaPreviewCardRow } from "../style";

import incubatorImage from "../../../assets/images/incubator2.png";
import { config } from "../../../config";
import { formatBigNumberToFixed } from "../../../utils/formatBalance";

type MonstaPreviewCardListProps = {
  auctionDataLoading: boolean;
  auctionDataError: any;
  auctionData: any;
  gotoDetailPage: Function;
  scrollTo: Function;
};

const MONSTA_CLASS_KEYS = [
  "PRIME",
  "TERMINATOR",
  "ALCHEMIST",
  "GOLEM",
  "NIGHTINGALE",
  "PROPHET",
  "GROGNAK",
  "JUGGERNAUT",
  "BOMOH",
];

const MONSTA_TYPE_KEYS = ["INCEPTION", "CHRONO-ZERO", "SOULBOND", "NORMAL"];

const MonstaPreviewCardList = (props: MonstaPreviewCardListProps) => {
  const {
    auctionDataLoading,
    auctionDataError,
    auctionData,
    gotoDetailPage,
    scrollTo,
  } = props;
  const [displayMonstas, setDisplayMonstas] = useState([]);
  const [displayMonstasLoading, setDisplayMonstasLoading] = useState(true);
  const [displayMonstasError, setDisplayMonstasError] = useState(false);
  let componentMounted = true;

  // Intentional duplication
  // Will refactor once Monsta view page is ready
  useEffect(() => {
    const controller = new AbortController();

    const initialize = async () => {
      setDisplayMonstasLoading(true);
      setDisplayMonstasError(false);

      const temp =
        auctionData &&
        auctionData.monstas &&
        auctionData.monstas.map(async (monsta: any) => {
          // Get image
          const tokenId = parseInt(monsta.id);
          const cloneUsage = parseInt(monsta.cloneUsage);
          const monstaGene = monsta.genes;
          const monstaGeneBin = BigInt(monstaGene).toString(2);
          const paddingString = "0";
          const padding = 256 - monstaGeneBin.length;
          const gene = paddingString.repeat(padding).toString() + monstaGeneBin;
          const isAdult = parseInt(gene.substr(8, 3));
          const res = await fetch(`${config.api.monsta}/preview`, {
            method: "POST",
            headers: {
              "content-Type": "application/json",
            },
            body: JSON.stringify({ data: gene }),
          });
          const result = await res.text();
          const image = isAdult
            ? `data:image/svg+xml;base64,${btoa(result)}`
            : incubatorImage;

          // Get price
          const now = new Date();
          const secondsSinceEpoch = Math.round(now.getTime() / 1000);

          const isMonstaEventPresent =
            monsta && monsta.monstaEvents && monsta.monstaEvents.length > 0;
          let currentPrice: any;

          if (isMonstaEventPresent && monsta.monstaEvents[0]) {
            const secondsPassed =
              secondsSinceEpoch - monsta.monstaEvents[0].timestamp;
            const isPassedDuration =
              secondsPassed > parseInt(monsta.monstaEvents[0].duration);

            const isFixedPrice =
              monsta.monstaEvents[0].endingPrice ===
              monsta.monstaEvents[0].startingPrice;

            if (isFixedPrice) {
              currentPrice = formatBigNumberToFixed(
                monsta.monstaEvents[0].endingPrice,
                0,
                8
              ).toString();
            } else {
              const currentPriceChange = isPassedDuration
                ? formatBigNumberToFixed(
                    monsta.monstaEvents[0].endingPrice,
                    0,
                    8
                  )
                : (formatBigNumberToFixed(
                    monsta.monstaEvents[0].startingPrice,
                    0,
                    8
                  ) -
                    formatBigNumberToFixed(
                      monsta.monstaEvents[0].endingPrice,
                      0,
                      8
                    )) *
                  (secondsPassed / parseInt(monsta.monstaEvents[0].duration));
              currentPrice = isPassedDuration
                ? parseFloat(
                    formatBigNumberToFixed(
                      monsta.monstaEvents[0].endingPrice,
                      0,
                      8
                    )
                  )
                : (formatBigNumberToFixed(
                    monsta.monstaEvents[0].startingPrice,
                    0,
                    8
                  ) -
                    formatBigNumberToFixed(
                      monsta.monstaEvents[0].endingPrice,
                      0,
                      8
                    )) *
                  (secondsPassed / parseInt(monsta.monstaEvents[0].duration));
              currentPrice = isPassedDuration
                ? parseFloat(
                    formatBigNumberToFixed(
                      monsta.monstaEvents[0].endingPrice,
                      0,
                      8
                    )
                  )
                : parseFloat(
                    (formatBigNumberToFixed(
                      monsta.monstaEvents[0].startingPrice,
                      0,
                      8
                    ) - currentPriceChange.toFixed(4)) as any
                  );
            }
          }

          // Get Monsta type
          let monstaTypeKey;
          if (tokenId <= 4089) {
            monstaTypeKey = MONSTA_TYPE_KEYS[0];
          } else if (tokenId >= 4090 && tokenId <= 14099) {
            monstaTypeKey = MONSTA_TYPE_KEYS[1];
          } else if (tokenId >= 14100 && tokenId <= 214099) {
            monstaTypeKey = MONSTA_TYPE_KEYS[2];
          } else if (tokenId >= 214100) {
            monstaTypeKey = MONSTA_TYPE_KEYS[3];
          }

          // Get Monsta class
          let monstaClassKey;
          const monstaGeneBinary = BigInt(monsta.genes)
            .toString(2)
            .padStart(256, "0");
          const stats = parseInt(monstaGeneBinary.substr(64, 6), 2);
          monstaClassKey = MONSTA_CLASS_KEYS[stats];

          return {
            id: tokenId,
            image,
            price: currentPrice,
            monstaTypeKey,
            monstaClassKey,
            cloneUsage,
            auctionStatus: monsta.auctionStatus,
          };
        });

      const tempMonstas = temp && ((await Promise.all(temp)) as any);
      if (componentMounted) {
        setDisplayMonstas(tempMonstas);
        setDisplayMonstasLoading(false);
        scrollTo();
      }
    };

    try {
      auctionData && initialize();
    } catch (error) {
      setDisplayMonstasError(true);
      setDisplayMonstasLoading(false);
    }

    return () => {
      componentMounted = false;
      controller.abort();
    };
  }, [auctionData]);

  useEffect(() => {
    scrollTo();
  }, [displayMonstasLoading]);

  if (auctionDataLoading || displayMonstasLoading) {
    return <Loading centered />;
  }

  if (auctionDataError || displayMonstasError) {
    return <p>An unexpected error has occured</p>;
  }

  return (
    <div>
      <MonstaPreviewCardRow gutter={[16, 40]}>
        {displayMonstas &&
          displayMonstas.map((monsta: any) => (
            <MonstaPreviewCard
              key={monsta.id}
              id={monsta.id}
              price={monsta.price}
              image={monsta.image}
              monstaTypeKey={monsta.monstaTypeKey}
              monstaClassKey={monsta.monstaClassKey}
              cloneUsage={monsta.cloneUsage}
              auctionStatus={monsta.auctionStatus}
              gotoDetailPage={gotoDetailPage}
            />
          ))}
      </MonstaPreviewCardRow>
      <svg className="svg" style={{ height: "0" }}>
        <clipPath id="marketplace-card-body" clipPathUnits="objectBoundingBox">
          <path d="M 0 0.12 C 0.1 0 0.9 0 1 0.12 V 1 H -1 V -1 Z" />
        </clipPath>
      </svg>
    </div>
  );
};

export default MonstaPreviewCardList;
