import { Layout, Pagination } from "antd";
import MonstaPreviewCardList from "./components/MonstaPreviewCardList";
import { GET_USER_AUCTIONS } from "../../graphql/queries/marketplace";
import {
  GetUserAuctionsQuery,
  GetUserAuctionsQueryVariables,
  MonstaEventType,
  Monsta_OrderBy,
  OrderDirection,
} from "../../graphql/generated-types";
import {
  MarketplaceButton,
  MarketplaceContent,
  MarketplaceFilterBarRow,
  MarketplaceRadioButton,
  MarketplaceRadioWrapper,
  SiderTriggerButtonOutside,
  MarketplacePagination,
  MarketplaceTotalMonsta,
} from "./style";
import { useQuery } from "@apollo/client";
import { marketplaceClient } from "../../graphql/apolloClient";
import SiderFilter from "./components/SiderFilter";
import { Heading } from "../../components/Heading";
import { useEffect, useState } from "react";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { ReloadOutlined, FilterOutlined } from "@ant-design/icons";
import SortSelect, { SORTING_OPTIONS } from "./components/SortSelect";
import { fetchAggregate } from "../../services/marketplace-aggregator-api";
import { formatToReadibleNumber } from "../../utils/formatBalance";
export interface MonstaFilterType {
  token?: string;
  cloneUsage?: number;
  classType_in?: number[];
  token_gte?: number;
  token_lte?: number;
  token_not_in?: number[];
  specialType?: number;
  specialType_in?: number[];
}

const { Content } = Layout;
export type SortOptionType = {
  label: String;
  value: String;
  order_by: Monsta_OrderBy;
  order_direction: OrderDirection;
};
export enum MonstaKind {
  NORMAL = "NORMAL",
  CHRONOZERO = "CHRONO-ZERO",
  INCEPTION = "INCEPTION",
}

const Marketplace = () => {
  const viewLimit = 30;
  const location = useLocation();
  const initialState = location.state || ({} as any);
  const [isInitialized, setIsInitialized] = useState(false);
  const [totalMonsta, setTotalMonsta] = useState(20000);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<MonstaFilterType>(
    initialState.filter || {}
  );
  const [monstaKinds, setMonstaKinds] = useState<MonstaKind[]>(
    initialState.monstaKinds || []
  );

  const [sortOrder, setSortOrder] = useState<SortOptionType>(
    initialState.sortOrder || SORTING_OPTIONS[0]
  );
  const navigate = useNavigate();
  const [marketplaceParams] = useSearchParams();
  const [filterSection, setFilterSection] = useState(
    initialState.filterSection || "sale"
  );
  const filterBySelection = [
    {
      key: "all",
      filter: {
        auctionStatus_in: [
          MonstaEventType.UserAuctionCreated,
          MonstaEventType.UserAuctionSuccessful,
          MonstaEventType.UserAuctionCancelled,
        ],
      },
    },
    {
      key: "sale",
      filter: {
        auctionStatus_in: [MonstaEventType.UserAuctionCreated],
      },
    },
    {
      key: "not-sale",
      filter: {
        auctionStatus_in: [
          MonstaEventType.UserAuctionSuccessful,
          MonstaEventType.UserAuctionCancelled,
        ],
      },
    },
  ];
  // TODO: use useGetAuctionMonsta hook
  const {
    loading: auctionDataLoading,
    error: auctionDataError,
    data: auctionData,
    refetch,
  } = useQuery<GetUserAuctionsQuery, GetUserAuctionsQueryVariables>(
    GET_USER_AUCTIONS,
    {
      variables: {
        first: viewLimit,
        skip: (currentPage - 1) * viewLimit,
        orderBy: sortOrder.order_by,
        orderDirection: sortOrder.order_direction,
        where: {
          ...filterBySelection.filter((item) => item.key === filterSection)[0]
            .filter,
          ...filter,
        },
      },
      client: marketplaceClient,
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    setCurrentPage(parseInt(marketplaceParams.get("page") || "1"));
  }, [marketplaceParams]);

  useEffect(() => {
    const fetchTotalMonsta = async () => {
      const totalMonsta = await fetchAggregate({
        ...filterBySelection.filter((item) => item.key === filterSection)[0]
          .filter,
        ...filter,
      });

      setTotalMonsta(totalMonsta.data.result);
      setIsInitialized(true);
    };
    if (filter) {
      fetchTotalMonsta();
      if (isInitialized) {
        navigate({ pathname: "" });
        setCurrentPage(1);
      }
    }
  }, [filter, filterSection]);

  const onChangeFilter = (
    filter: MonstaFilterType = {},
    kinds: MonstaKind[] = []
  ) => {
    if (filter.token) {
      navigate({ pathname: "" });
    }
    setMonstaKinds(kinds);
    setFilter(filter);
  };

  const onResetFilter = () => {
    setMonstaKinds([]);
    setFilter({});
    setFilterSection("sale");
    setSortOrder(SORTING_OPTIONS[0]);
  };

  const [collapsedSider, setCollapsedSider] = useState(
    window.outerWidth <= 767
  );

  function handleSortSelect(option: SortOptionType) {
    setSortOrder(option);
  }

  const gotoDetailPage = (id: string) => {
    navigate(`/monsta/${id}`, {
      state: {
        prevPath: location.pathname + location.search,
        filter,
        monstaKinds,
        filterSection,
        sortOrder,
        scrollTop: window.scrollY,
      },
    });
  };

  const scrollTo = () => {
    if (initialState.scrollTop) {
      window.scrollTo({
        top: initialState.scrollTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Content>
        <Layout>
          <SiderFilter
            collapsedSider={collapsedSider}
            setCollapsedSider={setCollapsedSider}
            filter={filter}
            monstaKinds={monstaKinds}
            onChangeFilter={onChangeFilter}
            onResetFilter={onResetFilter}
          />
          <MarketplaceContent>
            <Heading scale="lg">
              {collapsedSider ? (
                <span>Marketplace</span>
              ) : (
                <span>&nbsp;&nbsp;</span>
              )}
            </Heading>
            <MarketplaceTotalMonsta>
              {formatToReadibleNumber(totalMonsta.toString())} Monsta
            </MarketplaceTotalMonsta>
            <MarketplaceFilterBarRow justify="space-between" align="middle">
              <div>
                {collapsedSider && (
                  <SiderTriggerButtonOutside
                    icon={<FilterOutlined />}
                    onClick={() => {
                      setCollapsedSider(false);
                    }}
                  >
                    Filter
                  </SiderTriggerButtonOutside>
                )}
                <MarketplaceRadioWrapper
                  value={filterSection}
                  onChange={(e) => {
                    setFilterSection(e.target.value);
                  }}
                >
                  <MarketplaceRadioButton value="sale">
                    For Sale
                  </MarketplaceRadioButton>
                  <MarketplaceRadioButton value="not-sale">
                    Not For Sale
                  </MarketplaceRadioButton>
                  <MarketplaceRadioButton value="all">
                    All
                  </MarketplaceRadioButton>
                </MarketplaceRadioWrapper>
              </div>
              <div>
                <SortSelect
                  saleFilter={filterSection}
                  onChange={handleSortSelect}
                  value={sortOrder}
                />
                <MarketplaceButton
                  onClick={() => refetch()}
                  loading={auctionDataLoading}
                >
                  <ReloadOutlined />
                </MarketplaceButton>
              </div>
            </MarketplaceFilterBarRow>
            <MonstaPreviewCardList
              auctionDataLoading={auctionDataLoading}
              auctionDataError={auctionDataError}
              auctionData={auctionData}
              gotoDetailPage={gotoDetailPage}
              scrollTo={scrollTo}
            />
            <MarketplacePagination>
              <Pagination
                current={currentPage}
                total={totalMonsta}
                defaultPageSize={viewLimit}
                onChange={(page) => {
                  navigate({
                    pathname: "",
                    search: `?${createSearchParams({
                      page: `${page}`,
                    }).toString()}`,
                  });
                }}
                showSizeChanger={false}
                showLessItems={true}
              />
            </MarketplacePagination>
          </MarketplaceContent>
        </Layout>
      </Content>
    </>
  );
};

export default Marketplace;
