import { Col, Row } from "antd";
import imgAGI from "../../../../../assets/images/AGI.svg";
import imgINT from "../../../../../assets/images/INT.svg";
import imgSTR from "../../../../../assets/images/STR.svg";
import { MonstaStatsType } from "../../../../../models/monsta";
import {
  AGILabel,
  INTLabel,
  MonstaStatsInfo,
  MonstaStatsLabelRow1,
  MonstaStatsLabelRow1Label,
  MonstaStatsLabelRow1StatsInfoWrapper,
  MonstaStatsLabelRow1Value,
  MonstaStatsLabelRow2,
  MonstaStatsTableRow,
  StatsValue,
  STRLabel,
} from "../style";

type MonstaStatsTableProps = {
  monstaStats: MonstaStatsType;
};

type MonstaStatsLabelRow1ColTypes = {
  img: string;
  label: string;
  value: number;
};

const MonstaStatsLabelRow1Col = ({
  img,
  label,
  value,
}: MonstaStatsLabelRow1ColTypes) => (
  <Col span={8}>
    <MonstaStatsLabelRow1StatsInfoWrapper>
      <div>
        <img src={img} alt={label.toLowerCase()} />
      </div>
      <MonstaStatsInfo>
        <MonstaStatsLabelRow1Label>{label}</MonstaStatsLabelRow1Label>
        <MonstaStatsLabelRow1Value>{value}</MonstaStatsLabelRow1Value>
      </MonstaStatsInfo>
    </MonstaStatsLabelRow1StatsInfoWrapper>
  </Col>
);

const MonstaStatsTable = ({ monstaStats }: MonstaStatsTableProps) => {
  return (
    <MonstaStatsTableRow>
      <Col xs={24}>
        <MonstaStatsLabelRow1>
          <MonstaStatsLabelRow1Col
            img={imgSTR}
            label={"STR"}
            value={monstaStats.attributes.str}
          />

          <MonstaStatsLabelRow1Col
            img={imgAGI}
            label={"AGI"}
            value={monstaStats.attributes.agi}
          />

          <MonstaStatsLabelRow1Col
            img={imgINT}
            label={"INT"}
            value={monstaStats.attributes.int}
          />
        </MonstaStatsLabelRow1>
        <MonstaStatsLabelRow2>
          <Col span={8}>
            <Row>
              <STRLabel>HP</STRLabel>
              <StatsValue>{monstaStats.hp}</StatsValue>
            </Row>
            <Row>
              <STRLabel>ATK</STRLabel>
              <StatsValue>{monstaStats.attack}</StatsValue>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <AGILabel>DEF</AGILabel>
              <StatsValue>{monstaStats.defense}</StatsValue>
            </Row>
            <Row>
              <AGILabel>SP</AGILabel>
              <StatsValue>{monstaStats.sp}</StatsValue>
            </Row>
          </Col>
          <Col span={8}>
            <Row>
              <INTLabel>PWR</INTLabel>
              <StatsValue>{monstaStats.power}</StatsValue>
            </Row>
            <Row>
              <INTLabel>RES</INTLabel>
              <StatsValue>{monstaStats.res}</StatsValue>
            </Row>
            <Row>
              <INTLabel>MP</INTLabel>
              <StatsValue>{monstaStats.mp}</StatsValue>
            </Row>
          </Col>
        </MonstaStatsLabelRow2>
      </Col>
    </MonstaStatsTableRow>
  );
};

export default MonstaStatsTable;
