import { useEffect, useState } from "react";
import tapwarApi from "../../../services/tapwar-api/axios";

const UseTapshop = (isAuth: boolean, setIsAuth: (value: boolean) => void) => {
  const [shieldPrice, setShieldPrice] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [isSucess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setDataLoading(true);
        const response = await tapwarApi({
          method: "get",
          url: "/purchase/catalog",
        });
        if (response.data) {
          setShieldPrice(response.data.shield_price);
        }
        setDataLoading(false);
      } catch (error: any) {
        setDataLoading(false);
        console.log(error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          setIsAuth(false);
        }
      }
    };

    if (isAuth) {
      fetchData();
    } else {
      setShieldPrice(0);
      setDataLoading(false);
    }
  }, [isAuth]);

  const handleRecharge = async () => {
    setIsSubmitting(true);
    setIsSuccess(false);
    setError("");
    try {
      const response = await tapwarApi({
        method: "post",
        url: "/purchase/recharge-shield",
      });
      setIsSuccess(true);
    } catch (error: any) {
      console.log(error);
      const msg =
        error.response?.data?.detail ||
        "Something went wrong, please try again later";
      setError(msg);
    }
    setIsSubmitting(false);
  };

  return {
    shieldPrice,
    dataLoading,
    isSucess,
    error,
    isSubmitting,
    handleRecharge,
  };
};

export default UseTapshop;
