import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import tapwarApi from "../services/tapwar-api/axios";

const useTapwarAuth = () => {
  const { account, library } = useWeb3React();
  const [isAuth, setIsAuth] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("access");
    const authrizedAddress = localStorage.getItem("tapwar_mw");
    if (!accessToken || !account) {
      setIsAuth(false);
      return;
    }
    if (account !== authrizedAddress) {
      handleLogout();
      return;
    }
    setIsAuth(true);
  }, [account]);

  const handleLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("tapwar_mw");
    setIsAuth(false);
  };

  const handleLogin = async () => {
    if (!account || !library)
      throw new Error("Please connect to monsta wallet.");

    try {
      setLoginLoading(true);
      const message =
        Math.round(new Date().getTime() / 1000).toString() + "." + account;

      const signer = library.getSigner();
      const address = await signer.getAddress();
      const signature = await library.send("personal_sign", [
        ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message)),
        address.toLowerCase(),
      ]);
      const dataPayload = { message: message, signature: signature };

      const response = await tapwarApi({
        method: "POST",
        url: `/auth/login`,
        data: dataPayload,
      });

      if (response.data) {
        localStorage.setItem("access", response.data.access);
        localStorage.setItem("refresh", response.data.refresh);
        localStorage.setItem("tapwar_mw", account);
        setIsAuth(true);
      }

      setLoginLoading(false);
    } catch (error) {
      setLoginLoading(false);
      console.log(error);
    }
  };

  return {
    loginLoading,
    handleLogin,
    handleLogout,
    isAuth,
    setIsAuth,
  };
};

export default useTapwarAuth;
