import { FC, useEffect, useState } from "react";
import { Image, Row, Typography, message, Space } from "antd";
import { CopyFilled } from "@ant-design/icons";
import { useWeb3React } from "@web3-react/core";
import { encode as btoa } from "base-64";
import { ethers } from "ethers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import phoneBg from "../../../assets/images/qr-scanning.png";
import {
  StyledQRModal,
  LeftColQR,
  RightColQR,
  QRSVGWrapperMask,
  QRGenerateButton,
  QRClipboardButton,
  QRRegenerateButton,
} from "../style";

type GameLoginModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const GameLoginModal: FC<GameLoginModalProps> = ({ visible, onCancel }) => {
  const { account, library } = useWeb3React();
  const [encodedCode, setEncodedCode] = useState("");
  const [error, setError] = useState("");
  const { Text } = Typography;

  useEffect(() => {
    error && message.error(error);
  }, [error]);

  const signMessage = async ({ setError, message }: any) => {
    try {
      if (!library) throw new Error("Please connect to monsta wallet.");
      const signer = library.getSigner();
      const address = await signer.getAddress();
      const signature = await library.send("personal_sign", [
        ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message)),
        address.toLowerCase(),
      ]);
      return {
        message,
        signature,
      };
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleGenerateQRcode = async () => {
    const data =
      Math.round(new Date().getTime() / 1000).toString() + "." + account;
    setError("");
    const sig = await signMessage({
      setError,
      message: data,
    });
    if (sig) {
      setEncodedCode(btoa(JSON.stringify(sig)));
    }
  };

  const handleCopy = () => {
    message.success("Successfully copied to clipboard!");
  };

  return (
    <StyledQRModal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={1100}
    >
      <Row>
        <LeftColQR md={{ span: 14 }}>
          <Text>Scan QR code to play game</Text>
          <Image src={phoneBg} preview={false} />
        </LeftColQR>
        <RightColQR md={{ span: 9, offset: 1 }}>
          {encodedCode ? (
            <div>
              <div className="qr-svg-wrapper active">
                <QRCode value={encodedCode} />
              </div>
              <CopyToClipboard text={encodedCode} onCopy={handleCopy}>
                <QRClipboardButton>
                  Copy to Clipboard
                  <CopyFilled rotate={180} />
                </QRClipboardButton>
              </CopyToClipboard>
              <QRRegenerateButton>
                <span onClick={handleGenerateQRcode}>Regenerate QR Code</span>
              </QRRegenerateButton>
            </div>
          ) : (
            <div className="qr-svg-wrapper">
              <QRSVGWrapperMask />
              <QRCode value={Date()} />
              <QRGenerateButton onClick={handleGenerateQRcode}>
                Generate QR Code
              </QRGenerateButton>
            </div>
          )}
        </RightColQR>
      </Row>
    </StyledQRModal>
  );
};

export default GameLoginModal;
