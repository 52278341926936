import { Image, message } from "antd";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import {
  CenteredRow,
  MonstaParent,
  MonstaBodyImage,
  LeftRow,
  MonstaParentCardLabelImage,
  MonstaTitle,
} from "./style";
import { MonstaType } from "../../../../models/monsta";
import TagGroup from "../../../../components/TagGroup";
import { MONSTA_CLASSES } from "../../../../models/constants";
import { parseSvg } from "../../../../utils/parseSvg";

type MonstaParentCardProps = {
  parent: MonstaType;
};

const MonstaParentCard = ({ parent }: MonstaParentCardProps) => {
  const navigate = useNavigate();

  const handleClick = (
    id: number,
    timeLeft: string | null,
    isAdult: number
  ): void => {
    if (isAdult) {
      if (isMobile) {
        navigate(`/monsta/${id}`);
      } else {
        window.open(`/monsta/${id}`, "_blank");
      }
    } else if (timeLeft === null) {
      if (isMobile) {
        navigate(`/hatch/${id}`);
      } else {
        window.open(`/hatch/${id}`, "_blank");
      }
    } else {
      message.warning("Not ready to hatch");
    }
  };

  return (
    <MonstaParent
      style={{
        paddingLeft: "0",
        paddingRight: "0",
      }}
      xs={24}
      sm={10}
      md={10}
      lg={10}
    >
      <MonstaParentCardLabelImage>
        <Image
          preview={false}
          src={MONSTA_CLASSES[parent.classType].roundIcon}
        />
      </MonstaParentCardLabelImage>
      <CenteredRow
        space={"10px"}
        onClick={() => {
          handleClick(parent.id, parent.timeLeft, parent.isAdult);
        }}
      >
        <MonstaBodyImage>
          <Image preview={false} src={parseSvg(parent.img)} />
        </MonstaBodyImage>
        <LeftRow>
          <MonstaTitle>{`#${parent.id}`}</MonstaTitle>
        </LeftRow>
        <LeftRow>
          <TagGroup monsta={parent} />
        </LeftRow>
      </CenteredRow>
    </MonstaParent>
  );
};

export default MonstaParentCard;
