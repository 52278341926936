import armSvg from "../assets/images/body_parts/arms.svg";
import bodySvg from "../assets/images/body_parts/body.svg";
import faceSvg from "../assets/images/body_parts/face.svg";
import headSvg from "../assets/images/body_parts/head.svg";
import legsSvg from "../assets/images/body_parts/legs.svg";
import tailSvg from "../assets/images/body_parts/tail.svg";
import { MonstaBodyParts } from "../views/Monsta/components/MonstaCard/types";

type PartInfo = {
  class: number;
  variant: number;
};

export const getBodyParts = (binaryGenes: string): MonstaBodyParts[] => {
  return [
    {
      part: "face",
      img: faceSvg,
      isSpecial: Boolean(parseInt(binaryGenes.substring(44, 47), 2)),
      ...getPartInfo(192, binaryGenes),
    },
    {
      part: "head",
      img: headSvg,
      isSpecial: Boolean(parseInt(binaryGenes.substring(41, 44), 2)),
      ...getPartInfo(160, binaryGenes),
    },
    {
      part: "body",
      img: bodySvg,
      isSpecial: Boolean(parseInt(binaryGenes.substring(32, 35), 2)),
      ...getPartInfo(64, binaryGenes),
    },
    {
      part: "tail",
      img: tailSvg,
      isSpecial: Boolean(parseInt(binaryGenes.substring(47, 50), 2)),
      ...getPartInfo(224, binaryGenes),
    },
    {
      part: "arms",
      img: armSvg,
      isSpecial: Boolean(parseInt(binaryGenes.substring(35, 38), 2)),
      ...getPartInfo(96, binaryGenes),
    },
    {
      part: "legs",
      img: legsSvg,
      isSpecial: Boolean(parseInt(binaryGenes.substring(38, 41), 2)),
      ...getPartInfo(128, binaryGenes),
    },
  ];
};

const getPartInfo = (startIndex: number, gene: string): PartInfo => {
  return {
    class: parseInt(gene.substring(startIndex, startIndex + 6), 2),
    variant: parseInt(gene.substring(startIndex + 6, startIndex + 10), 2) + 1,
  };
};
