import { FC, ReactNode, useEffect, useState } from "react";
import {
  StyledInputGroup,
  InputGroupIcon,
  InputGroupLabelContainer,
  InputGroupInput,
  InputGroupLoadingContainer,
} from "../style";
import { Tooltip } from "antd";
import Loading from "../../../components/Loading/Loading";

type InputGroupProps = {
  icon?: string;
  label: string;
  LabelIcon: ReactNode;
  toolTipText?: String;
  labelDescription?: string;
  inputValue: string;
  inputSuffix?: string;
  onClick?: () => void;
  loading?: boolean;
};

const InputGroup: FC<InputGroupProps> = ({
  icon,
  label,
  LabelIcon,
  toolTipText,
  labelDescription,
  inputValue,
  inputSuffix,
  onClick,
  loading,
}) => {
  const sizeClass = icon ? "" : "small";
  const [value, setValue] = useState(inputValue);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);
  const clickable = onClick ? "clickable" : "";

  return (
    <StyledInputGroup className={`${sizeClass} ${clickable}`} onClick={onClick}>
      {icon && <InputGroupIcon src={icon} />}
      <InputGroupLabelContainer>
        <span className="label">{label}</span>
        <Tooltip placement="topLeft" title={toolTipText}>
          {LabelIcon}
        </Tooltip>
      </InputGroupLabelContainer>
      {loading ? (
        <InputGroupLoadingContainer>
          <Loading />
        </InputGroupLoadingContainer>
      ) : (
        <InputGroupInput
          value={value}
          suffix={inputSuffix}
          bordered={false}
          readOnly
        />
      )}
    </StyledInputGroup>
  );
};

export default InputGroup;
