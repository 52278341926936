import {
  MonstaSalesHistoryButtonsWrapper,
  MonstaSalesHistoryClickableLabel,
  MonstaSalesHistoryLabel,
  MonstaSalesHistorySeparator,
} from "./style";
type MonstaSalesHistoryButtonType = {
  toggleModalVisible: () => void;
};
const MonstaSalesHistoryButton = ({
  toggleModalVisible,
}: MonstaSalesHistoryButtonType) => {
  return (
    <MonstaSalesHistoryButtonsWrapper>
      <MonstaSalesHistoryLabel color={"--monsta-yellow"}>
        SALES HISTORY
      </MonstaSalesHistoryLabel>
      <MonstaSalesHistorySeparator>│</MonstaSalesHistorySeparator>
      <MonstaSalesHistoryClickableLabel
        onClick={() => {
          toggleModalVisible();
        }}
        color={"--monsta-gray-2"}
        hoverColor={"--white"}
      >
        CLICK TO VIEW
      </MonstaSalesHistoryClickableLabel>
    </MonstaSalesHistoryButtonsWrapper>
  );
};

export default MonstaSalesHistoryButton;
