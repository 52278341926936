import { config } from "../../config";

export const getMonstaPreview = async (gene: string) => {
  const res = await fetch(`${config.api.monsta}/preview`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: gene }),
  });
  return res;
};

export const hatchMonsta = async (token_id: string) => {
  const res = await fetch(`${config.api.monsta}/hatch`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token_id }),
  });
  return res;
};
