import {
  MonstaParentCrossImage,
  MonstaLabelWrapper,
  MonstaParentCardWrapper,
  ParentCrossLogo,
  MonstaNoParentLabelWrapper,
  MonstaNoParentLabelSubtitleWrapper,
  MonstaNoParentLabelSubtitleLine,
  MonstaNoParentLabelSubtitleText,
  MonstaNoParentLabelTitleText,
} from "./style";
import { Image, Typography } from "antd";
import DnaImg from "../../../../assets/images/dna.svg";
import MonstaNoParentBox from "../../../../assets/images/monsta-no-parent-box.png";
import MonstaParentCard from "./MonstaParentCard";
import { MonstaType } from "../../../../models/monsta";
import { useMonstaPreviewApi } from "../../../../hooks/useMonsta";
import { extractMonsta, getMonstaType } from "../../../../utils/monstaHelpers";
import { useEffect, useState } from "react";
import { useGetParents } from "../../../../hooks/useGetParents";
import { toBinaryGenes } from "../../../../utils/toBinaryGenes";

type MonstaParentCardListProps = {
  monstaId: string;
};

const { Text } = Typography;
const MonstaParentCardList = ({ monstaId }: MonstaParentCardListProps) => {
  const [fetchParents, { data: parentsRes }] = useGetParents(monstaId);
  const { preview } = useMonstaPreviewApi();
  const [matron, setMatron] = useState<MonstaType>();
  const [sire, setSire] = useState<MonstaType>();
  const monstaType = getMonstaType(parseInt(monstaId, 10));

  useEffect(() => {
    const getMonstaParents = async () => {
      await fetchParents();
      if (parentsRes?.monsta?.matron && parentsRes.monsta.sire) {
        const matronRes = parentsRes.monsta.matron;
        const matronInfo = extractMonsta(matronRes);
        const matronSvg = await preview(toBinaryGenes(matronRes.genes));
        setMatron({ ...matronInfo, img: matronSvg });

        const sireRes = parentsRes.monsta.sire;
        const sireInfo = extractMonsta(sireRes);
        const sireSvg = await preview(toBinaryGenes(sireRes.genes));
        setSire({ ...sireInfo, img: sireSvg });
      }
    };

    getMonstaParents();
  }, [fetchParents, parentsRes, preview]);

  return (
    <div>
      <MonstaLabelWrapper>
        {" "}
        <Text>Parents</Text>
      </MonstaLabelWrapper>
      <MonstaParentCardWrapper gutter={[16, 16]} justify="center">
        {matron && sire ? (
          <>
            <MonstaParentCard parent={matron} />
            <ParentCrossLogo sm={2}>
              <MonstaParentCrossImage>
                <Image src={DnaImg} preview={false} />
              </MonstaParentCrossImage>
            </ParentCrossLogo>
            <MonstaParentCard parent={sire} />
          </>
        ) : (
          <MonstaNoParentLabel monstaType={monstaType} />
        )}
      </MonstaParentCardWrapper>
    </div>
  );
};

const MonstaNoParentLabel = (props: { monstaType: string }) => {
  const { monstaType } = props;

  const getSubtitle = (monstaType: string): string => {
    if (monstaType === "Inception Monsta") {
      return "THE ORIGINAL MONSTA";
    } else if (monstaType === "Chrono Zero") {
      return "THE FIRST GENERATION";
    } else if (monstaType === "Soul Bond") {
      return "THE LIFE CONTRACT";
    } else {
      return "";
    }
  };

  const getTitle = (monstaType: string): string => {
    if (monstaType === "Inception Monsta") {
      return "INCEPTION";
    } else if (monstaType === "Chrono Zero") {
      return "CHRONO ZERO";
    } else if (monstaType === "Soul Bond") {
      return "SOULBOND";
    } else {
      return "";
    }
  };

  return (
    <MonstaNoParentLabelWrapper backgroundImage={MonstaNoParentBox}>
      <MonstaNoParentLabelSubtitleWrapper>
        <MonstaNoParentLabelSubtitleLine />
        <MonstaNoParentLabelSubtitleText>
          {getSubtitle(monstaType)}
        </MonstaNoParentLabelSubtitleText>
        <MonstaNoParentLabelSubtitleLine />
      </MonstaNoParentLabelSubtitleWrapper>
      <MonstaNoParentLabelTitleText>
        {getTitle(monstaType)}
      </MonstaNoParentLabelTitleText>
    </MonstaNoParentLabelWrapper>
  );
};

export default MonstaParentCardList;
