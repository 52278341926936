import {
  BaseText,
  CenteredRow,
  FixedHeightCol,
  ModalButton,
  ModalButtonCol,
  SquareTopHolder,
} from "../style";
import PurchaseFailedImage from "../../../assets/images/fc_sale/purchase_failed_image.png";
import { Col } from "antd";

type PurchaseFailedScreenProps = {
  onCancel: () => void;
};
const PurchaseFailedScreen = ({ onCancel }: PurchaseFailedScreenProps) => {
  return (
    <CenteredRow gutter={[8, 20]}>
      <FixedHeightCol height="60px" span={22}>
        <SquareTopHolder>
          <img src={PurchaseFailedImage} alt="Failed" />
        </SquareTopHolder>
      </FixedHeightCol>
      <Col md={18} sm={18}>
        <BaseText display="block" fontSize="36px">
          Purchase Failed!
        </BaseText>
      </Col>
      <Col md={20} sm={22}>
        <BaseText>
          Oh dear! Looks like your purchase has failed! Please make your
          purchase within the 5 minute transaction period or check whether your
          wallet balance is sufficient and try again!
        </BaseText>
      </Col>
      <ModalButtonCol span={20}>
        <ModalButton onClick={onCancel}>Back To Homepage</ModalButton>
      </ModalButtonCol>
    </CenteredRow>
  );
};
export default PurchaseFailedScreen;
