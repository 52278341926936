import { useLazyQuery } from "@apollo/client";
import { Contract } from "@ethersproject/contracts";
import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import Flash from "../../assets/images/Flash.png";
import InceptionEgg from "../../assets/images/InceptionEgg.svg";
import { config } from "../../config";
import { marketplaceClient } from "../../graphql/apolloClient";
import {
  GetUserMonstaQuery,
  GetUserMonstaQueryVariables,
  Monsta_OrderBy,
  OrderDirection,
} from "../../graphql/generated-types";
import { GET_USER_MONSTA } from "../../graphql/queries/marketplace";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import { useERC20Allowance } from "../../hooks/useERC20Allowance";
import { MonstaResType } from "../../models/monsta";
import {
  getImDistributorAddress,
  getImeggAddress,
} from "../../utils/addressHelpers";
import { callWithEstimateGas } from "../../utils/calls";
import {
  getImDistributorContract,
  getInceptionMonstaMediatorContract,
} from "../../utils/contractHelpers";
import {
  AlignedCol,
  CenteredRow,
  EggImage,
  FlashImage,
  ImageContainer,
  MonstaImage,
  TokenIdText,
} from "./style";
import { TextButton } from "../../components/Button";
import { parseSvg } from "../../utils/parseSvg";

const HatchInception = () => {
  const { account, library } = useActiveWeb3React();
  const [initialInceptionMonsta, setInitialInceptionMonsta] = useState<
    MonstaResType[]
  >([]);
  const [approveLoading, setApproveLoading] = useState(false);
  const [approveTx, setApproveTx] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [hatchTx, setHatchTx] = useState("");
  const [hatchLoading, setHatchLoading] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const [isHatched, setIsHatched] = useState(false);
  const gifRef = useRef<HTMLImageElement | null>(null);
  const [tokenId, setTokenId] = useState("");
  const [monstaSvg, setMonstaSvg] = useState("");

  const [fetchAllowance, { data: allowanceRes, startPolling, stopPolling }] =
    useERC20Allowance(getImeggAddress(), account!, getImDistributorAddress());

  const [fetchInceptionMonsta, { refetch: refetchInceptionMonsta }] =
    useLazyQuery<GetUserMonstaQuery, GetUserMonstaQueryVariables>(
      GET_USER_MONSTA,
      {
        variables: {
          orderBy: Monsta_OrderBy.Token,
          orderDirection: OrderDirection.Desc,
          where: { owner: account?.toLowerCase() || "", specialType: 1 },
        },
        client: marketplaceClient,
      }
    );

  useEffect(() => {
    if (account) {
      const checkInitialApproval = async () => {
        const allowance = await fetchAllowance();
        if (allowance.data && allowance.data.erc20Approval) {
          const initialAllowance = Number(
            allowance.data.erc20Approval.valueExact
          );
          if (initialAllowance === 1) {
            setIsApproved(true);
          }
        }
      };

      const getInitialInceptionMonsta = async () => {
        const iMonstaRes = await fetchInceptionMonsta();
        const inceptionMonstas = iMonstaRes.data?.monstas;
        if (inceptionMonstas) {
          setInitialInceptionMonsta(inceptionMonstas);
        }
      };

      checkInitialApproval();
      getInitialInceptionMonsta();
    }
  }, [account, fetchAllowance, fetchInceptionMonsta]);

  const handleApprove = async () => {
    if (library && account) {
      setApproveLoading(true);
      const inceptionMonstaMediatorContract: Contract | null =
        getInceptionMonstaMediatorContract(library.getSigner());
      try {
        const transactionRes = await callWithEstimateGas(
          inceptionMonstaMediatorContract,
          "approve",
          [getImDistributorAddress(), 1],
          {
            gasPrice: "0",
          },
          { from: account, value: 0 }
        );
        setApproveTx(transactionRes.hash);
      } catch (error: any) {
        if (error?.code === 4001) {
          message.error("Transaction rejected");
        } else {
          if (error?.code === -32603) {
            message.error(`Approve xIMEGG failed: ${error.data?.message}`);
          }
          throw Error(`Approve xIMEGG failed: ${error.message}`);
        }
      }
    } else {
      message.warn("Please connect your wallet");
    }
  };

  useEffect(() => {
    if (approveTx && approveLoading) {
      startPolling(1000);
      const allowance = Number(allowanceRes?.erc20Approval?.valueExact);
      if (allowance === 1) {
        stopPolling();
        setIsApproved(true);
        setApproveLoading(false);
      }
    }
  }, [approveTx, approveLoading, allowanceRes, startPolling, stopPolling]);

  const handleHatch = async () => {
    if (account && library) {
      const imDistributorContract: Contract | null = getImDistributorContract(
        library.getSigner()
      );

      try {
        const transactionRes = await callWithEstimateGas(
          imDistributorContract,
          "claim",
          [],
          {
            gasPrice: "0",
          },
          { from: account, value: 0 }
        );
        setHatchTx(transactionRes.hash);
      } catch (error: any) {
        if (error?.code === 4001) {
          message.error("Transaction rejected");
        } else {
          if (error?.code === -32603) {
            message.error(`Hatch xIMEGG failed: ${error.data?.message}`);
          }
          throw Error(`Hatch xIMEGG failed: ${error.message}`);
        }
      }
    } else {
      message.warn("Please connect your wallet");
    }
  };

  useEffect(() => {
    let isHatching = false;
    let bodyPartClass = 0;

    if (hatchTx) {
      (async function wait() {
        if (isHatching && gifRef.current != null) {
          setStartAnimation(true);
          gifRef.current.src = `/gifs/${bodyPartClass}.gif`;
          // prevent image being scale before gif load
          gifRef.current.style.display = "none";
          gifRef.current.style.transform = "scale(2.5)";
          gifRef.current.style.display = "block";
          setTimeout(() => {
            setHatchLoading(false);
            setIsHatched(true);
          }, 4000);
        } else {
          setHatchLoading(true);
          let inceptionMonsta: MonstaResType[] = [];
          const res = await refetchInceptionMonsta();
          if (res && res.data) {
            inceptionMonsta = res.data.monstas;
          }
          if (inceptionMonsta.length > initialInceptionMonsta.length) {
            setTokenId(inceptionMonsta[0].id);
            const monstaGeneBin = BigInt(inceptionMonsta[0].genes).toString(2);
            const monstaClass = "0000000" + monstaGeneBin;
            (async () => {
              const rawResponse = await fetch(`${config.api.monsta}/preview`, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ data: monstaClass }),
              });
              const content = await rawResponse.text();
              setMonstaSvg(content);
            })();
            bodyPartClass = parseInt(monstaClass.substring(64, 70), 2);
            isHatching = true;
          }
          setTimeout(wait, 1000);
        }
      })();
    }
  }, [hatchTx, initialInceptionMonsta, refetchInceptionMonsta]);

  return (
    <CenteredRow>
      <AlignedCol>
        <TokenIdText>{tokenId ? `#${tokenId}` : ""}</TokenIdText>
        <ImageContainer>
          <EggImage
            src={InceptionEgg}
            startAnimation={startAnimation}
            ref={gifRef}
          />
          <FlashImage src={Flash} startAnimation={startAnimation} />
          <MonstaImage
            src={parseSvg(monstaSvg)}
            startAnimation={startAnimation}
          />
        </ImageContainer>

        {!isApproved ? (
          <TextButton
            scale="md"
            bgColor="#fee615"
            textColor="#000000"
            loading={approveLoading}
            onClick={handleApprove}
          >
            Approve
          </TextButton>
        ) : !isHatched ? (
          <TextButton
            scale="md"
            bgColor="#fee615"
            textColor="#000000"
            loading={hatchLoading}
            onClick={handleHatch}
          >
            Hatch
          </TextButton>
        ) : (
          <TextButton
            scale="md"
            bgColor="#fee615"
            textColor="#000000"
            href={`/monsta/${tokenId}`}
          >
            Monsta Detail
          </TextButton>
        )}
      </AlignedCol>
    </CenteredRow>
  );
};

export default HatchInception;
