import { useEffect, useState } from "react";
import { Space, Checkbox } from "antd";
import MonstaTypeCheckItem from "./MonstaTypeCheckItem";
import {
  FilterOutlined,
  RedoOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { MonstaFilterType, MonstaKind } from "../";

import {
  MarketplaceSider,
  PageTitle,
  SiderContent,
  SiderTriggerButtonInside,
  SiderSectionTitle,
  SiderTextInput,
  SiderDivider,
  SiderCloneUsageSlider,
  SiderCheckbox,
  SiderCheckboxIcon,
} from "../style";
import bomohIcon from "../../../assets/images/bomoh-mini-icon.png";
import nightingaleIcon from "../../../assets/images/nightingale-mini-icon.png";
import juggernautIcon from "../../../assets/images/juggernaut-mini-icon.png";
import terminatorIcon from "../../../assets/images/terminator-mini-icon.png";
import golemIcon from "../../../assets/images/golem-mini-icon.png";
import prophetIcon from "../../../assets/images/prophet-mini-icon.png";
import grognakIcon from "../../../assets/images/grognak-mini-icon.png";
import primeIcon from "../../../assets/images/prime-mini-icon.png";
import alchemistIcon from "../../../assets/images/alchemist-mini-icon.png";

type SiderFilterProps = {
  collapsedSider: boolean;
  setCollapsedSider: Function;
  filter: MonstaFilterType;
  monstaKinds: MonstaKind[];
  onChangeFilter: (
    monstaFilter?: MonstaFilterType,
    monstaKinds?: MonstaKind[]
  ) => void;
  onResetFilter: () => void;
};

let inputTimeout: ReturnType<typeof setTimeout>;

const SiderFilter = (props: SiderFilterProps) => {
  const {
    collapsedSider,
    setCollapsedSider,
    filter,
    monstaKinds,
    onChangeFilter,
    onResetFilter,
  } = props;
  const [monstaId, setMonstaId] = useState(
    filter.token !== undefined ? filter.token : ""
  );
  const [monstaIdInput, setMonstaIdInput] = useState(
    filter.token !== undefined ? filter.token : ""
  );
  const [clonedUsage, setClonedUsage] = useState(
    filter.cloneUsage !== undefined ? filter.cloneUsage : -1
  );
  const [monstaClass, setMonstaClass] = useState<number[]>(
    filter.classType_in || []
  );

  const [isNormalType, setIsNormalType] = useState(
    monstaKinds.includes(MonstaKind.NORMAL)
  );
  const [isChronoZeroType, setIsChronoZeroType] = useState(
    monstaKinds.includes(MonstaKind.CHRONOZERO)
  );
  const [isInceptionType, setIsInceptionType] = useState(
    monstaKinds.includes(MonstaKind.INCEPTION)
  );

  const MONSTA_CLASSES = [
    {
      name: "Bomoh",
      image: bomohIcon,
      value: 8,
    },
    {
      name: "Nightingale",
      image: nightingaleIcon,
      value: 4,
    },
    {
      name: "Juggernaut",
      image: juggernautIcon,
      value: 7,
    },
    {
      name: "Terminator",
      image: terminatorIcon,
      value: 1,
    },
    {
      name: "Golem",
      image: golemIcon,
      value: 3,
    },
    {
      name: "Prophet",
      image: prophetIcon,
      value: 5,
    },
    {
      name: "Grognak",
      image: grognakIcon,
      value: 6,
    },
    {
      name: "Prime",
      image: primeIcon,
      value: 0,
    },
    {
      name: "Alchemist",
      image: alchemistIcon,
      value: 2,
    },
  ];

  const SIDER_CLONE_USAGE_SLIDER_MARKS = {
    "-1": "All",
    0: "0",
    1: "1",
    2: "2",
    3: "3",
    4: "4",
  };

  const searchById = (id: string) => {
    clearTimeout(inputTimeout);
    const validatedId = id.replace(/\D/g, "");
    setMonstaIdInput(validatedId);
    inputTimeout = setTimeout(() => {
      setMonstaId(validatedId);
    }, 2000);
  };

  const tipFormatter = (value?: number) => {
    return value === -1 ? "All" : value;
  };

  const onChangeCloned = (value: number) => {
    setClonedUsage(value);
  };

  const onChangeClass = (checkedValues: any[]) => {
    setMonstaClass(checkedValues);
  };

  const getMonstaKinds = (): MonstaKind[] => {
    let kinds = [];
    isNormalType && kinds.push(MonstaKind.NORMAL);
    isChronoZeroType && kinds.push(MonstaKind.CHRONOZERO);
    isInceptionType && kinds.push(MonstaKind.INCEPTION);
    return kinds;
  };

  const onReset = () => {
    setMonstaIdInput("");
    setMonstaId("");
    setMonstaClass([]);
    setClonedUsage(-1);
    setIsChronoZeroType(false);
    setIsInceptionType(false);
    setIsNormalType(false);
    onResetFilter();
  };

  useEffect(() => {
    const kinds = getMonstaKinds();
    onChangeFilter(filter, kinds);
  }, [isNormalType, isChronoZeroType, isInceptionType]);

  useEffect(() => {
    const filter: MonstaFilterType = {};
    if (monstaId) {
      filter.token = monstaId;
    }
    if (clonedUsage >= 0) {
      filter.cloneUsage = clonedUsage;
    }
    if (monstaClass.length > 0) {
      filter.classType_in = monstaClass;
    }

    // Handle Monsta Type
    if (isNormalType && !isChronoZeroType && !isInceptionType) {
      filter.specialType_in = [0, 1];
      filter.token_gte = 21400;
    } else if (!isNormalType && isChronoZeroType && !isInceptionType) {
      filter.specialType_in = [0, 1];
      filter.token_gte = 4090;
      filter.token_lte = 14099;
    } else if (!isNormalType && !isChronoZeroType && isInceptionType) {
      filter.specialType = 1;
    } else if (isNormalType && isChronoZeroType && !isInceptionType) {
      filter.specialType = 0;
    } else if (isNormalType && !isChronoZeroType && isInceptionType) {
      filter.specialType_in = [0, 1];
      const chronoZeroTokens = [];
      for (let i = 4090; i < 14100; i++) {
        chronoZeroTokens.push(i);
      }
      filter.token_not_in = chronoZeroTokens;
    } else if (!isNormalType && isChronoZeroType && isInceptionType) {
      filter.specialType_in = [0, 1];
      filter.token_lte = 14099;
    } else if (isNormalType && isChronoZeroType && isInceptionType) {
      filter.specialType_in = [0, 1];
    }

    const kinds = getMonstaKinds();
    onChangeFilter(filter, kinds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    monstaId,
    clonedUsage,
    monstaClass,
    isNormalType,
    isChronoZeroType,
    isInceptionType,
  ]);

  return (
    <MarketplaceSider
      collapsible
      collapsed={collapsedSider}
      collapsedWidth="0"
      width="320"
      trigger={null}
    >
      <SiderContent hidden={collapsedSider}>
        <PageTitle>Marketplace</PageTitle>
        <SiderTriggerButtonInside
          icon={<FilterOutlined />}
          onClick={() => {
            setCollapsedSider(true);
          }}
        >
          Filter
        </SiderTriggerButtonInside>
        <SiderTriggerButtonInside icon={<RedoOutlined />} onClick={onReset}>
          Reset
        </SiderTriggerButtonInside>
        <SiderSectionTitle>SEARCH MONSTA ID</SiderSectionTitle>
        <SiderTextInput
          placeholder="Search (e.g. 3798)"
          prefix={<SearchOutlined />}
          value={monstaIdInput}
          onChange={(e) => searchById(e.target.value)}
        />
        <SiderDivider />
        <SiderSectionTitle>MONSTA TYPE</SiderSectionTitle>
        <Space direction="horizontal" align="start" size="middle">
          <MonstaTypeCheckItem
            value={MonstaKind.NORMAL}
            onChange={(value) => {
              setIsNormalType(value);
            }}
            checked={isNormalType}
          />
          <MonstaTypeCheckItem
            value={MonstaKind.CHRONOZERO}
            onChange={(value) => {
              setIsChronoZeroType(value);
            }}
            checked={isChronoZeroType}
          />
          <MonstaTypeCheckItem
            value={MonstaKind.INCEPTION}
            onChange={(value) => {
              setIsInceptionType(value);
            }}
            checked={isInceptionType}
          />
        </Space>
        <SiderDivider />
        <SiderSectionTitle>CLASS</SiderSectionTitle>
        <Checkbox.Group onChange={onChangeClass} value={monstaClass}>
          <Space direction="vertical">
            {MONSTA_CLASSES.map((monstaClass) => (
              <SiderCheckbox key={monstaClass.value} value={monstaClass.value}>
                <SiderCheckboxIcon src={monstaClass.image} />
                <span>{monstaClass.name}</span>
              </SiderCheckbox>
            ))}
          </Space>
        </Checkbox.Group>
        <SiderDivider />
        <SiderSectionTitle>CLONE USAGE</SiderSectionTitle>
        <SiderCloneUsageSlider
          min={-1}
          max={4}
          marks={SIDER_CLONE_USAGE_SLIDER_MARKS}
          tipFormatter={null}
          onChange={onChangeCloned}
          value={clonedUsage}
        />
      </SiderContent>
    </MarketplaceSider>
  );
};

export default SiderFilter;
