import { useCallback } from "react";
import { getMonstaPreview } from "../services/monsta-api";

export const useMonstaPreviewApi = () => {
  const preview = useCallback(async (gene: string) => {
    try {
      const res = await getMonstaPreview(gene);
      const svg = res.text();
      return svg;
    } catch (err) {
      throw err;
    }
  }, []);

  return { preview };
};
