import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../services/redux";
import {
  addMonsta,
  removeMonsta,
  clearMonstas,
} from "../services/redux/clone-chamber";
import { MonstaType } from "../models/monsta";

export enum CloneChamberStatus {
  EMPTY,
  ONE,
  FULL,
}

const DESCRIPTIONS = {
  [CloneChamberStatus.EMPTY]: "Please select at least (1) Monsta for cloning.",
  [CloneChamberStatus.ONE]:
    "You have added (1) Monsta to your cloning chamber, please select second Monsta.",
  [CloneChamberStatus.FULL]: "Your Cloning Chamber is full and ready to clone!",
};

const useCloneChamber = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(CloneChamberStatus.EMPTY);
  const [description, setDescription] = useState(
    DESCRIPTIONS[CloneChamberStatus.EMPTY]
  );
  const { monstas } = useSelector((state) => state.cloneChamber);

  const addMonstaChamber = (monsta: MonstaType) => dispatch(addMonsta(monsta));

  const removeMonstaChamber = (id: number) => dispatch(removeMonsta(id));

  const clearMonstasChamber = () => dispatch(clearMonstas());

  useEffect(() => {
    const _status = monstas.filter((monsta) => !!monsta)
      .length as CloneChamberStatus;
    setStatus(_status);
  }, [monstas]);

  useEffect(() => {
    setDescription(DESCRIPTIONS[status]);
  }, [status]);

  return {
    addMonstaChamber,
    removeMonstaChamber,
    clearMonstasChamber,
    monstas,
    status,
    description,
  };
};

export default useCloneChamber;
