import styled from "@emotion/styled";
import { Form, Button, Typography, Select } from "antd";
const { Text } = Typography;

export const SellForm = styled(Form)`
  height: 80%;
  width: 95%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Helvetica;
  font-style: normal;
  .ant-input-affix-wrapper,
  .ant-select .ant-select-selector {
    background: #0d4134;
    border-radius: 30px;
    border: none;
    height: auto;
    color: white;
  }
  .ant-input-affix-wrapper {
    padding: 11px 20px;
  }
  .ant-select .ant-select-selector {
    padding: 5px 20px;
  }
  .ant-input {
    background: #0d4134;
    color: white;
  }
  .ant-form-item-label {
    align-self: center;
  }
`;
export const SellFormWrapper = styled.div`
  .ant-form-item-explain .ant-form-item-explain-error {
    color: #fee615;
    padding-left: 10px;
    padding-bottom: 1rem;
  }
`;
export const SellFormItem = styled(Form.Item)`
  .ant-select-arrow {
    color: white;
  }
`;

export const SellTax = styled(Text)`
  color: white;
  float: right;
`;
export const SellButtonWrapper = styled(Form.Item)`
  display: flex;
  justify-content: right;
  margin-top: 1.5rem;
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    background: #0d4134;
  }
`;

export const SellButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  color: #fee615;
  background: #5f907a;
  border: none;
  font-weight: 700;
  width: 100%;
  font-size: 2rem;
  box-shadow: 1px 1px 2px #0d4134;
  cursor: pointer;
  white-space: nowrap;
  padding: 25px;
  img {
    width: 1.5rem;
    margin-right: 1rem;
  }
  &:hover {
    background: #0d4134;
    color: #fee615;
  }
  &:active {
    background: white;
  }
  &:focus {
    background: #0d4134;
    color: #fee615;
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    border: none !important;
    padding: 8px 20px !important;
  }

  .ant-select-arrow {
    color: white;
  }

  .ant-select-item-option-selected {
    background: white;
    color: #253933;
  }

  .ant-select-item-option:hover {
    background: #516661;
    color: white;
  }
`;
