import { useEffect, useState } from "react";
import { Image, message } from "antd";
import { useWeb3React } from "@web3-react/core";
import { MONSTA_CLASSES } from "../../../../models/constants";
import { MonstaChildType } from "../../../../models/monsta";
import { getMonstaType } from "../../../../utils/monstaHelpers";
import { parseSvg } from "../../../../utils/parseSvg";
import MonstaTag from "./MonstaTag";
import MoniTokenLogo from "../../../../assets/images/moni_token.svg";
import incubatorImage from "../../../../assets/images/incubator2.png";
import {
  CenteredRow,
  MonstaChild,
  MonstaBodyImage,
  MonstaImage,
  LeftRow,
  MonstaParentCardLabelImage,
  MonstaTitle,
  MonstaPrice,
  OnSaleWrapper,
} from "./style";
import { MonstaEventType } from "../../../../graphql/generated-types";

type MonstaChildCardProps = {
  child: MonstaChildType;
};

const MonstaChildCard = ({ child }: MonstaChildCardProps) => {
  const { account } = useWeb3React();
  const [isOwner, setIsOwner] = useState(false);
  const isSale = child.auctionStatus === MonstaEventType.UserAuctionCreated;
  useEffect(() => {
    if (account) {
      if (child && child.owner === account.toLowerCase()) {
        setIsOwner(true);
      }
    } else {
      setIsOwner(false);
    }
  }, [account, child]);

  return (
    <MonstaChild xs={24} sm={11} md={11} lg={11}>
      <div
        onClick={() => {
          child.isAdult
            ? window.open(`/monsta/${child.id}`, "_blank")
            : child.timeLeft === null && isOwner
            ? window.open(`/hatch/${child.id}`, "_blank")
            : isOwner
            ? message.warning("Not ready to hatch")
            : message.warning(`This egg doesn't belong to you`);
        }}
      >
        <MonstaParentCardLabelImage>
          {child.isAdult ? (
            <Image
              src={MONSTA_CLASSES[child.classType].roundIcon}
              preview={false}
            />
          ) : (
            <></>
          )}
        </MonstaParentCardLabelImage>
        {isSale && (
          <OnSaleWrapper>
            <MonstaTag text="ON SALES" size="sm" color="--monsta-yellow" />
          </OnSaleWrapper>
        )}
        <CenteredRow space={"10px"}>
          <MonstaBodyImage>
            <MonstaImage
              src={child.isAdult ? parseSvg(child.img) : incubatorImage}
              preview={false}
              style={{ width: "100%" }}
            />
          </MonstaBodyImage>
          <LeftRow>
            <MonstaTitle sm>
              <Image src={MoniTokenLogo} preview={false} />
              {child.id}
            </MonstaTitle>
          </LeftRow>
          <LeftRow>
            <MonstaTag
              text={getMonstaType(child.id)}
              size="sm"
              color="--monsta-gray"
            />
            <MonstaTag
              text={`CLONE ${child.cloneUsage}/4`}
              size="sm"
              color="--monsta-gray"
            />
          </LeftRow>
          <LeftRow>
            <MonstaPrice>
              {isSale && (
                <>
                  <span>{child.currentPrice}</span>
                  <span>xSTT</span>
                </>
              )}
            </MonstaPrice>
          </LeftRow>
        </CenteredRow>
      </div>
    </MonstaChild>
  );
};
export default MonstaChildCard;
