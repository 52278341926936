/* eslint-disable no-param-reassign */
import { Token } from "@pancakeswap/sdk";
import { useMemo } from "react";
import { isAddress } from "../../utils";
import { useAllTokens } from "./useAllTokens";

export function useToken(tokenAddress?: string): Token | undefined {
  const tokens = useAllTokens();
  const address = isAddress(tokenAddress);
  const token: Token | undefined = address ? tokens[address] : undefined;

  return useMemo(() => {
    if (token) return token;
    return undefined;
  }, [token]);
}
