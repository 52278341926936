import { useEffect, useState } from "react";
import { Col, message, Row } from "antd";
import {
  ActiveBtn,
  FcSaleBaseBtn,
  FcSaleCard,
  FcSaleSlogan,
  FcSaleWrapper,
  PackageRow,
  PaymentWarning,
  SloganSpan,
  TapwarLogo,
  WarningIcon,
  WarningTextSpan,
  TCCheckbox,
  TCLink,
  BaseText,
  LogoRow,
  PageLoadingWrapper,
  StyledPurchaseModal,
} from "./style";
import { PACKAGE_VALUES } from "./constants";
import { FortuneCrate } from "../../models/tapwar";
import {
  fetchPaymentStatus,
  PurchaseCurrency,
} from "../../services/tapwar-api";
import useMonstaAuth from "../../hooks/useMonstaAuth";
import useTapwarAuth from "../../hooks/useTapwarAuth";
import usePurchase, {
  BundleCatalog,
  BundleType,
} from "../../hooks/usePurchase";
import Loading from "../../components/Loading/Loading";
import FcCardBg from "../../assets/images/fc_sale/fc_sale_bg.png";
import TapWarLogoImg from "../../assets/images/fc_sale/tapwar-logo.svg";
import FCPackage from "./components/FCPackage";
import ConfirmModal from "./components/ConfirmModal";
import PaymentScreen from "./components/PaymentScreen";
import Cross from "../../assets/images/cross.svg";
import MonthlyPackage from "./components/MonthlyPackage";
import BottomLinkGroup from "./components/BottomLinkGroup";
import PaymentProcessingScreen from "./components/PaymentProcessingScreen";
import PurchaseFailedScreen from "./components/PurchaseFailedScreen";
import PurchaseCompletedScreen from "./components/PurchaseCompletedScreen";

export enum ModalScreen {
  CONFIRM = "confirm",
  PAYMENT = "payment",
  PROCESSING = "processing",
  COMPLETED = "completed",
  FAILED = "failed",
}

const FcBundleSaleView = () => {
  const PAYMENT_INITIAL_VALUES: FortuneCrate = {
    payment_id: "",
    payment_status: "",
    pay_address: "",
    pay_amount: "",
    pay_currency: "",
    purchase_id: "",
    order_id: "",
    order_description: "",
    expireAt: "",
  };
  const { account, active, connect } = useMonstaAuth();
  const { isAuth, handleLogin, loginLoading } = useTapwarAuth();
  const {
    bundleDetail,
    monthlyPassDetail,
    catalogLoading,
    generatePaymentDetail,
  } = usePurchase();
  const [activeBundle, setActiveBundle] = useState<BundleCatalog | null>(null);
  const [tcChecked, setTcChecked] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
  const [modalScreen, setModalScreen] = useState<ModalScreen>(
    ModalScreen.PAYMENT
  );
  const [isSubmittingFortune, setIsSubmittingFortune] = useState(false);
  const [fortunePaymentDetails, setFortunePaymentDetails] =
    useState<FortuneCrate>(PAYMENT_INITIAL_VALUES);
  const [isAwaitingPayment, setIsAwaitingPayment] = useState(false);

  const handleCancel = (screen: ModalScreen) => {
    if (screen === ModalScreen.CONFIRM) {
      setIsConfirmModalOpen(false);
    } else {
      setIsPurchaseModalOpen(false);
    }
  };

  const handleChangeModalScreen = (screen: ModalScreen) => {
    setModalScreen(screen);
  };

  const handleBuyButton = () => {
    setIsConfirmModalOpen(true);
  };

  const handleSelection = (value: BundleCatalog) => {
    if (activeBundle === value) {
      setActiveBundle(null);
    } else {
      setActiveBundle(value);
    }
  };

  const handleFortuneCreate = async () => {
    if (!activeBundle) {
      message.warn("Please select a bundle");
      return;
    }
    setIsSubmittingFortune(true);
    try {
      const quota =
        activeBundle.type === BundleType.MONTHLY_PASS
          ? activeBundle.quota ?? 1
          : 1;
      const payment_details = await generatePaymentDetail(
        PurchaseCurrency.BUSD_BSC,
        activeBundle.quantity * quota,
        activeBundle.type
      );
      const { payment_status, pay_address } = payment_details;
      if (pay_address) {
        setFortunePaymentDetails(payment_details);
        if (payment_status === "failed") {
          setModalScreen(ModalScreen.FAILED);
        } else if (payment_status === "finished") {
          setModalScreen(ModalScreen.COMPLETED);
        } else if (payment_status) {
          setIsAwaitingPayment(true);
          setModalScreen(ModalScreen.PAYMENT);
          setIsConfirmModalOpen(false);
          setIsPurchaseModalOpen(true);
        }
      } else {
        const msg = `Already ${
          activeBundle.type === BundleType.BUNDLE
            ? "subscribed"
            : "purchased bundle"
        }`;
        message.error(msg);
      }
    } catch (error: any) {
      console.log(error);
      const detail =
        error.response?.data?.detail ||
        error.message ||
        "Something went wrong, please try again later";
      message.error(detail);
    }
    setIsSubmittingFortune(false);
  };

  useEffect(() => {
    const getPurchaseStatus = async () => {
      if (isAwaitingPayment) {
        const res = await fetchPaymentStatus(fortunePaymentDetails.payment_id);
        const payment_status = res.data.payment_status;
        setFortunePaymentDetails({ ...fortunePaymentDetails, payment_status });
        if (
          payment_status === "failed" ||
          payment_status === "finished" ||
          payment_status === "partially_paid"
        ) {
          setIsAwaitingPayment(false);
          if (payment_status === "finished") {
            setModalScreen(ModalScreen.COMPLETED);
          }
          if (
            payment_status === "failed" ||
            payment_status === "partially_paid"
          ) {
            setModalScreen(ModalScreen.FAILED);
          }
        }
      }
    };
    getPurchaseStatus();
    const interval = setInterval(() => {
      getPurchaseStatus();
    }, 5000);

    return () => clearInterval(interval);
  }, [isAwaitingPayment]);

  return (
    <FcSaleWrapper>
      <FcSaleCard bg={FcCardBg}>
        <LogoRow justify="center">
          <Col>
            <TapwarLogo src={TapWarLogoImg} alt="TapWar Logo" />
          </Col>
        </LogoRow>
        <Row justify="center" gutter={[16, 16]}>
          <Col>
            <FcSaleSlogan>
              Fortune Crate <SloganSpan>Sales!</SloganSpan>{" "}
            </FcSaleSlogan>
          </Col>
          <Col>
            <PaymentWarning>
              <span>
                <WarningIcon />
              </span>
              {"  "}
              Please Complete payment within{" "}
              <WarningTextSpan>5:00 Minutes</WarningTextSpan>
            </PaymentWarning>
          </Col>
        </Row>
        <PackageRow justify="center">
          <Col>
            <MonthlyPackage price={99} />
          </Col>
        </PackageRow>
        <PackageRow justify="center" gutter={[24, 24]}>
          {[bundleDetail, monthlyPassDetail].map((detail, i) => (
            <Col key={i}>
              <FCPackage
                title={PACKAGE_VALUES[i].title}
                topColor={PACKAGE_VALUES[i].topBarColor}
                topIcon={PACKAGE_VALUES[i].topIcon}
                defaultBg={PACKAGE_VALUES[i].defaultBg}
                selectedBg={PACKAGE_VALUES[i].selectedBg}
                bundleDetail={detail}
                selected={activeBundle?.type === detail.type}
                onClick={() => handleSelection(detail)}
              />
            </Col>
          ))}
        </PackageRow>
        <Row justify="center">
          <TCCheckbox
            checked={tcChecked}
            onChange={(e) => {
              setTcChecked(e.target.checked);
            }}
          >
            <BaseText fontSize="14px">
              I have read and accept the{" "}
              <TCLink
                href="https://monstainfinite.com/payment-terms-and-conditions/"
                target="_blank"
              >
                Terms and Conditions
              </TCLink>
            </BaseText>
          </TCCheckbox>
        </Row>
        <Row justify="center">
          <Col md={14} sm={12} xs={22}>
            <Row justify="center">
              {!activeBundle ? (
                <FcSaleBaseBtn disabled>Please Select a Bundle</FcSaleBaseBtn>
              ) : isAuth ? (
                <ActiveBtn onClick={handleBuyButton} disabled={!tcChecked}>
                  Buy Now!
                </ActiveBtn>
              ) : (
                <ActiveBtn
                  onClick={active ? handleLogin : connect}
                  loading={loginLoading}
                >
                  {active ? "Authorize" : "Connect Wallet"}
                </ActiveBtn>
              )}
            </Row>
          </Col>
        </Row>
        <BottomLinkGroup />
      </FcSaleCard>
      {catalogLoading && (
        <PageLoadingWrapper>
          <Loading />
        </PageLoadingWrapper>
      )}
      {activeBundle && (
        <ConfirmModal
          visible={isConfirmModalOpen}
          bundleDetail={activeBundle}
          isSubmitting={isSubmittingFortune}
          onSubmit={handleFortuneCreate}
          onCancel={() => handleCancel(ModalScreen.CONFIRM)}
        />
      )}
      <StyledPurchaseModal
        visible={isPurchaseModalOpen}
        onCancel={() => handleCancel(ModalScreen.PAYMENT)}
        centered
        footer={null}
        width={"auto"}
        screen={modalScreen}
        closeIcon={<img src={Cross} alt="Exit" />}
      >
        {modalScreen === ModalScreen.PAYMENT && (
          <PaymentScreen
            onChangeModalScreen={handleChangeModalScreen}
            onCancel={() => handleCancel(ModalScreen.PAYMENT)}
            paymentDetails={fortunePaymentDetails}
          />
        )}
        {modalScreen === ModalScreen.PROCESSING && (
          <PaymentProcessingScreen
            onCancel={() => handleCancel(ModalScreen.PROCESSING)}
          />
        )}
        {modalScreen === ModalScreen.FAILED && (
          <PurchaseFailedScreen
            onCancel={() => handleCancel(ModalScreen.FAILED)}
          />
        )}
        {modalScreen === ModalScreen.COMPLETED && (
          <PurchaseCompletedScreen
            onCancel={() => handleCancel(ModalScreen.COMPLETED)}
          />
        )}
      </StyledPurchaseModal>
    </FcSaleWrapper>
  );
};

export default FcBundleSaleView;
