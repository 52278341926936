import { useLazyQuery } from "@apollo/client";
import { marketplaceClient } from "../graphql/apolloClient";
import {
  GetChildrenQuery,
  GetChildrenQueryVariables,
} from "../graphql/generated-types";
import { GET_CHILDREN } from "../graphql/queries/marketplace";

export const useGetChildren = (tokenId: string) => {
  return useLazyQuery<GetChildrenQuery, GetChildrenQueryVariables>(
    GET_CHILDREN,
    { variables: { id: tokenId }, client: marketplaceClient }
  );
};
