import type { FC } from "react";
import { TabPaneHeader, ItemCount, RefreshButton } from "../../style";
import Loading from "../../../../components/Loading/Loading";
import UserAuctionCardList from "./UserAuctionCardList";
import { UserAuctionMonstaWithImage } from "./types";

type UserAuctionListProps = {
  loading: boolean;
  auctionData: UserAuctionMonstaWithImage[];
  refreshUserAuctions: () => void;
  onLoadMore: () => void;
  auctionsMaxReached: boolean;
};

const UserAuctionList: FC<UserAuctionListProps> = ({
  loading,
  auctionData,
  refreshUserAuctions,
  onLoadMore,
  auctionsMaxReached,
}) => {
  return (
    <>
      <TabPaneHeader>
        <ItemCount>
          {loading ? "Loading..." : `${auctionData?.length ?? 0} Item(s)`}
        </ItemCount>
        <RefreshButton onClick={refreshUserAuctions} loading={loading}>
          Refresh
        </RefreshButton>
      </TabPaneHeader>
      <UserAuctionCardList
        monstas={auctionData}
        loading={loading}
        onLoadMore={onLoadMore}
        auctionsMaxReached={auctionsMaxReached}
      />
    </>
  );
};

export default UserAuctionList;
