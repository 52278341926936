/* eslint-disable no-param-reassign */
import { TokenList } from "@uniswap/token-lists";
import { ChainId } from "../../services/web3";
import {
  EMPTY_LIST,
  listCache,
  TagInfo,
  TokenAddressMap,
  WrappedTokenInfo,
} from "./types";

/**
 * Token instances created from token info.
 */
export function combineMaps(map: TokenAddressMap): TokenAddressMap {
  return {
    [ChainId.MAINNET]: { ...map[ChainId.MAINNET] },
    [ChainId.TESTNET]: { ...map[ChainId.TESTNET] },
  };
}

export function listToTokenMap(list: TokenList): TokenAddressMap {
  const result = listCache?.get(list);
  if (result) return result;

  const map = list.tokens.reduce<TokenAddressMap>(
    (tokenMap, tokenInfo) => {
      const tags: TagInfo[] =
        tokenInfo.tags
          ?.map((tagId: any) => {
            if (!list.tags?.[tagId]) return undefined;
            return { ...list.tags[tagId], id: tagId };
          })
          ?.filter((x): x is TagInfo => Boolean(x)) ?? [];
      const token = new WrappedTokenInfo(tokenInfo, tags);
      if ((tokenMap as any)?.[token.chainId]?.[token.address] !== undefined)
        throw Error("Duplicate tokens.");
      return {
        ...tokenMap,
        [token.chainId]: {
          ...(tokenMap as any)[token.chainId],
          [token.address]: {
            token,
            list,
          },
        },
      };
    },
    { ...EMPTY_LIST }
  );
  listCache?.set(list, map);
  return map;
}
