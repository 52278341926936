import { useState } from "react";
import { CloseCircleFilled, RightOutlined } from "@ant-design/icons";
import {
  MainWrapper,
  MainBackground,
  CollapseIcon,
  MonstaPairOuterRing,
  MonstaPairInnerRing,
  MonstaImage,
  MonstaPairId,
  NotificationIcon,
  DescriptionOuter,
  DescriptionInner,
  GoButton,
} from "./style";
import cloneChamberBg from "../../assets/images/chamber-bg-landscape.png";
import pairLine from "../../assets/images/chamber-pair-line.png";
import useCloneChamber, {
  CloneChamberStatus,
} from "../../hooks/useCloneChamber";

type CloneChamberFloatingProps = {
  onClickGo: () => void;
  onRemoveMonstaChamber?: (key: number) => void;
};

const CloneChamberFloating = ({
  onClickGo,
  onRemoveMonstaChamber,
}: CloneChamberFloatingProps) => {
  const { removeMonstaChamber, monstas, status, description } =
    useCloneChamber();
  const MAIN_BG_COLORS = {
    [CloneChamberStatus.EMPTY]:
      "linear-gradient(90deg, #8E8985 0%, #A57D5E 100%);",
    [CloneChamberStatus.ONE]:
      "linear-gradient(90deg, #44A585 5.95%, #AC8363 100%);",
    [CloneChamberStatus.FULL]:
      "linear-gradient(90.2deg, #34B192 0.17%, #21D2B4 102.92%);",
  };
  const [descriptionVisible, setDescriptionVisible] = useState(true);
  const [collapsed, setCollapsed] = useState(true);

  return (
    <MainWrapper background={MAIN_BG_COLORS[status]} collapsed={collapsed}>
      <CollapseIcon
        collapsed={collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        <RightOutlined />
      </CollapseIcon>
      <MainBackground src={cloneChamberBg} preview={false} />
      {monstas.map((monsta, key) => (
        <MonstaPairOuterRing order={key} key={key}>
          <MonstaPairInnerRing lineBg={pairLine}>
            {!!monsta && (
              <>
                <CloseCircleFilled
                  onClick={() => {
                    removeMonstaChamber(key);
                    onRemoveMonstaChamber && onRemoveMonstaChamber(key);
                  }}
                />
                <MonstaImage src={monsta.img} preview={false} />
              </>
            )}
            <MonstaPairId active={!!monsta}>
              {monsta?.id ? `#${monsta.id}` : ``}&nbsp;
            </MonstaPairId>
          </MonstaPairInnerRing>
        </MonstaPairOuterRing>
      ))}
      {status !== CloneChamberStatus.EMPTY && (
        <NotificationIcon>{status}</NotificationIcon>
      )}
      {descriptionVisible && !collapsed && (
        <DescriptionOuter>
          <DescriptionInner>
            <span>{description}</span>
            <span
              className="close"
              onClick={() => setDescriptionVisible(false)}
            >
              ✕
            </span>
          </DescriptionInner>
        </DescriptionOuter>
      )}
      {status === CloneChamberStatus.FULL && (
        <GoButton onClick={onClickGo}>Go!</GoButton>
      )}
    </MainWrapper>
  );
};

export default CloneChamberFloating;
