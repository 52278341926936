import { useEffect } from "react";
import { Form, Row, Col, Tooltip, message, Button } from "antd";
import {
  ArrowLeftOutlined,
  CheckOutlined,
  InfoCircleOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import {
  ButtonsContainer,
  FlexContainer,
  NewAllowanceRow,
  StyledButton,
  TextButton,
} from "./style";
import { Screen } from ".";
import InputGroup from "./components/InputGroup";
import InputWithOperator from "./components/InputWithOperator";
import { formatToReadibleNumber } from "../../utils/formatBalance";
import useTapwarAllowance from "./hooks/useTapwarAllowance";
import {
  USABLE_STT_TOOLTIP,
  NEW_ALLOWANCE_TOOLTIP,
  WALLET_TOOLTIP,
} from "./constants";

type AllowanceScreenProps = {
  onChangeScreen: (screen: Screen) => void;
};

const AllowanceScreen = ({ onChangeScreen }: AllowanceScreenProps) => {
  const {
    sttBalance,
    usableStt,
    isApproving,
    isSucess,
    isRefreshingWallet,
    allowanceError,
    handleSetAllowance,
    handleRefreshWallet,
  } = useTapwarAllowance();

  useEffect(() => {
    allowanceError && message.error("Something Went Wrong! Try again");
  }, [allowanceError]);

  useEffect(() => {
    !isApproving &&
      isSucess &&
      message.success("The operation is successfully done.");
  }, [isApproving, isSucess]);

  const onSubmitFinish = async (values: any) => {
    const allowance: number = values.allowance;
    if (allowance > sttBalance.toNumber() / 1e8) {
      message.error("Allowance should be equal or less than wallet balance");
      return;
    }
    await handleSetAllowance(allowance);
  };

  return (
    <Form onFinish={onSubmitFinish}>
      <InputGroup
        label="Wallet Balance"
        LabelIcon={<InfoCircleOutlined />}
        toolTipText={WALLET_TOOLTIP}
        inputValue={formatToReadibleNumber(
          `${Math.trunc(sttBalance.toNumber() / 1e5) / 1e3}`
        )}
        inputSuffix="xSTT"
      />
      <InputGroup
        label="Usable xSTT in Game"
        LabelIcon={<InfoCircleOutlined />}
        toolTipText={USABLE_STT_TOOLTIP}
        inputValue={formatToReadibleNumber(
          `${Math.trunc(usableStt.toNumber() / 1e4) / 1e4}`
        )}
        inputSuffix="xSTT"
      />
      <NewAllowanceRow>
        <Col span={12}>
          New Allowance
          <Tooltip placement="topLeft" title={NEW_ALLOWANCE_TOOLTIP}>
            <InfoCircleOutlined />
          </Tooltip>
        </Col>
        <Col span={12}>
          <Form.Item
            name="allowance"
            required={true}
            rules={[
              { required: true, message: "Allowance Value Can't be empty!" },
              {
                type: "number",
                max: sttBalance.toNumber() / 1e8,
                message:
                  "Allowance should be equal or less than wallet balance",
              },
              {
                type: "number",
                min: 1,
                message: `Allowance must be greater than 1`,
              },
            ]}
          >
            <InputWithOperator />
          </Form.Item>
        </Col>
      </NewAllowanceRow>
      <ButtonsContainer>
        <Row gutter={20}>
          <Col span={12}>
            <StyledButton
              htmlType="submit"
              type="ghost"
              size="large"
              loading={isApproving}
              block
            >
              <CheckOutlined width="15" />
              set now
            </StyledButton>
          </Col>
          <Col span={12}>
            <StyledButton
              type="ghost"
              size="large"
              onClick={() => onChangeScreen(Screen.tapshop)}
              block
            >
              <ShoppingCartOutlined />
              go to tapshop
            </StyledButton>
          </Col>
        </Row>
      </ButtonsContainer>
      <FlexContainer justifyContent="center">
        <TextButton
          type="text"
          size="large"
          onClick={() => onChangeScreen(Screen.tapwar)}
        >
          <ArrowLeftOutlined />
          back
        </TextButton>
      </FlexContainer>
    </Form>
  );
};

export default AllowanceScreen;
