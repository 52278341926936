import { Alert, Typography } from "antd";
import { parseSvg } from "../../../../utils/parseSvg";
import { MonstaGameCardWrapper, MonstaLabelWrapper } from "./style";
import GameCard from "./GameCard";

type MonstaGameCardListProps = {
  gameCards: string[];
  error: string | null;
};

const { Text } = Typography;
const MonstaGameCardList = ({ gameCards, error }: MonstaGameCardListProps) => {
  return (
    <div>
      <MonstaLabelWrapper>
        {" "}
        <Text>Game Cards</Text>
      </MonstaLabelWrapper>
      {error && (
        <Alert
          message={`Problem fetching game cards : ${error}`}
          type="error"
          showIcon
          closable
        />
      )}
      <MonstaGameCardWrapper gutter={[16, 16]} justify="space-between">
        {gameCards.map((gameCard, index) => {
          return <GameCard svg={parseSvg(gameCard)} key={index} />;
        })}
      </MonstaGameCardWrapper>
    </div>
  );
};

export default MonstaGameCardList;
