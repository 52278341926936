import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "antd";

const { Text } = Typography;

type textProps = {
  size?: string;
  weight?: string;
  color?: string;
  underline?: boolean;
  ellipsis?: boolean;
};
const dynamictextSizeVariantSelector = ({
  size,
  weight,
  color = "white",
  underline = false,
  ellipsis = false,
}: textProps) => css`
  font-size: ${size && size};
  font-weight: ${weight && weight};
  color: ${color};
  text-decoration: ${underline && "underline"};
  overflow: ${ellipsis && "hidden"};
  text-overflow: ${ellipsis && "ellipsis"};
`;
export const StyledText = styled(Text)`
  ${dynamictextSizeVariantSelector}
`;
