import {
  MainCardTitle,
  FlexContainer,
  TransparentButton,
  ButtonGroup,
} from "../style";

import useTapwarAuth from "../../../hooks/useTapwarAuth";
import { RefreshButton } from "./RefreshButton";

type MainCardHeaderProps = {
  title: string;
  isAuth: boolean;
  handleLogin: React.MouseEventHandler<HTMLButtonElement>;
  handleLogout: React.MouseEventHandler<HTMLButtonElement>;
  loginLoading: boolean;
  containsRefreshButton: boolean;
};

const MainCardHeader = (props: MainCardHeaderProps) => {
  const {
    title,
    isAuth,
    handleLogin,
    handleLogout,
    loginLoading,
    containsRefreshButton,
  } = props;

  return (
    <FlexContainer>
      <MainCardTitle>{title}</MainCardTitle>
      <ButtonGroup>
        {containsRefreshButton && <RefreshButton />}
        {isAuth ? (
          <TransparentButton onClick={handleLogout}>Logout</TransparentButton>
        ) : (
          <TransparentButton onClick={handleLogin} loading={loginLoading}>
            Authorize
          </TransparentButton>
        )}
        {/* note: download button temporarily hidden for closed beta */}
        {/* <TransparentButton>Download</TransparentButton> */}
      </ButtonGroup>
    </FlexContainer>
  );
};

export default MainCardHeader;
