import { Currency } from "@pancakeswap/sdk";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../services/redux/index";
import {
  Field,
  selectCurrency,
  setRecipient,
  switchCurrencies,
  typeInput,
} from "../../services/redux/swap/actions";

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: () => void;
  onUserInput: (field: Field, typedValue: string) => void;
  onChangeRecipient: (recipient: string | null) => void;
} {
  const dispatch = useDispatch<AppDispatch>();
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId:
            field === Field.INPUT
              ? "0xD4d93d88fb12F9a006C3A37541b9fa8C22401035"
              : "0xf0a86Ce9D901dD8D687DCf467e6736C1aBeD8FF0",
        })
      );
    },
    [dispatch]
  );

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies());
  }, [dispatch]);

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }));
    },
    [dispatch]
  );

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }));
    },
    [dispatch]
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  };
}
