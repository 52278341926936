import { useState, useEffect } from "react";
import { BaseText } from "../style";

const TimerSlot = ({ mins, seconds }: { mins: string; seconds: string }) => {
  return (
    <BaseText display="block" fontSize="24px">
      {mins}:{seconds}
    </BaseText>
  );
};
const computeInitialTimeDiff = (destinationDate: Date) => {
  const diff = destinationDate.getTime() - Date.now();
  return diff > 0 ? diff : 0;
};

const MiniCountDown = ({
  destinationDate,
  onFinish,
}: {
  destinationDate: Date;
  onFinish: () => void;
}) => {
  const [timeValues, setTimeValues] = useState({
    minutes: 0,
    seconds: 0,
  });

  const [isTimerFinished, setIsTimerFinished] = useState(true);

  useEffect(() => {
    const curInterval = setInterval(() => {
      const timeDiff = computeInitialTimeDiff(destinationDate);
      if (timeDiff === 0) {
        setIsTimerFinished(true);
        onFinish();
        clearInterval(curInterval);
      }
      if (timeDiff > 0) {
        setIsTimerFinished(false);
      }
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      setTimeValues({ minutes, seconds });
    }, 1000);

    return () => clearInterval(curInterval);
  });

  return (
    <>
      <TimerSlot
        mins={String(timeValues.minutes).padStart(2, "0")}
        seconds={String(timeValues.seconds).padStart(2, "0")}
      />
    </>
  );
};

export default MiniCountDown;
