import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { marketplaceClient } from "../../../graphql/apolloClient";
import {
  GetUserAuctionsQuery,
  GetUserAuctionsQueryVariables,
  MonstaEventType,
  Monsta_OrderBy,
  OrderDirection,
} from "../../../graphql/generated-types";
import { GET_USER_AUCTIONS } from "../../../graphql/queries/marketplace";
import MonstaPreviewCardList from "../../Marketplace/components/MonstaPreviewCardList";
import {
  CheapestMonstaListContainer,
  CheapestDescription,
  ViewMoreButton,
} from "../style";

const CheapestMonstaList = () => {
  const navigate = useNavigate();

  const { loading, error, data } = useQuery<
    GetUserAuctionsQuery,
    GetUserAuctionsQueryVariables
  >(GET_USER_AUCTIONS, {
    variables: {
      first: 3,
      skip: 0,
      orderBy: Monsta_OrderBy.LastEndPrice,
      orderDirection: OrderDirection.Asc,
      where: {
        auctionStatus_in: [MonstaEventType.UserAuctionCreated],
      },
    },
    client: marketplaceClient,
  });

  const scrollTo = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  const gotoDetailPage = (id: string) => {
    navigate(`/monsta/${id}`);
  };

  return (
    <CheapestMonstaListContainer>
      <CheapestDescription>
        OUR TOP MONSTA PICKS, MATCHED TO YOU!
      </CheapestDescription>
      <MonstaPreviewCardList
        auctionData={data}
        auctionDataError={error}
        auctionDataLoading={loading}
        gotoDetailPage={gotoDetailPage}
        scrollTo={scrollTo}
      />
      <ViewMoreButton onClick={() => navigate("/marketplace")}>
        View More on Marketplace
      </ViewMoreButton>
    </CheapestMonstaListContainer>
  );
};

export default CheapestMonstaList;
