import React from "react";
import { Col } from "antd";
import { GameCardImage } from "./style";

type GameCardProps = {
  svg: string;
};

const GameCard = ({ svg }: GameCardProps) => {
  return (
    <Col className="gutter-row" xs={12} sm={8} md={8} lg={8}>
      <div style={{ width: "fit-content", margin: "auto" }}>
        <GameCardImage src={svg} />
      </div>
    </Col>
  );
};

export default GameCard;
