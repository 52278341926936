import { useLazyQuery, useQuery } from "@apollo/client";
import { useWeb3React } from "@web3-react/core";
import { message } from "antd";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import {
  marketplaceClient,
  tokenClient,
} from "../../../../graphql/apolloClient";
import {
  GetAccountQuery,
  GetAccountQueryVariables,
  GetUserMonstaQuery,
  GetUserMonstaQueryVariables,
  Monsta_OrderBy,
  OrderDirection,
} from "../../../../graphql/generated-types";
import { GET_ACCOUNT } from "../../../../graphql/queries/erc20";
import { GET_USER_MONSTA } from "../../../../graphql/queries/marketplace";
import useCloneChamber from "../../../../hooks/useCloneChamber";
import { useMonstaPreviewApi } from "../../../../hooks/useMonsta";
import useRefresh from "../../../../hooks/useRefresh";
import { MonstaType } from "../../../../models/monsta";
import { fetchAggregate } from "../../../../services/marketplace-aggregator-api";
import {
  getSttAddress,
  getSynthesisAddress,
} from "../../../../utils/addressHelpers";
import { getSynthesisContract } from "../../../../utils/contractHelpers";
import fetchAllowance from "../../../../utils/fetchAllowance";
import { formatBigNumberToFixed } from "../../../../utils/formatBalance";
import { extractMonsta } from "../../../../utils/monstaHelpers";
import { parseSvg } from "../../../../utils/parseSvg";
import { toBinaryGenes } from "../../../../utils/toBinaryGenes";
import CloneLoadingScreen from "./CloneLoadingScreen";
import CloneSuccessScreen from "./CloneSuccessScreen";
import SelectMonstaScreen from "./SelectMonstaScreen";
import { CloneCardWrapper, Subtitle, Title } from "./style";
import ToCloneScreen from "./ToCloneScreen";

type Screen = "to-clone" | "select-monsta" | "clone-loading" | "clone-success";

type CloneCardProps = {
  baseMonsta: MonstaType;
  selectedMonsta: MonstaType | null;
  secondaryChamberId: number;
};

const CloneCard = (props: CloneCardProps) => {
  const userMonstaPageSize = 20;
  const { baseMonsta, selectedMonsta, secondaryChamberId } = props;
  const { account, library } = useWeb3React();
  const { preview } = useMonstaPreviewApi();
  const { fastRefresh } = useRefresh();
  const { addMonstaChamber, removeMonstaChamber } = useCloneChamber();

  const [screen, setScreen] = useState<Screen>("to-clone");
  const [selectMonstaToCloneList, setSelectMonstaToCloneList] = useState<
    MonstaType[]
  >([]);
  const [selectMonstaToCloneListLoading, setSelectMonstaToCloneListLoading] =
    useState(true);

  const [isApproved, setIsApproved] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [allowance, setAllowance] = useState<BigNumber | undefined>(
    new BigNumber(0)
  );
  const [sttBalance, setSttBalance] = useState("0");
  const [sttCost, setSttCost] = useState("?");
  const [eggId, setEggId] = useState("");
  const [eggCloneUsage, setEggCloneUsage] = useState<number | null | undefined>(
    0
  );
  const [userMonstaCurrentPage, setUserMonstaCurrentPage] = useState(1);
  const [totalMonsta, setTotalMonsta] = useState(0);

  const [fetchUserMonsta, { data: userMonstaData }] = useLazyQuery<
    GetUserMonstaQuery,
    GetUserMonstaQueryVariables
  >(GET_USER_MONSTA, {
    variables: {
      first: userMonstaPageSize,
      skip: (userMonstaCurrentPage - 1) * userMonstaPageSize,
      orderBy: Monsta_OrderBy.Token,
      orderDirection: OrderDirection.Asc,
      where: {
        owner: account?.toLowerCase() || "",
        token_not: baseMonsta.id,
        cloneUsage_lt: 4,
        birthStatus: 1, // 0 = egg, 1 = adult monsta
      },
    },
    client: marketplaceClient,
  });
  const { refetch: refetchUpdatedUserMonsta } = useQuery<
    GetUserMonstaQuery,
    GetUserMonstaQueryVariables
  >(GET_USER_MONSTA, {
    variables: {
      orderBy: Monsta_OrderBy.Token,
      orderDirection: OrderDirection.Desc,
      where: { owner: account?.toLowerCase() || "" },
    },
    client: marketplaceClient,
  });
  const {
    loading: getAccountLoading,
    error: getAccountError,
    data: getAccountData,
  } = useQuery<GetAccountQuery, GetAccountQueryVariables>(GET_ACCOUNT, {
    variables: {
      id: account?.toLowerCase() || "",
    },
    client: tokenClient,
  });

  useEffect(() => {
    const fetchTotalMonsta = async () => {
      const _totalMonsta = await fetchAggregate({
        owner: account?.toLowerCase(),
        cloneUsage_lt: 4,
        token_not: baseMonsta.id,
        birthStatus: 1, // 0 = egg, 1 = adult monsta
      });

      setTotalMonsta(_totalMonsta.data.result);
    };
    if (account && baseMonsta) {
      fetchTotalMonsta();
    }
  }, [account, baseMonsta]);

  useEffect(() => {
    if (!account) {
      setSttBalance("0");
    }
    let stt = new BigNumber(0);
    if (getAccountData && getAccountData.account) {
      const walletData = getAccountData.account.ERC20balances;
      walletData.forEach((balance: any) => {
        const value = new BigNumber(balance.valueExact);
        if (balance.contract.symbol === "STT") {
          stt = value;
        }
      });
      setSttBalance(`${Math.trunc(stt.toNumber() / 1e4) / 1e4}`);
    }
    if (getAccountError) {
      message.error("Error getting STT balance. Please try again later.");
    }
  }, [getAccountData, getAccountError, account]);

  useEffect(() => {
    if (account) {
      fetchUserMonsta();
    }
  }, [account, userMonstaCurrentPage, fetchUserMonsta]);

  useEffect(() => {
    const loadUserMonsta = async (monstaData: Promise<MonstaType>[]) => {
      try {
        const userMonstas = await Promise.all(monstaData);
        setSelectMonstaToCloneList(userMonstas);
      } catch (error: any) {
        message.error("Something went wrong while loading your monsta:", error);
      } finally {
        setSelectMonstaToCloneListLoading(false);
      }
    };

    if (userMonstaData) {
      if (userMonstaData.monstas.length > 0) {
        let monstaData = userMonstaData.monstas.map(async (monsta) => {
          const baseMonsta = extractMonsta(monsta);
          const svg = await preview(toBinaryGenes(monsta.genes));
          return {
            ...baseMonsta,
            img: parseSvg(svg),
          };
        });
        loadUserMonsta(monstaData);
      } else {
        setSelectMonstaToCloneList([]);
      }
    }
  }, [userMonstaData, preview, setSelectMonstaToCloneList, baseMonsta.id]);

  useEffect(() => {
    const fetchAllowanceForAccount = async () => {
      const value = await fetchAllowance(
        getSttAddress(),
        account,
        getSynthesisAddress()
      );
      setAllowance(value);
      if (
        value?.toString() ===
        new BigNumber("99999999999999999999999999999999999").toString()
      ) {
        setIsApproved(true);
      }
    };

    if (account) {
      fetchAllowanceForAccount();
    }
  }, [account]);

  useEffect(() => {
    const fetchSttCost = async () => {
      const synthesisContract = getSynthesisContract(library.getSigner());
      if (synthesisContract && baseMonsta.id && selectedMonsta) {
        try {
          const monstaCost = await synthesisContract.sttCost(
            baseMonsta.cloneUsage
          );
          const selectedMonstaCost = await synthesisContract.sttCost(
            selectedMonsta.cloneUsage
          );
          const totalSttCost = monstaCost.add(selectedMonstaCost);
          setSttCost(formatBigNumberToFixed(totalSttCost, 0, 8));
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchSttCost();
  }, [selectedMonsta, library, baseMonsta]);

  useEffect(() => {
    const handlePendingApproval = async () => {
      const value = await fetchAllowance(
        getSttAddress(),
        account,
        getSynthesisAddress()
      );
      if (value?.toString() !== allowance?.toString()) {
        setApproveLoading(false);
        setIsApproved(true);
      }
      setAllowance(value);
    };

    if (approveLoading) {
      handlePendingApproval();
    }
  }, [fastRefresh, account]);

  const handleApprove = async () => {
    message.warning("Temporary out of service");
    // if (approveLoading || !selectedMonsta) return;
    // if (account && library) {
    //   const sttContract = getSttContract(library.getSigner());
    //   const synthesisContract = getSynthesisContract(library.getSigner());
    //   if (sttContract && synthesisContract) {
    //     try {
    //       setApproveLoading(true);
    //       await sttContract.approve(
    //         synthesisContract.address,
    //         "99999999999999999999999999999999999"
    //       );
    //     } catch (error) {
    //       console.error(error);
    //       setApproveLoading(false);
    //     }
    //   }
    // }
  };

  const handleClone = async () => {
    message.warning("Temporary out of service");
    // if (account && library && !getAccountError && !getAccountLoading) {
    //   if (parseInt(sttBalance) >= parseInt(sttCost)) {
    //     const synthesisContract = getSynthesisContract(library.getSigner());
    //     if (
    //       synthesisContract &&
    //       baseMonsta &&
    //       baseMonsta.id &&
    //       selectedMonsta &&
    //       selectedMonsta.id
    //     ) {
    //       try {
    //         setScreen("clone-loading");
    //         await synthesisContract.cloneMonsta(
    //           baseMonsta.id,
    //           selectedMonsta.id
    //         );
    //         await new Promise((resolve) => setTimeout(resolve, 20000));
    //         const updatedUserMonsta = await refetchUpdatedUserMonsta();
    //         setEggId(updatedUserMonsta.data.monstas[0].id);
    //         setEggCloneUsage(updatedUserMonsta.data.monstas[0].cloneUsage);
    //         setScreen("clone-success");
    //       } catch (error: any) {
    //         if (error?.code === 4001) {
    //           message.error("Transaction rejected.");
    //         } else if (error?.code === -32603) {
    //           switch (error.data.message) {
    //             case "execution reverted: ERC20: transfer amount exceeds balance":
    //               message.error("Insufficient balance.");
    //               break;
    //             case "execution reverted: Synthesis: sibling ban":
    //               message.error("Cloning not allowed. Reason: Sibling ban");
    //               break;
    //             default:
    //               message.error(`${error.data.message}`);
    //           }
    //         } else {
    //           console.log(error);
    //           message.error(`Transaction unsuccessful, please try again later`);
    //         }
    //       }
    //     }
    //   } else {
    //     message.error("Insufficient balance.");
    //     setScreen("to-clone");
    //   }
    // }
  };

  const handleClickSelectTheMonsta = () => {
    if (selectMonstaToCloneListLoading) return;
    setScreen("select-monsta");
  };

  const handleSelectMonsta = (selectedMonsta: MonstaType) => {
    removeMonstaChamber(secondaryChamberId);
    addMonstaChamber(selectedMonsta);
    setScreen("to-clone");
  };

  return (
    <CloneCardWrapper>
      <Title>CLONE MONSTA</Title>
      <Subtitle>Select monsta to move on to the cloning process</Subtitle>
      {screen === "to-clone" && (
        <ToCloneScreen
          baseMonsta={baseMonsta}
          onClickSelectTheMonsta={handleClickSelectTheMonsta}
          selectedMonsta={selectedMonsta}
          onApprove={handleApprove}
          selectMonstaToCloneListLoading={selectMonstaToCloneListLoading}
          sttCost={sttCost}
          isApproved={isApproved}
          approveLoading={approveLoading}
          onClone={handleClone}
        />
      )}
      {screen === "select-monsta" && (
        <SelectMonstaScreen
          selectedMonsta={selectedMonsta}
          handleSelectMonsta={handleSelectMonsta}
          selectMonstaToCloneList={selectMonstaToCloneList}
          onChangePage={setUserMonstaCurrentPage}
          currentPage={userMonstaCurrentPage}
          pageSize={userMonstaPageSize}
          totalMonsta={totalMonsta}
        />
      )}
      {screen === "clone-loading" && <CloneLoadingScreen />}
      {screen === "clone-success" && (
        <CloneSuccessScreen eggId={eggId} eggCloneUsage={eggCloneUsage} />
      )}
    </CloneCardWrapper>
  );
};

export default CloneCard;
