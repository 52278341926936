import { ethers } from "ethers";
import { formatUnits } from "ethers/lib/utils";

/**
 * This function formats huge numbers into numbers with commas
 * so that they become more readible for users.
 * E.g. 1000 becomes 1,000
 * Utilizes toLocalString fn, and defaults to the locale en-US.
 * @param price Can be string or number, but typescript insists on string only.
 * @returns string in readible format
 */
export const formatToReadibleNumber = (hugeNumbers: string): string => {
  return parseFloat(hugeNumbers).toLocaleString("en-US");
};

/**
 * Method to format the display of wei given an ethers.BigNumber object with toFixed
 * Note: rounds
 */
export const formatBigNumberToFixed = (
  number: ethers.BigNumber,
  displayDecimals = 18,
  decimals = 18
): any => {
  const formattedString = formatUnits(number, decimals);
  return (+formattedString).toFixed(displayDecimals);
};

export const formatToReadibleXstt = (hugeXstt: number) => {
  const xstt = hugeXstt / 1e8;

  const formatNoDecimal = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formatDecimal = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Use modulus to get the fractional part
  if (xstt % 1 === 0) {
    return formatNoDecimal.format(xstt);
  } else {
    return formatDecimal.format(xstt);
  }
};
