import type { FC } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Col, Form, Input, message } from "antd";
import { Contract } from "@ethersproject/contracts";
import { useQuery } from "@apollo/client";
import BigNumber from "bignumber.js";
import {
  BuyMonstaInfo,
  BuyModeText,
  BuyForm,
  BuyButton,
  BuyButtonWrapper,
} from "./style";
import {
  getMonstaAuctionUserContract,
  getSttContract,
} from "../../../../utils/contractHelpers";
import useActiveWeb3React from "../../../../hooks/useActiveWeb3React";
import useRefresh from "../../../../hooks/useRefresh";
import {
  GetAccountQuery,
  GetAccountQueryVariables,
  GetAuctionMonstaQuery,
} from "../../../../graphql/generated-types";
import {
  formatBigNumberToFixed,
  formatToReadibleNumber,
} from "../../../../utils/formatBalance";
import fetchAllowance from "../../../../utils/fetchAllowance";
import {
  getSttAddress,
  getMonstaAuctionUserAddress,
} from "../../../../utils/addressHelpers";
import { GET_ACCOUNT } from "../../../../graphql/queries/erc20";
import { tokenClient } from "../../../../graphql/apolloClient";
import { BIG_ZERO } from "../../../../utils/bigNumber";
import { formatDatetoLocale } from "../../../../utils/formatDate";

const checkZero = (_: any, value: any) => {
  const field = _.field;
  let label = field;
  if (field === "auction") label = "Auction";
  if (field === "fixed") label = "Fixed price";
  if (value! >= 1) {
    return Promise.resolve();
  }
  return Promise.reject(new Error(`${label} must be greater than 1`));
};

type BuyCardProps = {
  monstaData: GetAuctionMonstaQuery | undefined;
};

const BuyCard: FC<BuyCardProps> = ({ monstaData }) => {
  const navigate = useNavigate();
  const { fastRefresh } = useRefresh();
  const { account, library } = useActiveWeb3React();
  const [duration, setDuration] = useState("");
  const [sttBalance, setSttBalance] = useState(BIG_ZERO);
  const [isFixedPrice, setIsFixedPrice] = useState<boolean>(true);
  const [isBidding, setIsBidding] = useState<boolean>(false);
  const [isApproving, setIsApproving] = useState<boolean>(false);
  const [allowance, setAllowance] = useState(BIG_ZERO);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [startPrice, setStartPrice] = useState("0");
  const [endPrice, setEndPrice] = useState("0");
  const [form] = Form.useForm();
  const buyFormItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const { data: balanceData } = useQuery<
    GetAccountQuery,
    GetAccountQueryVariables
  >(GET_ACCOUNT, {
    variables: {
      id: account?.toLowerCase() || "",
    },
    client: tokenClient,
  });

  useEffect(() => {
    if (balanceData && balanceData.account) {
      const walletData = balanceData.account.ERC20balances;
      walletData.forEach((balance: any) => {
        const value = new BigNumber(balance.valueExact);
        if (balance.contract.symbol === "STT") {
          setSttBalance(value);
        }
      });
    }
  }, [balanceData, account]);

  // When switching account
  useEffect(() => {
    const fetchAllowanceForAccount = async () => {
      const value = await fetchAllowance(
        getSttAddress(),
        account,
        getMonstaAuctionUserAddress()
      );
      value && setAllowance(value);
    };

    if (account) {
      fetchAllowanceForAccount();
    }
  }, [account]);

  // When pending approval
  useEffect(() => {
    const handlePendingApproval = async () => {
      fetchAllowance(
        getSttAddress(),
        account,
        getMonstaAuctionUserAddress()
      ).then((value) => {
        if (value?.toString() !== allowance.toString()) {
          setIsApproving(false);
        }
        value && setAllowance(value);
      });
    };
    if (isApproving) {
      handlePendingApproval();
    }
  }, [fastRefresh, account, allowance, isApproving]);

  useEffect(() => {
    if (monstaData?.monsta?.monstaEvents) {
      const monstaEvent = monstaData.monsta.monstaEvents[0] as any;
      const _isFixedPrice =
        monstaEvent.endingPrice === monstaEvent.startingPrice;
      setIsFixedPrice(_isFixedPrice);
      const now = new Date();
      const secondsSinceEpoch = Math.round(now.getTime() / 1000);
      const secondsPassed = secondsSinceEpoch - monstaEvent.timestamp;
      const isPassedDuration = secondsPassed > parseInt(monstaEvent.duration);
      const currentPriceChange = isPassedDuration
        ? formatBigNumberToFixed(monstaEvent.endingPrice, 0, 8)
        : (formatBigNumberToFixed(monstaEvent.startingPrice, 0, 8) -
            formatBigNumberToFixed(monstaEvent.endingPrice, 0, 8)) *
          (secondsPassed / parseInt(monstaEvent.duration));
      const _currentPrice = isPassedDuration
        ? parseFloat(formatBigNumberToFixed(monstaEvent.endingPrice, 0, 8))
        : parseFloat(formatBigNumberToFixed(monstaEvent.startingPrice, 0, 8)) -
          parseFloat(currentPriceChange);
      const inputPrice = _isFixedPrice
        ? parseFloat(formatBigNumberToFixed(monstaEvent!.endingPrice, 0, 8)) +
          0.1 // TODO : if price is 0 will fail, find a better way to solve this
        : parseFloat((_currentPrice * 1.1).toFixed(0) + 0.1); // TODO : +0.1 quick fix for insufficient bid even price is 0
      form.setFieldsValue({ inputPrice });
      setCurrentPrice(_currentPrice);
      if (!_isFixedPrice) {
        const _startPrice = formatToReadibleNumber(
          formatBigNumberToFixed(monstaEvent.startingPrice, 0, 8)
        );
        const _endPrice = formatToReadibleNumber(
          formatBigNumberToFixed(monstaEvent.endingPrice, 0, 8)
        );
        setStartPrice(_startPrice);
        setEndPrice(_endPrice);
      }
    }
  }, [monstaData, form]);

  const handleBid = async (values: any) => {
    const inputPrice = (parseFloat(values.inputPrice) * 1e8).toFixed(0);
    if (account && library) {
      const contract: Contract | null = getMonstaAuctionUserContract(
        library.getSigner()
      );
      if (contract) {
        setIsBidding(true);
        try {
          await contract.bid(monstaData?.monsta?.id, inputPrice);
          setIsApproving(true);
          setIsBidding(false);
          navigate("/");
          message.success(
            "Transaction sent, but will not guarantee bid success. Successful brought monsta will reflect in your profile."
          );
        } catch (error: any) {
          setIsBidding(false);
          if (error?.code === 4001) {
            message.error("Transaction rejected.");
          } else if (error?.code === -32603) {
            switch (error.data.message) {
              case "execution reverted: AdminAuction: purchase limit exceeded":
                message.error(
                  "Purchase limited by system admin. Please check Terms and Conditions."
                );
                break;
              case "execution reverted: ERC20: transfer amount exceeds balance":
                message.error("Insufficient balance.");
                break;
              default:
                message.error(`${error.data.message}`);
            }
          } else {
            console.log(error);
            message.error(`Transaction unsuccessful, please try again later`);
          }
        }
      }
    }
  };

  const handleApprove = async () => {
    if (account && library) {
      const contract: Contract | null = getSttContract(library.getSigner());
      const AdminAuctionContract: Contract | null =
        getMonstaAuctionUserContract(library.getSigner());
      if (contract) {
        setIsApproving(true);
        try {
          await contract.approve(
            AdminAuctionContract?.address,
            "99999999999999999999999999999999999"
          );
        } catch (error) {
          console.log(error);
          setIsApproving(false);
        }
      }
    }
  };

  return (
    <>
      <Col>
        <BuyModeText>
          {isFixedPrice ? "FIXED PRICE" : "AUCTION"}
          <br />
        </BuyModeText>
        <BuyMonstaInfo>
          Listed at:{" "}
          {formatDatetoLocale(monstaData?.monsta?.monstaEvents[0].timestamp)}
          <br />
          {!isFixedPrice && (
            <>
              Duration: {duration}
              <br />
              Start price: {startPrice} xSTT
              <br />
              End price: {endPrice} xSTT
              <br />
            </>
          )}
          {isFixedPrice ? "Price" : "Current Estimated Price"}:{" "}
          {formatToReadibleNumber(currentPrice.toFixed())} xSTT
          <br />
          Wallet Balance:{" "}
          {formatToReadibleNumber(
            `${Math.trunc(sttBalance.toNumber() / 1e4) / 1e4}`
          )}{" "}
          xSTT
        </BuyMonstaInfo>
        <BuyForm
          layout="horizontal"
          form={form}
          colon={false}
          {...buyFormItemLayout}
          onFinish={handleBid}
        >
          <Form.Item
            label={isFixedPrice ? "Fixed price" : "Auction"}
            name="inputPrice"
            rules={[{ validator: checkZero }]}
          >
            <Input
              placeholder="Enter amount here"
              disabled={isFixedPrice}
              suffix=" | xSTT"
            />
          </Form.Item>
          <BuyButtonWrapper>
            <BuyButton
              htmlType="button"
              onClick={handleApprove}
              disabled={isApproving}
            >
              Approve
            </BuyButton>
            <BuyButton htmlType="submit" disabled={isBidding}>
              {isFixedPrice ? "Buy" : "Bid"}
            </BuyButton>
          </BuyButtonWrapper>
        </BuyForm>
      </Col>
    </>
  );
};

export default BuyCard;
