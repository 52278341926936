import { ModalScreen } from "../";
import QRCode from "react-qr-code";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";
import { Col, message } from "antd";
import {
  BaseText,
  PaymentText,
  CenteredRow,
  FixedHeightCol,
  ModalButton,
  ModalButtonCol,
  SquareTopHolder,
  FortuneDetailsContainer,
  FortuneDetailsItem,
  FortuneDetailsStatusLabel,
  AddressHolder,
} from "../style";
import MiniCountDown from "./MiniCountDown";
import { FortuneCrate } from "../../../models/tapwar";

type PaymentScreenProps = {
  onChangeModalScreen: (screen: ModalScreen) => void;
  onCancel: () => void;
  paymentDetails: FortuneCrate;
};

const PaymentScreen = ({
  onChangeModalScreen,
  onCancel,
  paymentDetails,
}: PaymentScreenProps) => {
  return (
    <>
      <CenteredRow gutter={[8, 20]}>
        <FixedHeightCol height="60px" span={22}>
          <SquareTopHolder className="qr-svg-wrapper">
            <QRCode
              size={170}
              value={paymentDetails.pay_address}
              className="qr-svg"
            />
          </SquareTopHolder>
        </FixedHeightCol>
        <Col md={18} sm={18}>
          <MiniCountDown
            destinationDate={new Date(paymentDetails.expireAt || "")}
            onFinish={() => {
              onChangeModalScreen(ModalScreen.FAILED);
            }}
          />
        </Col>
        <Col md={20} sm={22}>
          <FortuneDetailsContainer>
            <FortuneDetailsItem className="currency-row">
              {`${paymentDetails.pay_amount} ${paymentDetails.pay_currency}`}
              <CopyToClipboard
                text={paymentDetails.pay_amount}
                onCopy={() => message.success("Copied to Clipboard")}
              >
                <CopyOutlined />
              </CopyToClipboard>
            </FortuneDetailsItem>
            <FortuneDetailsItem>
              <AddressHolder>
                {paymentDetails.pay_address}
                <CopyToClipboard
                  text={paymentDetails.pay_address}
                  onCopy={() => message.success("Copied to Clipboard")}
                >
                  <CopyOutlined />
                </CopyToClipboard>
              </AddressHolder>
            </FortuneDetailsItem>
            <FortuneDetailsItem>
              Status
              <FortuneDetailsStatusLabel>
                {paymentDetails.payment_status !== "sending"
                  ? paymentDetails.payment_status
                  : "verifying"}
              </FortuneDetailsStatusLabel>
            </FortuneDetailsItem>
          </FortuneDetailsContainer>
        </Col>
        <Col md={18} sm={18}>
          <PaymentText>
            Kindly make exact payment to this QR address within
            <span style={{ color: "#FEE615" }}> 5 Minutes</span>.
          </PaymentText>
        </Col>
        <Col md={20} sm={22}>
          <BaseText>
            Once exceeded, you will need to re-purchase and re-generate a new QR
            code again.
          </BaseText>
          <BaseText display="block" color="#FEE615">
            Warning! You will not be eligible for a refund if you made made a
            transaction to the QR code after 5 minutes.
          </BaseText>
        </Col>
        <ModalButtonCol span={20}>
          <ModalButton onClick={onCancel}>Back To Purchase Page</ModalButton>
        </ModalButtonCol>
      </CenteredRow>
    </>
  );
};
export default PaymentScreen;
