import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import MonstaStats from "./MonstaStats";
import MonstaBodyCardList from "./MonstaBodyCardList";
import MonstaGameCardList from "./MonstaGameCardList";
import { useLazyQuery } from "@apollo/client";
import {
  ButtonWrapper,
  ButtonsContainer,
  CenteredRow,
  DetailsWrapper,
  MonstaCardLeft,
  MonstaCardLeftDetailsImage,
  MonstaCardLeftDivider,
  MonstaCardLeftImage,
  MonstaCardRight,
  MonstaCardRightPrice,
  MonstaCardRow,
  MonstaLeftDetailsValueWrapper,
  MonstaOwnerText,
} from "./style";
import MonstaParentCardList from "./MonstaParentCardList";
import MonstaChildCardList from "./MonstaChildCardList";
import { MonstaInfoType } from "../../../../models/monsta";
import { MonstaBodyParts } from "./types";
import { useFetchGameCardSvg } from "../../../../hooks/useFetchGameCardSvg";
import useMonstaStatsApi from "../../../../hooks/useMonstaStatsApi";
import TagGroup from "../../../../components/TagGroup";
import MonstaSalesHistoryButton from "./MonstaSalesHistoryButton";
import MonstaSalesHistoryModal from "./MonstaSalesHistoryModal";
import { TextButton } from "../../../../components/Button";
import { Text } from "../../../../components/Text";
import Loading from "../../../../components/Loading/Loading";
import { formatToReadibleNumber } from "../../../../utils/formatBalance";
import { marketplaceClient } from "../../../../graphql/apolloClient";
import { GET_USER_AUCTIONS } from "../../../../graphql/queries/marketplace";
import {
  GetAuctionMonstaQuery,
  GetUserAuctionsQuery,
  GetUserAuctionsQueryVariables,
  Monsta_OrderBy,
  OrderDirection,
  MonstaEventType,
} from "../../../../graphql/generated-types";
import {
  extractMonsta,
  getCurrentEstimatedPrice,
} from "../../../../utils/monstaHelpers";
import { getBodyParts } from "../../../../utils/getMonstaBodyParts";
import { toBinaryGenes } from "../../../../utils/toBinaryGenes";
import { useWeb3React } from "@web3-react/core";
import { MONSTA_CLASSES } from "../../../../models/constants";
import MonstaUnityAnimation from "./MonstaUnityAnimation";
import useCloneChamber from "../../../../hooks/useCloneChamber";
import CloneChamberButton from "./CloneChamberButton";

type MonstaProps = {
  monstaId: string;
  monstaLoading: boolean;
  monstaData: GetAuctionMonstaQuery | undefined;
  monstaSvg: string;
  onClickSell: React.MouseEventHandler<HTMLButtonElement>;
  onClickClone: React.MouseEventHandler<HTMLButtonElement>;
  onClickTransfer: React.MouseEventHandler<HTMLButtonElement>;
  onClickBuy: React.MouseEventHandler<HTMLButtonElement>;
};

type LabelProps = {
  text: string;
  children?: React.ReactNode;
};

const DetailsLabel = ({ text }: LabelProps) => (
  <Text color="#a6c0b8" size="1rem" weight="bold">
    {text.toUpperCase()}
  </Text>
);

const DetailsValue = ({ text, children }: LabelProps) => (
  <MonstaLeftDetailsValueWrapper>
    {children && children}
    <Text size="1rem" weight="200" ellipsis>
      {text}
    </Text>
  </MonstaLeftDetailsValueWrapper>
);

const WalletDetailValue = ({ text, children }: LabelProps) => {
  const start = text.slice(0, -4).trim();
  const end = text.slice(-4).trim();
  return (
    <MonstaLeftDetailsValueWrapper>
      {children && children}
      <MonstaOwnerText ellipsis={{ suffix: end }} copyable={{ text: text }}>
        {start}
      </MonstaOwnerText>
    </MonstaLeftDetailsValueWrapper>
  );
};

const MonstaCard = (props: MonstaProps) => {
  const {
    monstaId,
    monstaLoading,
    monstaData,
    monstaSvg,
    onClickSell,
    onClickClone,
    onClickTransfer,
    onClickBuy,
  } = props;
  const { addMonstaChamber } = useCloneChamber();

  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const [monstaCurrentEstimatedPrice, setMonstaCurrentEstimatedPrice] =
    useState(0);

  const { account } = useWeb3React();
  const [monstaInfo, setMonstaInfo] = useState<MonstaInfoType>({
    id: 0,
    cloneUsage: 0,
    isAdult: 0,
    tradable: false,
    transferable: false,
    timeLeft: null,
    classType: 0,
  });
  const [bodyParts, setBodyParts] = useState<MonstaBodyParts[]>([]);
  const [isOwner, setIsOwner] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  const [isForSale, setIsForSale] = useState(false);
  const { monstaStats, monstaStatsError } = useMonstaStatsApi(
    monstaData?.monsta?.genes
  );
  const { gameCards, gameCardError } = useFetchGameCardSvg(
    monstaData?.monsta?.genes
  );

  const [getSellMonsta] = useLazyQuery<
    GetUserAuctionsQuery,
    GetUserAuctionsQueryVariables
  >(GET_USER_AUCTIONS, {
    variables: {
      first: 1,
      skip: 0,
      orderBy: Monsta_OrderBy.Timestamp,
      orderDirection: OrderDirection.Asc,
      where: {
        auctionStatus_in: [MonstaEventType.UserAuctionCreated],
      },
    },
    client: marketplaceClient,
  });

  useEffect(() => {
    const parseAuctionMonsta = async () => {
      if (monstaData && monstaData.monsta) {
        const monstaGene = monstaData.monsta.genes;
        const monstaGeneBin = toBinaryGenes(monstaGene);
        const monstaInfo = extractMonsta(monstaData.monsta);
        setMonstaInfo(monstaInfo);
        const monstaEvent = monstaData.monsta.monstaEvents[0] as any;
        const marketplaceMonsta = getSellMonsta({
          variables: { where: { token: monstaId } },
        });
        if (
          (await marketplaceMonsta).data?.monstas[0].auctionStatus ===
          MonstaEventType.UserAuctionCreated
        ) {
          setIsForSale(true);
        }
        setMonstaCurrentEstimatedPrice(getCurrentEstimatedPrice(monstaEvent));
        setBodyParts(getBodyParts(monstaGeneBin));
      }
    };
    if (monstaData) {
      parseAuctionMonsta();
    }
  }, [monstaData]);

  useEffect(() => {
    if (account) {
      if (monstaData && monstaData.monsta?.owner === account.toLowerCase()) {
        setIsOwner(true);
      }
      if (
        monstaData &&
        monstaData?.monsta?.auctionStatus === MonstaEventType.UserAuctionCreated
      ) {
        if (
          monstaData?.monsta?.monstaEvents[0].__typename === "AuctionCreate"
        ) {
          if (
            monstaData?.monsta?.monstaEvents[0].seller === account.toLowerCase()
          ) {
            setIsSeller(true);
          }
        }
      }
    } else {
      setIsOwner(false);
    }
  }, [account, monstaData]);

  if (monstaLoading) {
    return <Loading centered />;
  }

  return (
    <MonstaCardRow>
      <MonstaCardLeft xs={24} md={12}>
        <CenteredRow height="10%">
          <Text size="2.5rem" weight="bold">
            #{monstaId}
          </Text>
        </CenteredRow>
        <CenteredRow>
          {monstaInfo && <TagGroup monsta={monstaInfo} />}
        </CenteredRow>
        <CenteredRow height="50%">
          <MonstaUnityAnimation gene={monstaData?.monsta?.genes} />
          {/* <MonstaCardLeftImage src={monstaSvg} /> */}
        </CenteredRow>
        <DetailsWrapper gutter={[0, 5]}>
          <Col xs={24} sm={24} md={24} lg={isForSale ? 12 : 24}>
            <Row>
              <Col>
                <DetailsLabel text="owner" />
                <WalletDetailValue
                  text={monstaData?.monsta?.owner.replace("0x", "monsta:")}
                >
                  <MonstaCardLeftDetailsImage src={monstaSvg} preview={false} />
                </WalletDetailValue>
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={12}>
                <DetailsLabel text="cloned" />
                <DetailsValue text={`${monstaInfo?.cloneUsage} / 4`} />
              </Col>
              <Col span={12}>
                <DetailsLabel text="class" />
                <DetailsValue text={MONSTA_CLASSES[monstaInfo?.classType].name}>
                  <MonstaCardLeftDetailsImage
                    src={MONSTA_CLASSES[monstaInfo?.classType].roundIcon}
                    preview={false}
                  />
                </DetailsValue>
              </Col>
            </Row>
          </Col>
          {isForSale && (
            <>
              <MonstaCardLeftDivider type="vertical" />
              <Col xs={24} sm={24} md={24} lg={11}>
                <MonstaCardRightPrice>
                  <Text
                    color="var(--monsta-yellow)"
                    size="2rem"
                    weight="bolder"
                  >
                    {formatToReadibleNumber(
                      monstaCurrentEstimatedPrice.toFixed(4)
                    )}{" "}
                    <Text
                      color="var(--monsta-yellow)"
                      size="1rem"
                      weight="bolder"
                    >
                      xSTT
                    </Text>
                  </Text>
                </MonstaCardRightPrice>
              </Col>
            </>
          )}
          <ButtonWrapper>
            {isOwner && (
              <ButtonsContainer>
                {monstaInfo.tradable && (
                  <TextButton
                    scale="full"
                    bgColor="#fee615"
                    textColor="#000000"
                    onClick={onClickSell}
                  >
                    Sell
                  </TextButton>
                )}
                {monstaInfo.transferable && (
                  <TextButton
                    scale="full"
                    bgColor="transparent"
                    textColor="#ffffff"
                    border="1px white solid"
                    onClick={onClickTransfer}
                  >
                    Transfer
                  </TextButton>
                )}
              </ButtonsContainer>
            )}
            {isOwner && monstaInfo.cloneUsage < 4 && (
              <CloneChamberButton
                onClick={() =>
                  addMonstaChamber({ ...monstaInfo, img: monstaSvg })
                }
                disabled={!monstaSvg}
              />
            )}
            {monstaData?.monsta?.auctionStatus ===
              MonstaEventType.UserAuctionCreated &&
              !isSeller && (
                <TextButton
                  scale="full"
                  bgColor="#fee615"
                  textColor="#000000"
                  onClick={onClickBuy}
                >
                  Buy Now
                </TextButton>
              )}
          </ButtonWrapper>
        </DetailsWrapper>
      </MonstaCardLeft>
      <MonstaCardRight xs={24} md={12}>
        <MonstaStats monstaStats={monstaStats} error={monstaStatsError} />
        <MonstaBodyCardList bodyParts={bodyParts} />
        <MonstaGameCardList gameCards={gameCards} error={gameCardError} />
        <MonstaParentCardList monstaId={monstaId} />
        <MonstaChildCardList monstaId={monstaId} />
        <MonstaSalesHistoryButton
          toggleModalVisible={() => {
            setHistoryModalVisible(!historyModalVisible);
          }}
        />
      </MonstaCardRight>
      {historyModalVisible && (
        <MonstaSalesHistoryModal
          monstaId={monstaId}
          modalVisible={historyModalVisible}
          toggleModalVisible={() => {
            setHistoryModalVisible(!historyModalVisible);
          }}
        />
      )}
    </MonstaCardRow>
  );
};

export default MonstaCard;
