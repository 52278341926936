import { useEffect, useState } from "react";
import { StyledSortSelect } from "../style";
import {
  Monsta_OrderBy,
  OrderDirection,
} from "../../../graphql/generated-types";
import { SortOptionType } from "..";

export const SORTING_OPTIONS: SortOptionType[] = [
  {
    label: "Lowest Price",
    value: "lowest-price",
    order_by: Monsta_OrderBy.LastEndPrice,
    order_direction: OrderDirection.Asc,
  },
  {
    label: "Highest Price",
    value: "highest-price",
    order_by: Monsta_OrderBy.LastEndPrice,
    order_direction: OrderDirection.Desc,
  },
  {
    label: "Latest",
    value: "latest",
    order_by: Monsta_OrderBy.Timestamp,
    order_direction: OrderDirection.Desc,
  },
  {
    label: "Lowest ID",
    value: "lowest-id",
    order_by: Monsta_OrderBy.Token,
    order_direction: OrderDirection.Asc,
  },
  {
    label: "Highest ID",
    value: "highest-id",
    order_by: Monsta_OrderBy.Token,
    order_direction: OrderDirection.Desc,
  },
];
type SortButtonProps = {
  saleFilter: string;
  onChange: any;
  value: SortOptionType;
};
const SortSelect = ({ saleFilter, onChange, value }: SortButtonProps) => {
  const [options, setOptions] = useState(SORTING_OPTIONS);

  useEffect(() => {
    const getOptions = () => {
      if (saleFilter !== "sale") {
        return SORTING_OPTIONS.filter(
          ({ order_by }) =>
            order_by !== Monsta_OrderBy.LastEndPrice &&
            order_by !== Monsta_OrderBy.Timestamp
        );
      }
      return SORTING_OPTIONS;
    };

    const _options = getOptions();
    setOptions(_options);
    if (saleFilter !== "sale" && value.order_by !== Monsta_OrderBy.Token) {
      onChange(_options[0]);
    }
  }, [saleFilter]);

  return (
    <StyledSortSelect
      onChange={(value, option: any) => onChange(option)}
      value={value}
      getPopupContainer={(trigger) => {
        return trigger;
      }}
      options={options}
    />
  );
};

export default SortSelect;
