import { Col, Row, Button, Tooltip } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useMonstaAggregateApi } from "../../../hooks/useMonstaAggregate";
import {
  formatBigNumberToFixed,
  formatToReadibleNumber,
  formatToReadibleXstt,
} from "../../../utils/formatBalance";
import {
  AggregateActiveButton,
  AggregateSubTitle,
  AggregateTitle,
  AggregateElementWrapper,
  AggregateIcon,
  AggregateIconContainer,
  AggregateInfoIcon,
  AggregateRadioWrapper,
  AggregateRowWrapper,
  LeftCol,
  AggregateColumnWrapper,
} from "../style";
import exchangeIcon from "../../../assets/images/exchange.svg";
import signalIcon from "../../../assets/images/signal.svg";
import { TOTAL_VOLUME_TOOLTIP, TOTAL_MONSTA_SOLD_TOOLTIP } from "../constants";
const MonstaVolume = () => {
  const { totalMonsta, totalVolume } = useMonstaAggregateApi();
  const [totalSoldMonsta, setTotalSoldMonsta] = useState(0);
  const [totalSoldVolume, setTotalSoldVolume] = useState(0);
  const [timeframe, setTimeframe] = useState("24");

  useEffect(() => {
    const dataTotalMonsta = async () => {
      let reqtm = await totalMonsta(parseInt(timeframe));
      //console.log(JSON.stringify(reqtm.data.result))
      setTotalSoldMonsta(reqtm.data.result);
    };

    const dataTotalVolume = async () => {
      let reqtv = await totalVolume(parseInt(timeframe));
      //console.log (reqtv.data.result)
      setTotalSoldVolume(reqtv.data.result);
    };

    dataTotalMonsta();
    dataTotalVolume();
  }, [timeframe]);

  return (
    <Col lg={24} sm={24}>
      <AggregateRadioWrapper
        value={timeframe}
        onChange={(e) => {
          setTimeframe(e.target.value);
        }}
      >
        <AggregateActiveButton value="24">Last 24hr</AggregateActiveButton>
        <AggregateActiveButton value="168">7 days</AggregateActiveButton>
        <AggregateActiveButton value="720">30 days</AggregateActiveButton>
      </AggregateRadioWrapper>

      <AggregateRowWrapper wrap={false}>
        <Col>
          <AggregateIconContainer>
            <AggregateIcon src={signalIcon} alt="Volume xSTT" />
          </AggregateIconContainer>
        </Col>
        <AggregateColumnWrapper>
          <AggregateElementWrapper>
            <AggregateSubTitle>Total Volume</AggregateSubTitle>
            <AggregateInfoIcon>
              <Tooltip title={TOTAL_VOLUME_TOOLTIP} placement="topLeft">
                <span>&#8505;</span>
              </Tooltip>
            </AggregateInfoIcon>
          </AggregateElementWrapper>
          <AggregateTitle>
            {formatToReadibleXstt(totalSoldVolume)} xSTT
          </AggregateTitle>
        </AggregateColumnWrapper>
        <Col>
          <AggregateIconContainer>
            <AggregateIcon src={exchangeIcon} alt="Monsta Sold" />
          </AggregateIconContainer>
        </Col>
        <Col>
          <AggregateElementWrapper>
            <AggregateSubTitle>Monsta Sold</AggregateSubTitle>
            <AggregateInfoIcon>
              <Tooltip title={TOTAL_MONSTA_SOLD_TOOLTIP}>
                <span>&#8505;</span>
              </Tooltip>
            </AggregateInfoIcon>
          </AggregateElementWrapper>
          <AggregateTitle>
            {formatToReadibleNumber(totalSoldMonsta.toString())}
          </AggregateTitle>
        </Col>
      </AggregateRowWrapper>
    </Col>
  );
};

export default MonstaVolume;
