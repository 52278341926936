import { FortuneCrate, PurchaseCatalog } from "../../models/tapwar";
import axios from "./axios";

export enum PurchaseCurrency {
  BNB_BSC = "bnbbsc",
  BUSD_BSC = "busdbsc",
  CAKE = "cake",
}

export const fetchFortuneCreate = async (
  currency: PurchaseCurrency,
  quantity: number
) => {
  const res = await axios.post<FortuneCrate>("/purchase/lootbox-ticket", {
    currency,
    quantity,
  });
  return res;
};

export const fetchCatalog = async () => {
  const res = await axios.get<PurchaseCatalog>("/purchase/catalog");
  return res;
};

export const fetchPaymentStatus = async (paymentId: string) => {
  const res = await axios.get(`/purchase/nowpayments/status/${paymentId}`);
  return res;
};

export const subscribeFortuneCrate = async (
  currency: PurchaseCurrency,
  quantity: number
) => {
  const res = await axios.post<FortuneCrate>(
    "/purchase/lootbox-ticket/subscription",
    {
      currency,
      quantity,
    }
  );
  return res;
};
export const assignAward = async (slug: string, monstaId: string) => {
  const res = await axios.post(`/fame/award/medal/${slug}/${monstaId}`);
  return res;
};

export const fetchAwardStatus = async (slug: string) => {
  const res = await axios.get(`/fame/award/status/${slug}`);
  return res;
};
