import { useCallback } from "react";
import {
  fetchAggregate,
  fetchTotalMonsta,
  fetchTotalVolume,
} from "../services/marketplace-aggregator-api";

export const useMonstaAggregateApi = () => {
  const totalMonsta = useCallback(async (hours: number): Promise<any> => {
    try {
      const res = await fetchTotalMonsta(hours);
      return res;
    } catch (err) {
      throw err;
    }
  }, []);

  const totalVolume = useCallback(async (hours: number): Promise<any> => {
    try {
      const res = await fetchTotalVolume(hours);
      return res;
    } catch (err) {
      throw err;
    }
  }, []);

  const aggregateCount = useCallback(async (filter: any): Promise<any> => {
    try {
      const res = await fetchAggregate(filter);
      return res;
    } catch (err) {
      throw err;
    }
  }, []);

  return { totalMonsta, totalVolume, aggregateCount };
};
