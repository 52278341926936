import styled from "@emotion/styled";

export const DisplayWrapper = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
`;

export const VerticalDottedLine = styled.div`
  width: 1px;
  height: 200px;
  border-left: 3px dashed white;
  border-bottom: none;
  position: absolute;
  left: 50%;
  bottom: 0%;
`;

export const CircleContainer = styled.div`
  width: 180px;
  height: 180px;
  background: #e6fbf2;
  border-radius: 100%;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CircleImage = styled.img`
  width: 60%;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
