import { Typography } from "antd";
import { MonstaBodyCardWrapper, MonstaLabelWrapper } from "./style";
import MonstaBodyCard from "./MonstaBodyCard";
import { InfoTooltip } from "../../../../components/Tooltip";
import { MonstaBodyParts } from "./types";
import { MONSTA_BODY_PARTS_TOOLTIP } from "../../constants";
type MonstaBodyCardListProps = {
  bodyParts: MonstaBodyParts[];
};

const { Text } = Typography;
const MonstaBodyCardList = ({ bodyParts }: MonstaBodyCardListProps) => {
  return (
    <div>
      <MonstaLabelWrapper>
        {" "}
        <Text>
          Monsta Body Part{" "}
          <InfoTooltip
            placement="left"
            title={MONSTA_BODY_PARTS_TOOLTIP}
            color="white"
          >
            ⓘ
          </InfoTooltip>
        </Text>
      </MonstaLabelWrapper>
      <MonstaBodyCardWrapper gutter={[16, 16]} justify="space-between">
        {bodyParts.map((bodyPart) => {
          return <MonstaBodyCard bodyPart={bodyPart} key={bodyPart.part} />;
        })}
      </MonstaBodyCardWrapper>
    </div>
  );
};

export default MonstaBodyCardList;
