import { Col, Row } from "antd";
import ReactTimeAgo from "react-time-ago";
import EllipsisMiddle from "../../../components/EllipsisMiddle";
import { Text } from "../../../components/Text";
import { TitleText } from "../style";
type ExpandedColumProps = {
  address: string;
  price: string;
  timeAgo: number;
};
const ExpandedColumn = ({ address, price, timeAgo }: ExpandedColumProps) => {
  return (
    <>
      <Row justify={"space-between"}>
        <Col span={14}>
          <TitleText>Buyer</TitleText>
          <EllipsisMiddle suffixCount={6}>
            {address?.replace("0x", "monsta:")}
          </EllipsisMiddle>
        </Col>
        <Col span={8}>
          <TitleText>Price</TitleText>
          <Text>{price} xSTT</Text>
        </Col>
      </Row>

      <TitleText>Time</TitleText>
      <ReactTimeAgo date={timeAgo * 1000} timeStyle="round" />
    </>
  );
};
export default ExpandedColumn;
