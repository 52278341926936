import { useEffect, useState } from "react";
import { config } from "../config";
import { MonstaStatsType } from "../models/monsta";
import { getHttpErrorMsg } from "../utils/getHttpErrorMsg";
import { toBinaryGenes } from "../utils/toBinaryGenes";

const MONSTA_STATS_API_URL = `${config.api.monsta}/stats`;

const useMonstaStatsApi = (genes: string | undefined) => {
  const [monstaStats, setMonstaStats] = useState<MonstaStatsType>({
    atk_percent: 0,
    attack: 0,
    attributes: {
      agi: 0,
      int: 0,
      str: 0,
    },
    defense: 0,
    hp: 0,
    hp_regen: 0,
    legendary: {
      arm: false,
      body: false,
      face: false,
      head: false,
      leg: false,
      tail: false,
    },
    mp: 0,
    mp_regen: 0,
    power: 0,
    power_percent: 0,
    res: 0,
    sp: 0,
    sp_regen: 0,
  });
  const [monstaStatsError, setError] = useState<string | null>("");

  useEffect(() => {
    const fetchData = async (binaryGenes: string) => {
      try {
        const res: Response = await fetch(MONSTA_STATS_API_URL, {
          method: "POST",
          body: JSON.stringify({
            data: binaryGenes,
          }),
        });
        if (!res.ok) {
          setError(getHttpErrorMsg(res.status));
          return;
        }
        const resData = await res.json();
        setMonstaStats(resData);
        setError(null);
      } catch (err: any) {
        setError("Something went wrong. Please try again later.");
      }
    };
    if (genes) {
      fetchData(toBinaryGenes(genes));
    }
  }, [genes]);

  return { monstaStats, monstaStatsError };
};

export default useMonstaStatsApi;
