import xsttIcon from "../../../../../assets/images/xstt-icon.svg";

import { MonstaInfoType, MonstaType } from "../../../../../models/monsta";
import { TextButton } from "../../../../../components/Button";
import MonstaDisplay from "./MonstaDisplay";
import {
  DisplayWrapper,
  VerticalDottedLine,
  CloneDisplayWrapper,
  SelectTheMonstaContainer,
  CostDisplayWrapper,
  CostDisplayCircle,
  CostDisplayPriceContainer,
  CostDisplayXsttIcon,
  CostDisplayPriceText,
  DottedLine,
  ButtonWrapper,
} from "./style";

type ToCloneScreenProps = {
  baseMonsta: MonstaType;
  onClickSelectTheMonsta: React.MouseEventHandler<HTMLDivElement>;
  selectedMonsta?: MonstaType | null;
  onApprove: React.MouseEventHandler<HTMLButtonElement>;
  selectMonstaToCloneListLoading: boolean;
  sttCost: string;
  isApproved: boolean;
  approveLoading: boolean;
  onClone: React.MouseEventHandler<HTMLButtonElement>;
};

const ToCloneScreen = (props: ToCloneScreenProps) => {
  const {
    baseMonsta,
    onClickSelectTheMonsta,
    selectedMonsta,
    onApprove,
    selectMonstaToCloneListLoading,
    sttCost,
    isApproved,
    approveLoading,
    onClone,
  } = props;

  return (
    <div>
      <DisplayWrapper>
        <VerticalDottedLine />
        <CloneDisplayWrapper>
          <MonstaDisplay
            image={baseMonsta.img}
            cloneUsage={baseMonsta.cloneUsage}
            id={`${baseMonsta.id}`}
          />
          <DottedLine />
          <CostDisplayWrapper>
            <CostDisplayCircle />
            <CostDisplayXsttIcon src={xsttIcon} alt="xSTT Icon" />
            <CostDisplayPriceContainer>
              <CostDisplayPriceText>{sttCost} xSTT</CostDisplayPriceText>
            </CostDisplayPriceContainer>
          </CostDisplayWrapper>
          <DottedLine />
          {selectedMonsta ? (
            <MonstaDisplay
              image={selectedMonsta.img}
              cloneUsage={selectedMonsta.cloneUsage}
              id={`${selectedMonsta.id}`}
              isDark
              onClick={onClickSelectTheMonsta}
            />
          ) : (
            <SelectTheMonstaContainer onClick={onClickSelectTheMonsta}>
              {selectMonstaToCloneListLoading ? (
                <p>Loading...</p>
              ) : (
                <p>Select the Monsta</p>
              )}
            </SelectTheMonstaContainer>
          )}
        </CloneDisplayWrapper>
      </DisplayWrapper>
      <ButtonWrapper>
        {(!isApproved || approveLoading) && (
          <TextButton
            scale="lg"
            bgColor="#fee615"
            textColor="#000000"
            onClick={onApprove}
            loading={approveLoading}
            disabled={!selectedMonsta}
          >
            Approve
          </TextButton>
        )}
        {isApproved && (
          <TextButton
            scale="lg"
            bgColor="#fee615"
            textColor="#000000"
            onClick={onClone}
          >
            Clone
          </TextButton>
        )}
      </ButtonWrapper>
    </div>
  );
};

export default ToCloneScreen;
