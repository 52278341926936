import { ApolloClient, InMemoryCache } from "@apollo/client";

export const marketplaceClient = new ApolloClient({
  uri: `${process.env.REACT_APP_MARKETPLACE_GRAPHQL}marketplace`,
  cache: new InMemoryCache(),
});

export const tokenClient = new ApolloClient({
  uri: `${process.env.REACT_APP_MARKETPLACE_GRAPHQL}token`,
  cache: new InMemoryCache(),
});
