import { FC, useState } from "react";
import { StyledInputWithOperator } from "../style";

enum Operator {
  plus = "plus",
  minus = "minus",
}

type InputAddonProps = {
  operator: Operator;
  onChange: (operator: Operator) => void;
};

const InputOperator: FC<InputAddonProps> = ({ operator, onChange }) => {
  return (
    <div onClick={() => onChange(operator)}>
      {operator === Operator.minus && "-"}
      {operator === Operator.plus && "+"}
    </div>
  );
};

type InputWithOperatorProps = {
  value?: number;
  onChange?: (value: number) => void;
};

const InputWithOperator: FC<InputWithOperatorProps> = ({ value, onChange }) => {
  const [fieldValue, setFieldValue] = useState(value);

  const handleOperator = (operator: Operator) => {
    let newFieldValue = fieldValue || 0;
    operator === Operator.minus && newFieldValue--;
    operator === Operator.plus && newFieldValue++;
    handleChange(newFieldValue);
  };

  const handleChange = (value: number) => {
    setFieldValue(value);
    onChange && onChange(value);
  };

  return (
    <StyledInputWithOperator
      addonAfter={
        <InputOperator operator={Operator.plus} onChange={handleOperator} />
      }
      addonBefore={
        <InputOperator operator={Operator.minus} onChange={handleOperator} />
      }
      bordered={false}
      value={fieldValue}
      onChange={(value) => handleChange(+value)}
    />
  );
};

export default InputWithOperator;
