import tapWar from "../../assets/images/announcement/desktop/1_Tapwar.webp";
import monstaVerse from "../../assets/images/announcement/desktop/2_MonstaVerse.webp";
import monstaWalletMobileAndroid from "../../assets/images/announcement/desktop/3_MonstaWalletMobile_android.webp";
import monstaWalletMobileIOS from "../../assets/images/announcement/desktop/3_MonstaWalletMobile_ios.webp";
import shieldRecharge from "../../assets/images/announcement/desktop/4_ShieldRecharge.webp";
import meetTheMonstas from "../../assets/images/announcement/desktop/5_MeetTheMonstas.webp";
import shopTheMonstas from "../../assets/images/announcement/desktop/6_ShopTheMonstaMarketplace.webp";
import juggySwap from "../../assets/images/announcement/desktop/7_JuggySwap.webp";
import stayInTheLoop from "../../assets/images/announcement/desktop/8_StayInTheLoop.webp";
import helpDesk from "../../assets/images/announcement/desktop/9_Helpdesk.webp";
import cloneBanner from "../../assets/images/announcement/desktop/10_Clone.webp";
import launchPad from "../../assets/images/announcement/desktop/11_Launchpad.webp";

import tapWarMobile from "../../assets/images/announcement/mobile/1_Tapwar.webp";
import monstaVerseMobile from "../../assets/images/announcement/mobile/2_MonstaVerse.webp";
import monstaWalletMobileMobileAndroid from "../../assets/images/announcement/mobile/3_MonstaWalletMobile_android.webp";
import monstaWalletMobileMobileIOS from "../../assets/images/announcement/mobile/3_MonstaWalletMobile_ios.webp";
import shieldRechargeMobile from "../../assets/images/announcement/mobile/4_ShieldRecharge.webp";
import meetTheMonstasMobile from "../../assets/images/announcement/mobile/5_MeetTheMonstas.webp";
import shopTheMonstasMobile from "../../assets/images/announcement/mobile/6_ShopTheMonstaMarketplace.webp";
import juggySwapMobile from "../../assets/images/announcement/mobile/7_JuggySwap.webp";
import stayInTheLoopMobile from "../../assets/images/announcement/mobile/8_StayInTheLoop.webp";
import helpDeskMobile from "../../assets/images/announcement/mobile/9_Helpdesk.webp";
import cloneBannerMobile from "../../assets/images/announcement/mobile/10_Clone.webp";
import launchPadMobile from "../../assets/images/announcement/mobile/11_Launchpad.webp";

export const ANNOUNCEMENT_SLIDES_MOBILE = [
  {
    img: tapWarMobile,
    url: "https://monstainfinite.com/tapwar-download/",
  },
  {
    img: monstaVerseMobile,
    url: "https://monstainfinite.com/monstaverse/",
  },
  {
    img: monstaWalletMobileMobileAndroid,
    url: "https://monstainfinite.com/monsta-wallet-download/",
  },
  {
    img: shieldRechargeMobile,
    url: "https://marketplace.monstainfinite.com/tapwar",
  },
  {
    img: meetTheMonstasMobile,
    url: "https://monstainfinite.com/meet-our-monstas/",
  },
  {
    img: shopTheMonstasMobile,
    url: "https://marketplace.monstainfinite.com/marketplace",
  },
  {
    img: juggySwapMobile,
    url: "https://marketplace.monstainfinite.com/swap",
  },
  {
    img: stayInTheLoopMobile,
    url: "https://monstainfinite.com/",
  },
  {
    img: helpDeskMobile,
    url: "https://help.monstainfinite.com/hc/en-us",
  },
  {
    img: cloneBannerMobile,
    url: "https://help.monstainfinite.com/hc/en-us/articles/5027731142681-How-do-I-clone-Monsta-",
  },
  {
    img: launchPadMobile,
    url: "#",
  },
];

export const ANNOUNCEMENT_SLIDES = [
  {
    img: tapWar,
    url: "https://monstainfinite.com/tapwar-download/",
  },
  {
    img: monstaVerse,
    url: "https://monstainfinite.com/monstaverse/",
  },
  {
    img: monstaWalletMobileAndroid,
    url: "https://monstainfinite.com/monsta-wallet-download/",
  },
  {
    img: shieldRecharge,
    url: "https://marketplace.monstainfinite.com/tapwar",
  },
  {
    img: meetTheMonstas,
    url: "https://monstainfinite.com/meet-our-monstas/",
  },
  {
    img: shopTheMonstas,
    url: "https://marketplace.monstainfinite.com/marketplace",
  },
  {
    img: juggySwap,
    url: "https://marketplace.monstainfinite.com/swap",
  },
  {
    img: stayInTheLoop,
    url: "https://monstainfinite.com/",
  },
  {
    img: cloneBanner,
    url: "https://help.monstainfinite.com/hc/en-us/articles/5027731142681-How-do-I-clone-Monsta-",
  },
  {
    img: helpDesk,
    url: "https://help.monstainfinite.com/hc/en-us",
  },
  {
    img: launchPad,
    url: "#",
  },
];
export const TOTAL_VOLUME_TOOLTIP =
  "Displays the total amount of xSTT involved in transactions within the period of time.";
export const TOTAL_MONSTA_SOLD_TOOLTIP =
  "Total amounts of Monstas sold within the period of time.";
