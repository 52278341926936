import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import type { ThunkAction } from "redux-thunk";
import type { Action } from "@reduxjs/toolkit";

import appReducer from "./app";
import swapReducer from "./swap/reducer";
import userReducer from "./user/reducer";
import blockReducer from "./block";
import transactionsReducer from "./transactions/reducer";
import cloneChamberReducer from "./clone-chamber";

const store = configureStore({
  reducer: {
    app: appReducer,
    swap: swapReducer,
    user: userReducer,
    block: blockReducer,
    transactions: transactionsReducer,
    cloneChamber: cloneChamberReducer,
  },
});

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, AppState, null, Action<string>>;
export const useSelector: TypedUseSelectorHook<AppState> = useReduxSelector;
export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
