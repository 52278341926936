import styled from "@emotion/styled";

export const MainCardWrapper = styled.div`
  margin: 25px auto;
  width: 85vw;
  max-width: 1100px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
  }
  background-color: #11644f;
  position: relative;
  border-radius: 10px;
  display: flex;
`;

export const CloseButton = styled.button`
  background-color: #202020;
  width: 46px;
  height: 46px;
  border: 0px;
  border-radius: 0 20px 20px 0;
  margin-top: 1rem;
  padding: 5px;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  width: calc(100% - 45px);
  height: 100%;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
