import styled from "@emotion/styled";
import { Button, Image } from "antd";

const flexCenterStyle = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

type MainWrapperProps = {
  background: string;
  collapsed: boolean;
};
export const MainWrapper = styled.div<MainWrapperProps>`
  ${flexCenterStyle}
  position: fixed;
  bottom: 30px;
  right: 0;
  background: ${({ background }) => background};
  border-radius: 100px 0 0 100px;
  z-index: 50;
  font-weight: 600;
  padding-right: 10px;
  transform: ${({ collapsed }) => (collapsed ? `translate(280px)` : "")};
  transition: transform 0.2s;
`;
export const MainBackground = styled(Image)`
  height: 140px;
  margin-top: -35px;
`;
type CollapseIconProps = {
  collapsed: boolean;
};
export const CollapseIcon = styled.div<CollapseIconProps>`
  position: absolute;
  left: 0;
  transform: translate(-50%);
  padding: 4px;
  background: #7dffda;
  border: 3px solid #1b1b1b;
  border-radius: 30px;
  cursor: pointer;
  font-size: 18px;
  line-height: 0;
  color: black;
  z-index: 10;
  svg {
    transition: transform 0.2s;
    transition-delay: 0.3s;
    transform: ${({ collapsed }) => (collapsed ? `rotate(180deg)` : ``)};
    stroke: black;
    stroke-width: 2em;
  }
`;
type MonstaPairOuterRingProps = {
  order: number;
};
export const MonstaPairOuterRing = styled.div<MonstaPairOuterRingProps>`
  display: flex;
  flex-flow: column-reverse;
  position: relative;
  margin-right: ${({ order }) => (order === 0 ? "3px" : "")};
  width: 90px;
  height: 90px;
  border-radius: 100px;
  border: 7px solid #ac8363;
  outline: 2px solid #382619;
  background: linear-gradient(
    180deg,
    #231f20 70.39%,
    #41695d 96.77%,
    #7dffda 100%
  );
  background-repeat: no-repeat;
  background-position: right;
  box-shadow: 0px 0px 0px 2px #382619 inset;
`;
type MonstaPairOuterInnerProps = {
  lineBg: string;
};
export const MonstaPairInnerRing = styled.div<MonstaPairOuterInnerProps>`
  ${flexCenterStyle}
  flex-direction: column;
  justify-content: flex-end;
  padding: 5px 0;
  width: 90px;
  height: 90px;
  background-image: url(${({ lineBg }) => lineBg});
  background-repeat: no-repeat;
  background-position: right -2px top 18px;
  background-size: 18px 62px;
  margin: -7px;
  border-radius: 100px;
  .anticon-close-circle {
    color: #202020;
    background: white;
    border-radius: 100px;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 10;
    svg {
      margin: -1px;
    }
  }
`;
export const MonstaImage = styled(Image)`
  height: 50px;
`;
type MonstaPairIdProps = {
  active?: boolean;
};
export const MonstaPairId = styled.div<MonstaPairIdProps>`
  ${flexCenterStyle}
  background: ${({ active }) => (active ? "#FFBD0C" : "#7A7A7A")};
  border: 2px solid #382619;
  border-radius: 30px;
  align-self: stretch;
  z-index: 10;
  font-weight: normal;
  color: #30413c;
  line-height: 20px;
`;
export const NotificationIcon = styled.div`
  ${flexCenterStyle}
  position: absolute;
  top: 10px;
  left: 100px;
  transform: translate(-50%, -50%);
  background: #7dffda;
  border-radius: 50px;
  padding: 3px;
  border: 3px solid black;
  color: #131313;
  width: 32px;
  height: 32px;
  font-weight: 700;
  font-family: "Obelix Pro";
  font-size: 11px;
`;
export const GoButton = styled(Button)`
  height: auto;
  position: absolute;
  left: 130px;
  bottom: -10px;
  padding: 3px 15px;
  background: #7dffda;
  border: 2px solid #000000;
  border-radius: 50px;
  font-weight: 700;
  font-family: "Obelix Pro";
  font-size: 20px;
  color: #1b1b1b;
  &:active,
  &:focus,
  &:hover {
    background: #25ccae;
    color: #1b1b1b;
    border-color: #000;
  }
`;
export const DescriptionOuter = styled.div`
  ${flexCenterStyle}
  z-index: -20;
  position: absolute;
  right: calc(100% - 60px);
  bottom: calc(100% + 60px);
  padding: 3px;
  background: white;
  border-radius: 30px 10px 31px 30px;
  @media (max-width: 1200px) {
    width: 456px;
  }
  @media (max-width: 768px) {
    width: calc(100vw - 32px);
    right: 16px;
    top: auto;
    bottom: calc(100% + 30px);
  }
`;
export const DescriptionInner = styled.div`
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90deg, #7dffda 29.67%, #e49d65 104.58%);
  padding: 10px 20px;
  color: #172924;
  font-size: 16px;
  white-space: nowrap;
  border-radius: 30px 10px 33px 30px;

  // right triangle
  &:after,
  &:before {
    z-index: -10;
    content: "";
    width: 0;
    height: 0;
    border-radius: 5px;
    border-left: 15px solid transparent;
    border-right: 14px solid transparent;
    position: absolute;
  }
  &:after {
    bottom: -15px;
    border-top: 30px solid #daa670;
    right: 7px;
  }
  &:before {
    bottom: -22px;
    border-top: 40px solid white;
    border-left: 22px solid transparent;
    border-right: 18px solid transparent;
    right: 2px;
  }
  .close {
    margin-left: 20px;
    cursor: pointer;
    font-size: 13px;
  }
  @media (max-width: 1200px) {
    width: 450px;
    white-space: break-spaces;
  }
  @media (max-width: 768px) {
    width: calc(100vw - 32px);
  }
`;
