import { Contract, ethers, Overrides } from "ethers";

/**
 * Estimate the gas needed to call a function, and add a 10% margin
 * @param contract Used to perform the call
 * @param methodName The name of the methode called
 * @param gasMarginPer10000 The gasMargin per 10000 (i.e. 10% -> 1000)
 * @param args An array of arguments to pass to the method
 * @returns https://docs.ethers.io/v5/api/providers/types/#providers-TransactionReceipt
 */
const estimateGas = async (
  contract: Contract,
  methodName: string,
  methodArgs: any[],
  gasMarginPer10000: number,
  options: any = {}
) => {
  if (!contract[methodName]) {
    throw new Error(
      `Method ${methodName} doesn't exist on ${contract.address}`
    );
  }
  const rawGasEstimation = await contract.estimateGas[methodName](
    ...methodArgs,
    options
  );
  // By convention, ethers.BigNumber values are multiplied by 1000 to avoid dealing with real numbers
  const gasEstimation = rawGasEstimation
    .mul(
      ethers.BigNumber.from(10000).add(ethers.BigNumber.from(gasMarginPer10000))
    )
    .div(ethers.BigNumber.from(10000));
  return gasEstimation;
};

/**
 * Perform a contract call with a gas value returned from estimateGas
 * @param contract Used to perform the call
 * @param methodName The name of the method called
 * @param methodArgs An array of arguments to pass to the method
 * @param overrides An overrides object to pass to the method
 * @returns https://docs.ethers.io/v5/api/providers/types/#providers-TransactionReceipt
 */
export const callWithEstimateGas = async (
  contract: Contract,
  methodName: string,
  methodArgs: any[] = [],
  overrides: Overrides = {},
  extra: any = {},
  gasMarginPer10000 = 1000
): Promise<ethers.providers.TransactionResponse> => {
  const gasEstimation = estimateGas(
    contract,
    methodName,
    methodArgs,
    gasMarginPer10000,
    extra
  );
  const tx = await contract[methodName](...methodArgs, {
    gasLimit: gasEstimation,
    ...extra,
    ...overrides,
  });
  return tx;
};
