import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Contract } from "@ethersproject/contracts";
import BigNumber from "bignumber.js";
import useActiveWeb3React from "../../../hooks/useActiveWeb3React";
import useRefresh from "../../../hooks/useRefresh";
import {
  GetAccountQuery,
  GetAccountQueryVariables,
} from "../../../graphql/generated-types";
import { GET_ACCOUNT } from "../../../graphql/queries/erc20";
import { tokenClient } from "../../../graphql/apolloClient";
import { getTapwarAddress, getSttAddress } from "../../../utils/addressHelpers";
import fetchAllowance from "../../../utils/fetchAllowance";
import { getSttContract } from "../../../utils/contractHelpers";
import { BIG_ZERO } from "../../../utils/bigNumber";

const UseTapwarAllowance = () => {
  const { account, library } = useActiveWeb3React();
  const [currentAllowance, setCurrentAllowance] = useState(BIG_ZERO);
  const [allowanceError, setAllowanceError] = useState<any>("");
  const [isSucess, setIsSuccess] = useState(false);
  const [sttBalance, setSttBalance] = useState(BIG_ZERO);
  const [isRefreshingWallet, setRefreshWallet] = useState(false);
  const [usableStt, setUsableStt] = useState(BIG_ZERO);
  const [isApproving, setIsApproving] = useState<boolean>(false);
  const { fastRefresh } = useRefresh();

  const [fetchAccountBalances, { data }] = useLazyQuery<
    GetAccountQuery,
    GetAccountQueryVariables
  >(GET_ACCOUNT, {
    variables: {
      id: account?.toLowerCase() || "",
    },
    client: tokenClient,
  });

  const fetchAllowanceForAccount = async () => {
    const value = await fetchAllowance(
      getSttAddress(),
      account,
      getTapwarAddress()
    );
    if (value && !value.isEqualTo(currentAllowance)) {
      setCurrentAllowance(value);
      if (isApproving) {
        setIsApproving(false);
        setIsSuccess(true);
      }
    }
  };

  // When switching account
  useEffect(() => {
    if (account) {
      fetchAllowanceForAccount();
    }
  }, [account]);

  // In the middle of approving
  useEffect(() => {
    if (account && isApproving) {
      fetchAllowanceForAccount();
    }
  }, [account, fastRefresh]);

  useEffect(() => {
    fetchAccountBalances();
    setRefreshWallet(false);
  }, [isRefreshingWallet]);

  useEffect(() => {
    if (!account || (data && !data.account)) {
      setSttBalance(BIG_ZERO);
    }
    let stt = BIG_ZERO;
    if (data && data.account) {
      const walletData = data.account.ERC20balances;
      walletData.forEach((balance: any) => {
        const value = new BigNumber(balance.valueExact);
        switch (balance.contract.symbol) {
          case "STT":
            stt = value;
            break;
        }
      });
      setSttBalance(stt);
    }
  }, [data, account]);

  useEffect(() => {
    const usableValue = currentAllowance.gte(sttBalance)
      ? sttBalance
      : currentAllowance;
    setUsableStt(usableValue);
  }, [sttBalance, currentAllowance]);

  const handleRefreshWallet = () => {
    account && setRefreshWallet(true);
  };

  const handleSetAllowance = async (allowance: number) => {
    if (account && library) {
      const contract: Contract | null = getSttContract(library.getSigner());
      if (contract) {
        setIsSuccess(false);
        setAllowanceError("");
        setIsApproving(true);
        try {
          await contract.approve(getTapwarAddress(), allowance * 1e8);
        } catch (error: any) {
          if (error.code === 4001) {
            setAllowanceError("User Denied Transaction Signature");
          } else {
            setAllowanceError(error.message);
          }
          setIsApproving(false);
        }
      }
    }
  };

  return {
    sttBalance,
    usableStt,
    isApproving,
    isSucess,
    isRefreshingWallet,
    allowanceError,
    handleSetAllowance,
    handleRefreshWallet,
  };
};

export default UseTapwarAllowance;
