import React from "react";
import { MainCardWrapper, CloseButton, ContentWrapper } from "./style";
import Cross from "../../../../assets/images/cross.svg";

type MainCardProps = {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
};

const MainCard = (props: MainCardProps) => {
  const { onClose, children = <></> } = props;

  return (
    <MainCardWrapper>
      <CloseButton onClick={onClose}>
        <img src={Cross} alt="Exit" />
      </CloseButton>
      <ContentWrapper>{children}</ContentWrapper>
    </MainCardWrapper>
  );
};

export default MainCard;
