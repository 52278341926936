import { Space, Table } from "antd";
import { TableData } from "./MonstaTable";
import {
  StyledTable,
  IdTagImage,
  TitleText,
  MonstaImage,
  ItemColWrapper,
  ExpandButton,
} from "../style";
import IdTag from "../../../assets/images/IdTag.svg";
import { parseSvg } from "../../../utils/parseSvg";
import ExpandedColumn from "./ExpandedColumn";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
const { Column } = Table;

export type MonstaMobileTableProps = {
  loading: boolean;
  data?: TableData[];
  handleOnRow: any;
};
const MobileTable = ({
  loading,
  data,
  handleOnRow,
}: MonstaMobileTableProps) => {
  return (
    <StyledTable
      dataSource={data}
      onRow={handleOnRow}
      pagination={false}
      size="middle"
      expandIconColumnIndex={2}
      expandable={{
        expandedRowRender: (record: any) => <ExpandedColumn {...record} />,
        expandRowByClick: true,
        expandIcon: ({ expanded, onExpand, record }) =>
          expanded ? (
            <ExpandButton
              type="default"
              shape="circle"
              onClick={(e) => {
                onExpand(record, e);
                console.log("Reverted");
              }}
              icon={<CaretUpFilled />}
            />
          ) : (
            <ExpandButton
              type="default"
              shape="circle"
              onClick={(e) => {
                onExpand(record, e);
                console.log("Expanded");
              }}
              icon={<CaretDownFilled />}
            />
          ),
      }}
      scroll={{ y: 500 }}
      loading={loading}
    >
      <Column
        title="ID"
        key="id"
        width={"100px"}
        fixed={"left"}
        render={(data: TableData) => (
          <>
            <TitleText>Clone usage: {data.cloneCount}</TitleText>
            <Space align="center">
              <IdTagImage src={IdTag} />
              {`#${data.id}`}
            </Space>
          </>
        )}
      />
      <Column
        title="Item"
        key="id"
        width={"auto"}
        render={(data: TableData) => (
          <ItemColWrapper>
            <MonstaImage src={parseSvg(data.img)} preview={false} />
            {data.className.toUpperCase()}
          </ItemColWrapper>
        )}
      />
    </StyledTable>
  );
};
export default MobileTable;
