import { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { Modal, message } from "antd";

import { getMonstaAuctionUserContract } from "../../../../../utils/contractHelpers";
import { extractMonsta } from "../../../../../utils/monstaHelpers";
import { timeConverter } from "../../../../../utils/common";
import { formatBigNumberToFixed } from "../../../../../utils/formatBalance";
import { MonstaInfoType } from "../../../../../models/monsta";

import { TextButton } from "../../../../../components/Button";

import { ModalContent } from "./style";
import { UserAuctionMonstaWithImage } from "../types";

type CancelAuctionModalProps = {
  showCancelAuctionModal: boolean;
  setShowCancelAuctionModal: Function;
  selectedMonsta: UserAuctionMonstaWithImage | null;
};

const CancelAuctionModal = (props: CancelAuctionModalProps) => {
  const { showCancelAuctionModal, setShowCancelAuctionModal, selectedMonsta } =
    props;

  const { account, library } = useWeb3React();

  const [monstaInfo, setMonstaInfo] = useState<MonstaInfoType | null>(null);
  const [timestamp, setTimestamp] = useState<number>(0);
  const [startingPrice, setStartingPrice] = useState<number>(0);
  const [endingPrice, setEndingPrice] = useState<number>(0);
  const [duration, setDuration] = useState<string>("");
  const [isFixed, setIsFixed] = useState<boolean>(false);

  const [cancelLoading, setCancelLoading] = useState(false);

  useEffect(() => {
    if (selectedMonsta) {
      const monstaInfo = extractMonsta(selectedMonsta);
      setMonstaInfo(monstaInfo);

      if (selectedMonsta.monstaEvents[0]) {
        setTimestamp(selectedMonsta.monstaEvents[0].timestamp);

        if (selectedMonsta.monstaEvents[0].__typename === "AuctionCreate") {
          const startingPrice = selectedMonsta.monstaEvents[0].startingPrice;
          const endingPrice = selectedMonsta.monstaEvents[0].endingPrice;
          setStartingPrice(formatBigNumberToFixed(startingPrice, 0, 8));
          setEndingPrice(formatBigNumberToFixed(endingPrice, 0, 8));
          setDuration(selectedMonsta.monstaEvents[0].duration);
          setIsFixed(startingPrice === endingPrice);

          let seconds = parseInt(selectedMonsta.monstaEvents[0].duration);
          const days = Math.floor(seconds / 3600 / 24);
          seconds = seconds - days * 24 * 3600;
          const hours = Math.floor(seconds / 3600);
          seconds = seconds - hours * 3600;
          const mins = Math.floor(seconds / 60);
          seconds = seconds - mins * 60;
          setDuration(
            `${days === 0 ? "" : days + " days"} ${
              hours === 0 ? "" : hours + " hrs"
            } ${mins === 0 ? "" : mins + " mins"}`
          );
        }
      }
    }
  }, [selectedMonsta]);

  const handleCancelSale = async () => {
    if (!account || !library || cancelLoading || !selectedMonsta) return;
    const contract = getMonstaAuctionUserContract(library.getSigner());
    if (!contract) return;

    try {
      setCancelLoading(true);
      await contract.cancelAuction(selectedMonsta.id);
      message.success(
        "Transaction sent, monsta will appear to your profile soon if no one bought before you cancel the sale",
        10
      );
      setCancelLoading(false);
      setShowCancelAuctionModal(false);
    } catch (error: any) {
      setCancelLoading(false);
      if (error?.code === 4001) {
        message.error("Transaction rejected.");
      } else if (error.code === -32603) {
        switch (error.data.message) {
          case "execution reverted: Auction: must be seller":
            message.error("Must be seller");
            break;
          default:
            message.error(`${error.data.message}`);
        }
      } else {
        console.log(error);
        message.error(`Unsuccessful, please try again later`);
      }
    }
  };

  return (
    <Modal
      centered
      visible={showCancelAuctionModal}
      footer={null}
      onCancel={() => {
        setShowCancelAuctionModal(false);
      }}
    >
      {monstaInfo && (
        <ModalContent>
          <p>#{monstaInfo.id}</p>
          <p>{isFixed ? "FIXED PRICE" : "AUCTION"}</p>
          <p>Listed at: {timeConverter(timestamp)}</p>
          {!isFixed && (
            <>
              <p>Duration: {duration}</p>
              <p>Starting price: {startingPrice} xSTT</p>
              <p>Ending price: {endingPrice} xSTT</p>
            </>
          )}
          <p>Current Estimated Price: {monstaInfo.currentPrice} xSTT</p>
          <TextButton
            scale="lg"
            bgColor="transparent"
            textColor="#ffffff"
            border="1px white solid"
            loading={cancelLoading}
            onClick={handleCancelSale}
          >
            Cancel Sell
          </TextButton>
        </ModalContent>
      )}
    </Modal>
  );
};

export default CancelAuctionModal;
