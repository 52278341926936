import styled from "@emotion/styled";

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CircleContainer = styled(FlexContainer)`
  width: 180px;
  height: 180px;
  background: #e6fbf2;
  border-radius: 100%;
  margin: 60px 0 40px;
`;

export const CircleImage = styled.img`
  width: 60%;
`;

export const InfoContainer = styled.div`
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: dashed 1px white;
  padding: 0 5px 10px;
`;

export const InfoText = styled.p`
  font-size: 1rem;
  margin-bottom: 0px;
`;

export const CloneUsageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

export const CloneUsageDnaImage = styled.img`
  transform: rotate(90deg);
  width: 1rem;
`;

export const MessageContainer = styled.div`
  padding: 8px 0px 40px;

  p {
    margin-bottom: 0;
    font-size: 0.8rem;
    line-height: 1.1rem;
    text-align: center;
  }
`;

export const ButtonWrapper = styled(FlexContainer)`
  width: 100%;
`;
