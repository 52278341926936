import PACKAGE_1_BG from "../../assets/images/fc_sale/package_1.png";
import PACKAGE_2_BG from "../../assets/images/fc_sale/monthly_pass_normal.png";
import PACKAGE_1_TOP_ICON from "../../assets/images/fc_sale/bundle-icon.png";
import PACKAGE_2_TOP_ICON from "../../assets/images/fc_sale/monthly-pass-icon.png";
import PACKAGE_1_SELECTED_BG from "../../assets/images/fc_sale/package_1_selected.png";
import PACKAGE_2_SELECTED_BG from "../../assets/images/fc_sale/monthly_pass_selected.png";
import { BundleType } from "../../hooks/usePurchase";

export const PACKAGE_VALUES = [
  {
    type: BundleType.BUNDLE,
    title: "Bundle",
    defaultBg: PACKAGE_1_BG,
    selectedBg: PACKAGE_1_SELECTED_BG,
    topIcon: PACKAGE_1_TOP_ICON,
    topBarColor: "#47FEE8",
    progressBarColor: "#55FEDE",
  },
  {
    type: BundleType.MONTHLY_PASS,
    title: "Monthly Pass",
    defaultBg: PACKAGE_2_BG,
    selectedBg: PACKAGE_2_SELECTED_BG,
    topIcon: PACKAGE_2_TOP_ICON,
    topBarColor: "#F5952D",
    progressBarColor: "#F6AA2E",
  },
];
