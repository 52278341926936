import { Col, Row, message } from "antd";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  AwardButton,
  AwardCard,
  AwardImage,
  AwardPageWrapper,
  AwardWarning,
  BaseText,
  InputTitle,
  MonstaIdInput,
  StopIcon,
  StopTextWrapper,
  TcAlertText,
  TCCheckbox,
  WarningIcon,
  WarningText,
  WarningTextSpan,
} from "./style";
import { MONSTA_AWARDS } from "./constants";
import { useEffect, useState } from "react";
import useMonstaAuth from "../../hooks/useMonstaAuth";
import useTapwarAuth from "../../hooks/useTapwarAuth";
import { assignAward, fetchAwardStatus } from "../../services/tapwar-api";
import Loading from "../../components/Loading/Loading";

type ErrorResponse = {
  detail: string;
  code: string;
};

type AwardDetails = {
  name: string;
  symbol: string;
  username: string;
  assigned: boolean;
};

const MonstaAward = () => {
  const { active, connect } = useMonstaAuth();
  const { isAuth, handleLogin, loginLoading } = useTapwarAuth();
  const { slug } = useParams();
  const [tcChecked, setTcChecked] = useState(false);
  const [monstaId, setMonstaId] = useState("");
  const [awardData, setAwardData] = useState<AwardDetails>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAward = async () => {
    if (!awardData) {
      message.error("Invalid Award!");
    } else if (slug) {
      if (monstaId === "") {
        message.error("Monsta ID Field can't be blank!");
      } else {
        setIsSubmitting(true);
        try {
          const res = await assignAward(slug, monstaId);

          if (res.status === 200) {
            message.success("Awarded Successfully");
          }
        } catch (err) {
          if (axios.isAxiosError(err)) {
            if (err.response?.status === 400 || err.response?.status === 404) {
              const errorMsg = err.response.data as ErrorResponse;
              message.error(errorMsg.detail);
            }
          }
        }
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      if (slug) {
        try {
          const response = await fetchAwardStatus(slug);
          setAwardData(response.data);
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 404) {
              message.error("Award does not exist");
            }
          }
        } finally {
          setIsLoading(false);
        }
      }
    };

    initialize();
  }, []);

  if (isLoading) {
    return (
      <AwardPageWrapper>
        <AwardCard>
          <Loading />
        </AwardCard>
      </AwardPageWrapper>
    );
  }

  return (
    <AwardPageWrapper>
      <AwardCard>
        <Row justify="center" gutter={[8, 16]}>
          {awardData ? (
            <>
              <Col>
                <BaseText fontSize="3rem" display="block">
                  Assign Award
                </BaseText>
              </Col>
              <Col span={22}>
                <AwardImage
                  src={MONSTA_AWARDS[awardData?.symbol]}
                  alt={awardData?.name}
                />
                <BaseText fontSize="2rem" display="block">
                  {awardData?.name}
                </BaseText>
              </Col>
              <Col span={24}>
                <InputTitle>
                  <BaseText>Monsta ID</BaseText>
                </InputTitle>
                <MonstaIdInput
                  value={monstaId}
                  onChange={(e) => setMonstaId(e.target.value)}
                />
              </Col>
              <Col>
                <AwardWarning>
                  <WarningIcon />
                  <WarningText>
                    The award will be permanently assigned to the selected
                    Monsta.{" "}
                    <WarningTextSpan>
                      Once assigned, the player cannot claim back the award or
                      assign it to other Monsta even if the original recipient
                      is sold or sacrificed.
                    </WarningTextSpan>
                  </WarningText>
                </AwardWarning>
              </Col>
              <Col>
                <TCCheckbox
                  checked={tcChecked}
                  onChange={(e) => {
                    setTcChecked(e.target.checked);
                  }}
                >
                  <BaseText fontSize="14px">
                    Agree and assign this award to the selected Monsta.
                  </BaseText>
                </TCCheckbox>
              </Col>
              <Col md={14} sm={12} xs={22}>
                {isAuth ? (
                  <AwardButton
                    disabled={!tcChecked}
                    loading={isSubmitting}
                    onClick={handleAward}
                  >
                    Assign
                  </AwardButton>
                ) : (
                  <AwardButton
                    onClick={active ? handleLogin : connect}
                    loading={loginLoading}
                  >
                    {active ? "Authorize" : "Connect Wallet"}
                  </AwardButton>
                )}
              </Col>
              {!tcChecked && (
                <Col>
                  <StopTextWrapper>
                    <StopIcon />
                    <TcAlertText>
                      Please read & agree the terms & conditions
                    </TcAlertText>
                  </StopTextWrapper>
                </Col>
              )}
            </>
          ) : (
            <Col>
              <BaseText fontSize="3rem" display="block">
                Award Not Found!
              </BaseText>
            </Col>
          )}
        </Row>
      </AwardCard>
    </AwardPageWrapper>
  );
};

export default MonstaAward;
