import {
  BaseText,
  CenteredRow,
  FixedHeightCol,
  ModalButton,
  ModalButtonCol,
} from "../style";
import { Col } from "antd";

type PaymentProcessingScreenProps = {
  onCancel: () => void;
};

const PaymentProcessingScreen = ({
  onCancel,
}: PaymentProcessingScreenProps) => {
  return (
    <CenteredRow gutter={[8, 20]}>
      <Col md={18} sm={18}>
        <BaseText display="block" fontSize="36px">
          Processing...
        </BaseText>
      </Col>
      <Col md={20} sm={22}>
        <BaseText color="#FEE615">
          Payment processing, do not make further payments, you will not be
          refunded.
        </BaseText>
      </Col>
      <ModalButtonCol span={20}>
        <ModalButton onClick={onCancel}>Back To Homepage</ModalButton>
      </ModalButtonCol>
    </CenteredRow>
  );
};
export default PaymentProcessingScreen;
