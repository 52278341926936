import { useEffect, useState } from "react";
import { config } from "../config";
import { getHttpErrorMsg } from "../utils/getHttpErrorMsg";
import { toBinaryGenes } from "../utils/toBinaryGenes";

export const useFetchGameCardSvg = (genes: string | undefined) => {
  const [gameCards, setGameCards] = useState<string[]>([]);
  const [gameCardError, setGameCardError] = useState<string | null>(null);

  useEffect(() => {
    const fetchGameCards = async (binaryGenes: string) => {
      try {
        const rawResponse = await fetch(`${config.api.monsta}/skill-cards`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: binaryGenes }),
        });
        if (!rawResponse.ok) {
          setGameCardError(getHttpErrorMsg(rawResponse.status));
          return;
        }
        const jsonObj: string = JSON.parse(await rawResponse.text());
        setGameCards(Object.values(jsonObj));
        setGameCardError(null);
      } catch (error: any) {
        return "Something went wrong. Please try again later.";
      }
    };

    if (genes) {
      fetchGameCards(toBinaryGenes(genes));
    }
  }, [genes]);

  return { gameCards, gameCardError };
};
