import styled from "@emotion/styled";

export const StyledTooltipWrapper = styled.span`
  & .ant-tooltip-inner {
    color: black;
    -webkit-text-fill-color: black;
    font-weight: bold;
    font-size: 0.75rem;
    min-height: 0.8px;
    padding: 2px 8px;
    border-radius: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
`;
