import { useEffect, useState } from "react";
import { message, Space } from "antd";
import {
  InfoCircleOutlined,
  ShoppingCartOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  FlexContainer,
  StyledButton,
  TextButton,
  TapShopConfirm,
  ProtectText,
  ModalContentText,
  ModalContent,
} from "./style";
import shieldIcon from "../../assets/images/shield-icon.png";
import InputGroup from "./components/InputGroup";
import { Screen } from "./";
import { SHIELD_TOOLTIP } from "./constants";
import useTapshop from "./hooks/useTapshop";
import { formatToReadibleNumber } from "../../utils/formatBalance";

type TapshopScreenProps = {
  onChangeScreen: (screen: Screen) => void;
  isAuth: boolean;
  setIsAuth: (value: boolean) => void;
};

const TapshopScreen = (props: TapshopScreenProps) => {
  const { onChangeScreen, isAuth, setIsAuth } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    shieldPrice,
    dataLoading,
    isSucess,
    error,
    isSubmitting,
    handleRecharge,
  } = useTapshop(isAuth, setIsAuth);

  useEffect(() => {
    if (isSucess) {
      message.success("Successfully recharged!");
      onChangeScreen(Screen.tapwar);
    }
  }, [isSucess]);

  useEffect(() => {
    error && message.error(error);
  }, [error]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <InputGroup
        label="Recharge Shield"
        icon={shieldIcon}
        LabelIcon={<InfoCircleOutlined />}
        toolTipText={SHIELD_TOOLTIP}
        inputValue={formatToReadibleNumber(`${shieldPrice}`)}
        inputSuffix="Runewords"
        loading={dataLoading}
      />

      <FlexContainer justifyContent="center">
        <Space direction="vertical" size="middle">
          <div></div>
          <StyledButton
            type="ghost"
            size="large"
            onClick={() => setIsModalVisible(true)}
          >
            <ShoppingCartOutlined />
            recharge now
          </StyledButton>
          <FlexContainer justifyContent="center">
            <TextButton
              type="text"
              size="large"
              onClick={() => onChangeScreen(Screen.allowance)}
            >
              <ArrowLeftOutlined />
              back
            </TextButton>
          </FlexContainer>
          <TapShopConfirm
            visible={isModalVisible}
            okText={"Confirm"}
            onOk={handleRecharge}
            onCancel={handleCancel}
            confirmLoading={isSubmitting}
            centered
          >
            <img src={shieldIcon} alt="" />
            <ModalContent>
              <ProtectText>Protect your soul power now! </ProtectText>
              <br />
              <ModalContentText>
                Would you like to spend {shieldPrice} Runewords to recharge
                shield?
              </ModalContentText>
            </ModalContent>
          </TapShopConfirm>
        </Space>
      </FlexContainer>
    </>
  );
};

export default TapshopScreen;
