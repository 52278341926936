import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, message } from "antd";
import {
  TransferButtonWrapper,
  TradeForm,
  TransferButton,
  TransferFormWrapper,
} from "./style";
import { getMonstaNFTContract } from "../../../../utils/contractHelpers";
import useActiveWeb3React from "../../../../hooks/useActiveWeb3React";
import { MonstaInfoType } from "../../../../models/monsta";

type TransferCardProps = {
  monsta: MonstaInfoType;
};

const TransferCard: FC<TransferCardProps> = ({ monsta }) => {
  const [form] = Form.useForm();
  const { account, library } = useActiveWeb3React();
  const navigate = useNavigate();

  const transferFormItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const handleTransfer = async (formItem: any) => {
    const recepient = formItem.transferTo;
    const contract = getMonstaNFTContract(library?.getSigner());
    try {
      const status = await contract.transferFrom(
        account,
        recepient.replace("monsta:", "0x"),
        monsta.id
      );
      if (status) {
        message.success(
          "Transaction sent, your recipient will receive it soon",
          10
        );
        navigate("/");
      } else {
        message.error("Transaction failed!");
      }
    } catch (error) {
      console.log("Error", error);
      message.error("Transaction Rejected");
    }
  };

  const onSubmitFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <TradeForm
      layout="horizontal"
      form={form}
      initialValues={{}}
      onFinish={handleTransfer}
      onFinishFailed={onSubmitFailed}
      colon={false}
      {...transferFormItemLayout}
    >
      <TransferFormWrapper>
        <Form.Item
          label="Transfer To"
          name="transferTo"
          rules={[{ required: true, message: "Address is required" }]}
        >
          <Input placeholder="monsta: DdzFFzCqrhshMSxfsdfs...." />
        </Form.Item>
      </TransferFormWrapper>
      <TransferButtonWrapper>
        <TransferButton htmlType="submit">Confirm Transfer</TransferButton>
      </TransferButtonWrapper>
    </TradeForm>
  );
};

export default TransferCard;
