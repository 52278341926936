export const USABLE_STT_TOOLTIP =
  "Usable xSTT displays the amount of xSTT you can spend in game. It is based on the allowance set and the xSTT balance in your Monsta Wallet.";
export const NEW_ALLOWANCE_TOOLTIP =
  "Set a new limit on the amount of xSTT you wish to spend in Tapstore. The maximum limit is based on the amount of STT available in your Wallet Balance.";
export const WALLET_TOOLTIP =
  "Displays the amount of STT in your connected Monsta Wallet.";
export const SHIELD_TOOLTIP =
  "Shield helps reduce the amount of Souls lost during a Tap or Soul Steal attack. Fill up your Shield at 100% by purchasing this Shield so you can stay glorious in Tapwar! A single purchase of the Shield will fill your Shield to a 100% capacity.";
export const SOULS_TOOLTIP =
  "Here’s the amount of Souls you’ve collected in Tapwar! Be glorious on the Leaderboard and win various prizes by hoarding as many Souls as you can!";
