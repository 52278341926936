import IncubatorImage from "../../../../../assets/images/incubator2.png";
import { TextButton } from "../../../../../components/Button";
import {
  DisplayWrapper,
  VerticalDottedLine,
  ButtonWrapper,
  CircleContainer,
  CircleImage,
} from "./style";

const CloneLoadingScreen = () => {
  return (
    <div>
      <DisplayWrapper>
        <VerticalDottedLine />
        <CircleContainer>
          <CircleImage src={IncubatorImage} alt="Incubator" />
        </CircleContainer>
      </DisplayWrapper>
      <ButtonWrapper>
        <TextButton scale="lg" bgColor="#fee615" textColor="#000000" loading>
          Cloning...
        </TextButton>
      </ButtonWrapper>
    </div>
  );
};

export default CloneLoadingScreen;
