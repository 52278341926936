import { ethers } from "ethers";
import bep20Abi from "../config/abi/erc20.json";
import erc721Abi from "../config/abi/erc721.json";
import imEggDistributorAbi from "../config/abi/imeggDistributor.json";
import monstaAuctionAdminAbi from "../config/abi/monstaAuctionAdmin.json";
import synthesisAbi from "../config/abi/synthesis.json";
import {
  getImDistributorAddress,
  getImeggAddress,
  getMarketplaceExchangeAddress,
  getMoniAddress,
  getSttAddress,
  getMonstaNFTAddress,
  getMonstaAuctionAdminAddress,
  getMonstaAuctionUserAddress,
  getSynthesisAddress,
} from "./addressHelpers";
import { simpleRpcProvider } from "./providers";
import marketplaceExchangeAbi from "../config/abi/marketplaceExchange.json";

const getContract = (
  abi: any,
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new ethers.Contract(address, abi, signerOrProvider);
};

export const getInceptionMonstaMediatorContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(bep20Abi, getImeggAddress(), signer);
};

export const getMoniContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(bep20Abi, getMoniAddress(), signer);
};

export const getSttContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(bep20Abi, getSttAddress(), signer);
};

export const getImDistributorContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(imEggDistributorAbi, getImDistributorAddress(), signer);
};

export const getMonstaNFTContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(erc721Abi, getMonstaNFTAddress(), signer);
};

export const getMarketplaceExchangecontract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(
    marketplaceExchangeAbi,
    getMarketplaceExchangeAddress(),
    signer
  );
};

export const getMonstaAuctionAdminContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(
    monstaAuctionAdminAbi,
    getMonstaAuctionAdminAddress(),
    signer
  );
};

export const getMonstaAuctionUserContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(
    monstaAuctionAdminAbi,
    getMonstaAuctionUserAddress(),
    signer
  );
};

export const getSynthesisContract = (
  signer?: ethers.Signer | ethers.providers.Provider
) => {
  return getContract(synthesisAbi, getSynthesisAddress(), signer);
};
