import { Pagination } from "antd";
import { MonstaType } from "../../../../../models/monsta";
import MonstaCard from "./MonstaCard";
import { MonstaCardList, MonstaCardPagination } from "./style";

type SelectMonstaScreenProps = {
  selectedMonstaIdToClone?: string | null;
  handleSelectMonsta: Function;
  selectMonstaToCloneList: MonstaType[];
  selectedMonsta?: MonstaType | null;
  onChangePage: Function;
  currentPage: number;
  pageSize: number;
  totalMonsta: number;
};

const SelectMonstaScreen = (props: SelectMonstaScreenProps) => {
  const {
    selectedMonsta = null,
    handleSelectMonsta,
    selectMonstaToCloneList,
    onChangePage,
    currentPage,
    pageSize,
    totalMonsta,
  } = props;

  return (
    <>
      <MonstaCardList>
        {selectMonstaToCloneList &&
          selectMonstaToCloneList.map((item) => (
            <MonstaCard
              key={item.id}
              id={`${item.id}`}
              image={item.img}
              cloneUsage={item.cloneUsage}
              isSelected={`${selectedMonsta?.id}` === `${item.id}`}
              onClick={() => {
                handleSelectMonsta(item);
              }}
            />
          ))}
      </MonstaCardList>
      <MonstaCardPagination>
        <Pagination
          current={currentPage}
          total={totalMonsta}
          defaultPageSize={pageSize}
          onChange={(page) => {
            onChangePage(page);
          }}
          showSizeChanger={false}
          showLessItems={true}
        />
      </MonstaCardPagination>
    </>
  );
};

export default SelectMonstaScreen;
