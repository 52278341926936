import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import {
  Button,
  HatchImg,
  MonstaImg,
  FlashImg,
  HatchViewContent,
} from "./style";
import { Col, message, Typography } from "antd";
import IncubatorImg from "../../assets/images/incubator2.png";
import Flash from "../../assets/gifs/Flash.png";
import {
  GetMonstaQuery,
  GetMonstaQueryVariables,
} from "../../graphql/generated-types";
import { useLazyQuery } from "@apollo/client";
import { GET_MONSTA } from "../../graphql/queries/marketplace";
import { marketplaceClient } from "../../graphql/apolloClient";
import Loading from "../../components/Loading/Loading";
import { parseGene } from "../../utils/parseGene";
import { useHatchApi } from "../../hooks/useHatchApi";
import useRefresh from "../../hooks/useRefresh";
import { parseSvg } from "../../utils/parseSvg";

type MonstaResponse = {
  monsta: {
    id: string;
    genes: string;
    owner: string;
  };
};

const HatchView = () => {
  const { tokenId } = useParams();
  const { account } = useWeb3React();
  const { preview, hatch } = useHatchApi();
  const navigate = useNavigate();
  const [isHatched, setIsHatched] = useState(false);
  const [isHatching, setIsHatching] = useState(false);
  const [monstaSvg, setMonstaSvg] = useState("");
  const [monstaGene, setMonstaGene] = useState("");
  const [startAnimation, setStartAnimation] = useState(false);
  const [isSubmiting, setSubmiting] = useState(false);
  const { fastRefresh } = useRefresh();
  const { Text } = Typography;
  const [hatchImgSrc, setHatchImgSrc] = useState(IncubatorImg);
  const [fetchMonsta, { loading: monstaLoading, data: monstaData }] =
    useLazyQuery<GetMonstaQuery, GetMonstaQueryVariables>(GET_MONSTA, {
      variables: {
        id: tokenId || "",
      },
      client: marketplaceClient,
    });

  useEffect(() => {
    if (account && tokenId) {
      fetchMonsta();
    }
  }, [account, fetchMonsta, tokenId]);

  useEffect(() => {
    if (monstaData) {
      setMonstaGene(monstaData.monsta?.genes);
    }
  }, [monstaData]);

  // When hatch transaction sent compare initial gene with gene fetch from api if different, show and stop fetching
  useEffect(() => {
    const checkHatchProcess = async () => {
      const monstaRes = await marketplaceClient.query<MonstaResponse>({
        query: GET_MONSTA,
        variables: { id: tokenId },
        fetchPolicy: "no-cache",
      });
      if (monstaRes) {
        if (monstaGene !== monstaRes.data.monsta.genes) {
          setMonstaGene(monstaRes.data.monsta.genes);
          setIsHatching(false);
          const gene = parseGene(monstaRes.data.monsta.genes);
          const previewRes = await preview(gene);
          const result = await previewRes.text();
          setMonstaSvg(parseSvg(result));
          const bodyPartClass = parseInt(gene.substring(64, 70), 2);

          // TODO : replace src have some visual bug
          const gifSrc =
            require(`../../assets/gifs/${bodyPartClass}.gif`).default;
          setHatchImgSrc(gifSrc);
          setStartAnimation(true);
          setTimeout(() => {
            setIsHatched(true);
            setSubmiting(false);
          }, 5000);
        }
      }
    };
    if (isHatching) {
      checkHatchProcess();
    }
  }, [fastRefresh, isHatching, monstaGene, preview, tokenId]);

  const handleHatch = async () => {
    if (!tokenId) return;

    try {
      setSubmiting(true);
      const res = await hatch(tokenId);
      if (res.status === 200) {
        setIsHatching(true);
      } else {
        message.error("Egg is not ready to hatch yet");
        setSubmiting(false);
      }
    } catch (err) {
      console.log(err);
      setSubmiting(false);
      message.error("Egg is not ready to hatch yet");
      setIsHatching(false);
    }
  };

  const gotoMonstaDetail = () => {
    navigate(`/monsta/${tokenId}`, { state: { prevPath: "hatch" } });
  };

  if (monstaLoading) {
    return (
      <HatchViewContent justify="center">
        <Loading />
      </HatchViewContent>
    );
  }

  if (!account || monstaData?.monsta?.owner !== account?.toLowerCase()) {
    return (
      <HatchViewContent justify="center">Unauthorized access</HatchViewContent>
    );
  }

  return (
    <HatchViewContent justify="center">
      <Col xs={24} sm={8} className="content-col">
        <Text className="hatch-id-text">{tokenId && `#${tokenId}`}</Text>
        <div className="images-wrapper">
          <HatchImg
            id="hatch_img"
            src={hatchImgSrc}
            startAnimation={startAnimation}
          />
          <MonstaImg
            id="monsta_img"
            src={monstaSvg}
            startAnimation={startAnimation}
          />
          <FlashImg
            id="flash_gif"
            src={Flash}
            startAnimation={startAnimation}
          />
        </div>
        {isHatched ? (
          <Button onClick={gotoMonstaDetail}>Monsta Details</Button>
        ) : (
          <Button loading={isSubmiting} onClick={handleHatch}>
            Hatch
          </Button>
        )}
      </Col>
    </HatchViewContent>
  );
};

export default HatchView;
