import { useNavigate } from "react-router";
import { Space, Table, Typography } from "antd";
import EllipsisMiddle from "../../../components/EllipsisMiddle";
import {
  TitleWrapper,
  TableTitle,
  HorizontalDivider,
  StyledTable,
  IdTagImage,
  TitleText,
  MonstaImage,
  ItemColWrapper,
} from "../style";
import IdTag from "../../../assets/images/IdTag.svg";
import { parseSvg } from "../../../utils/parseSvg";
import ReactTimeAgo from "react-time-ago";
import useWindowSize from "../../../hooks/useWindowSize";
import MobileTable from "./MobileTable";
const { Column } = Table;
const { Text } = Typography;

export enum MonstaTableType {
  Listed = "listed",
  Sold = "sold",
}

export type TableData = {
  key: string;
  id: string;
  img: string;
  cloneCount: number;
  className: string;
  address: string;
  price: string;
  timeAgo: number;
};

type MonstaTableProps = {
  title: string;
  loading: boolean;
  dataType?: MonstaTableType;
  data?: TableData[];
};
interface Size {
  width: number | undefined;
  height: number | undefined;
}
const MonstaTable = ({ title, loading, data, dataType }: MonstaTableProps) => {
  const navigate = useNavigate();
  const size: Size = useWindowSize();
  const handleOnRow = (record: any, rowIndex: number | undefined) => {
    return {
      onClick: () => {
        navigate(`/dashboard?type=${dataType}&key=${record.key}`);
        navigate(`/monsta/${record.id}`);
      },
    };
  };

  return (
    <>
      <TitleWrapper>
        <TableTitle>{title}</TableTitle>
      </TitleWrapper>
      <HorizontalDivider />
      {!size.width || size.width > 600 ? (
        <StyledTable
          dataSource={data}
          onRow={handleOnRow}
          pagination={false}
          size="middle"
          scroll={{ x: 700, y: 500 }} // Adjusting y value will adjust max table height
          loading={loading}
        >
          <Column
            title="ID"
            key="id"
            width={"auto"}
            fixed={"left"}
            render={(data: TableData) => (
              <>
                <Space align="center">
                  <IdTagImage src={IdTag} />
                  {`#${data.id}`}
                </Space>
                <TitleText>Clone usage: {data.cloneCount}</TitleText>
              </>
            )}
          />
          <Column
            title="Item"
            key="id"
            width={"auto"}
            render={(data: TableData) => (
              <ItemColWrapper>
                <MonstaImage src={parseSvg(data.img)} preview={false} />
                {data.className.toUpperCase()}
              </ItemColWrapper>
            )}
          />
          <Column
            title={dataType === MonstaTableType.Listed ? "Seller" : "Buyer"}
            key="id"
            width={"auto"}
            render={(data: TableData) => (
              <EllipsisMiddle suffixCount={6}>
                {data.address?.replace("0x", "monsta:")}
              </EllipsisMiddle>
            )}
          />
          <Column
            title="Price"
            key="id"
            width={"auto"}
            render={(data: TableData) => <Text>{data.price} xSTT</Text>}
          />
          <Column
            title="Time"
            key="id"
            width={"auto"}
            render={(data: TableData) => (
              <ReactTimeAgo date={data.timeAgo * 1000} timeStyle="round" />
            )}
          />
        </StyledTable>
      ) : (
        <MobileTable loading={loading} data={data} handleOnRow={handleOnRow} />
      )}
    </>
  );
};

export default MonstaTable;
