import { useState, useEffect } from "react";

import tapwarApi from "../../../services/tapwar-api/axios";

const UseTapwar = (isAuth: boolean, setIsAuth: (value: boolean) => void) => {
  const [isRefresing, setIsRefreshing] = useState(false);
  const [tapwarScreenLoading, setTapwarScreenLoading] = useState(true);
  const [shieldBalance, setShieldBalance] = useState(0);
  const [soulBalance, setSoulBalance] = useState(0);
  const [name, setName] = useState("name");

  const fetchData = async () => {
    try {
      setTapwarScreenLoading(true);
      const response = await tapwarApi({
        method: "get",
        url: "/game/profile",
      });
      if (response.data) {
        setShieldBalance(response.data.shield_balance);
        setSoulBalance(response.data.soul_balance);
        if (response.data.name === "") {
          const gameResponse = await tapwarApi({
            method: "get",
            url: "/friends/pair/me",
          });
          setName(gameResponse.data.code);
        } else {
          setName(response.data.name);
        }
      }
      setTimeout(() => {
        setTapwarScreenLoading(false);
      }, 2000);
      // setTapwarScreenLoading(false);
    } catch (error: any) {
      setTapwarScreenLoading(false);
      console.log(error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        setIsAuth(false);
      }
    }
  };

  useEffect(() => {
    if (isAuth) {
      fetchData();
    } else {
      setShieldBalance(0);
      setSoulBalance(0);
      setName("name");
      setTapwarScreenLoading(false);
    }
  }, [isAuth]);

  useEffect(() => {
    if (isRefresing) {
      if (isAuth) {
        fetchData();
      } else {
        setShieldBalance(0);
        setSoulBalance(0);
        setName("name");
        setTapwarScreenLoading(false);
      }
      setIsRefreshing(false);
    }
  }, [isAuth, isRefresing]);

  return {
    name,
    shieldBalance,
    soulBalance,
    tapwarScreenLoading,
    setIsRefreshing,
  };
};

export default UseTapwar;
