import { LoadingOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Spin } from "antd";

export const LoadingCentered = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
`;
export const LoadingIcon = styled(LoadingOutlined)`
  color: white;
  font-size: 30px;
`;
