import type { FC } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

type CarouselArrowProps = {
  currentSlide?: number;
  slideCount?: number;
  direction: "left" | "right";
};

const CarouselArrow: FC<CarouselArrowProps> = ({
  currentSlide,
  direction,
  slideCount,
  ...carouselProps
}) =>
  direction === "left" ? (
    <LeftOutlined {...carouselProps} />
  ) : (
    <RightOutlined {...carouselProps} />
  );

export default CarouselArrow;
