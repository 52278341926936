import styled from "@emotion/styled";

export const CloneCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  @media (min-width: 768px) {
    overflow-y: scroll;
    padding: 20px 20px 20px 0px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #072820;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: black;
  }
`;

export const Title = styled.p`
  font-size: 2.3rem;
  text-align: center;
  margin-bottom: 0;
`;

export const Subtitle = styled.p`
  text-align: center;
  margin-bottom: 3rem;
`;
