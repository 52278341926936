import { MonstaLabelWrapper, MonstaChildCardWrapper } from "./style";
import { Typography } from "antd";
import MonstaChildCard from "./MonstaChildCard";
import { MonstaChildType } from "../../../../models/monsta";
import { useState, useEffect } from "react";
import { useGetChildren } from "../../../../hooks/useGetChildren";
import { extractMonsta } from "../../../../utils/monstaHelpers";
import { toBinaryGenes } from "../../../../utils/toBinaryGenes";
import { useMonstaPreviewApi } from "../../../../hooks/useMonsta";
const { Text } = Typography;

type MonstaChildCardListProps = {
  monstaId: string;
};

const MonstaChildCardList = ({ monstaId }: MonstaChildCardListProps) => {
  const [children, setChildren] = useState<MonstaChildType[]>([]);
  const [fetchChildren, { data: childrenRes }] = useGetChildren(monstaId);
  const { preview } = useMonstaPreviewApi();

  useEffect(() => {
    const loadChildren = async (
      childrenPromise: Promise<MonstaChildType>[]
    ) => {
      const childrens = await Promise.all(childrenPromise);
      setChildren(childrens);
    };

    const getMonstaChildren = async () => {
      await fetchChildren();
      if (childrenRes?.matron || childrenRes?.sire) {
        const childMatron = childrenRes.matron;
        const childSire = childrenRes.sire;
        const allChildren = childMatron.concat(childSire);
        const childrenPromise = allChildren.map(async (child) => {
          const childInfo = extractMonsta(child);
          return {
            ...childInfo,
            img: await preview(toBinaryGenes(child.genes)),
            owner: child.owner,
          };
        });
        loadChildren(childrenPromise);
      } else {
        setChildren([]);
      }
    };

    getMonstaChildren();
  }, [childrenRes, fetchChildren, preview]);

  return (
    <div>
      <MonstaLabelWrapper>
        {" "}
        <Text>Children</Text>
      </MonstaLabelWrapper>
      <MonstaChildCardWrapper gutter={[16, 16]} justify="center">
        {children.length > 0 ? (
          children.map((child) => {
            return <MonstaChildCard key={child.id} child={child} />;
          })
        ) : (
          <Text>No children found</Text>
        )}
      </MonstaChildCardWrapper>
    </div>
  );
};
export default MonstaChildCardList;
