import { Spin } from "antd";
import { LoadingCentered, LoadingIcon } from "./style";

type LoadingProps = {
  centered?: boolean;
};

const Loading = ({ centered = false }: LoadingProps) => {
  const loadingIcon = <LoadingIcon spin />;
  if (centered) {
    return <LoadingCentered indicator={loadingIcon} />;
  } else {
    return <Spin indicator={loadingIcon} />;
  }
};

export default Loading;
