import { useState, useEffect } from "react";
import { Select, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";

import { getMonstaAuctionUserAddress } from "../../../../utils/addressHelpers";
import {
  getMonstaNFTContract,
  getMonstaAuctionUserContract,
} from "../../../../utils/contractHelpers";
import useActiveWeb3React from "../../../../hooks/useActiveWeb3React";
import useRefresh from "../../../../hooks/useRefresh";
import { MonstaInfoType } from "../../../../models/monsta";

import {
  SellForm,
  SellFormWrapper,
  SellFormItem,
  SellTax,
  SellButtonWrapper,
  SellButton,
  StyledSelect,
} from "./style";

const { Option } = Select;

type SellCardProps = {
  monsta: MonstaInfoType;
};

const SellCard = (props: SellCardProps) => {
  const { monsta } = props;

  const TYPE_FIXED = 1;
  const TYPE_AUCTION = 2;
  const [saleMethod, setSaleMethod] = useState(TYPE_FIXED);
  const [form] = Form.useForm();
  const sellFormItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const navigate = useNavigate();
  const { account, library } = useActiveWeb3React();
  const { fastRefresh } = useRefresh();

  const [approveLoading, setApproveLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    if (approveLoading) {
      setTimeout(() => {
        setIsApproved(true);
        setApproveLoading(false);
      }, 10000);
    }
  }, [fastRefresh, approveLoading]);

  const handleApprove = async () => {
    setApproveLoading(true);
    const contract = getMonstaNFTContract(library?.getSigner());
    try {
      const status = await contract.approve(
        getMonstaAuctionUserAddress(),
        monsta.id
      );
      if (status) {
        setApproveLoading(true);
      }
    } catch (error) {
      setApproveLoading(false);
      message.error("Approve failed, please try again later");
      console.log(error);
    }
  };

  const handleSubmit = async (values: any) => {
    if (account && library) {
      const contract = getMonstaAuctionUserContract(library?.getSigner());
      try {
        if (contract) {
          let start_price, end_price, duration;
          if (saleMethod === TYPE_FIXED) {
            start_price = end_price = values.price;
            duration = 60;
          } else if (saleMethod === TYPE_AUCTION) {
            start_price = values.start_price;
            end_price = values.end_price;
            duration = values.duration * 3600 * 24; // days
          }
          const status = await contract.createAuction(
            monsta.id,
            (parseFloat(start_price) * 1e8).toString(),
            (parseFloat(end_price) * 1e8).toString(),
            duration
          );
          if (status) {
            message.success(
              "Transaction sent, will be listed in marketplace soon",
              15
            );
            navigate("/");
          }
        }
      } catch (error) {
        console.log(error);
        message.error("Something went wrong, please try again later");
      }
    }
  };

  const handleChangeSaleMethod = (e: unknown) => {
    if (typeof e === "number") {
      const value = e;
      setSaleMethod(value);
    }
  };

  return (
    <SellForm
      layout="horizontal"
      initialValues={{ sale_method: TYPE_FIXED }}
      form={form}
      colon={false}
      {...sellFormItemLayout}
      onFinish={handleSubmit}
    >
      <SellFormWrapper>
        <SellFormItem label="Sale Method">
          <StyledSelect
            placeholder="Sale method"
            onChange={handleChangeSaleMethod}
            defaultValue={TYPE_FIXED}
            getPopupContainer={(trigger) => {
              return trigger;
            }}
          >
            <Option value={TYPE_FIXED}>Fixed Price</Option>
            <Option value={TYPE_AUCTION}>Auction</Option>
          </StyledSelect>
        </SellFormItem>
        {saleMethod === TYPE_FIXED ? <FixedPriceForm /> : <AuctionForm />}
        <SellTax>10% tax placed for successful transaction</SellTax>
      </SellFormWrapper>
      <SellButtonWrapper>
        {!isApproved || approveLoading ? (
          <SellButton
            htmlType="button"
            onClick={handleApprove}
            loading={approveLoading}
          >
            Approve
          </SellButton>
        ) : (
          <SellButton htmlType="submit">Confirm Sell</SellButton>
        )}
      </SellButtonWrapper>
    </SellForm>
  );
};

const checkZero = (_: any, value: any) => {
  const field = _.field;
  let label = field;
  if (field === "start_price") label = "Start Price";
  if (field === "end_price") label = "End Price";
  if (field === "duration") label = "Duration";
  if (field === "price") label = "Price";
  if (value! >= 1) {
    if (field === "duration" && value > 7)
      return Promise.reject(new Error(`${label} must be less than 7 days`));
    return Promise.resolve();
  }
  return Promise.reject(new Error(`${label} must be greater than 1`));
};

const FixedPriceForm = () => {
  return (
    <>
      <Form.Item
        label="Fixed price"
        name="price"
        rules={[{ validator: checkZero }]}
      >
        <PriceInput />
      </Form.Item>
    </>
  );
};

const PriceInput = (props: any) => {
  const [number, setNumber] = useState(0);

  const triggerChange = (newNumber: number) => {
    props.onChange(newNumber);
  };

  const handleChange = (e: any) => {
    const newNumber = parseInt(e.target.value || "0", 10);
    if (Number.isNaN(number)) {
      return;
    }
    setNumber(newNumber);
    triggerChange(newNumber);
  };

  return (
    <Input
      placeholder="Enter amount here"
      suffix=" | xSTT"
      onChange={handleChange}
      value={number}
    />
  );
};

const AuctionForm = () => {
  return (
    <>
      <Form.Item
        label="Start Price"
        name="start_price"
        rules={[{ validator: checkZero }]}
      >
        <PriceInput />
      </Form.Item>
      <Form.Item
        label="End Price"
        name="end_price"
        rules={[{ validator: checkZero }]}
      >
        <PriceInput />
      </Form.Item>
      <Form.Item
        label="Duration"
        name="duration"
        rules={[{ validator: checkZero }]}
      >
        <Input placeholder="Enter duration here" suffix=" | days" />
      </Form.Item>
    </>
  );
};

export default SellCard;
