import { Col } from "antd";
import { BottomBanner, BottomLinkRow } from "../style";
import BottomBannerImg from "../../../assets/images/fc_sale/fc_sale_bottom_banner.png";
const BottomLinkGroup = () => {
  return (
    <BottomLinkRow justify="center" gutter={[24, 24]}>
      <Col>
        <a
          href="https://monstainfinite.com/tapwar-commotion-board/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <BottomBanner src={BottomBannerImg} alt="Tapwar Commotion Board" />
        </a>
      </Col>
    </BottomLinkRow>
  );
};

export default BottomLinkGroup;
