import { useEffect, useState } from "react";
import { FortuneCrate } from "../models/tapwar";
import {
  PurchaseCurrency,
  fetchFortuneCreate,
  fetchCatalog,
  subscribeFortuneCrate,
} from "../services/tapwar-api";
import useActiveWeb3React from "./useActiveWeb3React";

export enum BundleType {
  BUNDLE,
  MONTHLY_PASS,
}

export type BundleCatalog = {
  type: BundleType;
  quantity: number;
  purchased: number;
  pending: number;
  price: number;
  quota?: number;
};

const usePurchase = () => {
  const STORAGE_KEY = "fortune_payment_details";
  const BUNDLE_TYPE_KEY = "bundle_type";
  const { account } = useActiveWeb3React();
  const [catalogLoading, setCatalogLoading] = useState(true);
  const [bundleDetail, setBundleDetail] = useState<BundleCatalog>({
    type: BundleType.BUNDLE,
    quantity: 0,
    purchased: 0,
    pending: 0,
    price: 0,
  });
  const [monthlyPassDetail, setMonthlyPassDetail] = useState<BundleCatalog>({
    type: BundleType.MONTHLY_PASS,
    quantity: 0,
    purchased: 0,
    pending: 0,
    price: 0,
    quota: 0,
  });

  useEffect(() => {
    const authrizedAddress = localStorage.getItem("tapwar_mw");
    if (account && account !== authrizedAddress) {
      clearFortuneStorage();
    }
  }, [account]);

  useEffect(() => {
    const fn = async () => {
      try {
        const res = await fetchCatalog();
        const {
          nowpay_tx_fee,
          ticket_price,
          ticket_purchased_1,
          ticket_pending_1,
          ticket_quantity_1,
          ticket_sub_purchased_1,
          ticket_sub_pending_1,
          ticket_sub_quantity_1,
          ticket_sub_quota_1,
        } = res.data;

        setBundleDetail({
          ...bundleDetail,
          quantity: ticket_quantity_1,
          purchased: ticket_purchased_1,
          pending: ticket_pending_1,
          price: ticket_quantity_1 * ticket_price + nowpay_tx_fee,
        });
        setMonthlyPassDetail({
          ...monthlyPassDetail,
          quantity: ticket_sub_quantity_1,
          purchased: ticket_sub_purchased_1,
          pending: ticket_sub_pending_1,
          price:
            (ticket_sub_quantity_1 * ticket_price + nowpay_tx_fee) *
            ticket_sub_quota_1,
          quota: ticket_sub_quota_1,
        });
      } catch (error) {
        console.log(error);
      }
      setCatalogLoading(false);
    };

    fn();
  }, []);

  const updateFortuneStorage = (data: FortuneCrate) => {
    if (!data.pay_address) {
      return;
    }
    try {
      const tmp = localStorage.getItem(STORAGE_KEY) || "";
      const old_data = JSON.parse(tmp);
      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({ ...old_data, ...data })
      );
    } catch (error) {
      console.log("No data saved or Invalid");
    }
  };

  const clearFortuneStorage = () => {
    localStorage.removeItem(STORAGE_KEY);
    localStorage.removeItem(BUNDLE_TYPE_KEY);
  };

  const purchaseFortuneCreate = async (
    currency: PurchaseCurrency,
    quantity: number,
    fromLocal: boolean = false
  ): Promise<FortuneCrate> => {
    try {
      try {
        const tmp = localStorage.getItem(STORAGE_KEY) || "";
        const payment_details = JSON.parse(tmp) as FortuneCrate;
        if (payment_details.expireAt) {
          const expireAtDate = new Date(payment_details.expireAt);
          const now = new Date();
          if (expireAtDate > now) {
            return payment_details;
          }
        }
        clearFortuneStorage();
      } catch (error) {
        console.log("No data saved or Invalid");
      }
      if (fromLocal) {
        return {} as FortuneCrate;
      }
      const res = await fetchFortuneCreate(currency, quantity);
      // expireAt datetime will be in 5 minutes
      const expireAt = new Date();
      expireAt.setMinutes(expireAt.getMinutes() + 5);
      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({ ...res.data, expireAt })
      );

      return {
        ...res.data,
        expireAt: expireAt.toISOString(),
      };
    } catch (err) {
      throw err;
    }
  };

  const generatePaymentDetail = async (
    currency: PurchaseCurrency,
    quantity: number,
    bundleType: BundleType = BundleType.BUNDLE
  ): Promise<FortuneCrate> => {
    try {
      try {
        const type = parseInt(localStorage.getItem(BUNDLE_TYPE_KEY) || "");
        const tmp = localStorage.getItem(STORAGE_KEY) || "";
        const payment_details = JSON.parse(tmp) as FortuneCrate;
        if (payment_details.expireAt) {
          const expireAtDate = new Date(payment_details.expireAt);
          const now = new Date();
          if (expireAtDate > now) {
            if (type === bundleType) {
              return payment_details;
            } else {
              return {} as FortuneCrate;
            }
          }
        }
        clearFortuneStorage();
      } catch (error) {
        console.log("No data saved or Invalid");
      }
      const generateFn =
        bundleType === BundleType.BUNDLE
          ? fetchFortuneCreate
          : subscribeFortuneCrate;
      const res = await generateFn(currency, quantity);
      // expireAt datetime will be in 5 minutes
      const expireAt = new Date();
      expireAt.setMinutes(expireAt.getMinutes() + 5);
      localStorage.setItem(BUNDLE_TYPE_KEY, bundleType.toString());
      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({ ...res.data, expireAt })
      );

      return {
        ...res.data,
        expireAt: expireAt.toISOString(),
      };
    } catch (err) {
      throw err;
    }
  };

  const purchaseCatalog = async () => {
    try {
      const res = await fetchCatalog();
      return res;
    } catch (err) {
      throw err;
    }
  };

  return {
    bundleDetail,
    monthlyPassDetail,
    catalogLoading,
    generatePaymentDetail,
    purchaseFortuneCreate,
    purchaseCatalog,
    updateFortuneStorage,
    clearFortuneStorage,
  };
};

export default usePurchase;
