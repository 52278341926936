import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { monstaConnector, setupNetwork } from "../services/web3";
import { InjectedConnector } from "@web3-react/injected-connector";
import { browserName, isDesktop } from "react-device-detect";
import { message } from "antd";

const monstaMobileConnector = new InjectedConnector({
  supportedChainIds: [parseInt(process.env.REACT_APP_CHAIN_ID!)],
});

export default function useMonstaAuth(): {
  account: typeof account;
  active: typeof active;
  connect: typeof connect;
  disconnect: typeof disconnect;
  refresh: typeof refresh;
} {
  const { account, activate, deactivate, active, error } = useWeb3React();

  const connectorType = (): boolean => {
    //console.log(browserName)
    if (isDesktop) {
      return true;
    } else {
      if (browserName === "Chrome WebView" || browserName === "WebKit") {
        return false;
      }
      return true;
    }
  };

  async function refresh() {
    try {
      const isAuthorized = await monstaConnector.isAuthorized();
      if (isAuthorized && !active && !error) {
        await activate(monstaConnector);
        await connect();
      }
    } catch (error) {
      console.log("Injected error : ", error);
    }
  }

  async function connect() {
    try {
      await activate(
        connectorType() ? monstaConnector : monstaMobileConnector,
        undefined,
        true
      );
    } catch (err) {
      if (err instanceof UnsupportedChainIdError) {
        const hasSetup = await setupNetwork();
        if (hasSetup) {
          await activate(monstaConnector, undefined, true);
        } else {
          console.error(err);
        }
      } else {
        message.warn("Please make sure Monsta Wallet is installed or enabled");
        console.error(err);
      }
    }
  }

  async function disconnect() {
    try {
      deactivate();
    } catch (err) {
      console.error(err);
    }
  }

  return { account, active, connect, disconnect, refresh };
}
