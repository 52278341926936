import { useLazyQuery } from "@apollo/client";
import { marketplaceClient } from "../graphql/apolloClient";
import {
  GetParentsQuery,
  GetParentsQueryVariables,
} from "../graphql/generated-types";
import { GET_PARENTS } from "../graphql/queries/marketplace";

export const useGetParents = (tokenId: string) => {
  return useLazyQuery<GetParentsQuery, GetParentsQueryVariables>(GET_PARENTS, {
    variables: { id: tokenId },
    client: marketplaceClient,
  });
};
