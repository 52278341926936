import {
  Button,
  Card,
  Image,
  Input,
  InputNumber,
  Layout,
  Row,
  Typography,
  Modal,
} from "antd";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";

const { Content } = Layout;
const { Text, Link } = Typography;

type ViewWrapperProps = {
  bg: string;
};
export const ViewWrapper = styled.div<ViewWrapperProps>`
  background-image: url(${({ bg }) => bg});
  min-height: calc(100vh - 64px);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
`;

export const MainCard = styled(Card)`
  background: #303b45;
  width: 600px;
  margin: 40px auto;
  border-radius: 25px;
  .ant-card-body {
    padding: 10px 30px 60px;
  }
  @media screen and (max-width: 576px) {
    width: 100%;
    .ant-card-body {
      padding: 10px 15px 60px;
    }
  }
`;

type FlexContainerProps = {
  justifyContent?: "space-between" | "center" | "space-around" | "flex-end";
};
export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  align-items: center;
  @media screen and (max-width: 576px) {
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
`;

export const MainCardTitle = styled.div`
  text-transform: uppercase;
  font-family: Obelix Pro;
  font-size: 1.7rem;
  line-height: 72px;
`;

type RotateSyncProps = {
  rotating: boolean;
};
const rotate360 = keyframes`
  to { transform: rotate(360deg); }
`;
export const RotateSync = styled.div<RotateSyncProps>`
  display: flex !important;
  justify-content: center;
  font-size: 25px !important;
  color: #303b45;

  ${({ rotating }) =>
    rotating &&
    css`
      animation: ${rotate360} 1.5s linear infinite;
    `}
`;
export const StyledRefreshButton = styled(Button)`
  background: #9fa7af;
  border: none;
  margin-left: auto;
  &:hover,
  &:focus {
    background: #9fa7af;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

export const TransparentButton = styled(Button)`
  position: relative;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 0.9rem;
  background-color: transparent;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 25px;
  border: 2px solid #839a9b;
  border-radius: 7px;
  text-transform: uppercase;
  &:hover {
    background-color: #ffffff;
    color: #000000;
    border: 2px solid #839a9b;
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  text-transform: uppercase;
  color: #262d34;
  padding: 25px 60px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.9rem;
  border-radius: 7px;
`;

export const TextButton = styled(StyledButton)`
  background: none;
  color: #ffffff;
`;

//InputGroup component
export const StyledInputGroup = styled.div`
  display: flex;
  align-items: center;
  background: black;
  padding: 10px 20px;
  margin: 15px 0;
  border-radius: 20px;
  &.clickable {
    cursor: pointer;
  }
  &.small {
    padding: 15px 20px;
    .label,
    input,
    .ant-input-suffix {
      font-size: 1rem;
    }
  }
`;
export const InputGroupIcon = styled.img`
  height: 80px;
  margin-right: 10px;
`;
export const InputGroupLabelContainer = styled.div`
  .label {
    font-size: 1.3rem;
    margin-right: 5px;
  }
  @media screen and (max-width: 480px) {
    .label {
      font-size: 1.2rem;
    }
  }
`;
export const InputGroupInput = styled(Input)`
  margin-left: auto;
  max-width: 180px;
  text-align: right;
  font-size: 1.3rem;
  input {
    text-align: right;
    font-size: 1.3rem;
  }
  @media screen and (max-width: 576px) {
    text-align: center;
    font-size: 1.3rem;
    max-width: 150px;
    input {
      text-align: center;
      font-size: 1.3rem;
    }
    flex-wrap: wrap;
    justify-content: center;
  }
  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
    input {
      font-size: 1.2rem;
    }
  }
`;
export const InputGroupLoadingContainer = styled.div`
  margin-left: auto;
  width: 250px;
  text-align: right;
`;

//Tapwar Screen
export const UserNameContainer = styled(FlexContainer)`
  margin-bottom: 20px;
  justify-content: space-between !important;
  gap: 10px;
`;
export const UserNameText = styled(Text)`
  font-size: 16px;
  text-transform: uppercase;
  width: 35%;
  @media screen and (max-width: 576px) {
    width: auto;
  }
`;
export const WalletAddressText = styled(Text)`
  min-width: 235px;
  width: 60%;
  display: flex;
  font-size: 13px;
  background: black;
  padding: 10px 15px;
  border-radius: 10px;
  white-space: nowrap;
`;
export const UnderLineLink = styled(Link)`
  color: white !important;
  border-bottom: 1px solid white;
  span {
    margin-right: 5px;
  }
`;

//Allowance Screen
export const ButtonsContainer = styled.div`
  margin: 30px 0;
`;
export const NewAllowanceRow = styled(Row)`
  padding: 40px 20px;
  background: black;
  border-radius: 20px;
  font-size: 1rem;
  .ant-form-item {
    margin: 0;
  }
  .ant-form-item-explain {
    display: flex;
    padding-top: 10px;
    align-items: bottom;
  }
  .anticon-info-circle {
    margin-left: 4px;
  }
`;
export const StyledInputWithOperator = styled(InputNumber)`
  border-bottom: 1px solid;
  input {
    text-align: center;
  }
  .ant-input-number {
    border: none;
  }
  .ant-input-number-group-addon {
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

// Tapshop Screen
export const TapShopConfirm = styled(Modal)`
  .ant-modal {
    max-width: 500px;
    max-height: 435px;
  }
  .ant-modal-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    border-radius: 12px;
    background: #ffffff;
    color: #000000;
    text-align: center;
  }
  .ant-modal-footer {
    border: none;
    text-align: center;
    padding-bottom: 60px;
    .ant-btn {
      font-family: Work Sans;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      width: 162px;
      height: 45px;
      color: black;
      background: rgba(38, 45, 52, 0.1);
      border-radius: 12px;
      &.ant-btn-primary {
        color: white;
        background: #000000;
      }
    }
  }
`;
export const ModalContent = styled.div`
  text-align: left;
  margin: 15px 30px 0px 40px;
`;
export const ModalContentText = styled(Text)`
  font-family: Poppins;
  font-weight: 400;
  font-size: 22px;
  line-height: 36px;
  color: black;
`;

export const ProtectText = styled(ModalContentText)`
  font-size: 24px;
  font-weight: 800;
  color: black;
`;
