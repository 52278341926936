import styled from "@emotion/styled";
import { Image, Col, Button } from "antd";

export const TradeCardWrapper = styled.div`
  margin-top: 30px;
  margin-right: 45px;
  max-width: 980px;
  font-family: "Helvetica";
  font-weight: 400;
  @media (max-width: 768px) {
    height: auto;
    margin: 25px;
    flex-direction: column;
  }
  @media (max-width: 480px) {
    margin: 25px;
  }
  position: relative;
  overflow: hidden;
`;

export const Title = styled.p`
  font-family: "Helvetica";
  font-size: 2rem;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 1.5rem;
`;

export const TradeCardCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  label {
    color: #fff;
    font-size: 1rem;
  }
  @media (min-width: 992px) {
    min-height: 500px;
    margin-bottom: 2.5rem;
  }
  @media (max-width: 480px) {
    max-width: 100%;
    flex: 0 0 100%;
  }
`;

export const MonstaName = styled.p`
  text-transform: uppercase;
  font-family: "Helvetica";
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 0;
`;
export const MonstaId = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  background: #3e1c58;
  border-radius: 10px;
  padding: 3px 19px 5px 15px;
`;

export const MonstaImage = styled(Image)`
  width: 70%;
  margin: 0 auto;
  margin-bottom: 20px;
  @media (min-width: 650px) {
    width: 40vw;
    max-width: 350px;
    margin: 0px auto;
  }
`;

export const TradeButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  color: #fee615;
  background: #5f907a;
  border: none;
  font-weight: 700;
  width: 100%;
  font-size: 2rem;
  box-shadow: 1px 1px 2px #0d4134;
  cursor: pointer;
  white-space: nowrap;
  padding: 30px;
  img {
    width: 1.5rem;
    margin-right: 1rem;
  }
  &:hover {
    background: #0d4134;
    color: #fee615;
  }
  &:active {
    background: white;
  }
  &:focus {
    background: #0d4134;
    color: #fee615;
  }
`;
