import { useEffect, useState, Fragment } from "react";
import {
  MenuOutlined,
  CloseOutlined,
  AppstoreOutlined,
  SwapOutlined,
  ShopOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  ExperimentOutlined,
} from "@ant-design/icons";
import { Link, NavLink } from "react-router-dom";

import useMonstaAuth from "../../hooks/useMonstaAuth";
import useCloneChamber from "../../hooks/useCloneChamber";
import monstaLogo from "../../assets/images/monsta-logo.png";
import connectIcon from "../../assets/images/connect-icon.png";

import {
  NavbarContainer,
  NavbarLeftItem,
  NavbarRightItem,
  NavbarMiddleItem,
  NavList,
  BrandImage,
  MobileMenuToggleButton,
  ConnectWalletButton,
  ConnectWalletIcon,
  StyledDrawer,
  MobileMenuCloseButton,
  MobileMenuImageContainer,
  MobileMenuFooterContainer,
  MobileMenuNavList,
} from "./style";

const Navbar = () => {
  const { account, active, connect, disconnect, refresh } = useMonstaAuth();
  const { clearMonstasChamber } = useCloneChamber();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const MENU_ITEMS = [
    {
      key: "/dashboard",
      label: "Dashboard",
      path: "/dashboard",
      icon: <AppstoreOutlined />,
    },
    {
      key: "/marketplace",
      label: "Marketplace",
      path: "/marketplace",
      icon: <ShopOutlined />,
    },
    { key: "/swap", label: "Exchange", path: "/swap", icon: <SwapOutlined /> },
    { key: "/", label: "Profile", path: "/", icon: <UserOutlined /> },
    {
      key: "/tapwar",
      label: "Tapwar",
      path: "/tapwar",
      icon: <ExperimentOutlined />,
    },
    {
      key: "/fortune-crate-sales",
      label: "FC Sale",
      path: "/fortune-crate-sales",
      icon: <ExperimentOutlined />,
    },
    {
      key: "/help",
      label: "Help",
      path: "https://help.monstainfinite.com/hc/en-us/categories/4418339090329-Marketplace",
      isExternalURL: true,
      icon: <QuestionCircleOutlined />,
    },
  ];

  useEffect(() => {
    clearMonstasChamber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  const mobileIsActiveStyle = {
    border: "solid 1px white",
    boxShadow:
      "0px -5px 20px rgba(24, 255, 220, 0.2), 0px -2px 20px rgba(12, 255, 211, 0.5)",
  };

  return (
    <>
      <NavbarContainer>
        <NavbarLeftItem>
          <MobileMenuToggleButton
            type="text"
            onClick={showDrawer}
            icon={<MenuOutlined />}
          />
        </NavbarLeftItem>
        <NavbarMiddleItem>
          <Link to="/">
            <BrandImage src={monstaLogo} />
          </Link>
          <NavList>
            {MENU_ITEMS.map((item) => (
              <Fragment key={item.key}>
                {item.isExternalURL ? (
                  <a
                    key={item.key}
                    href={item.path}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.label}
                  </a>
                ) : (
                  <NavLink
                    to={item.path}
                    style={({ isActive }) =>
                      isActive ? { color: "white" } : {}
                    }
                  >
                    {item.label}
                  </NavLink>
                )}
              </Fragment>
            ))}
          </NavList>
        </NavbarMiddleItem>
        <NavbarRightItem>
          <ConnectWalletButton
            shape="round"
            onClick={active ? disconnect : connect}
          >
            <ConnectWalletIcon src={connectIcon} alt="" />
            {active ? "Disconnect" : "Connect Wallet"}
          </ConnectWalletButton>
        </NavbarRightItem>
      </NavbarContainer>
      <StyledDrawer
        placement="left"
        closable={false}
        onClose={onCloseDrawer}
        visible={isDrawerVisible}
        key="left"
        footer={
          <MobileMenuFooterContainer>
            <ConnectWalletButton
              shape="round"
              block
              onClick={active ? disconnect : connect}
            >
              <ConnectWalletIcon src={connectIcon} alt="" />
              {active ? "Disconnect" : "Connect Wallet"}
            </ConnectWalletButton>
          </MobileMenuFooterContainer>
        }
      >
        <MobileMenuCloseButton
          type="text"
          onClick={onCloseDrawer}
          icon={<CloseOutlined />}
        />
        <MobileMenuImageContainer>
          <img src={monstaLogo} alt="" />
        </MobileMenuImageContainer>
        <MobileMenuNavList>
          {MENU_ITEMS.map((item) => (
            <Fragment key={item.key}>
              {item.isExternalURL ? (
                <a
                  key={item.key}
                  href={item.path}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.icon}
                  <span className="mobile-menu-text-link">{item.label}</span>
                </a>
              ) : (
                <NavLink
                  to={item.path}
                  onClick={onCloseDrawer}
                  style={({ isActive }) =>
                    isActive ? mobileIsActiveStyle : {}
                  }
                >
                  {item.icon}
                  <span className="mobile-menu-text-link">{item.label}</span>
                </NavLink>
              )}
            </Fragment>
          ))}
        </MobileMenuNavList>
      </StyledDrawer>
    </>
  );
};

export default Navbar;
