import { Col } from "antd";
import {
  BaseText,
  CenteredRow,
  FixedHeightCol,
  ModalButton,
  ModalButtonCol,
  PurchaseCompletedImageHolder,
} from "../style";
import PurchaseCompletedImage from "../../../assets/images/fc_sale/purchase_completed_image.png";

type PurchaseCompletedScreenProps = {
  onCancel: () => void;
};

const PurchaseCompletedScreen = ({
  onCancel,
}: PurchaseCompletedScreenProps) => {
  return (
    <>
      <CenteredRow gutter={[8, 20]}>
        <FixedHeightCol height="70px">
          <PurchaseCompletedImageHolder>
            <img src={PurchaseCompletedImage} alt="Completed" />
          </PurchaseCompletedImageHolder>
        </FixedHeightCol>
        <Col md={18} sm={18}>
          <BaseText display="block" fontSize="36px">
            Purchase Completed!
          </BaseText>
        </Col>
        <Col md={18} sm={22}>
          <BaseText>
            Your purchase is successful! Do check your game account and good
            luck earning with your Fortune Crates!
          </BaseText>
        </Col>
        <ModalButtonCol span={20}>
          <ModalButton onClick={onCancel}>Back To Homepage</ModalButton>
        </ModalButtonCol>
      </CenteredRow>
    </>
  );
};
export default PurchaseCompletedScreen;
