import { Image } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import { ChamberButton, ChamberImageWrapper } from "./style";
import chamberCroppedImg from "../../../../assets/images/chamber-cropped.png";

type CloneChamberButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled: boolean;
};

const CloneChamberButton = ({ onClick, disabled }: CloneChamberButtonProps) => {
  return (
    <ChamberButton
      onClick={onClick}
      shape="round"
      size="large"
      disabled={disabled}
      block
    >
      <PlusCircleFilled />
      Cloning Chamber
      <ChamberImageWrapper>
        <Image src={chamberCroppedImg} preview={false} />
      </ChamberImageWrapper>
    </ChamberButton>
  );
};

export default CloneChamberButton;
