import { BundleCatalog, BundleType } from "../../../hooks/usePurchase";
import {
  PackageContainer,
  PackagePrice,
  PackageQuantity,
  PackageTopBar,
} from "../style";

type FCPackageProps = {
  title?: string;
  topColor?: string;
  topIcon?: string;
  defaultBg: string;
  selectedBg: string;
  bundleDetail: BundleCatalog;
  selected?: boolean;
  onClick?: () => void;
};
const FCPackage = ({
  title,
  topColor,
  topIcon,
  defaultBg,
  selectedBg,
  bundleDetail,
  selected,
  onClick,
}: FCPackageProps) => {
  const { price, quantity, quota, type } = bundleDetail;

  return (
    <div onClick={onClick} className="package-wrapper">
      {topColor && (
        <PackageTopBar color={topColor}>
          <img src={topIcon} alt="" />
          {title}
        </PackageTopBar>
      )}
      <PackageContainer
        bgImage={defaultBg}
        bgHoverImage={selectedBg}
        selected={selected}
      >
        <PackageQuantity>
          {quantity}
          {type === BundleType.MONTHLY_PASS && !!quota && quota > 0 && (
            <>
              <span className="symbol-x">x</span>
              {quota}
            </>
          )}
        </PackageQuantity>
        <PackagePrice>{price} BUSD</PackagePrice>
      </PackageContainer>
    </div>
  );
};
export default FCPackage;
