import styled from "@emotion/styled";
import { Button as AntdButton, Row } from "antd";
import { eggFadeOut, monstaFadeIn, flashFadeInOut } from "./animations";

export const HatchViewContent = styled(Row)`
  height: 100%;
  overflow: hidden;
  margin-top: 10vh;
  font-size: 18px;
  .content-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .hatch-id-text {
      font-weight: bold;
      font-size: 18px;
    }
    .images-wrapper {
      position: relative;
      min-height: 60vh;
      display: flex;
      align-items: center;
    }
  }
`;

export const HatchImg = styled.img(
  {
    position: "relative",
    width: "30vh",
    opacity: "1",
  },
  ({ startAnimation }: any) =>
    startAnimation && {
      transform: "scale(1.6)",
      animation: `${eggFadeOut} 1s linear 2.2s forwards;`,
    }
);

export const MonstaImg = styled.img(
  {
    width: "30vh",
    position: "absolute",
    left: "0",
    top: "50%",
    opacity: "0",
    transform: "translateY(-50%)",
  },
  ({ startAnimation }: any) =>
    startAnimation && { animation: `${monstaFadeIn} 2s ease 3s forwards;` }
);

export const FlashImg = styled.img(
  {
    width: "30vh",
    position: "absolute",
    left: "0",
    top: "15vh",
    opacity: "0",
  },
  ({ startAnimation }: any) =>
    startAnimation && { animation: `${flashFadeInOut} 2s ease 2s;` }
);

export const Button = styled(AntdButton)`
  background: #fee615 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 65px;
  border: 0px;
  max-width: 250px;
  width: 100%;
  color: #000000;
  font-size: 1.2rem;
  font-weight: bold;
  height: 45px;
  &:hover,
  &:focus {
    background: #fee615 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    color: #000000;
  }
`;
