import addresses from "../config/constants/contracts";
import { Address } from "../config/constants/types";
import { config } from "../config";

export const getAddress = (address: Address): string => {
  const chainId = config.app.REACT_APP_CHAIN_ID as unknown as "1000" | "1001";
  return address[chainId] ? address[chainId] : address[1001];
};

export const getMoniAddress = () => {
  return getAddress(addresses.Moni);
};

export const getSttAddress = (): string => {
  return getAddress(addresses.STT);
};

export const getImeggAddress = () => {
  return getAddress(addresses.Imegg);
};

export const getImDistributorAddress = () => {
  return getAddress(addresses.ImeggDistributor);
};

export const getMonstaNFTAddress = () => {
  return getAddress(addresses.MonstaNFT);
};

export const getMarketplaceExchangeAddress = () => {
  return getAddress(addresses.MarketplaceExchange);
};

export const getMonstaAuctionAdminAddress = () => {
  return getAddress(addresses.MonstaAuctionAdmin);
};

export const getMonstaAuctionUserAddress = () => {
  return getAddress(addresses.MonstaAuctionUser);
};

export const getSynthesisAddress = () => {
  return getAddress(addresses.Synthesis);
};

export const getTapwarAddress = () => {
  return getAddress(addresses.Tapwar);
};
