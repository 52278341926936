/* eslint-disable no-param-reassign */
import { Currency, ETHER } from "@pancakeswap/sdk";
import { useToken } from "./useToken";

export function useCurrency(
  currencyId: string | undefined
): Currency | undefined {
  const isBNB = currencyId ? currencyId.toUpperCase() === "BNB" : false;
  const token = useToken(isBNB ? undefined : currencyId);
  return isBNB ? ETHER : token;
}
