import { WarningOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Divider, Row, Typography, Button, Checkbox, Modal, Col } from "antd";
import { ModalScreen } from ".";
import ContentBG from "../../assets/images/fc_sale/yellow_bg.png";
const { Text, Link } = Typography;

export const PageLoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000a3;
`;

export const FcSaleWrapper = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
`;
type FcSaleCardProps = {
  bg: string;
};
export const FcSaleCard = styled.div<FcSaleCardProps>`
  background: ${(props) => `url(${props.bg}) no-repeat top center`};
  background-size: 110% 100%;
  max-width: 850px;
  border-radius: 20px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 30px;
  margin-bottom: 20px;

  @media screen and (max-width: 576px) {
    width: 95%;
    background-size: cover;
  }
`;

export const LogoRow = styled(Row)`
  max-height: 50px;
`;

export const TapwarLogo = styled.img`
  max-width: 300px;
  margin-top: -85px;
`;

export const FcSaleBaseBtn = styled(Button)`
  width: 95%;
  height: 40px;
  margin-bottom: 2px;
  background: #fee615;
  border-radius: 35px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  border: none;
  &:hover {
    background: #fee615;
    opacity: 0.8;
    color: #000000;
  }
  &:active {
    opacity: 0.9;
    transform: translateY(1px);
    box-shadow: none;
  }
  &:focus {
    background: #fee615;
    color: #000000;
  }
  &:disabled {
    background: #fee615;
    color: #000000;
    opacity: 0.7;
    &:hover {
      background: #fee615;
      color: #000000;
    }
    &:active {
      opacity: 0.7;
      transform: translateY(1px);
      box-shadow: none;
    }
    &:focus {
      background: #fee615;
      opacity: 0.7;
      color: #000000;
    }
  }
`;
export const ActiveBtn = styled(FcSaleBaseBtn)`
  background: #fee615;
`;
export const SoldOutBtn = styled(FcSaleBaseBtn)`
  text-transform: uppercase;
  background: #30413c;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

type BaseTextProps = {
  color?: string;
  fontSize?: string;
  display?: string;
};
export const BaseText = styled(Text)<BaseTextProps>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  color: ${({ color }) => (color ? color : "#ffffff")};
  display: ${({ display }) => (display ? display : "inline")};
`;
export const PaymentText = styled(BaseText)`
  font-size: 36px;
  display: block;
  @media screen and (max-width: 768px) {
    font-size: 1.9em;
  }
`;

export const FcSaleSlogan = styled(BaseText)`
  font-family: "Poppins";
  font-weight: 700;
  font-size: 48px;
  color: #47fee8;
`;

export const SloganSpan = styled.span`
  color: #ffffff;
`;

export const PaymentWarning = styled.div`
  background: #204234;
  padding: 6px 20px;
  border-radius: 16px;
  line-height: 1.5rem;
`;

export const WarningTextSpan = styled.span`
  color: #f5952d;
`;

export const WarningIcon = styled(WarningOutlined)`
  color: #f5952d;
`;

export const ConfirmModalContentText = styled(BaseText)`
  font-size: 0.875em;
  text-align: center;
`;

export const CountDownBox = styled.div`
  height: 160px;
  max-width: 625px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`;

export const CountDownBoxItem = styled.div`
  min-width: 250px;
  text-align: center;
`;

export const CountDownBoxDivider = styled(Divider)`
  height: 75px;
  border-left: 1px solid #ffffff;
  @media screen and (max-width: 576px) {
    display: none;
  }
`;
// Timer
export const TextWrapper = styled(Row)`
  text-align: "center";
  width: 100%;
`;

export const TimerTimeText = styled(Text)`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  width: 100%;
`;

export const TimerTimeUnitText = styled(Text)`
  color: white;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  opacity: 0.6;
  width: 100%;
`;

export const PackageRow = styled(Row)`
  margin-top: 30px;
  margin-bottom: 20px;
  .package-wrapper {
    cursor: pointer;
  }
`;
// FC Package Component
type PackageContainerProps = {
  bgImage: string;
  bgHoverImage: string;
  selected?: boolean;
};
export const PackageContainer = styled.div<PackageContainerProps>`
  background: ${(props) =>
    `url(${
      props.selected ? props.bgHoverImage : props.bgImage
    }) no-repeat top center`};
  background-size: contain;
  height: 315px;
  width: 205px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  -webkit-transition: background 0.2s linear, height 0.2s linear;
  -moz-transition: background 0.2s linear, height 0.2s linear;
  -ms-transition: background 0.2s linear, height 0.2s linear;
  -o-transition: background 0.2s linear, height 0.2s linear;
  transition: background 0.2s linear, height 0.2s linear;
  &:hover {
    transform: scale(1.03);
  }
`;
type PackageTopBarProps = {
  color: string;
};
export const PackageTopBar = styled.div<PackageTopBarProps>`
  color: ${(props) => props.color};
  border-bottom: 2px solid ${(props) => props.color};
  margin: 0 15px 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  img {
    width: 30px;
    margin-right: 10px;
  }
`;
export const PackageQuantity = styled.div`
  height: 85px;
  width: 185px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Bowlby One";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 50px;
  .symbol-x {
    font-size: 16px;
    margin: 0 2px -10px;
  }
`;
export const PackagePrice = styled.div`
  height: 55px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #f6b62f 0%, #f3792a 100%);
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 0px #352b1f;
  border-radius: 35px;
  font-family: "Bowlby One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  position: relative;
  top: -10px;
`;
type MiniStockBarProps = {
  textColor: string;
};

export const MiniStockBar = styled.div<MiniStockBarProps>`
  width: 160px;
  margin-left: 15px;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.textColor};
  p {
    margin: -0.3em 0 0.5em 0;
  }
`;

export const TCCheckbox = styled(Checkbox)`
  margin-left: 20px;
  margin-bottom: 20px;
  .ant-checkbox-checked .ant-checkbox-inner {
    color: #ffffff;
    border-color: #ffffff;
    background: transparent;
  }
`;

export const TCLink = styled(Link)`
  color: #47fee8 !important;
  text-decoration: underline !important;
`;

export const StyledConfirmModal = styled(Modal)`
  .ant-modal-content {
    background: linear-gradient(
        180deg,
        #bffffd 0%,
        #25b77e 3.7%,
        #38745c 20.1%,
        #30644f 31.5%,
        #193429 100%
      ),
      #ffffff;
    box-shadow: 0px 3px 23px rgba(0, 49, 35, 0.51);
    border-radius: 30px;
    .ant-modal-body {
      padding: 0 24px;
    }
    @media screen and (max-width: 767px) {
      .ant-modal-body {
        margin-top: 170px;
      }
    }
  }
`;
export const ConfirmModalPackageWrapper = styled.div`
  display: flex;
  justify-content: center;
  transform: rotate(7deg);
  margin: 40px auto;
  @media (min-width: 768px) {
    margin: -40px 0;
  }

  .package-wrapper {
    position: relative;
  }

  @media screen and (max-width: 767px) {
    .package-wrapper {
      position: absolute;
      top: -220px;
    }
    padding-bottom: 15px;
    margin-bottom: 55px;
  }
`;
export const ConfirmModalText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 160%;
  margin: 20px auto;
  @media (min-width: 768px) {
    font-size: 36px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 375px) {
    font-size: 28px;
  }
`;
export const ConfirmModalOkButton = styled(Button)`
  &,
  &:hover {
    width: 100%;
    height: auto;
    background: linear-gradient(180deg, #34f22e 0%, #008809 100%);
    box-shadow: 0px 4px 0px #02490d;
    border-radius: 10px;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 40px;
  }
`;
export const ConfirmModalNoButton = styled(Button)`
  width: 100%;
  height: auto;
  background: linear-gradient(0deg, #23362f, #23362f),
    linear-gradient(180deg, #36f62f 0%, #008809 100%);
  border: 1px solid #3e7762;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.161),
    0px 3px 23px rgba(0, 49, 35, 0.51);
  border-radius: 10px;
  font-weight: 400;
  font-size: 20px;
`;

// Purchase Modal
type StyledPurchaseModalProps = {
  screen: ModalScreen;
};
export const StyledPurchaseModal = styled(Modal)<StyledPurchaseModalProps>`
  .ant-modal {
    max-width: 928px;
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    width: 100vw;
    max-width: 928px;
    margin-top: 150px;
  }
  .ant-modal-content {
    border-radius: 20px;
    background: ${({ screen }) =>
      screen === "failed" ? "#30413C" : "#11644f"};

    .ant-modal-close {
      left: 0;
      right: auto;

      .ant-modal-close-x {
        background-color: #202020;
        width: 46px;
        height: 46px;
        border: 0px;
        border-radius: 0 20px 20px 0;
        margin-top: 22px;
        cursor: pointer;

        > img {
          vertical-align: baseline;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 100px;
  }
`;
export const CenteredRow = styled(Row)`
  justify-content: center;
  text-align: center;
`;
type FiexdHeightColProps = {
  height?: string;
};
export const FixedHeightCol = styled(Col)<FiexdHeightColProps>`
  height: ${({ height }) => (height ? height : "100px")};
`;
export const ModalButton = styled(ActiveBtn)`
  width: 100%;
  height: 55px;
`;
export const ModalButtonCol = styled(Col)`
  margin-top: 30px;
  margin-bottom: 30px;
`;
export const SquareTopHolder = styled.div`
  height: 250px;
  > img {
    position: relative;
    height: 220px;
    top: -150px;
  }
  &.qr-svg-wrapper {
    width: fit-content;
    margin: -140px auto;
    padding: 10px;
    border: 5px solid #26503f;
    border-radius: 15px;
    height: auto;
    background: white;
  }
`;
export const FortuneDetailsContainer = styled.div`
  background: #30413c;
  border-radius: 20px;
  padding: 10px;
`;
export const FortuneDetailsItem = styled.div`
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  &.currency-row {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .anticon-copy svg {
    margin-left: 15px;
    color: #fee615;
    font-size: 20px;
  }
`;
export const AddressHolder = styled.div`
  max-width: 95%;
`;
export const FortuneDetailsStatusLabel = styled.span`
  background: #fee615;
  margin-left: 15px;
  padding: 5px 10px;
  border-radius: 50px;
  color: #11644f;
  text-transform: capitalize;
`;

// Monthly Package

export const MonthlyPassWrapper = styled.div`
  max-width: 625px;
  margin-bottom: 30px;
`;
export const MonthlyPassCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  border: 3px solid #204234;
  border-radius: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.01);
  }
`;
export const MonthlyPassTitleWrapper = styled.div`
  width: 100%;
  height: 45px;
  border-radius: 15px 15px 0px 0px;
  padding-top: 0.5rem;
  text-align: center;
  background: #204234;
  position: relative;
  @media screen and (max-width: 576px) {
    padding-left: 0.5rem;
    text-align: left;
  }
`;

export const MonthlyPassTitleText = styled(Text)`
  font-family: Bowlby One;
  font-size: 1.25rem;
  font-weight: 400;
  text-align: center;
  @media screen and (max-width: 576px) {
    font-size: 1rem;
  }
  @media screen and (max-width: 325px) {
    font-size: 0.875rem;
  }
`;

export const MonthlyPassContentWrapper = styled.div`
  width: 100%;
  height: 150px;
  background: url(${ContentBG}) no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > img {
    height: 150px;
    position: absolute;
    left: 5px;
    top: -25px;
  }

  @media screen and (max-width: 576px) {
    height: 110px;
    > img {
      height: 110px;
      position: absolute;
      left: 0px;
      top: -15px;
    }
  }

  @media screen and (max-width: 325px) {
    height: 100px;
    > img {
      height: 90px;
      position: absolute;
      left: 0px;
      top: -5px;
    }
  }
`;

export const MonthlyPassContent = styled.div`
  width: 80%;
  text-align: center;
  @media screen and (max-width: 576px) {
    width: calc(100% - 125px);
    text-align: center;
    padding-right: 0.8rem;
  }

  @media screen and (max-width: 325px) {
    width: calc(100% - 100px);
    padding-right: 0.5rem;
  }
`;

export const MonthlyPassContentTitle = styled(MonthlyPassTitleText)`
  color: #204234;
  display: block;
  @media screen and (max-width: 576px) {
    font-size: 1.15rem;
  }
  @media screen and (max-width: 325px) {
    font-size: 0.875rem;
  }
`;

export const MonthlyPassContentText = styled(BaseText)`
  font-weight: 700;
  color: #204234;
  @media screen and (max-width: 576px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 325px) {
    font-size: 0.75rem;
  }
`;

export const MonthlyPassConditionWrapper = styled.div`
  width: 100%;
  background: #204234;
  text-align: left;
  padding: 1rem;
  border-radius: 0px 0px 15px 15px;
`;

export const MonthlyPassAltText = styled.span`
  color: #f4872b;
`;

export const BottomLinkRow = styled(Row)`
  margin: 40px 0;
`;
type BottomLinkProps = {
  background: string;
  textColor: string;
  padding?: string;
};
// Purchase Status Modals
export const PurchaseCompletedImageHolder = styled.div`
  width: 100%;
  max-width: 350px;
  margin-top: -50%;

  > img {
    position: relative;
    height: 100%;
    margin-left: 25px;
  }
  @media screen and (max-width: 576px) {
    height: 250px;
    max-width: 350px;
    > img {
      margin-left: 25px;
    }
  }
  @media screen and (max-width: 350px) {
    height: 220px;
  }
`;

export const BottomBanner = styled.img`
  width: 100%;
`;
