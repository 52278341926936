import BigNumber from "bignumber.js";
import React from "react";
import { Currency, Pair } from "@pancakeswap/sdk";
import { useLazyQuery } from "@apollo/client";
import useActiveWeb3React from "../../../../hooks/useActiveWeb3React";
import {
  InputRow,
  LabelRow,
  InputPanel,
  CurrencyContainer,
  RowBetween,
  Label,
  BalanceLabel,
  MaxButton,
  TokenIcon,
  TokenName,
} from "../../style";
import { tokenClient } from "../../../../graphql/apolloClient";
import {
  GetAccountQuery,
  GetAccountQueryVariables,
} from "../../../../graphql/generated-types";
import { GET_ACCOUNT } from "../../../../graphql/queries/erc20";
import { BIG_ZERO } from "../../../../utils/bigNumber";
import { Input as NumericalInput } from "./NumericalInput";
import MoniIcon from "../../../../assets/images/Moni.png";
import XsttIcon from "../../../../assets/images/xstt-icon.svg";
import useRefresh from "../../../../hooks/useRefresh";
import { getSttAddress } from "../../../../utils/addressHelpers";

interface CurrencyInputPanelProps {
  value: string;
  onUserInput: (value: string) => void;
  onMax?: () => void;
  showMaxButton: boolean;
  label?: string;
  onCurrencySelect?: (currency: Currency) => void;
  currency?: Currency | null;
  disableCurrencySelect?: boolean;
  hideBalance?: boolean;
  pair?: Pair | null;
  hideInput?: boolean;
  otherCurrency?: Currency | null;
  id: string;
  showCommonBases?: boolean;
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  currency,
  hideInput = false,
  id,
}: CurrencyInputPanelProps) {
  const { account } = useActiveWeb3React();
  const [selectedCurrencyBalance, setSelectedCurrencyBalance] =
    React.useState(BIG_ZERO);

  const [fetchBalance] = useLazyQuery<
    GetAccountQuery,
    GetAccountQueryVariables
  >(GET_ACCOUNT, {
    variables: {
      id: account?.toLowerCase() || "",
    },
    onCompleted: (data) => {
      let val = BIG_ZERO;
      if (data && data.account) {
        const walletData = data.account.ERC20balances;

        walletData.some((balance) => {
          if (
            balance.contract.symbol === currency?.symbol ||
            `x${balance.contract.symbol}` === currency?.symbol ||
            balance.contract.symbol === `x${currency?.symbol}`
          ) {
            val = new BigNumber(balance.valueExact);
            setSelectedCurrencyBalance(val);
            return true;
          }
          return false;
        });
      }
    },
    client: tokenClient,
    fetchPolicy: "no-cache",
  });
  const { slowRefresh } = useRefresh();
  React.useMemo(() => fetchBalance(), [fetchBalance, account, slowRefresh]);

  const handleMaxInput = () => {
    if (currency?.symbol && selectedCurrencyBalance) {
      const balance = selectedCurrencyBalance.div(1e18).toString();
      onUserInput(balance);
    }
  };

  return (
    <InputPanel id={id}>
      <CurrencyContainer hideInput={hideInput}>
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              <Label>
                {/* {translatedLabel} */}
                {label}
              </Label>
              {account && (
                <BalanceLabel>
                  {true ? "Balance: " : " -"}
                  {currency &&
                    ((currency as any).address === getSttAddress() ||
                    currency?.symbol === ""
                      ? parseFloat(selectedCurrencyBalance.toFixed()) / 1e8
                      : parseFloat(selectedCurrencyBalance.toFixed()) / 1e18 ??
                        "Loading")}
                  {/* Need change */}
                </BalanceLabel>
              )}
            </RowBetween>
          </LabelRow>
        )}
        <InputRow selected={true}>
          {!hideInput && (
            <>
              <NumericalInput
                className="token-amount-input"
                value={value}
                onUserInput={(val) => {
                  onUserInput(val);
                }}
              />
              {account && currency && showMaxButton && label !== "To" && (
                <MaxButton onClick={handleMaxInput} type="text" size="small">
                  MAX
                </MaxButton>
              )}
            </>
          )}

          <TokenIcon src={label !== "To" ? MoniIcon : XsttIcon} />
          <TokenName>{label !== "To" ? "xMONI" : "xSTT"}</TokenName>
        </InputRow>
      </CurrencyContainer>
    </InputPanel>
  );
}
