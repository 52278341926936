import styled from "@emotion/styled";
import { Button, Drawer } from "antd";

export const NavbarContainer = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavbarLeftItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  @media screen and (min-width: 992px) {
    display: none;
  }
`;

export const NavbarMiddleItem = styled.div`
  height: 64px;
  display: flex;
`;

export const NavbarRightItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const MobileMenuToggleButton = styled(Button)``;

export const BrandImage = styled.img`
  height: 70px;
  transform: translateY(4px);
  padding: 0;
  margin: 0;
`;

export const NavList = styled.div`
  display: flex;
  gap: 2.4rem;
  margin-left: 3rem;

  a {
    color: #7a7a7a;

    &:hover {
      color: white;
    }
  }

  @media screen and (max-width: 1150px) {
    gap: 1rem;
    margin-left: 1.5rem;
  }

  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const ConnectWalletButton = styled(Button)`
  background: black;
  font-family: ${(props) => props.theme.fontFamily.fourth};
  @media screen and (max-width: 379px) {
    font-size: 12px;
  }
`;
export const ConnectWalletIcon = styled.img`
  width: 1rem;
  margin-right: 0.5rem;
  margin-bottom: 2px;
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    background: black;
  }

  .ant-drawer-footer {
    background: black;
    border-top: none;
  }
`;

export const MobileMenuCloseButton = styled(Button)`
  float: right;
`;

export const MobileMenuImageContainer = styled.div`
  text-align: center;
  margin-bottom: 1rem;
`;

export const MobileMenuFooterContainer = styled.div`
  border-top: solid 1px #444;
  padding: 1rem 0rem 0.5rem 0rem;
`;

export const MobileMenuNavList = styled.div`
  display: flex;
  flex-direction: column;

  a {
    padding: 1rem;
    border-radius: 5px;
    color: white;
    margin: 0.3rem 0rem;
  }

  .mobile-menu-text-link {
    margin-left: 1rem;
  }
`;
