import {
  MonstaDisplayWrapper,
  MonstaDisplayImage,
  MonstaDisplayCloneUsageContainer,
  MonstaDisplayIdContainer,
} from "./style";

type MonstaDisplayProps = {
  image: string;
  cloneUsage: number;
  id: string;
  isDark?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const MonstaDisplay = (props: MonstaDisplayProps) => {
  const { image, cloneUsage, id, isDark = false } = props;

  return (
    <MonstaDisplayWrapper onClick={props.onClick}>
      <MonstaDisplayImage src={image} alt="Monsta" />
      <MonstaDisplayCloneUsageContainer isDark={isDark}>
        <p>Clone Usage {cloneUsage}/4</p>
      </MonstaDisplayCloneUsageContainer>
      <MonstaDisplayIdContainer>
        <p>#{id}</p>
      </MonstaDisplayIdContainer>
    </MonstaDisplayWrapper>
  );
};

export default MonstaDisplay;
