import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useQuery } from "@apollo/client";
import {
  GetAuctionMonstaQuery,
  GetAuctionMonstaQueryVariables,
} from "../../graphql/generated-types";
import { MonstaInfoType, MonstaType } from "../../models/monsta";
import { GET_AUTION_MONSTA } from "../../graphql/queries/marketplace";
import { marketplaceClient } from "../../graphql/apolloClient";
import { useMonstaPreviewApi } from "../../hooks/useMonsta";
import useCloneChamber, {
  CloneChamberStatus,
} from "../../hooks/useCloneChamber";
import { parseSvg } from "../../utils/parseSvg";
import { toBinaryGenes } from "../../utils/toBinaryGenes";
import { extractMonsta } from "../../utils/monstaHelpers";
import { getImDistributorAddress } from "../../utils/addressHelpers";
import CloneChamberFloating from "../../components/CloneChamberFloating";
import MonstaCard from "./components/MonstaCard";
import MainCard from "./components/MainCard";
import CloneCard from "./components/CloneCard";
import SellCard from "./components/SellCard";
import TransferCard from "./components/TransferCard";
import BuyCard from "./components/BuyCard";
import TradeCardLayout from "./components/MainCard/TradeCardLayout";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";

type Screen = "monsta" | "clone" | "sell" | "transfer" | "buy";

const Monsta = () => {
  const { monstaId = "0" } = useParams();
  const { account } = useWeb3React();
  const navigate = useNavigate();
  const location = useLocation();
  const { monstas: chamberMonstas, status: chamberStatus } = useCloneChamber();
  const initialState = location.state || ({} as any);

  const [screen, setScreen] = useState<Screen>("monsta");
  const { preview } = useMonstaPreviewApi();
  const [monstaSvg, setMonstaSvg] = useState("");
  const [baseChamberId, setBaseChamberId] = useState(0); // index of chamberMonstas, to be cloned as base
  const [secondaryChamberId, setSecondaryChamberId] = useState(1); // index of chamberMonstas, to be cloned as secondary
  const [fromCloneChamber, setFromCloneChamber] = useState(
    !!initialState.fromCloneChamber
  );
  const sendDataToGTM = useGTMDispatch();

  const { loading: monstaLoading, data: monstaData } = useQuery<
    GetAuctionMonstaQuery,
    GetAuctionMonstaQueryVariables
  >(GET_AUTION_MONSTA, {
    variables: {
      id: monstaId,
    },
    client: marketplaceClient,
  });
  const [monstaInfo, setMonstaInfo] = useState<MonstaInfoType>({
    id: 0,
    cloneUsage: 0,
    isAdult: 0,
    tradable: false,
    transferable: false,
    timeLeft: null,
    classType: 0,
  });

  useEffect(() => {
    const _tmpId = chamberMonstas.findIndex(
      (e) => e?.id.toString() === monstaId
    );
    const _baseChamberId = _tmpId >= 0 ? _tmpId : 0;
    const _secondaryChamberId = chamberMonstas.findIndex(
      (e, id) => id !== _baseChamberId
    );
    setBaseChamberId(_baseChamberId);
    setSecondaryChamberId(_secondaryChamberId);
    if (fromCloneChamber && chamberStatus === CloneChamberStatus.FULL) {
      setFromCloneChamber(false);
      setScreen("clone");
    }
  }, [fromCloneChamber, chamberStatus, chamberMonstas, monstaId]);

  useEffect(() => {
    const parseMonstaSvg = async () => {
      if (monstaData && monstaData.monsta) {
        const monstaGene = monstaData.monsta.genes;
        const monstaGeneBin = toBinaryGenes(monstaGene);
        const monstaInfo = extractMonsta(monstaData.monsta);
        // redirect to previous page if monsta is not adult
        if (!monstaInfo.isAdult) navigate("/");
        // redirect to previous page if monsta is inception egg
        if (
          monstaData.monsta.owner.toLowerCase() ===
          getImDistributorAddress().toLowerCase()
        ) {
          navigate("/");
        }
        setMonstaInfo(monstaInfo);
        const monstaSvg = await preview(monstaGeneBin);
        setMonstaSvg(parseSvg(monstaSvg));
      }
    };
    if (monstaData) {
      parseMonstaSvg();
    }
  }, [monstaData, preview]);

  const handleNavigateToPrevPage = () => {
    const { prevPath, ...state } = location.state || ({} as any);
    if (prevPath === "hatch" || location.key === "default") {
      navigate("/");
    } else if (prevPath?.includes("/marketplace")) {
      navigate(prevPath, { state });
    } else if (prevPath === "profile") {
      navigate("/", { state });
    } else {
      navigate(-1);
    }
  };

  const handleSwitchToMonstaScreen = () => {
    setScreen("monsta");
  };

  const handleSwitchToCloneScreen = () => {
    sendDataToGTM({
      event: "gtm.loadClick",
      gtm: {
        "gtm.elementText": "Clone",
      },
    });
    setScreen("clone");
  };

  const handleSwitchToSellScreen = () => {
    sendDataToGTM({
      event: "gtm.loadClick",
      gtm: {
        "gtm.elementText": "Sell",
      },
    });
    setScreen("sell");
  };

  const handleSwitchToTransferScreen = () => {
    setScreen("transfer");
  };

  const handleSwitchToBuyScreen = () => {
    sendDataToGTM({
      event: "gtm.loadClick",
      "gtm.elementText": "Buy Now",
    });
    setScreen("buy");
  };

  if (screen === "monsta") {
    return (
      <MainCard onClose={handleNavigateToPrevPage}>
        <MonstaCard
          monstaId={monstaId}
          monstaLoading={monstaLoading}
          monstaData={monstaData}
          monstaSvg={monstaSvg}
          onClickSell={handleSwitchToSellScreen}
          onClickClone={handleSwitchToCloneScreen}
          onClickTransfer={handleSwitchToTransferScreen}
          onClickBuy={handleSwitchToBuyScreen}
        />
        <CloneChamberFloating onClickGo={handleSwitchToCloneScreen} />
      </MainCard>
    );
  } else if (screen === "clone") {
    return (
      <MainCard onClose={handleSwitchToMonstaScreen}>
        <CloneCard
          baseMonsta={chamberMonstas[baseChamberId] || ({} as MonstaType)}
          selectedMonsta={chamberMonstas[secondaryChamberId] || null}
          secondaryChamberId={secondaryChamberId}
        />
        <CloneChamberFloating
          onClickGo={handleSwitchToCloneScreen}
          onRemoveMonstaChamber={(key: number) => {
            key === baseChamberId && setScreen("monsta");
          }}
        />
      </MainCard>
    );
  } else {
    let cardTitle = "BUY MONSTA";
    let cardContent = <BuyCard monstaData={monstaData} />;

    if (screen === "sell") {
      cardTitle = "SELL MONSTA";
      cardContent = <SellCard monsta={monstaInfo} />;
    } else if (screen === "transfer") {
      cardTitle = "TRANSFER MONSTA";
      cardContent = <TransferCard monsta={monstaInfo} />;
    }

    return (
      <MainCard onClose={handleSwitchToMonstaScreen}>
        <TradeCardLayout
          title={cardTitle}
          monstaLoading={monstaLoading}
          monstaInfo={monstaInfo}
          monstaData={monstaData}
          monstaSvg={monstaSvg}
        >
          {cardContent}
        </TradeCardLayout>
        <CloneChamberFloating onClickGo={handleSwitchToCloneScreen} />
      </MainCard>
    );
  }
};

export default Monsta;
