import { useLazyQuery } from "@apollo/client";
import { tokenClient } from "../graphql/apolloClient";
import {
  GetErc20ApprovalQuery,
  GetErc20ApprovalQueryVariables,
} from "../graphql/generated-types";
import { GET_ERC20_APPROVAL } from "../graphql/queries/erc20";

export const useERC20Allowance = (
  token: string,
  owner: string,
  spender: string
) => {
  let erc20ApprovalId = "";

  if (owner) {
    erc20ApprovalId = `${token.toLowerCase()}/${owner.toLowerCase()}/${spender.toLowerCase()}`;
  }

  return useLazyQuery<GetErc20ApprovalQuery, GetErc20ApprovalQueryVariables>(
    GET_ERC20_APPROVAL,
    { variables: { id: erc20ApprovalId }, client: tokenClient }
  );
};
