const addresses = {
  Moni: {
    1000: "0xb12Ba3134d569a6961429511dCa6bEa0755CA39E",
    1001: "0x7D2ea85F1dF98201289598E20778529f98E0d2Ba",
  },
  Imegg: {
    1000: "0xE29dF390c1654018884fe31259Bea8Bef118F702",
    1001: "0x3a825c7Bd7fe4811eC687EeEE4164C153c2FcD3C",
  },
  ImeggDistributor: {
    1000: "0x01818d434c0e968fF44156441027fFb06CEab2D6",
    1001: "0xdc4A83c0127F139AE1c6d1E52657829F88EF6e46",
  },
  MonstaNFT: {
    1000: "0x4c48DF9837Abd10358892c9d2a2b1C386D890d84",
    1001: "0x168b09EeaF44aEe9aB574e6F0F8e9f423835FcC7",
  },
  STT: {
    1000: "0xa5cee110eF72333E44D8aeb882B07aAB0F7175F4",
    1001: "0xae8b6389A951eF58E997bbBB886E721be5F33a0D",
  },
  MarketplaceExchange: {
    1000: "0x2a3167fc2Fc1130AAd87f13Dec763620E99E18b4",
    1001: "0x76572E74Aa5798633Bf73097eBd6Cad4C8469583",
  },
  MonstaAuctionAdmin: {
    1000: "0x3D92f207FF1cf422eC636CDE479A50CC329bCa18",
    1001: "0x8838D0946C1EFAe8E8077912bc29BC094EB8c9c3",
  },
  MonstaAuctionUser: {
    1000: "0xe75feaC8766d551C440B59810987120B3020000e",
    1001: "0x3Ae0496f97CB6A9776927986c6809ceF169f3099",
  },
  Synthesis: {
    1000: "0x799513B5DCda4C46247463FC73faD637dC8fD9c6",
    1001: "0x84F72D1a3c7f64FA262f5176A72C5b082d811F3E",
  },
  Tapwar: {
    1000: "0xf9f7ecd7adB93aD8CD4b8f6E6D83b3775D8D85E6",
    1001: "0xFeba2CC94A4D409f255DC774A62b82C405513835",
  },
};

export default addresses;
