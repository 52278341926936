import styled from "@emotion/styled";
import { Form, Button, Typography } from "antd";
const { Text } = Typography;

export const BuyMonstaInfo = styled(Text)`
  padding: 2px 0px;
  color: white;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const BuyModeText = styled(BuyMonstaInfo)`
  font-weight: 700;
`;

export const BuyForm = styled(Form)`
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .ant-input-affix-wrapper {
    background: #11644f;
    box-sizing: border-box;
    border-radius: 30px;
    border: 1px solid #ffffff;
    height: auto;
    color: white;
  }
  .ant-input-affix-wrapper {
    padding: 10px 25px 10px 15px;
  }

  .ant-input {
    background: #11644f;
    color: white;
  }
  .ant-form-item-label {
    align-self: center;
    text-align: left;
  }
`;
export const BuyButtonWrapper = styled(Form.Item)`
  display: flex;
  justify-content: right;
`;
export const BuyButton = styled(Button)`
  display: flex;
  justify-content: center;
  margin-top: 1.3rem;
  align-items: center;
  border-radius: 20px;
  color: #fee615;
  background: #5f907a;
  border: none;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  width: 100%;
  box-shadow: 2px 2px 3px #0d4134;
  cursor: pointer;
  white-space: nowrap;
  padding: 20px;
  &:hover {
    background: #0d4134;
    color: #fee615;
  }
  &:active {
    background: white;
  }
  &:focus {
    background: #0d4134;
    color: #fee615;
  }
`;
