import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import {
  CenteredRow,
  HistoryModalCloseIcon,
  HistoryTableWrapper,
  ModalSubtitle,
  ModalTable,
  ModalTitle,
  MonstaTableColumn,
  StyledModal,
} from "./style";
import Loading from "../../../../components/Loading/Loading";
import { useQuery } from "@apollo/client";
import { marketplaceClient } from "../../../../graphql/apolloClient";
import {
  AuctionSucceed_OrderBy,
  OrderDirection,
  GetAuctionSucceedsQuery,
  GetAuctionSucceedsQueryVariables,
} from "../../../../graphql/generated-types";
import { GET_AUCTION_SUCCEEDS } from "../../../../graphql/queries/marketplace";
import {
  formatBigNumberToFixed,
  formatToReadibleNumber,
} from "../../../../utils/formatBalance";

type SoldDataType = {
  key: string;
  wallet_address: string;
  stt: string;
  date: number;
};

type MonstaSalesHistoryTableType = {
  title: string;
  tableData: SoldDataType[];
};

const formatDate = (timestamp: number) => {
  const a = new Date(timestamp * 1000).toLocaleDateString();
  return a;
};

const MonstaSalesHistoryTable = ({
  title,
  tableData,
}: MonstaSalesHistoryTableType) => {
  return (
    <HistoryTableWrapper>
      <ModalSubtitle>{title}</ModalSubtitle>

      <ModalTable dataSource={tableData} pagination={false}>
        <MonstaTableColumn
          title="Wallet Address"
          dataIndex="wallet_address"
          key="wallet_address"
          ellipsis={{ showTitle: false }}
          render={(wallet_address: string) => (
            <Tooltip placement="topLeft" title={wallet_address}>
              {wallet_address}
            </Tooltip>
          )}
        />
        <MonstaTableColumn title="xSTT" dataIndex="stt" key="stt" />
        <MonstaTableColumn
          title="Date Purchased"
          dataIndex="date"
          key="date"
          render={(timestamp: number) => formatDate(timestamp)}
        />
      </ModalTable>
    </HistoryTableWrapper>
  );
};
type MonstaSalesHistoryModalType = {
  monstaId: string;
  modalVisible: boolean;
  toggleModalVisible: () => void;
};
const MonstaSalesHistoryModal = ({
  monstaId,
  modalVisible,
  toggleModalVisible,
}: MonstaSalesHistoryModalType) => {
  const [soldData, setSoldData] = useState<SoldDataType[]>([]);
  const { loading, data } = useQuery<
    GetAuctionSucceedsQuery,
    GetAuctionSucceedsQueryVariables
  >(GET_AUCTION_SUCCEEDS, {
    variables: {
      where: {
        token: monstaId,
      },
      orderBy: AuctionSucceed_OrderBy.Timestamp,
      orderDirection: OrderDirection.Desc,
    },
    client: marketplaceClient,
  });

  useEffect(() => {
    if (data) {
      const _soldData = data.auctionSucceeds.map((succeed) => {
        return {
          key: succeed.id,
          wallet_address: succeed.winner.replace("0x", "monsta:"),
          stt: formatToReadibleNumber(
            formatBigNumberToFixed(succeed.totalPrice, 0, 8)
          ),
          date: succeed.timestamp,
        };
      });
      setSoldData(_soldData);
    }
  }, [data]);

  return (
    <>
      <StyledModal
        bodyStyle={{ padding: 0 }}
        visible={modalVisible}
        onCancel={toggleModalVisible}
        footer={null}
        closeIcon={<HistoryModalCloseIcon />}
      >
        <CenteredRow>
          <ModalTitle>SALES HISTORY</ModalTitle>
        </CenteredRow>
        {loading ? (
          <Loading centered />
        ) : (
          <MonstaSalesHistoryTable title="Buyer" tableData={soldData} />
        )}
      </StyledModal>
    </>
  );
};

export default MonstaSalesHistoryModal;
