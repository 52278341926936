import { MonstaInfoType } from "../models/monsta";
import MonstaTag from "../views/Monsta/components/MonstaCard/MonstaTag";

type TagGroupProps = {
  monsta: MonstaInfoType;
};

const TagGroup = ({ monsta }: TagGroupProps): JSX.Element => {
  const monstaTypeTag = (id: number) => {
    if (id <= 4089) {
      return <MonstaTag text="Inception Monsta" color="--monsta-yellow" />;
    } else if (id >= 4090 && id <= 14099) {
      return <MonstaTag text="Chrono Zero" />;
    } else if (id >= 14100 && id <= 214099) {
      return <MonstaTag text="Soul Bond" />;
    } else {
      return <MonstaTag text="Normal" />;
    }
  };

  const renderMonstaTags = (monsta: MonstaInfoType): JSX.Element => {
    return (
      <>
        <MonstaTag
          text={monsta.transferable ? "Transferable" : "Non-transferable"}
        />
        <MonstaTag text={monsta.tradable ? "Tradable" : "Non-Tradable"} />
        {monstaTypeTag(monsta.id)}
      </>
    );
  };

  return renderMonstaTags(monsta);
};

export default TagGroup;
