import { Typography } from "antd";

const { Text } = Typography;

type EllipsisMiddleProps = {
  suffixCount: number;
  children: string;
};

const EllipsisMiddle = ({ suffixCount, children }: EllipsisMiddleProps) => {
  const start = children.slice(0, children.length - suffixCount).trim();
  const suffix = children.slice(-suffixCount).trim();
  return <Text ellipsis={{ suffix }}>{start}</Text>;
};

export default EllipsisMiddle;
