import React from "react";
import { Row } from "antd";
import { GetAuctionMonstaQuery } from "../../../../../graphql/generated-types";
import Loading from "../../../../../components/Loading/Loading";
import { MONSTA_CLASSES } from "../../../../../models/constants";
import {
  TradeCardWrapper,
  Title,
  TradeCardCol,
  MonstaName,
  MonstaImage,
  MonstaId,
} from "./style";
import { MonstaInfoType } from "../../../../../models/monsta";

type TradeCardProps = {
  title: string;
  monstaLoading: boolean;
  monstaData: GetAuctionMonstaQuery | undefined;
  monstaInfo: MonstaInfoType;
  monstaSvg: string;
  children?: React.ReactNode;
};

const TradeCardLayout = (props: TradeCardProps) => {
  const {
    title,
    monstaLoading,
    monstaData,
    monstaInfo,
    monstaSvg,
    children = <></>,
  } = props;
  if (monstaLoading) {
    return <Loading centered />;
  }
  return (
    <TradeCardWrapper>
      <Title>{title}</Title>
      <Row>
        <TradeCardCol lg={10} md={10} xs={22}>
          <MonstaName>{MONSTA_CLASSES[monstaInfo?.classType].name}</MonstaName>
          <MonstaId># {monstaData?.monsta?.id}</MonstaId>
          <MonstaImage src={monstaSvg} preview={false} />
        </TradeCardCol>
        <TradeCardCol lg={12} md={12} xs={22}>
          {children}
        </TradeCardCol>
      </Row>
    </TradeCardWrapper>
  );
};

export default TradeCardLayout;
