import { useEffect, useState } from "react";
import { MONSTA_TYPE_DISPLAY_VALUES } from "../constants";
import { MonstaTypeItem } from "../style";
import { MonstaKind } from "../";

type MonstaTypeCheckItemProps = {
  value: MonstaKind;
  checked?: boolean;
  onChange?: (value: boolean) => void;
};

const MonstaTypeCheckItem = (props: MonstaTypeCheckItemProps) => {
  const { value, onChange } = props;
  const { image, title } = MONSTA_TYPE_DISPLAY_VALUES[value];
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(!!props.checked);
  }, [props.checked]);

  useEffect(() => {
    onChange && onChange(!!checked);
  }, [checked]);

  return (
    <MonstaTypeItem checked={checked} onClick={() => setChecked(!checked)}>
      <img src={image} />
      <div className="title">{title}</div>
    </MonstaTypeItem>
  );
};

export default MonstaTypeCheckItem;
