import styled from "@emotion/styled";
import {
  Image,
  Col,
  Row,
  Typography,
  Divider,
  Modal,
  Table,
  Button,
} from "antd";
import { css } from "@emotion/react";
import Column from "antd/lib/table/Column";
import { CloseOutlined } from "@ant-design/icons";
import { Text as StyledText } from "../../../../components/Text";
const { Text, Paragraph } = Typography;

// Common
type CenteredRowStylesType = {
  space?: string;
  height?: string;
};
const centeredRowStyles = ({
  space = "auto",
  height = "auto",
}: CenteredRowStylesType) => css`
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: ${space};
  height: ${height};
`;
export const CenteredRow = styled(Row)`
  ${centeredRowStyles}
`;
export const LeftRow = styled(Row)`
  justify-content: start;
  width: 100%;
`;
// MonstaBody
export const MonstaBody = styled.div`
  background: transparent;
  border: 1px solid #464646;
  border-radius: 10px;
  column-gap: 10px;
  position: relative;
  max-width: 200px;
  padding-bottom: 10px;
`;

export const MonstaBodyLabelImage = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  left: -10px;
  top: -10px;
`;

export const MonstaBodyImage = styled.div`
  margin: 20pt auto 10pt;
  width: 80%;
`;

export const MonstaImage = styled(Image)`
  max-height: 128px;
`;

type MonstaTitleProps = {
  sm?: boolean;
};
export const MonstaTitle = styled.span<MonstaTitleProps>`
  font-size: ${(props) => (props.sm ? "1.1rem" : "1.2rem")};
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const MonstaOwnerText = styled(Text)`
  font-size: 16px;
  font-weight: 200;
  color: white;
  & .ant-typography-copy {
    color: white;
  }
`;

// Monsta tag
type MonstaTagProps = {
  color: string;
  size: string;
};
const dynamicStyleMonstaTagText = ({ color, size }: MonstaTagProps) => css`
  color: var(${color});
  text-transform: uppercase;
  border: 1px solid var(${color});
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0px 5px;
  margin-bottom: 4px;
  margin-right: 4px;
  font-size: ${size};
`;
export const MonstaTagText = styled(Text)`
  ${dynamicStyleMonstaTagText};
`;

// Monsta Card

export const MonstaCardRow = styled(Row)`
  width: 100%;
  height: 100%;
`;
export const MonstaCardLeft = styled(Col)`
  padding: 20px 20px 20px 0;
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 100%;
  @media (max-width: 768px) {
    padding: 0px 20px 40px 20px;
  }
`;
export const MonstaCardRight = styled(Col)`
  padding: 20px 20px;
  position: relative;
  background-color: #202020;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
  @media (min-width: 768px) {
    overflow-y: scroll;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #072820;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: black;
  }
`;

export const MonstaCardLeftImage = styled(Image)`
  margin: 20pt auto 10pt;
  width: 250px;
  height: auto;
`;
export const DetailsWrapper = styled(Row)`
  height: 30%;
`;
export const MonstaCardLeftDivider = styled(Divider)`
  border-left: 1px solid white;
  height: auto;
`;
export const MonstaCardLeftDetailsLabel = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #a6c0b8;
  font-weight: bold;
`;
export const MonstaLeftDetailsValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const MonstaCardLeftDetailsValue = styled.span`
  margin: 0;
  font-size: 1rem;
  color: white;
  font-weight: 200;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const MonstaCardLeftDetailsImage = styled(Image)`
  height: 1.5rem;
  width: auto;
`;
export const MonstaCardRightPrice = styled(Row)`
  justify-content: flex-end;
`;

export const MonstaCardRightPriceSTT = styled(Text)`
  color: var(--monsta-yellow);
  font-size: 2rem;
  font-weight: bolder;
`;
export const MonstaCardRightPriceSTTLabel = styled(Text)`
  color: var(--monsta-yellow);
  font-size: 1rem;
  font-weight: bolder;
`;
export const MonstaCardRightPriceUSD = styled(Text)`
  color: white;
  font-size: 2rem;
`;
export const MonstaCardRightPriceUSDLabel = styled(Text)`
  color: white;
  font-size: 1rem;
`;
export const ButtonWrapper = styled(Row)`
  margin-top: 1rem;
  width: 100%;
  gap: 1rem;
  & > button {
    flex: 1;
  }
`;
export const ChamberButton = styled(Button)`
  height: auto !important;
  font-size: 1.2rem !important;
  margin-top: 10px;
  overflow: hidden;
  &,
  &:hover,
  &:active,
  &:focus {
    background: linear-gradient(90deg, #7dffda 33.74%, #e49d65 104.31%);
    border: 1px solid #ffffff;
    color: black;
  }
  .anticon-plus-circle {
    font-size: 18px;
    vertical-align: middle;
  }
`;
export const ChamberImageWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  img {
    height: 48px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
`;
export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  @media screen and (max-width: 576px) {
    justify-content: space-around;
  }
`;

// MonstaBodyCards
export const MonstaBodyCardWrapper = styled(Row)`
  margin: 10px;
  padding: 0 10px;

  & > div {
    width: 100%;
  }

  & > div img {
    width: 100%;
    height: auto;
  }
`;

// MonstaGameCards
export const MonstaGameCardWrapper = styled(Row)`
  margin: 10px;
  padding: 0 10px;

  & > div {
    width: 100%;
  }

  & > div img {
    width: 100%;
    height: auto;
  }
`;

// MonstaStats

export const MonstaLabelWrapper = styled(Row)`
  margin-top: 28px;
  margin-bottom: 28px;
  width: 100%;
  font-family: "Obelix Pro", sans serif;

  & span {
    text-align: center;
    margin: auto;
    font-size: 1.5rem;
    font-weight: bold;
    background: -webkit-linear-gradient(white, #d1d6db);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: underline;
    text-decoration-color: white;
  }
`;
export const MonstaTableToggleText = styled(Row)`
  margin-bottom: 20px;
  width: 100%;
  color: white;

  & span {
    text-align: center;
    margin: auto;
    font-size: ${(props) => props.theme.fontSize.five};
    color: white;
    font-family: "Molot", sans serif;
    &:hover {
      cursor: pointer;
    }
  }
`;
export const MonstaRadarChartLabel = styled.text`
  font-family: "Obelix Pro";
  font-size: 0.8rem;
`;

export const MonstaStatsTableRow = styled(Row)`
  margin-left: -20px;
  margin-right: -20px;
  -moz-box-shadow: inset 0 0 30px #000000;
  -webkit-box-shadow: inset 0 0 30px #000000;
  box-shadow: inset 0 0 30px #000000;
  & .ant-col {
    font-size: 1em;
    & .ant-typography {
      font-size: 16px;
      color: white;
    }
  }
`;

export const MonstaStatsLabelRow1 = styled(Row)`
  width: 100%;
  border-bottom: 1px #464646 solid;
  padding: 20px;
`;
export const MonstaStatsLabelRow1StatsInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & img {
    width: 40px;
  }
`;

export const MonstaStatsInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MonstaStatsLabelRow1Label = styled(Text)`
  color: white;
`;
export const MonstaStatsLabelRow1Value = styled(Text)`
  font-size: 2rem;
  color: white;
`;
export const MonstaStatsLabelRow2 = styled(Row)`
  padding: 20px;
`;

export const STRLabel = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #f05353;
  width: 4rem;
  margin-bottom: 0px;
  font-weight: 600;
`;
export const AGILabel = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #5cbc7f;
  width: 4rem;
  margin-bottom: 0px;
  font-weight: 600;
`;
export const INTLabel = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #703ac2;
  width: 4rem;
  margin-bottom: 0px;
  font-weight: 600;
`;
export const StatsValue = styled.p`
  margin-bottom: 0px;
  color: white;
  font-size: 1.5rem;
`;

// GameCard
export const GameCardImage = styled(Image)`
  /* height: 100%; */
  max-width: 200px;
`;

// Monsta Parent Card
export const MonstaParent = styled(Col)`
  background: transparent;
  border: 1px solid #464646;
  border-radius: 10px;
  column-gap: 10px;
  padding: auto 0px;
  max-width: 200px;
  cursor: pointer;

  &:hover {
    border: 1px solid transparent;
    padding: 0;
    border-radius: 10px;
    background-image: linear-gradient(#202020, #202020),
      linear-gradient(to bottom, yellow, orange);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
`;
export const MonstaParentCardWrapper = styled(Row)`
  margin: 10px;
  padding: 0 10px;
  // column-gap: 1rem;
  & > div {
    width: 100%;
  }

  & > div img {
    width: 100%;
    height: auto;
  }
`;
export const ParentCrossLogo = styled(Col)`
  padding: 10px;
  height: fit-content;
  width: fit-content;
  margin: auto -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const MonstaParentCrossImage = styled.div`
  width: 4rem;
  height: 4rem;
  border: 1px solid #464646;
  background-color: #202020;
  z-index: 100;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
type MonstaPriceProps = {
  sm?: boolean;
};
export const MonstaParentPriceSTT = styled.span<MonstaPriceProps>`
  color: var(--monsta-yellow);
  font-size: ${(props) => (props.sm ? "1.1rem" : "1.2rem")};
  font-weight: bolder;
`;

export const MonstaParentPriceSTTLabel = styled(StyledText)`
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: 4px;
`;
export const MonstaParentCardLabelImage = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  right: 8px;
  top: 10px;
`;
export const OnSaleWrapper = styled.div`
  position: absolute;
  left: 8px;
  top: 10px;
`;
// Monsta Child
export const MonstaChild = styled(Col)`
  & > div {
    background: transparent;
    border: 1px solid #464646;
    border-radius: 10px;
    column-gap: 10px;
    padding: auto 0px;
    max-width: 180px;
    position: relative;
    cursor: pointer;
    &:hover {
      border: 1px solid white;
    }
    /* margin: auto; */
  }
  &:nth-of-type(odd) {
    & > div {
      max-width: 180px;
      position: relative;
      margin-left: auto;
      @media (max-width: 768px) {
        margin: auto;
      }
    }
  }
  &:nth-of-type(even) {
    & > div {
      max-width: 180px;
      position: relative;
      margin-right: auto;
      @media (max-width: 768px) {
        margin: auto;
      }
    }
  }
`;
export const MonstaChildCardWrapper = styled(Row)`
  margin: 10px;
  padding: 0 10px;
  column-gap: 16px;

  & > div {
    width: 100%;
  }

  & > div img {
    width: 100%;
    height: auto;
  }
`;
// Monsta Sales History Button

type MonstaSalesHistoryLabelType = {
  color?: string;
  hoverColor?: string;
};
const MonstaSalesHistoryLabelStyles = ({
  color = "--white",
  hoverColor = color,
}: MonstaSalesHistoryLabelType) => css`
  font-family: "Obelix Pro", sans serif;
  color: var(${color});
  font-size: 0.7rem;
  margin: auto 0 0 0;

  &:hover {
    color: var(${hoverColor});
  }
`;
export const MonstaSalesHistoryLabel = styled.span`
  ${MonstaSalesHistoryLabelStyles}
`;

const MonstaSalesHistoryClickableLabelStyles = ({
  color = "--white",
  hoverColor = color,
}: MonstaSalesHistoryLabelType) => css`
  font-family: "Obelix Pro", sans serif;
  color: var(${color});
  font-size: 0.7rem;
  cursor: pointer;
  margin: auto 0 0 0;

  &:hover {
    color: var(${hoverColor});
  }
`;
export const MonstaSalesHistoryClickableLabel = styled(Text, {
  shouldForwardProp: (props) => props !== "hoverColor",
})`
  ${MonstaSalesHistoryClickableLabelStyles}
`;
export const MonstaSalesHistorySeparator = styled(Text)`
  margin: 0 1rem;
  color: var(--monsta-gray-2);
`;
// modal
export const HistoryModalCloseIcon = styled(CloseOutlined)`
  color: var(--monsta-gray-2);
`;
export const StyledModal = styled(Modal)`
  min-width: 840px;
  background-color: ${(props: any) => props.theme.green1};
  .ant-modal-content {
    background-color: #0d4134;
    border-radius: 20px;
    padding: 16px;
  }
  @media screen and (max-width: 767px) {
    min-width: 100%;
  }
`;
export const HistoryTableWrapper = styled.div`
  background: #172924;
  border-radius: 16px;
  padding: 16px 0px 12px;
  min-height: 300px;
  width: 100%;
  overflow-x: scroll;
`;
export const ModalTitle = styled(Text)`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  margin: 6px auto 22px;
`;
export const ModalSubtitle = styled(Text)`
  font-size: 24px;
  font-weight: bold;
  padding: 16px 16px 20px;
  color: #ffb839;
`;
export const ModalTable = styled(Table)`
  color: white;
  min-width: 800px;
  & .ant-table {
    background-color: transparent;
  }
  & .ant-table-thead .ant-table-cell {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: white !important;
  }
  & .ant-table-cell {
    background-color: transparent;
    border: 0;
    color: var(--monsta-gray-2);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    &:hover: {
      color: var(--monsta-gray-2);
      background-color: transparent;
    }
  }
  & .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: transparent;
    border: 0;
    color: var(--monsta-gray-2);
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 4px 16px;
  }
  .ant-table-container table > thead > tr:first-of-type th:first-of-type {
    width: 60%;
  }
`;
export const MonstaTableColumn = styled(Column)`
  background-color: transparent;
  border: 0;
  & .ant-table-cell {
    background-color: transparent;
    border: 0;
  }
`;
export const MonstaSalesHistoryButtonsWrapper = styled(CenteredRow)`
  margin: auto;
  width: fit-content;
  padding: 0.5rem 0 0 0;
  color: var(--monsta-gray-2);
  border-bottom: 1px solid var(--monsta-gray-2);
`;
type SpacerStylesType = {
  size: number;
};
const SpacerStyles = ({ size = 16 }: SpacerStylesType) => css`
  height: ${size}px;
`;
export const Spacer = styled.div`
  ${SpacerStyles}
`;

export const MonstaPrice = styled.div`
  height: 20px;
  color: #fee615;
  font-size: 17px;
  display: flex;
  align-items: baseline;
  column-gap: 5px;
  & > span:first-of-type {
    font-size: 17px;
  }
  & > span:last-of-type {
    font-size: 12px;
  }
`;

// Monsta No Parent Label
type MonstaNoParentLabelWrapperProps = {
  backgroundImage: string;
};
export const MonstaNoParentLabelWrapper = styled.div<MonstaNoParentLabelWrapperProps>`
  background-image: url(${(props) => props.backgroundImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 1.5rem 1rem;
  text-align: center;
`;

export const MonstaNoParentLabelSubtitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.6rem;
`;

export const MonstaNoParentLabelSubtitleLine = styled.div`
  width: 60px;
  border-bottom: 2px solid white;
`;

export const MonstaNoParentLabelSubtitleText = styled.p`
  padding: 0;
  margin: 0;
  letter-spacing: 3px;
  font-size: 0.7rem;
  background: -webkit-linear-gradient(white, #d1d6db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const MonstaNoParentLabelTitleText = styled.p`
  padding: 0;
  margin: 0;
  font-family: "Obelix Pro", sans serif;
  font-size: 2rem;
  font-weight: bold;
  background: -webkit-linear-gradient(white, #d1d6db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const UnityContentWrapper = styled.div`
  position: relative;
  .ant-spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .unity {
    width: 300px;
    height: 300px;
  }
`;
