import { MonstaInfoType } from "../models/monsta";
import { formatBigNumberToFixed } from "../utils/formatBalance";
import { toBinaryGenes } from "./toBinaryGenes";

type SimpleMonsta = {
  id: string;
  genes: any;
  auctionStatus?: string | null;
  timestamp?: string;
  monstaEvents?: Array<any>;
};

export const extractMonsta = (monsta: SimpleMonsta): MonstaInfoType => {
  const geneBin = toBinaryGenes(monsta.genes);
  const auctionStatus = monsta.auctionStatus;
  const id = parseInt(monsta.id);
  const isAdult = parseInt(geneBin.substring(8, 11));
  const cloneUsage = parseInt(geneBin.substr(11, 3), 2);
  const classType = parseInt(geneBin.substr(64, 6), 2);
  const transferable = parseInt(geneBin.substr(14, 1), 2) === 0;
  const tradable = parseInt(geneBin.substr(15, 1), 2) === 0;

  const now = new Date();
  const secondsSinceEpoch = Math.round(now.getTime() / 1000);

  const isMonstaEventPresent =
    monsta && monsta.monstaEvents && monsta.monstaEvents.length > 0;
  let currentPrice: any;

  if (isMonstaEventPresent && monsta.monstaEvents && monsta.monstaEvents[0]) {
    const secondsPassed = secondsSinceEpoch - monsta.monstaEvents[0].timestamp;
    const isPassedDuration =
      secondsPassed > parseInt(monsta.monstaEvents[0].duration);

    const isFixedPrice =
      monsta.monstaEvents[0].endingPrice ===
      monsta.monstaEvents[0].startingPrice;

    if (isFixedPrice) {
      currentPrice = formatBigNumberToFixed(
        monsta.monstaEvents[0].endingPrice,
        0,
        8
      ).toString();
    } else {
      const currentPriceChange = isPassedDuration
        ? formatBigNumberToFixed(monsta.monstaEvents[0].endingPrice, 0, 8)
        : (formatBigNumberToFixed(monsta.monstaEvents[0].startingPrice, 0, 8) -
            formatBigNumberToFixed(monsta.monstaEvents[0].endingPrice, 0, 8)) *
          (secondsPassed / parseInt(monsta.monstaEvents[0].duration));
      currentPrice = isPassedDuration
        ? parseFloat(
            formatBigNumberToFixed(monsta.monstaEvents[0].endingPrice, 0, 8)
          )
        : (formatBigNumberToFixed(monsta.monstaEvents[0].startingPrice, 0, 8) -
            formatBigNumberToFixed(monsta.monstaEvents[0].endingPrice, 0, 8)) *
          (secondsPassed / parseInt(monsta.monstaEvents[0].duration));
      currentPrice = isPassedDuration
        ? parseFloat(
            formatBigNumberToFixed(monsta.monstaEvents[0].endingPrice, 0, 8)
          )
        : parseFloat(
            (formatBigNumberToFixed(
              monsta.monstaEvents[0].startingPrice,
              0,
              8
            ) - currentPriceChange.toFixed(4)) as any
          );
    }
  }
  let timeLeft = null;
  if (!isAdult && monsta.timestamp) {
    timeLeft = getIncubatorTimeLeft(monsta.timestamp);
  }

  return {
    id,
    auctionStatus,
    isAdult,
    cloneUsage,
    classType,
    currentPrice,
    transferable,
    tradable,
    timeLeft,
  };
};

export const getCurrentEstimatedPrice = (monstaEvent: any) => {
  let currentPrice = 0;
  if (monstaEvent) {
    const now = new Date();
    const secondsSinceEpoch = Math.round(now.getTime() / 1000);
    const secondsPassed = secondsSinceEpoch - monstaEvent.timestamp;
    const isPassedDuration = secondsPassed > parseInt(monstaEvent.duration);
    const currentPriceChange = isPassedDuration
      ? parseFloat(formatBigNumberToFixed(monstaEvent.endingPrice, 0, 8))
      : (parseFloat(formatBigNumberToFixed(monstaEvent.startingPrice, 0, 8)) -
          parseFloat(formatBigNumberToFixed(monstaEvent.endingPrice, 0, 8))) *
        (secondsPassed / parseInt(monstaEvent.duration));
    currentPrice = isPassedDuration
      ? parseFloat(formatBigNumberToFixed(monstaEvent.endingPrice, 0, 8))
      : parseFloat(formatBigNumberToFixed(monstaEvent.startingPrice, 0, 8)) -
        currentPriceChange;
  }
  return currentPrice;
};

export const getIncubatorTimeLeft = (timestamp: string) => {
  const secondsLeft =
    120 * 3600 - Math.floor(new Date().getTime() / 1000 - parseInt(timestamp));
  const hoursLeft = secondsLeft > 0 ? Math.floor(secondsLeft / 3600) : 0;
  const minsLeft =
    secondsLeft > 0 ? Math.floor((secondsLeft - hoursLeft * 3600) / 60) : 0;

  if (secondsLeft > 0) {
    return `${hoursLeft > 0 ? hoursLeft + " Hours" : ""} ${
      minsLeft > 0 ? minsLeft + " Mins" : ""
    }`;
  } else {
    return null;
  }
};

export const getMonstaType = (id: number) => {
  if (id <= 4089) {
    return "Inception Monsta";
  } else if (id >= 4090 && id <= 14099) {
    return "Chrono Zero";
  } else if (id >= 14100 && id <= 214099) {
    return "Soul Bond";
  } else {
    return "Normal";
  }
};
