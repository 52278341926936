import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Tabs, Space, Typography, message } from "antd";
import { useLazyQuery } from "@apollo/client";
import { useWeb3React } from "@web3-react/core";
import {
  GET_USER_MONSTA,
  GET_USER_AUCTION,
} from "../../../graphql/queries/marketplace";
import {
  GetUserMonstaQuery,
  GetUserMonstaQueryVariables,
  GetUserAuctionQuery,
  GetUserAuctionQueryVariables,
  MonstaEventType,
  Monsta_OrderBy,
  OrderDirection,
} from "../../../graphql/generated-types";
import { MonstaType } from "../../../models/monsta";
import { extractMonsta } from "../../../utils/monstaHelpers";
import UserMonstaCards from "./UserMonstaCards";
import {
  InventoryWrapper,
  ItemCount,
  TabPaneHeader,
  RefreshButton,
} from "../style";
import Loading from "../../../components/Loading/Loading";
import { marketplaceClient } from "../../../graphql/apolloClient";
import { TabComponent } from "../../../components/TabComponent";
import InceptionEggCardList from "./InceptionEggCardList";
import { useMonstaPreviewApi } from "../../../hooks/useMonsta";
import { toBinaryGenes } from "../../../utils/toBinaryGenes";
import UserAuctionList from "./UserAuctionList";
import {
  UserAuctionMonsta,
  UserAuctionMonstaWithImage,
} from "./UserAuctionList/types";
import { fetchAggregate } from "../../../services/marketplace-aggregator-api";
import { parseSvg } from "../../../utils/parseSvg";

const MONSTAS_LOAD_MORE_SIZE = 25;
const AUCTIONS_LOAD_MORE_SIZE = 25;
const REFRESH_TIME_INTERVAL = 10000; // 10 seconds
const { TabPane } = Tabs;
const { Text } = Typography;

type InventoryProps = {
  inceptionEgg: number;
  setOwnedMonstas: (n: number) => void;
  setListedMonstas: (n: number) => void;
};

const Inventory = ({
  inceptionEgg,
  setOwnedMonstas,
  setListedMonstas,
}: InventoryProps) => {
  const TAB_MONSTA_EGG = "1";
  const TAB_AUCTION = "2";
  const TAB_IMEGG = "3";
  const { account } = useWeb3React();
  const { preview } = useMonstaPreviewApi();
  const location = useLocation();
  const initialState = location.state || ({} as any);

  const [pageLoading, setPageLoading] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const [monstas, setMonstas] = useState<MonstaType[]>([]);
  const [monstasLoading, setMonstasLoading] = useState(true);
  const [monstasTiming, setMonstasTiming] = useState(false);
  const [monstasSkip, setMonstasSkip] = useState(0);
  const [monstasFirst, setMonstasFirst] = useState(
    initialState.monstasCount || MONSTAS_LOAD_MORE_SIZE
  );
  const [monstasMaxReached, setMonstasMaxReached] = useState(false);

  const [auctionData, setAuctionData] = useState<UserAuctionMonstaWithImage[]>(
    []
  );
  const [auctionLoading, setAuctionLoading] = useState(true);
  const [auctionsTiming, setAuctionsTiming] = useState(false);
  const [auctionsSkip, setAuctionsSkip] = useState(0);
  const [auctionsMaxReached, setAuctionsMaxReached] = useState(false);

  const [
    fetchUserMonsta,
    { data: userMonstaData, loading: userMonstaLoading },
  ] = useLazyQuery<GetUserMonstaQuery, GetUserMonstaQueryVariables>(
    GET_USER_MONSTA,
    {
      variables: {
        orderBy: Monsta_OrderBy.Token,
        orderDirection: OrderDirection.Asc,
        where: { owner: account?.toLowerCase() || "" },
        first: monstasFirst,
        skip: monstasSkip,
      },
      client: marketplaceClient,
      fetchPolicy: "cache-and-network",
    }
  );

  const [
    fetchUserAuction,
    { data: userAuctionData, loading: userAuctionLoading },
  ] = useLazyQuery<GetUserAuctionQuery, GetUserAuctionQueryVariables>(
    GET_USER_AUCTION,
    {
      variables: {
        where: {
          auctionStatus_in: [MonstaEventType.UserAuctionCreated],
          lastSeller: account?.toLowerCase() || "",
        },
        first: AUCTIONS_LOAD_MORE_SIZE,
        skip: auctionsSkip,
      },
      client: marketplaceClient,
      fetchPolicy: "cache-and-network",
    }
  );

  const refreshUserMonstas = () => {
    if (monstasTiming) {
      message.warn("You may refresh after 10 seconds");
    } else {
      setPageLoading(true);
      setMonstas([]);
      setMonstasSkip(0);
      fetchUserMonsta();
      setMonstasTiming(true);
    }
  };

  const refreshUserAuctions = () => {
    if (auctionsTiming) {
      message.warn("You may refresh after 10 seconds");
    } else {
      setAuctionLoading(true);
      setAuctionData([]);
      setAuctionsSkip(0);
      fetchUserAuction();
      setAuctionsTiming(true);
    }
  };

  useEffect(() => {
    const fetchInventoryAggregate = async () => {
      try {
        const ownedMonstasFilter = { owner: account?.toLowerCase() || "" };
        const listedMonstasFilter = {
          auctionStatus_in: [MonstaEventType.UserAuctionCreated],
          lastSeller: account?.toLowerCase() || "",
        };
        const ownedMonstasResponse = await fetchAggregate(ownedMonstasFilter);
        const listedMonstasResponse = await fetchAggregate(listedMonstasFilter);
        if (ownedMonstasResponse.data) {
          setOwnedMonstas(ownedMonstasResponse.data.result);
        }
        if (listedMonstasResponse.data) {
          setListedMonstas(listedMonstasResponse.data.result);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (account) {
      fetchInventoryAggregate();
    }
  }, [account]);

  const onLoadMoreMonsta = () => {
    setMonstasLoading(true);
    setMonstasFirst(MONSTAS_LOAD_MORE_SIZE);
    setMonstasSkip(monstas.length);
  };

  const onLoadMoreAuction = () => {
    setAuctionLoading(true);
    setAuctionsSkip(auctionsSkip + AUCTIONS_LOAD_MORE_SIZE);
  };

  useEffect(() => {
    setMonstas([]);
    setMonstasSkip(0);
    setAuctionData([]);
    setAuctionsSkip(0);
  }, [account]);

  useEffect(() => {
    if (account) {
      setPageLoading(true);
      setMonstasMaxReached(false);
      fetchUserMonsta();
    } else {
      setPageLoading(false);
      setMonstasMaxReached(true);
      setMonstas([]);
    }
  }, [account, fetchUserMonsta]);

  useEffect(() => {
    const loadUserMonsta = async (monstaData: Promise<MonstaType>[]) => {
      try {
        const userMonstas = await Promise.all(monstaData);
        setMonstas((monstas) => [...monstas, ...userMonstas]);
      } catch (error: any) {
        message.error("Something went wrong while loading your monsta:", error);
      } finally {
        setPageLoading(false);
        setMonstasLoading(false);
        scrollTo();
        setIsInitialized(true);
        setTimeout(() => {
          setMonstasTiming(false);
        }, REFRESH_TIME_INTERVAL);
      }
    };

    if (userMonstaData && !userMonstaLoading) {
      if (userMonstaData.monstas.length > 0) {
        let monstaData = userMonstaData.monstas.map(async (monsta) => {
          const monstaInfo = extractMonsta(monsta);
          const monstaSvg = await preview(toBinaryGenes(monsta.genes));
          return {
            ...monstaInfo,
            img: parseSvg(monstaSvg),
          };
        });
        loadUserMonsta(monstaData);
      } else {
        setPageLoading(false);
        setMonstasMaxReached(true);
      }
      if (userMonstaData.monstas.length < MONSTAS_LOAD_MORE_SIZE) {
        setMonstasMaxReached(true);
      }
    }
  }, [userMonstaData, preview, userMonstaLoading]);

  // Executed on mount or account change
  useEffect(() => {
    if (account) {
      setPageLoading(true);
      setAuctionsMaxReached(false);
      fetchUserAuction();
    } else {
      setPageLoading(false);
      setAuctionsMaxReached(true);
      setAuctionData([]);
    }
  }, [account, fetchUserAuction]);

  // On data change, on query complete
  useEffect(() => {
    const fn = async () => {
      const monstas = userAuctionData?.monstas;
      if (monstas) {
        const monstaWithImagePromises = monstas.map(
          async (monsta: UserAuctionMonsta) => {
            const monstaGeneBin = BigInt(monsta.genes)
              .toString(2)
              .padStart(256, "0");
            const monstaSvg = await preview(monstaGeneBin);
            return {
              img: monstaSvg,
              ...monsta,
            };
          }
        );
        const monstaWithImage = await Promise.all(monstaWithImagePromises);
        setAuctionData((auctionData) => [...auctionData, ...monstaWithImage]);
        setAuctionLoading(false);
        setTimeout(() => {
          setAuctionsTiming(false);
        }, REFRESH_TIME_INTERVAL);

        if (monstas.length < AUCTIONS_LOAD_MORE_SIZE) {
          setAuctionsMaxReached(true);
        }
      }
    };

    !userAuctionLoading && fn();
  }, [preview, userAuctionData, userAuctionLoading]);

  const scrollTo = () => {
    if (initialState.scrollTop && !isInitialized) {
      window.scrollTo({
        top: initialState.scrollTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <InventoryWrapper>
      <TabComponent defaultActiveKey={TAB_MONSTA_EGG} onChange={() => {}}>
        <TabPane
          tab={
            <Space direction="horizontal">
              <Text>MONSTA</Text>
            </Space>
          }
          key={TAB_MONSTA_EGG}
        >
          <TabPaneHeader>
            <ItemCount>
              {pageLoading ? "Loading..." : `${monstas.length || 0} Item(s)`}
            </ItemCount>
            <RefreshButton onClick={refreshUserMonstas} loading={pageLoading}>
              Refresh
            </RefreshButton>
          </TabPaneHeader>
          {pageLoading ? (
            <Loading centered={true} />
          ) : (
            <UserMonstaCards
              monstas={monstas}
              onLoadMore={onLoadMoreMonsta}
              monstasLoading={monstasLoading}
              monstasMaxReached={monstasMaxReached}
            />
          )}
        </TabPane>
        <TabPane
          tab={
            <Space direction="horizontal">
              <Text>Listed On Marketplace</Text>
            </Space>
          }
          key={TAB_AUCTION}
        >
          <UserAuctionList
            auctionData={auctionData}
            loading={auctionLoading}
            onLoadMore={onLoadMoreAuction}
            auctionsMaxReached={auctionsMaxReached}
            refreshUserAuctions={refreshUserAuctions}
          />
        </TabPane>
        <TabPane
          tab={
            <Space direction="horizontal">
              <Text>Inception Eggs</Text>
            </Space>
          }
          key={TAB_IMEGG}
        >
          <ItemCount>{inceptionEgg} Item(s)</ItemCount>
          <InceptionEggCardList eggs={inceptionEgg} />
        </TabPane>
      </TabComponent>
    </InventoryWrapper>
  );
};

export default Inventory;
