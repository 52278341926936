import DnaImage from "../../../../../assets/images/dna.svg";
import {
  MonstaCardContainer,
  MonstaCardImageWrapper,
  MonstaCardImage,
  MonstaCardInfoContainer,
  MonstaCardInfoText,
  MonstaCardCloneUsageContainer,
  MonstaCardCloneUsageDnaImage,
} from "./style";

type MonstaCardProps = {
  image: string;
  cloneUsage: number;
  id: string;
  isSelected?: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

const MonstaCard = (props: MonstaCardProps) => {
  const { image, cloneUsage, id, isSelected = false, onClick } = props;

  return (
    <MonstaCardContainer onClick={onClick}>
      <MonstaCardImageWrapper>
        <MonstaCardImage src={image} alt="Monsta" isSelected={isSelected} />
      </MonstaCardImageWrapper>
      <MonstaCardInfoContainer>
        <MonstaCardInfoText>#{id}</MonstaCardInfoText>
        <MonstaCardCloneUsageContainer>
          <MonstaCardCloneUsageDnaImage src={DnaImage} alt="DNA Icon" />
          <MonstaCardInfoText>Clone {cloneUsage}/4</MonstaCardInfoText>
        </MonstaCardCloneUsageContainer>
      </MonstaCardInfoContainer>
    </MonstaCardContainer>
  );
};

export default MonstaCard;
