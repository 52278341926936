import { useState } from "react";
import { Button } from "antd";
import CancelAuctionModal from "./CancelAuctionModal";
import {
  MonstaPreviewCardCol,
  MonstaPreviewCardIDCard,
  MonstaPreviewCardMonstaImage,
  ClonedStatus,
  MonstaTypeLabel,
  CardIcon,
  Divider,
  CenteredRow,
  MonstaCardWrapper,
  MonstaLoadMoreButtonWrapper,
} from "../../style";
import { UserAuctionMonstaWithImage } from "./types";
import { parseSvg } from "../../../../utils/parseSvg";
import { getMonstaType } from "../../../../utils/monstaHelpers";
import { MONSTA_CLASSES } from "../../../../models/constants";

type UserAuctionCardListProps = {
  monstas: UserAuctionMonstaWithImage[];
  loading: boolean;
  onLoadMore: () => void;
  auctionsMaxReached: boolean;
};

const UserAuctionCardList = (props: UserAuctionCardListProps) => {
  const { monstas, loading, onLoadMore, auctionsMaxReached } = props;

  const [selectedMonsta, setSelectedMonsta] =
    useState<UserAuctionMonstaWithImage | null>(null);
  const [showCancelAuctionModal, setShowCancelAuctionModal] = useState(false);

  const handleClickMonsta = (monsta: UserAuctionMonstaWithImage) => {
    setSelectedMonsta(monsta);
    setShowCancelAuctionModal(true);
  };

  return (
    <>
      <MonstaCardWrapper>
        {monstas.map((monsta) => (
          <MonstaPreviewCardCol
            key={monsta.id}
            onClick={() => handleClickMonsta(monsta)}
          >
            <MonstaPreviewCardMonstaImage
              src={parseSvg(monsta.img)}
              preview={false}
              adult={true}
            />
            <MonstaPreviewCardIDCard>#{monsta.id}</MonstaPreviewCardIDCard>
            {monsta.classType ? (
              <CardIcon
                src={MONSTA_CLASSES[monsta.classType].roundIcon}
                alt="icon"
              />
            ) : (
              <></>
            )}
            <CenteredRow>
              <MonstaTypeLabel>
                {getMonstaType(parseInt(monsta.id, 10))}
              </MonstaTypeLabel>
            </CenteredRow>
            <CenteredRow>
              <Divider />
            </CenteredRow>
            <CenteredRow>
              <ClonedStatus>Cloned {monsta.cloneUsage}/4</ClonedStatus>
            </CenteredRow>
          </MonstaPreviewCardCol>
        ))}
      </MonstaCardWrapper>
      <MonstaLoadMoreButtonWrapper hidden={auctionsMaxReached}>
        <Button
          shape="round"
          size="large"
          loading={loading}
          onClick={onLoadMore}
        >
          Load More
        </Button>
      </MonstaLoadMoreButtonWrapper>
      <CancelAuctionModal
        showCancelAuctionModal={showCancelAuctionModal}
        setShowCancelAuctionModal={setShowCancelAuctionModal}
        selectedMonsta={selectedMonsta}
      />
    </>
  );
};

export default UserAuctionCardList;
