import React from "react";
import { Typography, Alert } from "antd";

import { MonstaLabelWrapper } from "../style";
import MonstaStatsTable from "./MonstaStatsTable";
import { MonstaStatsType } from "../../../../../models/monsta";

const { Text } = Typography;

type MonstaStatsProps = {
  monstaStats: MonstaStatsType;
  error: string | null;
};

const MonstaStats = ({ monstaStats, error }: MonstaStatsProps) => {
  return (
    <div>
      {error && (
        <Alert
          message={`Problem fetching monsta stats : ${error}`}
          type="error"
          showIcon
          closable
        />
      )}
      <MonstaLabelWrapper>
        {" "}
        <Text>Monsta Stats</Text>
      </MonstaLabelWrapper>
      <MonstaStatsTable monstaStats={monstaStats} />
    </div>
  );
};

export default MonstaStats;
