import { useCallback, useEffect, useState, useRef } from "react";
import { Col, Carousel } from "antd";
import {
  WrapperRow,
  DashboardContainer,
  LeftCol,
  RightCol,
  ViewMoreButton,
  CarouselWrapper,
  CarouselItemWrapper,
  CarouselItemImage,
  WrapperRowGreen,
} from "./style";
import { useQuery } from "@apollo/client";
import { marketplaceClient } from "../../graphql/apolloClient";
import {
  OrderDirection,
  AuctionSucceed_OrderBy,
  GetAuctionCreatedQuery,
  GetAuctionSucceedsQuery,
  GetAuctionCreatedQueryVariables,
  GetAuctionSucceedsQueryVariables,
} from "../../graphql/generated-types";
import {
  GET_AUCTION_CREATED,
  GET_AUCTION_SUCCEEDS,
} from "../../graphql/queries/marketplace";
import { useLocation, useNavigate } from "react-router-dom";
import { extractMonsta } from "../../utils/monstaHelpers";
import { useMonstaPreviewApi } from "../../hooks/useMonsta";
import { toBinaryGenes } from "../../utils/toBinaryGenes";
import { MONSTA_CLASSES } from "../../models/constants";
import { formatToReadibleXstt } from "../../utils/formatBalance";
import { AuctionCreatedRes, AuctionSucceedRes } from "../../models/monsta";
import MonstaTable, {
  MonstaTableType,
  TableData,
} from "./components/MonstaTable";
import Footer from "../../components/Footer";
import { ANNOUNCEMENT_SLIDES, ANNOUNCEMENT_SLIDES_MOBILE } from "./constants";
import MonstaVolume from "./components/MonstaVolume";
import CarouselArrow from "./components/CarouselArrow";

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { preview } = useMonstaPreviewApi();
  const [soldDataLoading, setSoldDataLoading] = useState(false);
  const [listedDataLoading, setListedDataLoading] = useState(false);
  const [soldTableData, setSoldTableData] = useState<TableData[]>([]);
  const [listedTableData, setListedTableData] = useState<TableData[]>([]);
  const componentMounted = useRef(true);
  const abortController = new AbortController();
  const query = new URLSearchParams(location.search);
  const prevType = query.get("type");
  const prevKey = query.get("key");

  // Handling Window Width State logic for carousel images
  const [width, setWidth] = useState<number>(window.innerWidth);

  const resizeHandler = () => {
    const width = window.innerWidth;
    setWidth(width);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (prevType && prevKey) {
      if (
        (prevType === MonstaTableType.Listed && listedTableData.length) ||
        (prevType === MonstaTableType.Sold && soldTableData.length)
      ) {
        const ele = document.querySelector(`[data-row-key="${prevKey}"]`);

        if (ele) {
          ele.scrollIntoView();
        }
      }
    }
  }, [prevType, prevKey, soldTableData.length, listedTableData.length]);

  const { loading: listedResLoading, data: listedMonstasRes } = useQuery<
    GetAuctionCreatedQuery,
    GetAuctionCreatedQueryVariables
  >(GET_AUCTION_CREATED, {
    variables: {
      first: 50,
    },
    client: marketplaceClient,
  });

  const { loading: soldResLoading, data: soldMonstasRes } = useQuery<
    GetAuctionSucceedsQuery,
    GetAuctionSucceedsQueryVariables
  >(GET_AUCTION_SUCCEEDS, {
    variables: {
      orderBy: AuctionSucceed_OrderBy.Timestamp,
      orderDirection: OrderDirection.Desc,
      first: 50,
    },
    client: marketplaceClient,
  });

  const getTableData = useCallback(
    async (data: AuctionCreatedRes[] | AuctionSucceedRes[]) => {
      const promises = data.map(async (auctionMonsta) => {
        const monstaInfo = extractMonsta(auctionMonsta.token);
        const address =
          (auctionMonsta as AuctionCreatedRes).seller ||
          (auctionMonsta as AuctionSucceedRes).winner;
        const price = formatToReadibleXstt(
          (auctionMonsta as AuctionCreatedRes).endingPrice ||
            (auctionMonsta as AuctionSucceedRes).totalPrice
        );
        let monstaAuction = {} as TableData;
        monstaAuction = {
          key: auctionMonsta.id,
          id: auctionMonsta.token.id,
          img: await preview(toBinaryGenes(auctionMonsta.token.genes)),
          cloneCount: monstaInfo.cloneUsage,
          className: MONSTA_CLASSES[monstaInfo.classType].name,
          price,
          address,
          timeAgo: parseInt(auctionMonsta.timestamp),
        };
        return monstaAuction;
      });
      const retVal = await Promise.all(promises);
      return retVal;
    },
    [preview]
  );

  const openInNewTab = (url: string) => {
    if (url !== "#") {
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  useEffect(() => {
    const loadMonstas = async (monstaData: AuctionCreatedRes[]) => {
      const monstas = await getTableData(monstaData);
      if (componentMounted.current) {
        setListedTableData(monstas);
        setListedDataLoading(false);
      }
    };

    if (listedMonstasRes) {
      setListedDataLoading(true);
      loadMonstas(listedMonstasRes.auctionCreates);
    }
  }, [listedMonstasRes, getTableData]);

  useEffect(() => {
    const loadMonstas = async (monstaData: AuctionSucceedRes[]) => {
      const monstas = await getTableData(monstaData);
      if (componentMounted.current) {
        setSoldTableData(monstas);
        setSoldDataLoading(false);
      }
    };

    if (soldMonstasRes) {
      setSoldDataLoading(true);
      loadMonstas(soldMonstasRes.auctionSucceeds);
    }
  }, [soldMonstasRes, getTableData]);

  // unmount
  useEffect(
    () => () => {
      componentMounted.current = false;
      abortController.abort();
    },
    []
  );

  return (
    <>
      {/* Temporary -- Hide dashboard announcement carousel for 2.3.0 release */}
      <CarouselWrapper>
        <Carousel
          arrows
          prevArrow={<CarouselArrow direction="left" />}
          nextArrow={<CarouselArrow direction="right" />}
        >
          {(!width || width >= 600) &&
            ANNOUNCEMENT_SLIDES.map((bannerImage, index) => (
              <CarouselItemWrapper
                key={index}
                onClick={() => openInNewTab(bannerImage.url)}
              >
                <CarouselItemImage src={bannerImage.img} />
              </CarouselItemWrapper>
            ))}
          {width &&
            width < 600 &&
            ANNOUNCEMENT_SLIDES_MOBILE.map((bannerImage, index) => (
              <CarouselItemWrapper
                key={index}
                onClick={() => openInNewTab(bannerImage.url)}
              >
                <CarouselItemImage src={bannerImage.img} />
              </CarouselItemWrapper>
            ))}
        </Carousel>
      </CarouselWrapper>
      <DashboardContainer>
        <Col flex="auto">
          <WrapperRowGreen justify="start">
            <MonstaVolume />
          </WrapperRowGreen>
          <WrapperRow>
            <LeftCol xs={24} lg={12}>
              <MonstaTable
                title={"Recently Listed"}
                loading={listedResLoading || listedDataLoading}
                data={listedTableData}
                dataType={MonstaTableType.Listed}
              />
            </LeftCol>
            <RightCol xs={24} lg={12}>
              <MonstaTable
                title={"Recently Sold"}
                loading={soldResLoading || soldDataLoading}
                data={soldTableData}
                dataType={MonstaTableType.Sold}
              />
            </RightCol>
          </WrapperRow>
          <WrapperRow justify="center">
            <ViewMoreButton onClick={() => navigate("/marketplace")}>
              View more on Marketplace
            </ViewMoreButton>
          </WrapperRow>
        </Col>
        <Col flex="200px">
          <Footer />
        </Col>
      </DashboardContainer>
    </>
  );
};

export default Dashboard;
